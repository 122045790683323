import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    container: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      marginBottom: '1rem',
    },
    flexRowSpaceBetween: {
      ...theme.flexRowCenter,
      justifyContent: 'start',
      marginBottom: '1rem',
      flexWrap: 'wrap',
      '& > *': {
        margin: '0.5em',
      },
    },
  })

export default styles

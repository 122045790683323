const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  hasBorderBottom: {
    borderBottom: '1px solid',
    paddingBottom: theme.spacing(1),
  },
  iconLeft: {
    padding: theme.spacing(1 / 2),
    border: '2px solid',
    borderRadius: '100%',
    '& .icon-funds': {
      fontSize: '1.25rem',
    },
    alignSelf: 'baseline',
  },
  benefitsContainer: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  benefitsLabel: {
    fontSize: '0.75rem',
    width: '100%',
    fontWeight: 700,
  },
  benefitsValues: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1 / 3),
  },
  benefitsValue: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'left',
  },
  factorBenefits: {
    width: '100%',
    '& span:nth-child(1)': {
      fontSize: '0.75rem',
    },
    '& span:nth-child(2)': {
      fontWeight: 700,
    },
  },
  labelBenefits: {
    width: '100%',
    fontSize: '0.75rem',
  },
})

export default styles

const styles = theme => ({
  formControl: {
    width: '10rem',
    marginLeft: '1rem',
    '& > div > div:first-child': {
      padding: '9px 14px',
    },
  },
  itemRow: {
    '& img': {
      width: '5rem',
      maxHeight: '5rem',
    },
  },
  label: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#333',
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    textAlign: 'left',
    textDecoration: 'none',
    '&:hover, &:focus': {
      color: theme.palette.common.black,
      textDecoration: 'underline',
      outline: 'none',
    },
  },
  middle: {
    gridArea: 'middle',
    gridColumn: 2,
    gridRow: 2,
  },
  tableHeader: {
    padding: theme.spacing(1),
    display: 'flex',
  },
  tableTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    width: '50%',
    '& > span': {
      fontSize: '31px',
      marginRight: theme.spacing(1),
    },
    '& > h3': {
      width: '100%',
      fontWeight: '500',
    },
  },
  top: {
    gridArea: 'top',
    gridColumn: 2,
    gridRow: 2,
    textAlign: 'center',
  },
  topContainer: {
    display: 'flex',
  },
  hours: {
    fontSize: '0.75rem',
    fontWeight: 400,
  },
})

export default styles

import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    appLogo: {
      height: '58px',
    },
    button: {
      margin: theme.spacing(1),
      color: 'white',
      borderColor: 'white',
      fontWeight: 'bold',
    },
    footer: {
      gridArea: 'footer',
      gridTemplateColumns: 'auto 960px auto',
      gridTemplateRows: '1fr',
      gridTemplateAreas: '". footerContent ."',
      padding: '2rem 15px',
      backgroundColor: theme.palette.secondary.main ? theme.palette.secondary.main : '#494D4E',
      color: theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText : '#fff',
      '@media(min-width:993px)': {
        display: 'grid',
      }
    },
    footerContent: {
      gridArea: 'footerContent',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText : '#fff',
      '@media(min-width:768px)': {
        display: 'flex',
      }
    },
    footerNavigationLink: {
      justifyContent: "space-around",
      alignItems: "center",
      backgroundColor: theme.palette.secondary.main ? theme.palette.secondary.main : '#494D4E',
      color: theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText : '#fff',
      '@media(min-width:768px)': {
        display: 'flex',
      }
    },
    link: {
      backgroundColor: theme.palette.secondary.main ? theme.palette.secondary.main : '#494D4E',
      color: theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText : '#fff',

    },
    link1: {
      backgroundColor: theme.palette.secondary.main ? theme.palette.secondary.main : '#494D4E',
      color: theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText : '#fff',

    },
    summary: {
      marginBottom: '16px',
      '@media(min-width:768px)': {
        width: '170px',
      }
    },
    summaryIcon: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& b': {
        fontSize: '40px',
      },
      '& span': {
        fontSize: '32px',
        fontFamily: '"HelveticaNeueCondensedBold", "Helvetica Neue", sans-serif',
        fontStretch: 'condensed',
        fontWeight: 'bold',
      },
    },
    summaryText: {
      fontSize: '12px',
      paddingLeft: 44,
      display: 'inline-block',
    },
    links: {
      gridArea: 'links',
      gridTemplateColumns: 'auto 960px auto',
      gridTemplateRows:
        '[linksContent] 100% [linksContentEnd customizedMessageFooter] 100% [customizedMessageFooterEnd]',
      gridTemplateAreas: '". linksContent ." ". customizedMessageFooter ."',
      // padding: '1rem 0',
      // backgroundColor: '#f0f0f0',
      fontSize: '11px',
      transition: 'all .2s ease-in-out',
      backgroundColor: theme.palette.secondary.main ? theme.palette.secondary.main : '#494D4E',
      color: theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText : '#fff',
      padding: "1rem 15px",
      '@media(min-width:993px)': {
        display: 'grid',
      }
    },
    linksContent: {
      gridArea: 'linksContent',

      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& span': {
        color: 'rgba(0, 0, 0, 0.5)',
      },
      '& a': {
        color: 'rgba(0, 0, 0, 0.5)',
        fontWeight: 'bolder',
        textDecoration: 'none',
      },
      '& a:visited': {
        color: 'rgba(0, 0, 0, 0.5)',
      },
      '@media(min-width:768px)': {
        display: 'flex',
      }
    },
    customizedMessageFooter: {
      gridArea: 'customizedMessageFooter',
      display: 'flex',
      color: 'darkgray',
      marginBottom: '1rem',
      fontSize: '14px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    copyrightButton: {
      // color: 'rgba(0, 0, 0, 0.5)',
      color: theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText : '#fff',
      textAlign: 'left',
      fontWeight: 'bolder',
      textDecoration: 'none',
      display: 'block',
      marginBottom: '8px',
      '&:hover': {
        textDecoration: 'none',
      },
      '&:focus': {
        textDecoration: 'none',
      },
    },
    linkButton: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      textDecoration: 'underline',

      margin: '0',
      padding: '0',

      // color: props => parseColor(theme, props.color),
      color: '#383838'
    },
    flat: {
      fontWeight: '700',
      textDecoration: 'none',
      fontSize: theme.spacing(1.75),
    },

    footerNavigationLink: {
      justifyContent: "space-around",
      alignItems: "center",
      backgroundColor: theme.palette.secondary.main ? theme.palette.secondary.main : '#494D4E',
      borderTop: "1px solid",
      color: theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText : '#fff',
      '& > h4': {
        color: "rgba(132,132,132,1)",
      },

      '@media(min-width:768px)': {
        display: 'flex',
      }

    },
    flexForLinks: {
      display: 'flex',
      flexDirection: "column",
      color: theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText : '#fff',
      backgroundColor: theme.palette.secondary.main ? theme.palette.secondary.main : '#494D4E',
      marginBottom: "10px",
      padding: "0 15px",
      '& > h4': {
        color:  theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText :  "rgba(132,132,132,1)",
      },

    },
    footerLogo: {
      display: "flex",
      flexDirection: "column",
      padding: "0 15px",
    },

    customFooter: {
      color: theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText : '#fff',
      backgroundColor: theme.palette.secondary.main ? theme.palette.secondary.main : '#494D4E',
      padding: "0 15px 16px",
      wordBreak: 'break-word',
      '& > span': {
        color: theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText : '#fff',
      },
      '& > span > p': {
        marginTop: '0',
        marginBottom: '0',
        padding: '8px 0px'
      },
      '@media(min-width:678px)': {
        padding: "75px",
      }

    }





  })

export default styles

import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import styles from './View.Style'

const IdeasGrid = props => {
  const { classes, children, countSquare, length } = props
  return (
    <div className={classes.container}>
      {countSquare && (
        <div className={classes.countCard}>
          <i className="icon-idea" />
          <h1>
            {length} {length === 1 ? i18n.t('SINGULAR_IDEA') : i18n.t('IDEAS')}
          </h1>
        </div>
      )}
      {children}
    </div>
  )
}

IdeasGrid.defaultProps = {
  countSquare: true,
  length: 0,
}

IdeasGrid.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    countCard: PropTypes.string.isRequired,
  }).isRequired,
  countSquare: PropTypes.bool,
  length: PropTypes.number,
}

export default withStyles(styles)(IdeasGrid)

import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    listContainer: {
      width: '55%',
      '& ul': {
        borderRadius: '4px',
        listStyle: 'none',
        padding: '10px 0px',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'nowrap',
        margin: 0,
      },
      '& ul > li': {
        flexGrow: 1,
        textAlign: 'center',
      },
      '& li:first-child > a': {
        borderRadius: '4px 0 0 4px',
        overflow: 'hidden',
      },
      '& li:last-child > a': {
        borderRadius: '0 4px 4px 0',
        overflow: 'hidden',
        borderRight: '0',
      },
      '& li > a': {
        display: 'flex',
        borderRight: '1px solid #dedede',
        background: theme.palette.common.white,
        borderRadius: 0,
        color: theme.palette.common.black,
        lineHeight: '50px',
        textDecoration: 'none',
        boxSizing: 'border-box',
        padding: 0,
      },
      '& li > a > span': {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 10px',
        color: theme.palette.common.black,
        boxSizing: 'border-box',
      },
      '& li > a:hover': {
        backgroundColor: theme.palette.common.white,
      },
      '& li > a > span > [class^="icon-"]': {
        fontSize: '20px',
      },
      '& li > a > span > b': {
        marginRight: '10%',
      },
      '& li > a > span > span': {
        fontSize: '0.85rem',
        textTransform: 'none',
      },
    },
    domainMenuContainer: {
      display: 'flex',
    },
  })

export default styles

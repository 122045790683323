export const ADD_IMAGE_STARTED = 'ADD_IMAGE_STARTED'
export const ADD_IMAGE_SUCCESS = 'ADD_IMAGE_SUCCESS'
export const ADD_IMAGE_FAILURE = 'ADD_IMAGE_FAILURE'

export const GET_KPIS_STARTED = 'GET_KPIS_STARTED'
export const GET_KPIS_SUCCESS = 'GET_KPIS_SUCCESS'
export const GET_KPIS_FAILURE = 'GET_KPIS_FAILURE'

export const POST_KPIS_STARTED = 'POST_KPIS_STARTED'
export const POST_KPIS_SUCCESS = 'POST_KPIS_SUCCESS'
export const POST_KPIS_FAILURE = 'POST_KPIS_FAILURE'

export const POST_NEW_KPI_STARTED = 'POST_NEW_KPI_STARTED'
export const POST_NEW_KPI_SUCCESS = 'POST_NEW_KPI_SUCCESS'
export const POST_NEW_KPI_FAILURE = 'POST_NEW_KPI_FAILURE'

export const UPDATE_KPI_SUCCESS_STARTED = 'UPDATE_KPI_SUCCESS_STARTED'
export const UPDATE_KPI_SUCCESS_SUCCESS = 'UPDATE_KPI_SUCCESS_SUCCESS'
export const UPDATE_KPI_SUCCESS_FAILURE = 'UPDATE_KPI_SUCCESS_FAILURE'

export const DELETE_KPIS_STARTED = 'DELETE_KPIS_STARTED'
export const DELETE_KPIS_SUCCESS = 'DELETE_KPIS_SUCCESS'
export const DELETE_KPIS_FAILURE = 'DELETE_KPIS_FAILURE'

export const DELETE_IDEA_IMAGE_STARTED = 'DELETE_IDEA_IMAGE_STARTED'
export const DELETE_IDEA_IMAGE_SUCCESS = 'DELETE_IDEA_IMAGE_SUCCESS'
export const DELETE_IDEA_IMAGE_FAILURE = 'DELETE_IDEA_IMAGE_FAILURE'

export const SAVE_IDEA_DRAFT_STARTED = 'SAVE_IDEA_DRAFT_STARTED'
export const SAVE_IDEA_DRAFT_SUCCESS = 'SAVE_IDEA_DRAFT_SUCCESS'
export const SAVE_IDEA_DRAFT_FAILURE = 'SAVE_IDEA_DRAFT_FAILURE'

export const EDIT_IDEA_STARTED = 'EDIT_IDEA_STARTED'
export const EDIT_IDEA_SUCCESS = 'EDIT_IDEA_SUCCESS'
export const EDIT_IDEA_FAILURE = 'EDIT_IDEA_FAILURE'

export const CREATE_IDEA_STARTED = 'CREATE_IDEA_STARTED'
export const CREATE_IDEA_SUCCESS = 'CREATE_IDEA_SUCCESS'
export const CREATE_IDEA_FAILURE = 'CREATE_IDEA_FAILURE'

export const SEARCH_TITLE_STARTED = 'SEARCH_TITLE_STARTED'
export const SEARCH_TITLE_SUCCESS = 'SEARCH_TITLE_SUCCESS'
export const SEARCH_TITLE_FAILURE = 'SEARCH_TITLE_FAILURE'

export const GET_IDEA_STARTED = 'GET_IDEA_STARTED'
export const GET_IDEA_SUCCESS = 'GET_IDEA_SUCCESS'
export const GET_IDEA_FAILURE = 'GET_IDEA_FAILURE'

export const REPORT_IDEA_STARTED = 'REPORT_IDEA_STARTED'
export const REPORT_IDEA_SUCCESS = 'REPORT_IDEA_SUCCESS'
export const REPORT_IDEA_FAILURE = 'REPORT_IDEA_FAILURE'

export const UPLOAD_DOCUMENT_STARTED = 'UPLOAD_DOCUMENT_STARTED'
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS'
export const GET_URL_UPLOAD_FILE = 'GET_URL_UPLOAD_FILE'
export const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE'

export const SET_IDEA_FORM_TITLE = 'SET_IDEA_FORM_TITLE'
export const SET_IDEA_FORM_IMAGES = 'SET_IDEA_FORM_IMAGES'
export const SET_IDEA_FORM_DESCRIPTION = 'SET_IDEA_FORM_DESCRIPTION'
export const SET_IDEA_FORM_VIDEO = 'SET_IDEA_FORM_VIDEO'
export const SET_IDEA_FORM_CHALLENGE = 'SET_IDEA_FORM_CHALLENGE'
export const SET_IDEA_FORM_ORGANIZATION = 'SET_IDEA_FORM_ORGANIZATION'
export const SET_IDEA_FORM_TYPE = 'SET_IDEA_FORM_TYPE'
export const SET_IDEA_FORM_GOAL = 'SET_IDEA_FORM_GOAL'
export const SET_IDEA_FORM_PILOT_ESTIMATED_QUANTITY = 'SET_IDEA_FORM_PILOT_ESTIMATED_QUANTITY'
export const SET_IDEA_FORM_PILOT_ESTIMATED_HOURS = 'SET_IDEA_FORM_PILOT_ESTIMATED_HOURS'
export const SET_IDEA_FORM_PILOT_ESTIMATED_TIME = 'SET_IDEA_FORM_PILOT_ESTIMATED_TIME'
export const SET_IDEA_FORM_PILOT_ESTIMATED_TIME_TYPE = 'SET_IDEA_FORM_PILOT_ESTIMATED_TIME_TYPE'
export const SET_IDEA_FORM_IMPLEMENT_ESTIMATED_QUANTITY =
  'SET_IDEA_FORM_IMPLEMENT_ESTIMATED_QUANTITY'
export const SET_IDEA_FORM_IMPLEMENT_ESTIMATED_HOURS = 'SET_IDEA_FORM_IMPLEMENT_ESTIMATED_HOURS'
export const SET_IDEA_FORM_IMPLEMENT_ESTIMATED_TIME = 'SET_IDEA_FORM_IMPLEMENT_ESTIMATED_TIME'
export const SET_IDEA_FORM_IMPLEMENT_ESTIMATED_TIME_TYPE =
  'SET_IDEA_FORM_IMPLEMENT_ESTIMATED_TIME_TYPE'
export const SET_IDEA_FORM_ECONOMIC_BENEFITS = 'SET_IDEA_FORM_ECONOMIC_BENEFITS'
export const SET_IDEA_FORM_OTHER_BENEFITS = 'SET_IDEA_FORM_OTHER_BENEFITS'
export const SET_IDEA_FORM_DOCUMENTS = 'SET_IDEA_FORM_DOCUMENTS'
export const SET_IDEA_FORM_INITIAL_INVESTMENT = 'SET_IDEA_FORM_INITIAL_INVESTMENT'
export const INITIALIZE_IDEA_PAGE = 'INITIALIZE_IDEA_PAGE'
export const RESET_IDEA_STATE = 'RESET_IDEA_STATE'

export const SET_IDEA_CONTRIBUTIONS_STARTED = 'SET_IDEA_CONTRIBUTIONS_STARTED'
export const SET_IDEA_CONTRIBUTIONS_SUCCESS = 'SET_IDEA_CONTRIBUTIONS_SUCCESS'
export const SET_IDEA_CONTRIBUTIONS_FAILURE = 'SET_IDEA_CONTRIBUTIONS_FAILURE'

export const SET_CONTRIBUTION_TIME = 'SET_CONTRIBUTION_TIME'
export const SET_CONTRIBUTION_BUDGET = 'SET_CONTRIBUTION_BUDGET'
export const SET_CONTRIBUTION_OTHER = 'SET_CONTRIBUTION_OTHER'

export const GET_IDEA_INVESTORS_STARTED = 'GET_IDEA_INVESTORS_STARTED'
export const GET_IDEA_INVESTORS_SUCCESS = 'GET_IDEA_INVESTORS_SUCCESS'
export const GET_IDEA_INVESTORS_FAILURE = 'GET_IDEA_INVESTORS_FAILURE'

export const GET_IDEA_TEAM_STARTED = 'GET_IDEA_TEAM_STARTED'
export const GET_IDEA_TEAM_SUCCESS = 'GET_IDEA_TEAM_SUCCESS'
export const GET_IDEA_TEAM_FAILURE = 'GET_IDEA_TEAM_FAILURE'

export const DELETE_USER_FROM_TEAM_STARTED = 'DELETE_USER_FROM_TEAM_STARTED'
export const DELETE_USER_FROM_TEAM_SUCCESS = 'DELETE_USER_FROM_TEAM_SUCCESS'
export const DELETE_USER_FROM_TEAM_FAILURE = 'DELETE_USER_FROM_TEAM_FAILURE'

export const POST_ASK_FOR_INVESTMENTS_STARTED = 'POST_ASK_FOR_INVESTMENTS_STARTED'
export const POST_ASK_FOR_INVESTMENTS_SUCCESS = 'POST_ASK_FOR_INVESTMENTS_SUCCESS'
export const POST_ASK_FOR_INVESTMENTS_FAILURE = 'POST_ASK_FOR_INVESTMENTS_FAILURE'

export const GET_IS_USER_INVITED_TO_TEAM_STARTED = 'GET_IS_USER_INVITED_TO_TEAM_STARTED'
export const GET_IS_USER_INVITED_TO_TEAM_SUCCESS = 'GET_IS_USER_INVITED_TO_TEAM_SUCCESS'
export const GET_IS_USER_INVITED_TO_TEAM_FAILURE = 'GET_IS_USER_INVITED_TO_TEAM_FAILURE'

export const POST_ACCEPT_JOIN_IDEA_TEAM_STARTED = 'POST_ACCEPT_JOIN_IDEA_TEAM_STARTED'
export const POST_ACCEPT_JOIN_IDEA_TEAM_SUCCESS = 'POST_ACCEPT_JOIN_IDEA_TEAM_SUCCESS'
export const POST_ACCEPT_JOIN_IDEA_TEAM_FAILURE = 'POST_ACCEPT_JOIN_IDEA_TEAM_FAILURE'

export const POST_REJECT_JOIN_IDEA_TEAM_STARTED = 'POST_REJECT_JOIN_IDEA_TEAM_STARTED'
export const POST_REJECT_JOIN_IDEA_TEAM_SUCCESS = 'POST_REJECT_JOIN_IDEA_TEAM_SUCCESS'
export const POST_REJECT_JOIN_IDEA_TEAM_FAILURE = 'POST_REJECT_JOIN_IDEA_TEAM_FAILURE'

export const POST_ACCEPT_JOIN_INNOVATION_TEAM_STARTED = 'POST_ACCEPT_JOIN_INNOVATION_TEAM_STARTED'
export const POST_ACCEPT_JOIN_INNOVATION_TEAM_SUCCESS = 'POST_ACCEPT_JOIN_INNOVATION_TEAM_SUCCESS'
export const POST_ACCEPT_JOIN_INNOVATION_TEAM_FAILURE = 'POST_ACCEPT_JOIN_INNOVATION_TEAM_FAILURE'

export const POST_REJECT_JOIN_INNOVATION_TEAM_STARTED = 'POST_REJECT_JOIN_INNOVATION_TEAM_STARTED'
export const POST_REJECT_JOIN_INNOVATION_TEAM_SUCCESS = 'POST_REJECT_JOIN_INNOVATION_TEAM_SUCCESS'
export const POST_REJECT_JOIN_INNOVATION_TEAM_FAILURE = 'POST_REJECT_JOIN_INNOVATION_TEAM_FAILURE'

export const GET_IS_USER_INVITED_TO_INNOVATION_TEAM_STARTED =
  'GET_IS_USER_INVITED_TO_INNOVATION_TEAM_STARTED'
export const GET_IS_USER_INVITED_TO_INNOVATION_TEAM_SUCCESS =
  'GET_IS_USER_INVITED_TO_INNOVATION_TEAM_SUCCESS'
export const GET_IS_USER_INVITED_TO_INNOVATION_TEAM_FAILURE =
  'GET_IS_USER_INVITED_TO_INNOVATION_TEAM_FAILURE'

export const POST_SEND_INVITATION_STARTED = 'POST_SEND_INVITATION_STARTED'
export const POST_SEND_INVITATION_SUCCESS = 'POST_SEND_INVITATION_SUCCESS'
export const POST_SEND_INVITATION_FAILURE = 'POST_SEND_INVITATION_FAILURE'

export const POST_INVEST_IDEA_STARTED = 'POST_INVEST_IDEA_STARTED'
export const POST_INVEST_IDEA_SUCCESS = 'POST_INVEST_IDEA_SUCCESS'
export const POST_INVEST_IDEA_FAILURE = 'POST_INVEST_IDEA_FAILURE'

export const DELETE_IDEA_DOCUMENT_STARTED = 'DELETE_IDEA_DOCUMENT_STARTED'
export const DELETE_IDEA_DOCUMENT_SUCCESS = 'DELETE_IDEA_DOCUMENT_SUCCESS'
export const DELETE_IDEA_DOCUMENT_FAILURE = 'DELETE_IDEA_DOCUMENT_FAILURE'

export const POST_IDEA_COMMENT_STARTED = 'POST_IDEA_COMMENT_STARTED'
export const POST_IDEA_COMMENT_SUCCESS = 'POST_IDEA_COMMENT_SUCCESS'
export const POST_IDEA_COMMENT_FAILURE = 'POST_IDEA_COMMENT_FAILURE'

export const POST_VALIDATE_IDEA_STARTED = 'POST_VALIDATE_IDEA_STARTED'
export const POST_VALIDATE_IDEA_SUCCESS = 'POST_VALIDATE_IDEA_SUCCESS'
export const POST_VALIDATE_IDEA_FAILURE = 'POST_VALIDATE_IDEA_FAILURE'

export const POST_PUBLISH_DRAFT_STARTED = 'POST_PUBLISH_DRAFT_STARTED'
export const POST_PUBLISH_DRAFT_SUCCESS = 'POST_PUBLISH_DRAFT_SUCCESS'
export const POST_PUBLISH_DRAFT_FAILURE = 'POST_PUBLISH_DRAFT_FAILURE'

export const DELETE_IDEA_STARTED = 'DELETE_IDEA_STARTED'
export const DELETE_IDEA_SUCCESS = 'DELETE_IDEA_SUCCESS'
export const DELETE_IDEA_FAILURE = 'DELETE_IDEA_FAILURE'

export const GET_IDEA_COMMENTS_STARTED = 'GET_IDEA_COMMENTS_STARTED'
export const GET_IDEA_COMMENTS_SUCCESS = 'GET_IDEA_COMMENTS_SUCCESS'
export const GET_IDEA_COMMENTS_FAILURE = 'GET_IDEA_COMMENTS_FAILURE'

export const DELETE_IDEA_COMMENT_STARTED = 'DELETE_IDEA_COMMENT_STARTED'
export const DELETE_IDEA_COMMENT_SUCCESS = 'DELETE_IDEA_COMMENT_SUCCESS'
export const DELETE_IDEA_COMMENT_FAILURE = 'DELETE_IDEA_COMMENT_FAILURE'

export const VOTE_IDEA_COMMENT_STARTED = 'VOTE_IDEA_COMMENT_STARTED'
export const VOTE_IDEA_COMMENT_SUCCESS = 'VOTE_IDEA_COMMENT_SUCCESS'
export const VOTE_IDEA_COMMENT_FAILURE = 'VOTE_IDEA_COMMENT_FAILURE'

export const SHARE_IDEA_STARTED = 'SHARE_IDEA_STARTED'
export const SHARE_IDEA_SUCCESS = 'SHARE_IDEA_SUCCESS'
export const SHARE_IDEA_FAILURE = 'SHARE_IDEA_FAILURE'

export const ACTIVATE_PILOT_PROJECT_STARTED = 'ACTIVATE_PILOT_PROJECT_STARTED'
export const ACTIVATE_PILOT_PROJECT_SUCCESS = 'ACTIVATE_PILOT_PROJECT_SUCCESS'
export const ACTIVATE_PILOT_PROJECT_FAILURE = 'ACTIVATE_PILOT_PROJECT_FAILURE'

export const IMPLEMENT_PILOT_PROJECT_STARTED = 'IMPLEMENT_PILOT_PROJECT_STARTED'
export const IMPLEMENT_PILOT_PROJECT_SUCCESS = 'IMPLEMENT_PILOT_PROJECT_SUCCESS'
export const IMPLEMENT_PILOT_PROJECT_FAILURE = 'IMPLEMENT_PILOT_PROJECT_FAILURE'

export const TRANSFER_IDEA_PROMOTER_STARTED = 'TRANSFER_IDEA_PROMOTER_STARTED'
export const TRANSFER_IDEA_PROMOTER_SUCCESS = 'TRANSFER_IDEA_PROMOTER_SUCCESS'
export const TRANSFER_IDEA_PROMOTER_FAILURE = 'TRANSFER_IDEA_PROMOTER_FAILURE'

export const RESET_IDEA_FORM = 'RESET_IDEA_FORM'

export const GET_IS_TEAM_REQUEST_ACTIVE_STARTED = 'GET_IS_TEAM_REQUEST_ACTIVE_STARTED'
export const GET_IS_TEAM_REQUEST_ACTIVE_SUCCESS = 'GET_IS_TEAM_REQUEST_ACTIVE_SUCCESS'
export const GET_IS_TEAM_REQUEST_ACTIVE_FAILURE = 'GET_IS_TEAM_REQUEST_ACTIVE_FAILURE'


export const GET_SERVICE_LINE_STATUS_STARTED = 'GET_SERVICE_LINE_STATUS_STARTED'
export const GET_SERVICE_LINE_STATUS_SUCCESS = 'GET_SERVICE_LINE_STATUS_SUCCESS'
export const GET_SERVICE_LINE_STATUS_FAILURE = 'GET_SERVICE_LINE_STATUS_FAILURE'

const styles = theme => ({
  suggestionsList: {
    overflowY: 'auto',
    zIndex: 2,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 5px 5px -4px rgba(0,0,0,0.75)',
    position: 'absolute',
    padding: theme.spacing(0, 2, 2, 2),
    maxHeight: '150px',
  },
})

export default styles

import { connect } from 'react-redux'
import Navigation from 'support/navigation'
import cookie from 'react-cookie'
import { goToAdminThunk } from 'support/navigation/Thunks'
import { setLoggedOut } from 'actions/sync/auth'
import { msalApp } from '../../pages/azure_sso/auth-utils'
import View from './View'

const mapStateToProps = (state, ownProps) => {
  return {
    showAdmin: ownProps.showAdmin,
    isInnovator: state.data.user.isInnovator,
    localAdmin: state.data.user.localAdmin,
    localInnovator: state.data.user.localInnovation,
    evaluator: state.data.user.evaluator,
    localEvaluator: state.data.user.localEvaluator,
    isPrivateFlowActive: state.data.domain.domainConfiguration.isPrivateFlowActive,
    data: state.data.user,
  }
}

const removeCookiesFn = ()=>{
     console.log('logout cookie')
  // cookie.remove('JSESSIONID');
  // window.location.href = '/';

  document.cookie.split(";").forEach(function(c) { 
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";domain=.vodafone.com;path=/;"); 
  });

  document.cookie = `Name=JSESSIONID;path=/; domain=.vodafone.com; expires=${new Date(
    0
  ).toUTCString()}`

  // document.cookie.split(";").forEach((c) => {
  //   document.cookie = 
  //     .replace(/^ +/, "")
  //     .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/;domain=.vodafone.com;");
  // });

  // document.cookie = 'path=/; domain=.highcharts.com; expires=' + new Date(0).toUTCString();
  
  // document.cookie = `s_cc; path=/; domain=.vodafone.com; expires=${new Date(
  //   0
  // ).toUTCString()}`
}

const mapDispatchToProps = dispatch => {
  return {
    navigateToAdmin: () => {
      dispatch(goToAdminThunk())
    },
    navigateToDashboard: () => {
      Navigation.navigateToDashboard()
    },
    navigateToPendingValidation: () => {
      Navigation.navigateToPendingValidation()
    },
    navigateToEvaluationBoard: () => {
      Navigation.navigateToEvaluationBoard()
    },
    navigateToPrivateIdeas: () => {
      Navigation.navigateToPrivateIdeas()
    },
    navigateToMyProfile: () => {
      Navigation.navigateToUserProfile('me')
    },
    navigateToRanking: () => {
      Navigation.navigateToRanking()
    },
    navigateToSettings: () => {
      Navigation.navigateToSettings()
    },
    navigateToLogout: () => {
      dispatch(setLoggedOut())
      if (msalApp.getAccount()) msalApp.logout(); 
      
      // Navigation.navigateToLogout();
      removeCookiesFn()

  
    },
    navigateToInvestments: () => {
      Navigation.navigateToInvestments()
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

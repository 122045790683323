import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/Button'
import i18n from 'support/i18n'
import styles from './View.Style'

const HeaderButtons = props => {
  const {
    classes,
    navigateToExplore,
    navigateToMyIdeas,
    // navigateToInvestments,
    navigateToChallenges,
    navigateToAboutus,
    navigateToNovaResearch,
  } = props

  return (
    <div className={classes.listContainer} role="navigation">
      <ul className={classes.domainMenuContainer}>
        <li className="active">
          <ButtonBase component="a" onClick={navigateToExplore}>
            {/* <b className="icon-explore" /> */}
            <span>{i18n.t('MENU.HOME')}</span>
          </ButtonBase>
        </li>
        <li>
          <ButtonBase component="a" onClick={navigateToMyIdeas}>
            {/* <b className="icon-idea" /> */}
            <span>{i18n.t('MENU.MY_IDEAS')}</span>
          </ButtonBase>
        </li>
        <li>
          <ButtonBase component="a" onClick={navigateToChallenges}>
            {/* <b className="icon-target" /> */}
            <span>{i18n.t('MENU.CHALLENGES')}</span>
          </ButtonBase>
        </li>
        <li>
          <ButtonBase component="a" onClick={navigateToNovaResearch}>
            {/* <b className="icon-details" /> */}
            <span>{i18n.t('MENU.NOVA_RESEARCH')}</span>
          </ButtonBase>
        </li>
        <li>
          <ButtonBase component="a" onClick={navigateToAboutus}>
            {/* <b className="icon-details" /> */}
            <span>{i18n.t('MENU.ABOUT_US')}</span>
          </ButtonBase>
        </li>
      </ul>
    </div>
  )
}

HeaderButtons.propTypes = {
  classes: PropTypes.shape({
    listContainer: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(HeaderButtons)

import backGroundImage from 'img/bg-pattern-icons-2x.png'

const styles = theme => ({
  header: {
    paddingTop: theme.spacing(1.125),
    backgroundColor: '#142630',
  },
  logoContainer: {
    margin: theme.spacing(0, 17, 0, 17),
    borderBottom: '1px solid #A2A8AC',
  },
  nextInitLogo: {
    height: 90,
  },
  titleSection: {
    backgroundColor: '#142630',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleContainer: {
    padding: theme.spacing(9, 0),
    margin: theme.spacing(0, 17, 0, 17),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleSpan: {
    color: '#fff',
    fontSize: 37,
    fontWeight: 600,
  },
  subLine: {
    borderRadius: '4px',
    color: '#ffff',
    border: '2px solid',
    width: '106%',
    marginTop: theme.spacing(1),
  },
  textContainer: {
    backgroundColor: '#007ebe',
    backgroundImage: `url(${backGroundImage})`,
    backgroundSize: '142px 136px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textSpan: {
    padding: theme.spacing(9, 0),
    margin: theme.spacing(0, 17, 0, 17),
    fontSize: theme.spacing(2.5),
    color: '#fff',
  },
  footerContainer: {
    paddingTop: theme.spacing(12.5),
    margin: theme.spacing(0, 17, 0, 17),
  },
  logoAndMobileContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mobileStores: {
    '& > ul': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 0,
      listStyle: 'none',
      margin: 0,
      padding: 0,
      '& > li': {
        margin: theme.spacing(0, 1.5),
        '& > a > img': {
          width: theme.spacing(17),
        },
      },
      '& > li:last-child': {
        marginRight: 0,
      },
    },
  },
  logoButton: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      '& > img': {
        width: 30,
      },
      '& > p': {
        float: 'left',
        margin: theme.spacing(0, 0, 0, 1 / 2),
        textTransform: 'lowercase',
        fontSize: theme.spacing(2.75),
        fontWeight: 600,
        color: '#ababab',
      },
    },
    '& > p': {
      margin: theme.spacing(1 / 2, 0, 0, 0),
      fontSize: theme.spacing(2.25),
      color: '#ababab',
    },
  },
  mobileStoreImage: {
    '& > img': {
      width: theme.spacing(17),
    },
  },
  footerDivider: {
    border: 0,
    borderTop: '1px solid #ababab',
    height: '1px',
    margin: theme.spacing(3, 0),
  },
  socialLinks: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 0,
    listStyle: 'none',
    margin: theme.spacing(0, 0, 3, 0),
    padding: 0,
    '& > li': {
      margin: theme.spacing(0, 1),
    },
    '& > li:first-child': {
      marginLeft: 0,
    },
    '& a': {
      textDecoration: 'none',
      fontSize: theme.spacing(4.25),
      color: '#ababab',
    },
  },
})

export default styles

import { createBrowserHistory } from 'history'
import ROUTES from '../../common_constants/Routes'

class NavigationService {
  constructor() {
    if (!NavigationService.instance) {
      this.history = undefined
      this.routes = ROUTES()
      NavigationService.instance = this
    }

    return NavigationService.instance
  }

  setDomain(domain) {
    this.routes = ROUTES(domain)
  }

  getRoutes() {
    return this.routes
  }

  getHistory() {
    if (!this.history) {
      this.history = createBrowserHistory()
    }
    return this.history
  }
}

const instance = new NavigationService()

export default instance

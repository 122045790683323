const styles = theme => ({
  root: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  userDetailContainer: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    '& > a:first-child > img': {
      height: 36,
      width: 36,
      borderRadius: '50%',
    },
  },
  userLinksContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > a': {
      padding: theme.spacing(1 / 4),
      color: '#5e5e5e',
      textDecoration: 'unset',
    },
    '& > a:first-child': {
      fontSize: '0.875rem',
    },
    '& > a:last-child': {
      fontSize: '0.75rem',
    },
  },
  participationSuccessContainer: {
    display: 'flex',
    borderTop: '1px solid #5e5e5e',
    borderBottom: '1px solid #5e5e5e',
    padding: theme.spacing(1.25, 0),
    '& > div': {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      width: 150,
      '& > span:first-child': {
        fontSize: '2rem',
      },
      '& > span:last-child': {
        fontSize: '0.75rem',
      },
    },
    '& > div:first-child': {
      color: '#fa5d7f',
      marginRight: theme.spacing(1.5),
    },
    '& > div:last-child': {
      color: '#00d24c',
      marginLeft: theme.spacing(1.5),
    },
  },
  userActivityContainer: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    '& > div': {
      width: '50%',
    },
    '& > div:first-child': {
      borderRight: '1px dashed #dcdcdc',
    },
  },
  detailsColumn: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '30px',
      '& > span:first-child': {
        fontSize: '1.25rem',
        fontWeight: 'bold',
        width: '30%',
        textAlign: 'right',
      },
      '& > span:last-child': {
        fontSize: '0.75rem',
        width: '70%',
        padding: theme.spacing(0, 1),
        whiteSpace: 'normal',
        color: '#5e5e5e',
      },
    },
  },
  yellowText: { color: '#ffcd40' },
  brownText: { color: '#d19600' },
  lightBlueText: { color: '#63ffe4' },
  blueText: { color: '#0097e2' },
})

export default styles

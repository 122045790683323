const styles = theme => ({
  root: {
    display: 'flex',
    '& > div > p': {
      minWidth: theme.spacing(30),
      marginRight: theme.spacing(5),
      fontSize: '12px',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& > span': {
      color: theme.palette.error.main,
      marginBottom: theme.spacing(2),
      fontSize: '12px',
    },
  },
})

export default styles

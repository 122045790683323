export const parseGoals = goals => {
  if (!goals) return []
  return goals.map(goal => {
    return {
      desc: goal.desc,
      name: goal.name,
      id: `${goal.id || ''}`,
      order: goal.order,
    }
  })
}

export const transformGoalServiceResponseDataWithState = jsonResponse => {
  const parsedGoals = parseGoals(jsonResponse)
  return {
    goals: parsedGoals,
  }
}

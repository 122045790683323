import React, { Component, Fragment } from 'react'
import { Divider, Typography, withStyles, Paper } from '@material-ui/core'
import i18n from 'i18n-js'
import * as PropTypes from 'prop-types'
import NxtResumeCard from 'components/nxt_resume_card/View'
import { fromValueToPercentage } from 'pages/challenge/form/View.Utils'
import ChallengeResumeDetail from 'components/nxt_challenge_resume_detail/View'
import ActivePeriod from './components/active_period/View'
import styles from './View.Style'
import OrganizationSelector from './components/organization_selector/View'
import GroupsLimit from './components/groups_limit/View'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'

class PreviewLaunch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // globalChallenge: 'Global Challenge',
      globalChallenge: '1',
      challengeTypeList: [
        { name: 'Global', id: '1' },
        { name: 'Local', id: '2' },
        { name: 'Group', id: '3' },
      ],
      challengeValue: 'global',
      limitToGroup: false,
    }
    this.activePeriodRef = React.createRef()
    this.handleChangeGlobalChallenge = this.handleChangeGlobalChallenge.bind(this)
    this.handleChangeChallenge = this.handleChangeChallenge.bind(this)
  }

  componentDidMount() {
    const { challengeStatus, onChallengeLocation } = this.props
    if (challengeStatus === 'DRAFT' || challengeStatus === 'PROPOSED') {
      onChallengeLocation(true)
    }
  }

  componentDidUpdate(prevProps) {
    const { errorObject } = this.props
    if (prevProps.errorObject !== errorObject && Object.keys(errorObject).length > 0) {
      this.activePeriodRef.current.scrollIntoView()
    }
  }
  handleChangeGlobalChallenge = event => {
    const { onChallengeLocation } = this.props
    // if (event.target.checked) {
    //   onNextClick()
    // } else {
    //   console.log(`unchecked....`)
    // }
    // if (event.target.value === 'Global') onChallengeLocation(true)
    // if (event.target.value === 'Local') onChallengeLocation(false)
    // if (event.target.value === 'Group') onChallengeLocation(false)
    if (event === '1') onChallengeLocation(true)
    if (event === '2') onChallengeLocation(false)
    if (event === '3') onChallengeLocation(false)
    this.setState({ globalChallenge: event })
  }
  handleChangeChallenge = event => {
    const { onChallengeLocation } = this.props
    if (event.target.value === 'global') {
      onChallengeLocation(true)
      this.setState({ challengeValue: event.target.value, limitToGroup: false })
    }
    if (event.target.value === 'local') {
      onChallengeLocation(false)
      this.setState({ challengeValue: event.target.value, limitToGroup: false })
    }
    if (event.target.value === 'group') {
      this.setState({ challengeValue: event.target.value, limitToGroup: !this.state.limitToGroup })
    }
  }

  onSelectedChallengeType() {
    const { previewChallenge, challengeStatus } = this.props
    const { rightGroups, isGlobal } = previewChallenge

    if (challengeStatus === 'DRAFT') {
      //this.setState({ challengeValue: 'global' })
    } else {
      if (isGlobal) {
        this.setState({ challengeValue: 'global' })
      }
      if (rightGroups.length > 0) {
        this.setState({ challengeValue: 'group' })
      } else {
        this.setState({ challengeValue: 'local' })
      }
    }
  }

  render() {
    const {
      classes,
      previewChallenge,
      onSelectPreviewFromDate,
      onSelectPreviewToDate,
      onOrganizationSelect,
      organizationList,
      isOrganizationsActive,
      onMoveToRight,
      onMoveToLeft,
      onRightGroupClick,
      approveMult,
      implementMult,
      supportMult,
      challengeDescription,
      reward,
      selectedSponsor,
      selectedProposer,
      onClickToShowRewardImage,
      errorObject,
      defaultValues,
      showOrganisations,
      isFromDateDisable,
      isGroupDisabled,
      challengeStatus,
      isServiceLineActivated,
    } = this.props
    const {
      fromDate,
      toDate,
      defaultGroups,
      rightGroups,
      organization,
      isGlobal,
    } = previewChallenge
    const {
      title,
      description,
      images,
      externalVideo,
      fileVideo,
      whyImportant,
    } = challengeDescription
    const supportPercentage = fromValueToPercentage(defaultValues.supportMult, supportMult)
    const implementPercentage = fromValueToPercentage(defaultValues.implementMult, implementMult)
    const approvePercentage = fromValueToPercentage(defaultValues.approveMult, approveMult)
    return (
      <div className={classes.root}>
        <NxtResumeCard
          images={images}
          title={title}
          description={description}
          videoSrc={externalVideo || fileVideo}
          selectedProposer={selectedProposer}
        >
          <ChallengeResumeDetail
            onClickToShowRewardImage={onClickToShowRewardImage}
            reward={reward}
            whyImportant={whyImportant}
            supportMult={supportPercentage}
            implementMult={implementPercentage}
            approveMult={approvePercentage}
            selectedSponsor={selectedSponsor}
            defaultValues={defaultValues}
          />
        </NxtResumeCard>
        <Typography variant="h5" className={classes.mainTitle} ref={this.activePeriodRef}>
          {i18n.t('CHALLENGES.WHEN_ACTIVE')}
        </Typography>
        <Divider />
        <div className={classes.datesContainer}>
          <ActivePeriod
            isFromDateDisable={isFromDateDisable}
            fromDate={fromDate}
            toDate={toDate}
            onSelectPreviewFromDate={onSelectPreviewFromDate}
            onSelectPreviewToDate={onSelectPreviewToDate}
            hasToDateError={errorObject.toDate}
          />
        </div>
        <div className={classes.challengeType}>
          {/* Global Challenge
          <Checkbox
            checked={this.state.globalChallenge}
            onChange={this.handleChangeGlobalChallenge}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          /> */}
          {/* <Fragment>
            <Typography variant="h5" className={classes.mainTitle}>
              {i18n.t('CHALLENGES.CHALLENGE_TYPE')}
            </Typography>
            <Divider />
            <RadioSelector
              items={this.state.challengeTypeList}
              onValueChange={this.handleChangeGlobalChallenge}
              value={isGlobalChallenge ? 'Global' : 'Local'}
              subItemsKey="challenge_type"
            />
          </Fragment> */}
          <Typography variant="h5" className={classes.mainTitle}>
            {i18n.t('CHALLENGES.CHALLENGE_TYPE')}
          </Typography>
          {}
          {(challengeStatus === 'DRAFT' || challengeStatus === 'PROPOSED') && (
            <FormControl component="fieldset">
              {/* <FormLabel component="legend">Gender</FormLabel> */}
              <RadioGroup
                aria-label="challengeType"
                name="challengeType1"
                value={this.state.challengeValue}
                //value={this.onSelectedChallengeType}
                onChange={this.handleChangeChallenge}
              >
                <FormControlLabel value="global" control={<Radio />} label="Global" />
                {!isServiceLineActivated && (
                  <FormControlLabel value="local" control={<Radio />} label="Local" />
                )}
                <FormControlLabel
                  value="group"
                  control={<Radio />}
                  label="Limit to the specific group"
                />
              </RadioGroup>
            </FormControl>
          )}
          {challengeStatus === 'ACTIVE' && (
            <div>
              {isGlobal && <div className={classes.groupsSelected}>Global</div>}
              {!isGlobal && rightGroups.length === 0 && (
                <div className={classes.groupsSelected}>Local</div>
              )}
              {!isGlobal && rightGroups.length > 0 && (
                <div className={classes.groupsSelected}>Group</div>
              )}
            </div>
          )}
        </div>

        {(challengeStatus === 'ACTIVE' || this.state.limitToGroup) &&
          ((defaultGroups && defaultGroups.length > 0) || isGroupDisabled) && (
            <Fragment>
              <Typography variant="h5" className={classes.mainTitle}>
                {i18n.t('CHALLENGES.LIMIT_TO_GROUP')}
              </Typography>
              <Divider />
              {isGroupDisabled && rightGroups.length > 0 && (
                <ol className={classes.groupsSelected}>
                  {rightGroups.map(group => (
                    <li>{group.name}</li>
                  ))}
                </ol>
              )}
              {isGroupDisabled && rightGroups.length === 0 && (
                <Paper className={classes.noGroupsSelected}>
                  {i18n.t('CHALLENGES.NO_GROUPS_SELECTED')}
                </Paper>
              )}
              {!isGroupDisabled && (
                <div className={classes.groupsLimit}>
                  <GroupsLimit
                    leftGroups={defaultGroups}
                    rightGroups={rightGroups}
                    onMoveToRight={onMoveToRight}
                    onMoveToLeft={onMoveToLeft}
                    onRightGroupClick={onRightGroupClick}
                  />
                </div>
              )}
            </Fragment>
          )}
        {isOrganizationsActive && showOrganisations && (
          <Fragment>
            <Typography variant="h5" className={classes.mainTitle}>
              {i18n.t('CHALLENGES.ORGANIZATION')}
            </Typography>
            <Divider />
            <OrganizationSelector
              onOrganizationSelect={onOrganizationSelect}
              organization={organization}
              organizationList={organizationList}
            />
          </Fragment>
        )}
      </div>
    )
  }
}
PreviewLaunch.defaultProps = {
  classes: {
    root: '',
    mainTitle: '',
    datesContainer: '',
    groupsLimit: '',
  },
}
PreviewLaunch.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    mainTitle: PropTypes.string,
    datesContainer: PropTypes.string,
    groupsLimit: PropTypes.string,
  }),
  previewChallenge: PropTypes.shape({
    fromDate: PropTypes.instanceOf(Date).isRequired,
    toDate: PropTypes.instanceOf(Date),
    leftGroups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        category: PropTypes.string,
        id: PropTypes.string,
        checked: PropTypes.bool,
      }),
    ),
    rightGroups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        category: PropTypes.string,
        id: PropTypes.string,
        checked: PropTypes.bool,
      }),
    ),
    organization: PropTypes.string,
    isGlobal: PropTypes.bool.isRequired,
  }).isRequired,
  isOrganizationsActive: PropTypes.bool.isRequired,
  isFromDateDisable: PropTypes.bool.isRequired,
  showOrganisations: PropTypes.bool.isRequired,
  isGroupDisabled: PropTypes.bool.isRequired,
  onSelectPreviewFromDate: PropTypes.func.isRequired,
  onSelectPreviewToDate: PropTypes.func.isRequired,
  onOrganizationSelect: PropTypes.func.isRequired,
  organizationList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  onMoveToRight: PropTypes.func.isRequired,
  onMoveToLeft: PropTypes.func.isRequired,
  onClickToShowRewardImage: PropTypes.func.isRequired,
  onRightGroupClick: PropTypes.func.isRequired,
  approveMult: PropTypes.number.isRequired,
  implementMult: PropTypes.number.isRequired,
  supportMult: PropTypes.number.isRequired,
  challengeDescription: PropTypes.shape({}).isRequired,
  reward: PropTypes.shape({}).isRequired,
  defaultValues: PropTypes.shape({}).isRequired,
  selectedSponsor: PropTypes.shape({}).isRequired,
  selectedProposer: PropTypes.shape({}).isRequired,
  errorObject: PropTypes.shape({}).isRequired,
}
export default withStyles(styles)(PreviewLaunch)

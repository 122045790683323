import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    container: {
      padding: '3rem',
    },
    media: {
      margin: '10px auto 0',
      width: '95%',
      height: 400,
      borderRadius: '4px',
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
      position: 'relative',
      zIndex: 1000,
    },
    root: {
      width: '100%',
      flexGrow: 1,
    },
    name: {
      textAlign: 'center',
    },
    faqDescription: {
      margin: '10px',
    },
    topic: {
      margin: '20px',
      color: 'red',
    },
    heading: {
      // fontSize: theme.typography.pxToRem(15),
      flexBasis: '90.33%',
      // flexShrink: 0,
    },
    secondaryHeading: {
      // fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    question: {
      color: 'red',
    },
    root1: {
      maxWidth: 400,
      flexGrow: 1,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
    img: {
      height: 455,
      display: 'block',
      maxWidth: '100%',
      overflow: 'hidden',
      width: '100%',
    },
    solid: {
      width: '100vw',
    },
    root_card: {
      padding: 5,
      display: 'flex',
      height: '250px',
      marginBottom: '15px',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      width: '22vw',
      textAlign: 'center',
    },
    cover: {
      width: '100vw',
    },
  })

export default styles

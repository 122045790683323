export const dashboardTabs = {
  ACTIVITY: 'activity',
  IDEAS: 'ideas',
  PEOPLE: 'people',
  PRIVATE_IDEAS: 'privateIdeas',
}

export const typesViewAs = {
  QUADRANT: 0,
  GRID: 1,
  LIST: 2,
}

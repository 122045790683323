const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 56px 1fr',
    gridTemplateRows: '1fr',
    gridTemplateAreas: '"left-content bar right-content"',
  },
  left: {
    gridArea: 'left-content',
  },
  right: {
    gridArea: 'right-content',
  },
  middle: {
    gridArea: 'bar',
    height: '100%',
    borderLeft: '3px solid #ddd',
    marginLeft: '50%',
    position: 'relative',
  },
  ball: {
    position: ' absolute',
    left: ' -44%',
    top: ' 0',
    borderRadius: '50%',
    width: '12px',
    height: '12px',
    backgroundColor: 'white',
    border: ' 3px #ddd solid',
  },
  timeAgoLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  primaryBackground: {
    backgroundColor: theme.palette.primary.main,
  },
})

export default styles

import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import AuthProvider from '../azure_sso/AuthProvider'
import ExternalLogin from './components/external_login/View'
import { FullPageDialog } from 'components'
import IMan from './img/innovation_man.svg'
import Lightbulb from './img/lightbulb.svg'
import Graphicdesign from './img/process.svg'
import NovaNotSupport from './img/nove_not_support.jpg'
import styles from './View.Style'
import NovaHomeSlides from './components/slides/View'
import MainLoader from 'support/loader/nxt_loader'
import { isIE } from 'react-device-detect'

class NovaHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      showtnc: false,
    }
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.showTnC = this.showTnC.bind(this)
  }

  // onSsoLogin() {
  //   const { onSSOSubmit, account } = this.props

  //   if (account) {
  //     const jsonData = JSON.stringify(account)
  //     const obj = JSON.parse(jsonData)
  //     onSSOSubmit(
  //       obj.idToken.given_name,
  //       obj.idToken.family_name,
  //       obj.idToken.email,
  //       obj.idToken.ctry,
  //       true,
  //     )
  //   }
  // }

  componentDidUpdate(prevProps) {
    const { isLogging, account, onSSOSubmit, ssoLoginError, error } = this.props

    if (prevProps.account !== account) {
      if (account) {
        const jsonData = JSON.stringify(account)
        const obj = JSON.parse(jsonData)
        onSSOSubmit(
          obj.idToken.given_name,
          obj.idToken.family_name,
          obj.idToken.email,
          obj.idToken.ctry,
          true,
        )
      }
    }
    if (prevProps.error !== error) {
      // console.log(error)
      ssoLoginError(`Error: ${error}`)
    }
  }

  showModal = () => {
    const { onErrorReset } = this.props
    onErrorReset()
    this.setState({ show: true })
  }

  showTnC = () => {
    const { onErrorReset } = this.props
    onErrorReset()
    this.setState({ showtnc: true })
  }

  hideModal = () => {
    this.setState({ show: false, showtnc: false })
  }

  render() {
    if (isIE)
      return (
        <div>
          {' '}
          <img src={NovaNotSupport} width="100%" height="100%" alt="NOVA" />{' '}
        </div>
      )
    const { show, showtnc } = this.state
    // const { show, showtnc, loginWait } = this.state
    const {
      classes,
      onSignIn,
      onSSOSubmit,
      account,
      error,
      isLogging,
      ssoLoginError,
      onExternalSubmit,
      onForgotPasswordClick,
      isEmailNotVerifiedYet,
      messageText,
      messageType,
      resetAuthMessageBox,
      onSendMeActivationEmailClick,
      ssoLoginStarted,
    } = this.props
    // if (!isLogging) {
    //   if (account) {
    //     const jsonData = JSON.stringify(account)
    //     const obj = JSON.parse(jsonData)
    //     onSSOSubmit(
    //       obj.idToken.given_name,
    //       obj.idToken.family_name,
    //       obj.idToken.email,
    //       obj.idToken.ctry,
    //       true,
    //     )
    //   } else {
    //     console.log(`.`)
    //   }
    // }
    // if (error) {
    //   console.log(error)
    //   ssoLoginError(`Error: ${error}`)
    // }

    return (
      <React.Fragment>
        <Grid container spacing={0} alignItems="stretch">
          <Grid
            classes={{
              root: classes.slidesBG,
            }}
            item
            xs={12}
            sm={6}
          >
            <NovaHomeSlides />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid
              container
              classes={{
                root: classes.top,
              }}
              justify="space-around"
              alignItems="center"
              spacing={3}
            >
              <Typography variant="h4" gutterBottom>
                Welcome to NOVA
              </Typography>
            </Grid>
            <Grid
              container
              classes={{
                root: classes.icon,
              }}
              direction="row"
              justify="space-evenly"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={3}>
                <img src={Lightbulb} width="60%" height="60%" alt="NOVA" />
              </Grid>

              <Grid item xs={3}>
                <img src={IMan} width="60%" height="60%" alt="NOVA" />
              </Grid>
              <Grid item xs={3}>
                <img src={Graphicdesign} width="60%" height="60%" alt="NOVA" />
              </Grid>
            </Grid>
            <Grid
              container
              classes={{
                root: classes.buttons,
              }}
              direction="row"
              justify="space-around"
              alignItems="center"
            // spacing={0}
            >
              <Grid item xs={3} sm={3}>
                <button
                  disabled={ssoLoginStarted}
                  type="button"
                  className={
                    ssoLoginStarted ? classes.buttonAccessDisabled : classes.buttonExAccess
                  }
                  onClick={onSignIn}
                
                >
                  Vodafone User
                </button>
              </Grid>
              <Grid item xs={3} sm={3}>
                <button type="button" className={classes.buttonExAccess} onClick={this.showModal}>
                  External User
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isLogging && <MainLoader />}
        {show && (
          <ExternalLogin
            openDialog={show}
            isLogging={isLogging}
            closeDialog={this.hideModal}
            forgotPassword={onForgotPasswordClick}
            onExternalSubmit={onExternalSubmit}
            isEmailNotVerifiedYet={isEmailNotVerifiedYet}
            messageText={messageText}
            messageType={messageType}
            onSendMeActivationEmailClick={onSendMeActivationEmailClick}
            resetAuthMessageBox={resetAuthMessageBox}
          />
        )}
        {showtnc && (
          <FullPageDialog
            openDialog={showtnc}
            closeDialog={this.hideModal}
            title={'Terms of Service'}
            legalNotice={true}
          />
        )}
      </React.Fragment>
      // <div className="container">
      //     <div className={classes.footer}>
      //       <a href="https://vodafone.facebook.com/groups/2136237873262314/?ref=bookmarks">
      //         <button type="button" className={classes.tnc}>
      //           <img src={Workplace} width="5%" height="5%" alt="NOVA" />
      //         </button>
      //       </a>
      //       <button type="button" className={classes.tnc} onClick={this.showTnC}>
      //         {/* <img src={Contract} width="5%" height="5%" alt="NOVA" /> */}
      //         <img src={Workplace} width="5%" height="5%" alt="NOVA" />
      //       </button>
      //     </div>
      //   </div>
      //   {show && (
    )
  }
}

NovaHome.propTypes = {
  account: PropTypes.object,
  onSignIn: PropTypes.func.isRequired,
  error: PropTypes.string,
  onSSOSubmit: PropTypes.func.isRequired,
  isLogging: PropTypes.bool.isRequired,
  isUserAlreadyExisting: PropTypes.bool.isRequired,
  isEmailNotVerifiedYet: PropTypes.bool.isRequired,
  messageType: PropTypes.number.isRequired,
  messageText: PropTypes.string.isRequired,
}

export default AuthProvider(withStyles(styles)(NovaHome))

const styles = theme => ({
  loaderScreenWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: theme.zIndex.modal,
    top: 0,
    left: 0,
    backgroundColor: '#fff',
    overflowX: 'hidden',
    transition: '0.5s',
  },
  loaderWrapper: {
    maxWidth: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blockquoteWrapper: {
    marginTop: theme.spacing(5),
    '&:before': {
      content: "'\\201c'",
      fontSize: 55,
      lineHeight: 1,
      float: 'left',
      marginRight: 10,
    },
  },
  blockquote: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  quote: {
    fontSize: 18,
    color: 'black',
  },
  author: {
    fontSize: 12,
    marginTop: theme.spacing(2),
    color: '#ababab',
  },
})

export default styles

import { connect } from 'react-redux'

import Navigation from 'support/navigation'
import { postExpertComments } from 'services/evaluation'

import { showPopupErrorSnackbar } from 'support/popup_dialogs'

import { getIdea } from 'services/create_idea'
import i18n from 'support/i18n'
import View from './View'

const mapStateToProps = state => {
  return {
    title: state.data.createIdea.title,
    images: state.data.createIdea.images,
    description: state.data.createIdea.description,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    navigateToEvaluationBoard: () => {
      Navigation.navigateToEvaluationBoard()
    },
    getIdea: id => {
      dispatch(getIdea(id))
    },
    postExpertComments: (id, rating, comment) => {
      const onSuccess = data => {
        // console.log(data)
        Navigation.navigateToEvaluationBoard()
      }
      const onFailure = ({ data }) => {
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'

        showPopupErrorSnackbar(i18n.t(messageTag), dispatch)
      }
      dispatch(postExpertComments(id, rating, comment, onSuccess, onFailure))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

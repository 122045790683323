import i18n from 'support/i18n'
import removeTags from 'support/removeTags'


const getDateFromObject = date => {
  const result = date === null ? undefined : date

  if (
    typeof result !== 'undefined' &&
    typeof result !== 'string' &&
    Object.keys(result).length > 0
  ) {
    return result.timestamp === null ? undefined : result.timestamp
  }

  if (
    (typeof result !== 'undefined' && Object.keys(result).length === 0) ||
    typeof result === 'string'
  ) {
    return new Date(result).getTime()
  }
  return result
}

export const parseIdeasData = data => {
  return data.map(object => {
    return {
      anonymous: object.anonymous,
      challengeRef: object.challengeRef,
      challengeTitle: object.challengeTitle,
      challenged: object.challenged,
      countdownEnds: getDateFromObject(object.countdownEnds),
      created: getDateFromObject(object.created),
      documentRL: object.documentRL,
      externalVideo: object.externalVideo,
      goal: `${object.goal || '0'}`,
      id: object.id,
      images: object.images,
      innovationTeamUsers: object.innovationTeamUsers,
      investors: `${object.investors || '0'}`,
      isPrivate: object.isPrivate,
      kpis: object.kpis,
      logistic: object.logistic,
      logisticRef: object.logisticRef,
      numDocuments: object.numDocuments,
      numPrivateComments: object.numPrivateComments,
      numPublicComments: object.numPublicComments,
      organizations: object.organizations,
      owner: object.owner,
      ownerName: object.anonymous ? i18n.t('ANONYMOUS') : object.ownerName,
      pledged: `${object.pledged || '0'}`,
      published: object.published,
      singleId: object.singleId,
      sponsored: object.sponsored,
      status: object.status,
      teamUsers: object.teamUsers,
      text: object.text,
      textWithoutTags: object.textWithoutTags,
      title: object.title,
      url: object.url,
      visits: object.visits,
    }
  })
}

export const parseIdeasMe = data =>
  data.map(object => ({
    anonymous: object.anonymous,
    amountInvested: `${object.amountInvested || '0'}`,
    challenged: object.challenged,
    countdownEnds: getDateFromObject(object.countdownEnds),
    created: getDateFromObject(object.created),
    goal: object.goal,
    id: object.id,
    images: object.images,
    investors: `${object.investors || '0'}`,
    ownerName: object.anonymous ? i18n.t('ANONYMOUS') : object.ownerName,
    pledged: `${object.pledged || '0'}`,
    profit: `${object.profit || '0'}`,
    sponsored: object.sponsored,
    status: object.status,
    title: object.title,
    ownerEmail: object.owner,
    text: object.text,
    textWithoutTags: removeTags(object.text)
  }))

export const transformFilterIdeasServiceResponseDataWithState = jsonResponse => {
  return {
    data: parseIdeasData(jsonResponse.data),
    items: jsonResponse.items,
    total: jsonResponse.total,
  }
}

export const transformExpiringIdeasDataWithState = jsonResponse => {
  return{
    data: parseIdeasData(jsonResponse)
  }
}

export const transformMyIdeasToInvestInResponseDataWithState = jsonResponse => {
  const myIdeas = jsonResponse.mines || []
  return myIdeas.map(idea => ({
    id: idea.id,
    title: idea.title,
  }))
}

export const transformIdeaMeServiceResponseDataWithState = jsonResponse => {
  return {
    userIdeas: parseIdeasMe(jsonResponse.mines),
  }
}

export const transformOrganizationsFilterServiceResponseDataWithState = jsonResponse => {
  return jsonResponse
}

export const transformTypesFilterServiceResponseDataWithState = jsonResponse => {
  return jsonResponse
}

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.styles'

class TextEditor extends Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value, domain: props.domain }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.editorConfig = {
      toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList'],
    }
  }

  componentDidUpdate(prevProps) {
    const { value, domain } = this.props
    if (prevProps.value !== value) {
      this.setState({ value })
    }
    if (prevProps.domain !== domain) {
      this.setState({ domain })
    }
  }

  handleOnChange(event, editor) {
    const { onTextChange } = this.props
    const value = editor.getData()
    this.setState({ value })
    onTextChange(value)
  }

  render() {
    const { classes, reference, showDescription } = this.props
    const { value, domain } = this.state
    
    return (
      <div ref={reference} className={classes.description}>

        {domain === 'eris' && (

          <CKEditor
            config={this.editorConfig}
            editor={ClassicEditor}
            data={value}
            onChange={this.handleOnChange}
            onInit={editor => {
              if (value.length === 0 && showDescription) editor.setData(`${i18n.t('IDEA.DESC_HINT')}`)

            }}
          />

        )}

        {domain !== 'eris' && (

          <CKEditor
            config={this.editorConfig}
            editor={ClassicEditor}
            data={value}
            onChange={this.handleOnChange}
            onInit={editor => {
              if (value.length === 0 && showDescription) editor.setData(`${i18n.t('IDEA.DESC_HINT_VOIS')}`)

            }}
          />

        )}

      </div>
    )
  }
}

TextEditor.defaultProps = {
  reference: null,
  value: '',
}

TextEditor.propTypes = {
  classes: PropTypes.shape({
    description: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  onTextChange: PropTypes.func.isRequired,
  reference: PropTypes.shape({}),
  value: PropTypes.string,
}

export default withStyles(styles)(TextEditor)

import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import en from './quotes/en.json'
import es from './quotes/es.json'
import fr from './quotes/fr.json'
import nl from './quotes/nl.json'
import logo from './loader.gif'
import styles from './View.Style'

const translationQuotes = { en, es, fr, nl }

const LoaderView = props => {
  const { classes, lang } = props
  const quotes = translationQuotes[lang]
  const randomIndex = Math.floor(Math.random() * quotes.length)
  const quote = quotes[randomIndex]
  return (
    <div className={classes.loaderScreenWrapper}>
      <div className={classes.loaderWrapper}>
        <img src={logo} alt="loading..." />
        <div className={classes.blockquoteWrapper}>
          <div className={classes.blockquote}>
            <span className={classes.quote}>{quote.quote}</span>
            <span className={classes.author}>{quote.author}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

LoaderView.propTypes = {
  lang: PropTypes.string.isRequired,
}

export default withStyles(styles)(LoaderView)

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { FormControlLabel, Paper, withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import RadioSelector from 'components/radio_selector/View'
import NxtCheckbox from 'components/nxt_checkbox/View'
import GoalsSlider from 'pages/idea/form/components/goals_slider/View'
import SimpleDivider from 'components/simple_divider/View'
import { checkYoutubeUrl } from 'components/video_form/View.Utils'
import NxtFormHelper from 'components/nxt_form_helper/View'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import MainFormSection from './components/main_form_section/View'
import LogisticData from './components/logistic_data_section/View'
import ButtonsFooter from './components/buttons_footer/View'
import style from './View.Style'
import  { withRouter } from 'react-router-dom';

class IdeaForm extends Component {
  constructor(props) {
    super(props)
    // const location  = useLocation()
    

  // console.log('log...',this.props)
    const {match,location }  = this.props 
    this.state = {
      // id:timestampId,
      // id: location && location.key ? location.key : undefined,
     id: match && match.params && match.params.id ? match.params.id : undefined,
      // main data
      title: props.title,
      description: props.description,
      video: props.externalVideo,
      endDate: props.endDate,
      isPrivate: props.isPrivate,
      // challenges
      challenge:
        match && match.params && match.params.challengeId
          ? match.params.challengeId
          : props.challenge,
      // organizations
      organization: props.organization,
      // types
      type: props.type,
      // types
      goalValues: Object.keys(props.goalValues).reduce(
        (acc, item) => ({ ...acc, [item]: props.goalValues[item].percentage }),
        {},
      ),
      // ideas location
      isGlobal: props.isGlobal,
      isServiceLineActivated: props.isServiceLineActivated,
      // anonymous
      isAnonymous: props.isAnonymousIdeasMandatory || props.isAnonymous,
      // logistic data
      implementEstimatedHours: props.implementEstimatedHours,
      implementEstimatedQuantity: props.implementEstimatedQuantity,
      implementEstimatedTime: props.implementEstimatedTime,
      implementEstimatedTimeType: props.implementEstimatedTimeType,
      pilotEstimatedHours: props.pilotEstimatedHours,
      pilotEstimatedQuantity: props.pilotEstimatedQuantity,
      pilotEstimatedTime: props.pilotEstimatedTime,
      pilotEstimatedTimeType: props.pilotEstimatedTimeType,
      economicBenefits: props.economicBenefits,
      otherBenefits: props.otherBenefits,
      logisticDataErrors: {
        implementEstimatedHours: false,
        implementEstimatedQuantity: false,
        implementEstimatedTime: false,
        pilotEstimatedHours: false,
        pilotEstimatedQuantity: false,
        pilotEstimatedTime: false,
        economicBenefits: false,
      },
    }
    this.typesRef = React.createRef()
    this.challengeRef = React.createRef()
    this.organizationsRef = React.createRef()
    this.descriptionRef = React.createRef()
    this.titleRef = React.createRef()
    this.imageRef = React.createRef()
    this.externalVideoRef = React.createRef()
    this.logisticDataRef = React.createRef()

    this.handleSubmitDraft = this.handleSubmitDraft.bind(this)
    this.handleSubmitIdea = this.handleSubmitIdea.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSaveIdea = this.handleSaveIdea.bind(this)
    this.handleResubmitIdea = this.handleResubmitIdea.bind(this)
    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.handleStateChange = this.handleStateChange.bind(this)
    this.handleChallengeChange = this.handleChallengeChange.bind(this)
    this.handleIsAnonymous = this.handleIsAnonymous.bind(this)
    this.isLogisticDataVisible = this.isLogisticDataVisible.bind(this)
    this.handleDeleteImage = this.handleDeleteImage.bind(this)
    this.handleGlobleIdea = this.handleGlobleIdea.bind(this)
    this.handleDocumentUpload = this.handleDocumentUpload.bind(this)
    this.handleDeleteDocument = this.handleDeleteDocument.bind(this)
  }

  componentDidMount() {
    const {
      getGoals,
      getChallenges,
      getOrganizations,
      getTypes,
      getIdea,
      resetForm,
      getServiceLineStatus,
    } = this.props
    const { id } = this.state
    if (id) {
      getIdea(id)
    } else {
      resetForm()
    }
    getGoals()
    getChallenges()
    getOrganizations()
    getTypes()
    getServiceLineStatus()
  }

  componentDidUpdate(prevProps) {
    const {
      isTitleAvailable,
      title,
      description,
      images,
      externalVideo,
      endDate,
      challenge,
      organization,
      type,
      goalValues,
      implementEstimatedHours,
      implementEstimatedQuantity,
      implementEstimatedTime,
      implementEstimatedTimeType,
      pilotEstimatedHours,
      pilotEstimatedQuantity,
      pilotEstimatedTime,
      pilotEstimatedTimeType,
      economicBenefits,
      otherBenefits,
      isAnonymous,
      isPrivate,
      checkVideoState,
      fileVideoId,
      onVideoEncodingError,
      hasVideoError,
      hasLoaded,
      isGlobal,
      isServiceLineActivated,
    } = this.props
    let newState = {}

    // console.log("fileVideoid",fileVideoId,"hasLoaded",hasLoaded)
    
    if (
      (prevProps.fileVideoId !== fileVideoId && fileVideoId) ||
      (prevProps.hasLoaded !== hasLoaded && hasLoaded && fileVideoId)
    ) {
      checkVideoState(fileVideoId)
    }
    if (prevProps.hasVideoError !== hasVideoError && hasVideoError) {
      onVideoEncodingError()
    }
    if (prevProps.isPrivate !== isPrivate) {
      newState = { ...newState, isPrivate }
    }
    if (prevProps.isTitleAvailable !== isTitleAvailable) {
      newState = { ...newState, titleError: isTitleAvailable ? '' : i18n.t('IDEA.DUPLICATE_ERROR') }
    }
    if (prevProps.title !== title) {
      newState = { ...newState, title }
    }
    if (prevProps.description !== description) {
      newState = { ...newState, description }
    }
    if (prevProps.images !== images) {
      newState = { ...newState, images }
    }
    if (prevProps.externalVideo !== externalVideo) {
      newState = { ...newState, video: externalVideo }
    }
    if (prevProps.endDate !== endDate) {
      newState = { ...newState, endDate }
    }
    if (prevProps.challenge !== challenge) {
      newState = { ...newState, challenge }
    }
    if (prevProps.organization !== organization) {
      newState = { ...newState, organization }
    }
    if (prevProps.type !== type) {
      newState = { ...newState, type }
    }
    if (prevProps.goalValues !== goalValues) {
      newState = {
        ...newState,
        goalValues: Object.keys(goalValues).reduce(
          (acc, item) => ({ ...acc, [item]: goalValues[item].percentage }),
          {},
        ),
      }
    }
    if (prevProps.implementEstimatedHours !== implementEstimatedHours) {
      newState = { ...newState, implementEstimatedHours }
    }
    if (prevProps.implementEstimatedQuantity !== implementEstimatedQuantity) {
      newState = { ...newState, implementEstimatedQuantity }
    }
    if (prevProps.implementEstimatedTime !== implementEstimatedTime) {
      newState = { ...newState, implementEstimatedTime }
    }
    if (prevProps.implementEstimatedTimeType !== implementEstimatedTimeType) {
      newState = { ...newState, implementEstimatedTimeType }
    }
    if (prevProps.pilotEstimatedHours !== pilotEstimatedHours) {
      newState = { ...newState, pilotEstimatedHours }
    }
    if (prevProps.pilotEstimatedQuantity !== pilotEstimatedQuantity) {
      newState = { ...newState, pilotEstimatedQuantity }
    }
    if (prevProps.pilotEstimatedTime !== pilotEstimatedTime) {
      newState = { ...newState, pilotEstimatedTime }
    }
    if (prevProps.pilotEstimatedTimeType !== pilotEstimatedTimeType) {
      newState = { ...newState, pilotEstimatedTimeType }
    }
    if (prevProps.economicBenefits !== economicBenefits) {
      newState = { ...newState, economicBenefits }
    }
    if (prevProps.otherBenefits !== otherBenefits) {
      newState = { ...newState, otherBenefits }
    }
    if (prevProps.isAnonymous !== isAnonymous) {
      newState = { ...newState, isAnonymous }
    }
    if (prevProps.isGlobal !== isGlobal) {
      newState = { ...newState, isGlobal }
    }
    if (prevProps.isServiceLineActivated !== isServiceLineActivated) {
      newState = { ...newState, isServiceLineActivated }
    }
    if (Object.keys(newState).length > 0) {
      this.setState(newState)
    }

  }

  validateForm(isSavingDraft = false) {
    const {
      isOrganizationsActive,
      isTypesActive,
      isTitleAvailable,
      isChallengeMandatoryForIdeaCreation,
      isPrivate,
      images,
    } = this.props
    const {
      title,
      description,
      organization,
      type,
      challenge,
      video,
      externalVideoError,
      logisticDataErrors,
    } = this.state
    let newState = {
      titleError: '',
      descriptionError: '',
      challengeError: '',
      organizationError: '',
      typeError: '',
      missingImageError: '',
      invalidYoutubeUrlError: '',
    }
    let reference = null
    if (!title) {
      newState = { ...newState, titleError: i18n.t('IDEA.TITLE_ERROR') }
      reference = this.titleRef
    }
    if (!isTitleAvailable) {
      newState = { ...newState, titleError: i18n.t('IDEA.DUPLICATE_ERROR') }
      reference = this.titleRef
    }
    if (!description) {
      newState = { ...newState, descriptionError: i18n.t('IDEA.DESC_ERROR') }
      reference = this.descriptionRef
    }
    if (!isSavingDraft && !images.length) {
      newState = { ...newState, missingImageError: i18n.t('IDEA.IMAGE_ERROR') }
      reference = this.imageRef
    }
    if (externalVideoError || (video && !checkYoutubeUrl(video))) {
      newState = { ...newState, invalidYoutubeUrlError: i18n.t('IDEA.EXTERNAL_VIDEO_ERROR') }
      reference = this.externalVideoRef
    }
    if (!isPrivate && isChallengeMandatoryForIdeaCreation && !challenge) {
      newState = { ...newState, challengeError: i18n.t('IDEA.CHALLENGE_MANDATORY_ERROR') }
      reference = this.challengeRef
    }
    if (isOrganizationsActive && !organization) {
      newState = { ...newState, organizationError: i18n.t('IDEA.ORGANIZATION_MANDATORY_ERROR') }
      reference = this.organizationsRef
    }
    if (isTypesActive && !type) {
      newState = { ...newState, typeError: i18n.t('IDEA.TYPE_MANDATORY_ERROR') }
      reference = this.typesRef
    }
    if (Object.values(logisticDataErrors).includes(true)) {
      reference = this.logisticDataRef
    }

    this.setState(newState)

    if (reference) {
      if (reference.current) {
        reference.current.scrollIntoView()
      }
      return false
    }

    return true
  }

  handleTitleChange(value) {
    const { isTitleLoading, checkTitleChange } = this.props
    this.setState({
      title: value,
    })
    if (!isTitleLoading) {
      checkTitleChange(value)
    }
  }

  handleIsAnonymous() {
    const { isAnonymous } = this.state
    this.setState({
      isAnonymous: !isAnonymous,
    })
  }

  handleStateChange(element) {
    return value => {
      this.setState({
        [element]: value,
      })
    }
  }

  handleNumericStateChange(element) {
    const { logisticDataErrors } = this.state
    return value => {
      const error = value < 1 && value !== ''
      this.setState({
        [element]: value,
        logisticDataErrors: {
          ...logisticDataErrors,
          [element]: error,
        },
      })
    }
  }

  handleChallengeChange(value) {
    // console.log('old challenge value',value)
    const { challenge, isGlobal } = this.state
    const { challenges } = this.props
    let innerChallengeId = value
    let isGlobalLocal = false
    if (challenge === value) {
      innerChallengeId = ''
    }
    challenges.map(item => {
      if (item.id === value) {
        // isGlobalLocal = item.isGlobal ? true : false
        isGlobalLocal = !!item.isGlobal
        return null
      }
    })
    if (isGlobal) {
      this.setState({
        challenge: innerChallengeId,
        isGlobal: isGlobalLocal,
      })
    } else {
      this.setState({
        challenge: innerChallengeId,
        isGlobal: isGlobalLocal,
      })
    }
  }

  handleSubmitDraft() {
    debugger;
    const { submitDraft } = this.props
    if (this.validateForm(true)) {
      submitDraft(this.state)
    }
  }

  handleSubmitIdea() {
    const { submitIdea } = this.props
    if (this.validateForm()) {
      submitIdea(this.state)
    }
  }

  handleCancel() {
    const { onCancel } = this.props
    onCancel()
  }

  handleSaveIdea() {
    const { editIdea, isDraft } = this.props
    const { id } = this.state
    if (this.validateForm(isDraft)) {
      editIdea(id, this.state)
    }
  }

  handleResubmitIdea() {
    const { resubmitIdea, editIdea, isDraft } = this.props
    const { id } = this.state
    if (this.validateForm(isDraft)) {
      // resubmitIdea(id, true, this.state)
      editIdea(id, this.state)
      resubmitIdea(id, true, this.state)
    }
  }

  handleDeleteImage(newImages, _imageName, imageToDelete) {
    const { onDeleteImage } = this.props
    const { id } = this.state
    onDeleteImage(id, newImages, imageToDelete)
  }

  isLogisticDataVisible() {
    const {
      showLogisticData,
      isLogisticActiveOnCreate,
      isAdmin,
      isInnovator,
      isPendingValidation,
    } = this.props
    if (isLogisticActiveOnCreate) {
      return showLogisticData
    }
    return showLogisticData && (isAdmin || isInnovator) && isPendingValidation
  }

  handleGlobleIdea() {
    const { isGlobal } = this.state
    this.setState({ isGlobal: !isGlobal, challenge: '' })
  }

  handleDocumentUpload(file) {
    const { onDocumentUpload, videoIdeaId, documents } = this.props
    if (documents.length > 0 && documents[0].key) onDocumentUpload(documents[0].key, file)
    else if (videoIdeaId) onDocumentUpload(videoIdeaId, file)
    else onDocumentUpload(this.id, file)
  }

  handleDeleteDocument(documentId) {
    const { onDocumentDelete } = this.props
    onDocumentDelete(this.id, documentId)
  }

  render() {

    const { match } = this.props

    const {
      classes,
      images,
      imageUploadError,
      fileVideo,
      isFileVideoLoading,
      isFileVideoReady,
      isVideoRemoving,
      onSelectFileVideo,
      onRemoveFileVideo,
      challenges = [],
      organizationsName,
      organizationHelpText,
      organizations = [],
      isOrganizationsActive,
      types = [],
      isTypesActive,
      goals = [],
      navigateToChallenge,
      isTitleLoading,
      isPreviousValidationEnabled,
      currencyIcon,
      onSelectImage,
      logisticCurrency,
      isPrivate,
      isAnonymousIdeas,
      isAnonymousIdeasMandatory,
      creatingIdea,
      fileVideoId,
      hasVideoError,
      isAdmin,
      isInnovator,
      localAdmin,
      localInnovator,
      isOwner,
      isDocumentLoading,
      documents,
      typeTitle,
      typeHelpText,
      csrf,
      domainName,
    } = this.props

    const {
      id,
      titleError,
      missingImageError,
      descriptionError,
      organizationError,
      invalidYoutubeUrlError,
      typeError,
      // main data
      title,
      description,
      video,
      endDate,
      // challenges
      challenge,
      // organizations
      organization,
      // types
      type,
      // goals
      goalValues,
      // logistic data
      implementEstimatedHours,
      implementEstimatedQuantity,
      implementEstimatedTime,
      implementEstimatedTimeType,
      pilotEstimatedHours,
      pilotEstimatedQuantity,
      pilotEstimatedTime,
      pilotEstimatedTimeType,
      economicBenefits,
      otherBenefits,
      challengeError,
      logisticDataErrors,
      // anonymous
      isAnonymous,
      isGlobal,
      isServiceLineActivated,
      
    } = this.state

    return (
      <div className={classes.root}>
        <h1>
          <span className="icon-idea" />
          {i18n.t('IDEA.CREATE')}
        </h1>
        <Paper className={classes.paper}>
          <NxtCircularLoader isLoading={creatingIdea}>
            <MainFormSection
              id={id}
              titleValue={title}
              descriptionValue={description}
              imageValues={images}
              externalVideoValue={video}
              onExternalVideoError={error => this.setState({ externalVideoError: error })}
              onExternalVideoChange={this.handleStateChange('video')}
              fileVideoValue={fileVideo}
              onSelectFileVideo={() =>
                onSelectFileVideo(documents.length > 0 ? documents[0].key : id)
              }
              onRemoveFileVideo={() => onRemoveFileVideo(fileVideoId)}
              isFileVideoLoading={isFileVideoLoading}
              isFileVideoReady={isFileVideoReady}
              isVideoRemoving={isVideoRemoving}
              hasVideoError={hasVideoError}
              expireDateValue={endDate}
              onTitleChange={this.handleTitleChange}
              onDescriptionChange={this.handleStateChange('description')}
              onSelectImage={image => onSelectImage(id, image)}
              deleteImage={this.handleDeleteImage}
              onChangeExpiredDate={this.handleStateChange('endDate')}
              isTitleLoading={isTitleLoading}
              titleError={titleError}
              descriptionError={descriptionError}
              imageError={imageUploadError}
              titleRef={this.titleRef}
              descriptionRef={this.descriptionRef}
              imageRef={this.imageRef}
              missingImageError={missingImageError}
              externalVideoRef={this.externalVideoRef}
              invalidYoutubeUrlError={invalidYoutubeUrlError}
              canEditTitle={id !== undefined}
              handleGlobleIdea={this.handleGlobleIdea}
              globalIdeaCheck={isGlobal}
              serviceLineActivated={isServiceLineActivated}
              handleDocumentUpload={this.handleDocumentUpload}
              handleDeleteDocument={this.handleDeleteDocument}
              isDocumentLoading={isDocumentLoading}
              documents={documents}
              isOwner={isOwner}
              isAdmin={isAdmin}
              isInnovator={isInnovator}
              localAdmin={localAdmin}
              localInnovator={localInnovator}
              csrf={csrf}
              domainName={domainName}
            />
            <SimpleDivider />
            {!isPrivate && (
              <div className={classes.section} ref={this.challengeRef}>
                <h2>
                  <span className="icon-target" />
                  {i18n.t('CHALLENGE.TITLE')}
                </h2>
                <RadioSelector
                  onValueChange={this.handleChallengeChange}
                  items={challenges}
                  linkName={i18n.t('EXPLORE_IDEA.VIEW_CHALLENGE')}
                  helpText={i18n.t('CHALLENGE.DESC')}
                  value={challenge}
                  navigateTo={navigateToChallenge}
                  error={challengeError}
                  globalIdeaCheck={isGlobal}
                />
                <SimpleDivider />
              </div>
            )}
            {isOrganizationsActive && (
              <div className={classes.section} ref={this.organizationsRef}>
                <h2>
                  <span className="icon-th-list" />
                  {`${organizationsName}*`} {/* Org field header */}
                </h2>
                <RadioSelector
                  items={organizations}
                  helpText={organizationHelpText}
                  subItemsKey="organizations"
                  value={organization}
                  onValueChange={this.handleStateChange('organization')}
                  error={organizationError}
                />
                {organizations.length === 0 && (
                  <h3 className={classes.noOrgsSpan}>{i18n.t('ORGANIZATION.NO_ACTIVE')}</h3>
                )}
                <SimpleDivider />
              </div>
            )}
            {isTypesActive && (
              <div className={classes.section} ref={this.typesRef}>
                <h2>
                  <span className="icon-th-list" />
                  {`${typeTitle}*`}
                </h2>
                <RadioSelector
                  items={types}
                  helpText={typeHelpText}
                  value={type}
                  onValueChange={this.handleStateChange('type')}
                  error={typeError}
                />
                <SimpleDivider />
              </div>
            )}

            {goals.length > 0 && (
              <div className={classes.section}>
                <h2>
                  <span className="icon-web" />
                  {i18n.t('IDEA.STRATEGIC')}
                </h2>
                <GoalsSlider
                  helpText={i18n.t('IDEA.STRATEGIC_SUB')}
                  goals={goals}
                  values={goalValues}
                  onGoalChange={this.handleStateChange('goalValues')}
                />
                <SimpleDivider />
              </div>
            )}

            {this.isLogisticDataVisible() && (
              <div className={classes.section} ref={this.logisticDataRef}>
                <h2>
                  <span className="icon-calculator" />
                  {i18n.t('LOGISTIC.TITLE')}
                </h2>
                <LogisticData
                  logisticCurrency={logisticCurrency}
                  implementEstimatedHours={implementEstimatedHours}
                  implementEstimatedQuantity={implementEstimatedQuantity}
                  implementEstimatedTime={implementEstimatedTime}
                  implementEstimatedTimeType={implementEstimatedTimeType}
                  pilotEstimatedHours={pilotEstimatedHours}
                  pilotEstimatedQuantity={pilotEstimatedQuantity}
                  pilotEstimatedTime={pilotEstimatedTime}
                  pilotEstimatedTimeType={pilotEstimatedTimeType}
                  economicBenefits={economicBenefits}
                  otherBenefits={otherBenefits}
                  onImplementQuantityChange={this.handleNumericStateChange(
                    'implementEstimatedQuantity',
                  )}
                  onImplementHoursChange={this.handleNumericStateChange('implementEstimatedHours')}
                  onImplementTimeChange={this.handleNumericStateChange('implementEstimatedTime')}
                  onImplementTimeTypeChange={this.handleStateChange('implementEstimatedTimeType')}
                  onPilotQuantityChange={this.handleNumericStateChange('pilotEstimatedQuantity')}
                  onPilotHoursChange={this.handleNumericStateChange('pilotEstimatedHours')}
                  onPilotTimeChange={this.handleNumericStateChange('pilotEstimatedTime')}
                  onPilotTimeTypeChange={this.handleStateChange('pilotEstimatedTimeType')}
                  onEconomicBenefitsChange={this.handleNumericStateChange('economicBenefits')}
                  onOtherBenefitsChange={this.handleStateChange('otherBenefits')}
                  logisticDataErrors={logisticDataErrors}
                />
                <SimpleDivider />
              </div>
            )}
            {!isAnonymousIdeasMandatory && isAnonymousIdeas && (
              <div className={classes.section}>
                <h2>
                  <span className="icon-help" />
                  {i18n.t('IDEA.ANONYMOUS')}
                </h2>
                <NxtFormHelper
                  helpText={i18n.t('IDEA.ANONYMOUS_SUB').replace('##BRAND_NAME##', '')}
                >
                  <FormControlLabel
                    className={classes.check}
                    control={<NxtCheckbox isChecked={isAnonymous} />}
                    onClick={this.handleIsAnonymous}
                    label={i18n.t('IDEA.ANONYMOUS_CHECK')}
                  />
                </NxtFormHelper>
                <SimpleDivider />
              </div>
            )}
          </NxtCircularLoader>
        </Paper>
        <ButtonsFooter
          isPreviousValidationEnabled={isPreviousValidationEnabled}
          onInitialInvestmentChange={this.handleNumericStateChange('initialInvestment')}
          currencyIcon={currencyIcon}
          edit={id !== undefined}
          isSaveButtonEnable={isAdmin || isInnovator || localAdmin || localInnovator}
          onSubmitDraft={this.handleSubmitDraft}
          onSubmitIdea={this.handleSubmitIdea}
          onCancel={this.handleCancel}
          onSaveIdea={this.handleSaveIdea}
          onResubmitIdea={this.handleResubmitIdea}
          isSubmitting={creatingIdea}
        />
      </div>
    )
  }
}

IdeaForm.defaultProps = {
  title: '',
  description: '',
  externalVideo: '',
  challenge: '',
  challenges: [],
  organization: '',
  organizations: [],
  organizationsName: '',
  organizationHelpText: '',
  isOrganizationsActive: false,
  type: '',
  types: [],
  isTypesActive: false,
  goalValues: {},
  goals: [],
  isTitleLoading: false,
  isTitleAvailable: true,
  isPreviousValidationEnabled: false,
}

IdeaForm.propTypes = {
  title: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      src: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ).isRequired,
  imageUploadError: PropTypes.string.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  onDeleteImage: PropTypes.func.isRequired,
  externalVideo: PropTypes.string,
  checkVideoState: PropTypes.func.isRequired,
  fileVideoId: PropTypes.string.isRequired,
  fileVideo: PropTypes.string.isRequired,
  isFileVideoLoading: PropTypes.bool.isRequired,
  isFileVideoReady: PropTypes.bool.isRequired,
  isVideoRemoving: PropTypes.bool.isRequired,
  hasVideoError: PropTypes.bool.isRequired,
  onVideoEncodingError: PropTypes.func.isRequired,
  onSelectFileVideo: PropTypes.func.isRequired,
  onRemoveFileVideo: PropTypes.func.isRequired,
  description: PropTypes.string,
  challenge: PropTypes.string,
  challenges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  organization: PropTypes.string,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  organizationsName: PropTypes.string,
  organizationHelpText: PropTypes.string,
  isOrganizationsActive: PropTypes.bool,
  type: PropTypes.string,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  isTypesActive: PropTypes.bool,
  goalValues: PropTypes.shape({}),
  goals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  isTitleLoading: PropTypes.bool,
  isTitleAvailable: PropTypes.bool,
  isPreviousValidationEnabled: PropTypes.bool,
  navigateToChallenge: PropTypes.func.isRequired,
  currencyIcon: PropTypes.string.isRequired,
  getGoals: PropTypes.func.isRequired,
  getChallenges: PropTypes.func.isRequired,
  getOrganizations: PropTypes.func.isRequired,
  getTypes: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitDraft: PropTypes.func.isRequired,
  submitIdea: PropTypes.func.isRequired,
  checkTitleChange: PropTypes.func.isRequired,
  getIdea: PropTypes.func.isRequired,
  editIdea: PropTypes.func.isRequired,
  resubmitIdea: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // Logistic data
  implementEstimatedHours: PropTypes.string.isRequired,
  implementEstimatedQuantity: PropTypes.string.isRequired,
  implementEstimatedTime: PropTypes.string.isRequired,
  implementEstimatedTimeType: PropTypes.string.isRequired,
  pilotEstimatedHours: PropTypes.string.isRequired,
  pilotEstimatedQuantity: PropTypes.string.isRequired,
  pilotEstimatedTime: PropTypes.string.isRequired,
  pilotEstimatedTimeType: PropTypes.string.isRequired,
  economicBenefits: PropTypes.string.isRequired,
  otherBenefits: PropTypes.string.isRequired,
  logisticCurrency: PropTypes.string.isRequired,
  isChallengeMandatoryForIdeaCreation: PropTypes.bool.isRequired,
  isDraft: PropTypes.bool.isRequired,
  isLogisticActiveOnCreate: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isInnovator: PropTypes.bool.isRequired,
  isPendingValidation: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
}

export default withStyles(style)(IdeaForm)

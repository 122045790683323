const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 0),
    '& > span:first-child': {
      fontSize: '2rem',
      marginRight: theme.spacing(1),
    },
  },
  titleText: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  ideaLinkButton: {
    textDecoration: 'unset',
    fontFamily: 'unset',
    marginLeft: theme.spacing(1.2),
    '&:hover': {
      textDecoration: 'unset',
    },
  },
  noIdeasPaper: {
    padding: theme.spacing(2, 3),
    textAlign: 'center',
    fontSize: '0.875rem',
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
})

export default styles

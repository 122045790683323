import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    '@global': {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    content: {
      gridArea: 'content',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      gridTemplateAreas: '"loginContent"',
    },
    completeText: {
      gridArea: 'completeText',
      display: 'flex',
      maxWidth: '31rem',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      background: props =>
        props.useCustomLogin && props.useCustomBgImg ? theme.palette.common.white : 'unset',
      borderRadius: props => (props.useCustomLogin && props.useCustomBgImg ? '0.3rem' : 'unset'),
      color: props =>
        props.useCustomLogin && props.useCustomBgImg ? theme.palette.common.black : 'unset',
      padding: props => (props.useCustomLogin && props.useCustomBgImg ? '1rem' : 'unset'),
      '& > span': {
        paddingTop: theme.spacing(3),
      },
    },
    login: {
      gridArea: 'loginContent',
      padding: '0 3rem',
      display: 'grid',
      gridTemplateColumns: '1fr 31rem 1fr',
      gridTemplateRows: '20% 1fr 20%',
      gridTemplateAreas: '". loginTitle ." ". completeText ."',
    },
    terms: {
      gridArea: 'loginForm',
    },
    title: {
      display: 'flex',
      textAlign: 'right',
      alignItems: 'flex-end',
      gridArea: 'loginTitle',
      paddingBottom: theme.spacing(1 / 2),
      '& > h1': {
        fontFamily: '"Open Sans", open-sans, sans-serif',
        fontSize: '28px',
        fontWeight: '400',
        width: '100%',
        margin: 0,
        '& small': {
          display: 'block',
          marginTop: '10px',
          fontSize: '16px',
        },
      },
    },
  })

export default styles

export const GET_INVESTMENTS_STARTED = 'GET_INVESTMENTS_STARTED'
export const GET_INVESTMENTS_SUCCESS = 'GET_INVESTMENTS_SUCCESS'
export const GET_INVESTMENTS_FAILURE = 'GET_INVESTMENTS_FAILURE'

export const EDIT_PROFILE_STARTED = 'EDIT_PROFILE_STARTED'
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS'
export const EDIT_PROFILE_FAILURE = 'EDIT_PROFILE_FAILURE'

export const POST_REQUEST_INVESTMENT_STARTED = 'POST_REQUEST_INVESTMENT_STARTED'
export const POST_REQUEST_INVESTMENT_SUCCESS = 'POST_REQUEST_INVESTMENT_SUCCESS'
export const POST_REQUEST_INVESTMENT_FAILURE = 'POST_REQUEST_INVESTMENT_FAILURE'


export const APPLY_FILTER_INVESTMENTS_STARTED = 'APPLY_FILTER_INVESTMENTS_STARTED'
export const APPLY_FILTER_INVESTMENTS_SUCCESS = 'APPLY_FILTER_INVESTMENTS_SUCCESS'
export const APPLY_FILTER_INVESTMENTS_FAILURE = 'APPLY_FILTER_INVESTMENTS_FAILURE'
import { connect } from 'react-redux'
import { getInvestments } from 'services/user'
import Navigation from 'support/navigation'
import View from './View'

const mapStateToProps = state => {
  return {
    ideas: state.data.user.investments.ideas,
    totalInvested: state.data.user.investments.totalInvested,
    totalProfit: state.data.user.investments.totalProfit,
    fullInfoLoaded: state.data.domain.dataLoaded,
    userEmail: state.data.user.userEmail,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getInvestments: userEmail => {
      dispatch(getInvestments(userEmail))
    },
    onTitleClick: id => {
      dispatch(Navigation.navigateToIdea(id))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

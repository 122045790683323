import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    data: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '80%',
      padding: '0 1rem',
    },
    dataList: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      height: '11.5rem',
      maxHeight: '11.5rem',
      overflowY: 'auto',
      width: '400px',
    },
    dataItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0.3rem 0.3rem 0.3rem 1rem',
      '&:hover': {
        backgroundColor: '#00000063',
      },
    },
    earnList: {
      listStyle: 'none',
      width: '300px',
      margin: 0,
      padding: 0,
      '& strong': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '0.5rem',
        '& > button': {
          color: theme.palette.common.white,
          fontSize: '14px',
          '&:hover': {
            color: theme.palette.primary.main,
          },
        },
      },
      '& b': {
        fontSize: '24px',
      },
    },
    howItWorks: {
      marginTop: '10px',
    },
    exploreBottomCenter: {
      gridArea: 'footerCenter',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: '"left right"',
      '& [class^="icon-"]': {
        marginRight: '0.5rem',
      },
      '& > div': {
        padding: '1rem',
        paddingBottom: '2rem',
      },
      '& button': {
        color: theme.palette.common.white,
      },
    },
    left: {
      gridArea: 'left',
    },
    // center: {
    //   gridArea: 'center',
    // },
    right: {
      gridArea: 'right',
    },
    helpList: {
      listStyle: 'none',
      maxHeight: '20rem',
      overflow: 'hidden',
      width: '200px',
    },
    helpItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > b, & > div > a': {
        fontWeight: 'bold',
      },
      '& div': {
        padding: '0.5rem',
      },
      '& button': {
        display: 'block',
        color: theme.palette.common.white,
        fontSize: '1.2rem',
      },
    },
    investedAmount: {
      color: theme.palette.primary.main,
    },
    lineColor: {
      borderTopColor: 'rgba(255, 255, 255, 0.18)',
    },
    lineRoot: {
      padding: 0,
    },
    linkButton: {
      fontSize: '1rem',
    },
    seeAll: {
      '& a': {
        color: theme.palette.common.white,
        '&:hover': {
          color: '#0096e2',
        },
      },
    },
    tabs: {
      backgroundColor: '#303030',
      marginBottom: '1.5rem',
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      marginBottom: '1rem',
      height: '3rem',
      '& span': {
        fontSize: '32px',
      },
      '& h3': {
        fontSize: '20px',
        margin: 0,
        '& > a': {
          color: theme.palette.common.white,
        },
        '& > button': {
          fontSize: '20px',
          fontWeight: 'bold',
          textAlign: 'start',
        },
      },
    },
    typographyRoot: {
      '& > div': {
        padding: '0.5rem 0',
      },
    },
    userAvatar: {
      width: '20%',
      '& img': {
        display: 'inherit',
        width: 42,
        height: 42,
        borderRadius: 42,
      },
    },
    userName: {
      '& p': {
        margin: 0,
      },
      '& p:first-child': {
        marginBottom: '0.1',
      },
    },
  })

export default styles

import React from 'react'
import { withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'

const SignUpComplete = props => {
  const { classes } = props

  return (
    <div className={classes.content}>
      <div className={classes.login}>
        <div className={classes.completeText}>
          <h1>{i18n.t('COMMON_LOGIN.HEADER.SIGN_UP_COMPLETE_TITLE')}</h1>
          <span>{i18n.t('COMMON_LOGIN.HEADER.SIGN_UP_COMPLETE_TEXT')}</span>
        </div>
      </div>
    </div>
  )
}

SignUpComplete.propTypes = {}

export default withStyles(styles)(SignUpComplete)

const styles = theme => ({
  root: {
    backgroundColor: 'yellow',
    minHeight: '100vh',
    minWidth: '100vw',
  },
  slidesBG: {
    backgroundColor: 'red',
  },
  top: {
    marginTop: '200px',
  },
  icon: {
    marginTop: '100px',
  },
  buttons: {
    marginTop: '100px',
    marginBottom: '100px',
  },
  mainContainer: {
    height: '100%',
    display: 'flex',
  },
  buttonAccess: {
    backgroundColor: 'red',
    border: '1px solid white',
    borderColor: 'white',
    borderRadius: '5rem',
    color: 'white',
    padding: '20px',
    textDecoration: 'solid',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#fabd44',
      opacity: 1,
    },
    '&:focus': {
      outline: 0,
    },
  },
  buttonAccessDisabled: {
    backgroundColor: 'grey',
    border: '1px solid white',
    borderColor: 'white',
    borderRadius: '5rem',
    color: 'white',
    padding: '20px',
    textDecoration: 'solid',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'grey',
      opacity: 1,
    },
    '&:focus': {
      outline: 0,
    },
  },
  buttonExAccess: {
    backgroundColor: 'red',
    border: '1px solid white',
    borderColor: 'white',
    borderRadius: '5rem',
    color: 'white',
    padding: '20px',
    textDecoration: 'solid',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#fabd44',
      opacity: 1,
    },
    '&:focus': {
      outline: 0,
    },
  },
  footer: {
    // position: 'absolute',
    // display: 'flex',
    // flexDirection: 'row-reverse',
    // bottom: '10px',
    // width: '95%',
    position: 'fixed',
    right: 0,
    bottom: '1rem',
    width: '50%',
    display: 'flex',
    flexDirection: 'row-reverse',
    backgroundColor: 'blue',
  },
  tnc: {
    cursor: 'pointer',
    fontSize: 'small',
    background: 'none',
    border: 'none',
    padding: 0,
    outline: 0,
    '&:focus': {
      outline: 0,
    },
  },
})

export default styles

import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    amount: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    days: {
      margin: 0,
    },
    descriptionCard: {
      width: '50%',
      backgroundColor: '#333',
      color: theme.palette.common.white,
    },
    dialGraph: {
      width: '96px',
      height: '96px',
      display: 'inline-block',
      position: 'relative',
      top: '7px',
      left: '-24px',
      background: '#333',
      borderRadius: '100%',
      padding: '12px',
    },
    dialGraphText: {
      position: 'relative',
      top: '-102px',
      left: '-12px',
      marginBottom: '0',
      width: '96px',
      height: '96px',
      lineHeight: '96px',
      textAlign: 'center',
      fontSize: '12px',
    },
    direction: theme.direction,
    graphBlock: {},
    sliderContent: {
      display: 'flex',
      flexDirection: 'row',
    },
    stepperRoot: {
      paddingLeft: 0,
      paddingRight: 0,
      '& button > span': {
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
  })

export default styles

import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'

const SliderModifiedPanel = props => {
  const { classes, currentValue, modifiedValue } = props
  return (
    <div className={classes.root}>
      <span>{`${i18n.t('CHALLENGES.ACTAUL')}: ${currentValue}`}</span>
      <span className={classes.modifiedWrapper}>
        <span className={classes.modifiedLabel}>{i18n.t('CHALLENGES.MODIFIED')}: </span>
        {modifiedValue}
      </span>
    </div>
  )
}
SliderModifiedPanel.propTypes = {
  currentValue: PropTypes.string.isRequired,
  modifiedValue: PropTypes.string.isRequired,
}
export default withStyles(styles)(SliderModifiedPanel)

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'
export const SET_DATES = 'SET_DATES'

export const GET_ACTIVITY_TABLE_STARTED = 'GET_ACTIVITY_TABLE_STARTED'
export const GET_ACTIVITY_TABLE_SUCCESS = 'GET_ACTIVITY_TABLE_SUCCESS'
export const GET_ACTIVITY_TABLE_FAILURE = 'GET_ACTIVITY_TABLE_FAILURE'

export const REQUEST_DASHBOARD_REPORT_STARTED = 'REQUEST_DASHBOARD_REPORT_STARTED'
export const REQUEST_DASHBOARD_REPORT_SUCCESS = 'REQUEST_DASHBOARD_REPORT_SUCCESS'
export const REQUEST_DASHBOARD_REPORT_FAILURE = 'REQUEST_DASHBOARD_REPORT_FAILURE'

export const GET_IDEAS_TABLE_STARTED = 'GET_IDEAS_TABLE_STARTED'
export const GET_IDEAS_TABLE_SUCCESS = 'GET_IDEAS_TABLE_SUCCESS'
export const GET_IDEAS_TABLE_FAILURE = 'GET_IDEAS_TABLE_FAILURE'

export const GET_PEOPLE_TABLE_STARTED = 'GET_PEOPLE_TABLE_STARTED'
export const GET_PEOPLE_TABLE_SUCCESS = 'GET_PEOPLE_TABLE_SUCCESS'
export const GET_PEOPLE_TABLE_FAILURE = 'GET_PEOPLE_TABLE_FAILURE'

export const GET_PRIVATE_IDEAS_TABLE_STARTED = 'GET_PRIVATE_IDEAS_TABLE_STARTED'
export const GET_PRIVATE_IDEAS_TABLE_SUCCESS = 'GET_PRIVATE_IDEAS_TABLE_SUCCESS'
export const GET_PRIVATE_IDEAS_TABLE_FAILURE = 'GET_PRIVATE_IDEAS_TABLE_FAILURE'

export const GET_SYSTEM_ACCESSES_STARTED = 'GET_SYSTEM_ACCESSES_STARTED'
export const GET_SYSTEM_ACCESSES_SUCCESS = 'GET_SYSTEM_ACCESSES_SUCCESS'
export const GET_SYSTEM_ACCESSES_FAILURE = 'GET_SYSTEM_ACCESSES_FAILURE'

export const GET_IDEAS_SERIES_STARTED = 'GET_IDEAS_SERIES_STARTED'
export const GET_IDEAS_SERIES_SUCCESS = 'GET_IDEAS_SERIES_SUCCESS'
export const GET_IDEAS_SERIES_FAILURE = 'GET_IDEAS_SERIES_FAILURE'

export const GET_PRIVATE_IDEAS_SERIES_STARTED = 'GET_PRIVATE_IDEAS_SERIES_STARTED'
export const GET_PRIVATE_IDEAS_SERIES_SUCCESS = 'GET_PRIVATE_IDEAS_SERIES_SUCCESS'
export const GET_PRIVATE_IDEAS_SERIES_FAILURE = 'GET_PRIVATE_IDEAS_SERIES_FAILURE'

export const GET_USERS_TABLE_STARTED = 'GET_USERS_TABLE_STARTED'
export const GET_USERS_TABLE_SUCCESS = 'GET_USERS_TABLE_SUCCESS'
export const GET_USERS_TABLE_FAILURE = 'GET_USERS_TABLE_FAILURE'

export const GET_USERS_PARTICIPATION_STARTED = 'GET_USERS_PARTICIPATION_STARTED'
export const GET_USERS_PARTICIPATION_SUCCESS = 'GET_USERS_PARTICIPATION_SUCCESS'
export const GET_USERS_PARTICIPATION_FAILURE = 'GET_USERS_PARTICIPATION_FAILURE'

export const SET_USERS_TABLE_FILTER = 'SET_USERS_TABLE_FILTER'
export const SET_IDEAS_TABLE_FILTER = 'SET_IDEAS_TABLE_FILTER'
export const SET_PRIVATE_IDEAS_TABLE_FILTER = 'SET_PRIVATE_IDEAS_TABLE_FILTER'

export const SEND_USERS_REPORT_STARTED = 'SEND_USERS_REPORT_STARTED'
export const SEND_USERS_REPORT_SUCCESS = 'SEND_USERS_REPORT_SUCCESS'
export const SEND_USERS_REPORT_FAILURE = 'SEND_USERS_REPORT_FAILURE'

export const GET_PRIVATE_IDEAS_TAB_FAILURE = 'GET_PRIVATE_IDEAS_TAB_FAILURE'
export const GET_PRIVATE_IDEAS_TAB_STARTED = 'GET_PRIVATE_IDEAS_TAB_STARTED'
export const GET_PRIVATE_IDEAS_TAB_SUCCESS = 'GET_PRIVATE_IDEAS_TAB_SUCCESS'

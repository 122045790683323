import React from 'react'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from './View.Style'

const ButtonLink = props => {
  const { classes, children, flat, onLinkClick } = props
  return (
    <button
      className={clsx(classes.linkButton, flat && classes.flat)}
      onClick={onLinkClick}
      type="button"
    >
      {children}
    </button>
  )
}

ButtonLink.defaultProps = {
  color: 'default',
  flat: false,
}

ButtonLink.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  flat: PropTypes.bool,
  onLinkClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(ButtonLink)

import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Paper } from '@material-ui/core'
import i18n from 'support/i18n'
import NxtMiniCard from 'components/nxt_mini_card/View'
import ButtonLink from 'components/button_link/View'
import styles from './View.Style'

const TeamsSection = props => {
  const { classes, ideas, onIdeaClick, username, sectionText } = props
  return (
    <div className={classes.root}>
      <div className={classes.titleSection}>
        <span className="icon-team" />
        <span className={classes.titleText}>{sectionText}</span>
      </div>

      {ideas.length > 0 && (
        <div>
          {ideas.map(idea => (
            <ButtonLink
              key={idea.id}
              onLinkClick={() => onIdeaClick(idea.id)}
              classes={{ linkButton: classes.ideaLinkButton }}
            >
              <Paper className={classes.paper} square>
                <NxtMiniCard
                  title={idea.title}
                  imageSrc={idea.imageSrc}
                  investors={idea.investors}
                  pledged={idea.pledged}
                />
              </Paper>
            </ButtonLink>
          ))}
        </div>
      )}

      {ideas.length === 0 && (
        <Paper className={classes.noIdeasPaper}>
          {username} {i18n.t('PROFILE.NO_TEAMS')}
        </Paper>
      )}
    </div>
  )
}

TeamsSection.propTypes = {
  ideas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onIdeaClick: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  sectionText: PropTypes.string.isRequired,
}

export default withStyles(styles)(TeamsSection)

import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    badgeBox: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '88px',
      height: '88px',
      overflow: 'hidden',
    },
    badgeBoxCorner: {
      backgroundColor: theme.palette.primary.main,
      position: ' relative',
      width: ' 140px',
      height: ' 70px',
      top: ' -15px',
      left: ' -51px',
      transform: ' rotate(-45deg)',
      '& > p': {
        color: '#fff',
        position: 'absolute',
        left: '0',
        bottom: 4,
        fontSize: '12px',
        fontWeight: 'bold',
        width: '100%',
        margin: '0',
        textAlign: 'center',
        textTransform: 'uppercase',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
      },
      '& > p > i': {
        margin: 0,
        fontSize: '25px',
      },
    },
  })

export default styles

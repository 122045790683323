import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, Paper } from '@material-ui/core'
import i18n from 'support/i18n'
import bulbImage from 'img/bulb_rocket.png'
import Image from 'components/image/View'
import { MainTooltip } from 'components'
import clsx from 'clsx'
import styles from './View.Style'

const BadgesSection = props => {
  const { classes, badges, onShowAllClick, buttonLabel, showAll, hasBadges } = props
  return (
    <div className={classes.root}>
      <div className={classes.titleSection}>
        <div>
          <span className="icon-best" />
          <span className={classes.titleText}>{i18n.t('PROFILE.BADGES')}</span>
        </div>
        <Button onClick={onShowAllClick}>{buttonLabel}</Button>
      </div>
      <div className={classes.badgesSection}>
        {(hasBadges || showAll) &&
          badges.map(badge => (
            <MainTooltip title={badge.tooltip} placement="bottom" key={badge.name}>
              <div>
                <Image
                  defaultSrc={bulbImage}
                  src={badge.imgSrc}
                  className={clsx(
                    !badge.isActive && classes.isNotActive,
                    !showAll && !badge.isActive && classes.isHidden,
                  )}
                />
              </div>
            </MainTooltip>
          ))}
        {!hasBadges && !showAll && (
          <Paper className={classes.noBadgesPaper}>{i18n.t('BADGES.EMPTY')}</Paper>
        )}
      </div>
    </div>
  )
}

BadgesSection.propTypes = {
  showAll: PropTypes.bool.isRequired,
  hasBadges: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      imgSrc: PropTypes.string,
      name: PropTypes.string,
      tooltip: PropTypes.string,
    }),
  ).isRequired,
  onShowAllClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(BadgesSection)

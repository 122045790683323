import React, { Component, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Filter, ShowFilter } from 'components'
import i18n from 'support/i18n'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import { FIRST_LIMIT_CONSTANT, LIMIT_CONSTANT } from 'common_constants/Ideas'
import styles from './View.Style'
import ExploreFilterFields from '../explore/components/explore_filter_fields/View'
import SearchInput from '../explore/components/search_input/View'

class PrivateIdeas extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFilters: {
        status: [],
        challenge: {},
        organizations: [],
        types: [],
      },
      offset: 0,
      search: '',
      showSlider: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
    this.handleOnLoadMore = this.handleOnLoadMore.bind(this)
    this.handleActiveFilters = this.handleActiveFilters.bind(this)
    this.getIdeas = this.getIdeas.bind(this)
    this.handleResetSearchClick = this.handleResetSearchClick.bind(this)
  }

  componentDidMount() {
    this.getIdeas()
  }

  getIdeas(offset = 0) {
    const { getPrivateIdeas } = this.props
    const { activeFilters } = this.state
    const { challenge, status, organizations, types } = activeFilters
    getPrivateIdeas({
      challenge,
      organizations: organizations.map(org => `${org.id}`),
      status,
      types: types.map(type => `${type.id}`),
      offset,
    })
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleActiveFilters(newState) {
    this.setState({ ...newState, offset: 0 }, this.getIdeas)
  }

  handleFilter(value) {
    this.setState({ showSlider: value })
  }

  handleOnLoadMore() {
    const { offset } = this.state
    const newLimit = offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT
    const newOffset = newLimit + offset
    this.getIdeas(newOffset)
    this.setState({ offset: newOffset })
  }

  handleResetSearchClick() {
    this.getIdeas()
    this.setState({ offset: 0 })
  }

  render() {
    const {
      getPrivateIdeas,
      applySearch,
      classes,
      getOrganization,
      getTypes,
      hasMoreIdeasToLoad,
      ideas,
      ideaStatusStepper,
      isOrganizationsActive,
      isTypesActive,
      navigateToIdeaDetail,
      organizationsName,
      organizations,
      folderOrganizations,
      totalIdeas,
      types,
      isIdeasLoading,
    } = this.props
    const { activeFilters, search, showSlider } = this.state
    // console.log("inside private ideas")
    return (
      <Fragment>
        <div className={classes.top}>
          <div className={classes.topCenter}>
            <h1>
              <i className="icon-target" /> {i18n.t('SCREEN.PRIVATE_IDEAS')}
            </h1>
          </div>
        </div>
        <div className={classes.middle}>
          <div className={classes.center}>
            <Filter className={classes.center}>
              <ExploreFilterFields
                activeFilters={activeFilters}
                applyFilter={getPrivateIdeas}
                challengeDetail={this.id}
                className={classes.gridCenter}
                getOrganization={getOrganization}
                getTypes={getTypes}
                organizationsName={organizationsName}
                organizations={organizations}
                folderOrganizations={folderOrganizations}
                types={types}
                isOrganizationsActive={isOrganizationsActive}
                isTypesActive={isTypesActive}
                handleActiveFilters={this.handleActiveFilters}
              />
            </Filter>
            <div className={classes.ideasFilter}>
              <SearchInput
                applySearch={applySearch}
                resetSearch={this.handleResetSearchClick}
                handleChange={this.handleChange}
                value={search}
              />
            </div>
            <NxtCircularLoader isLoading={isIdeasLoading}>
              <ShowFilter
                cards={ideas}
                showSlider={showSlider}
                onClick={navigateToIdeaDetail}
                onCardTypeChange={this.handleFilter}
                onLoadMore={this.handleOnLoadMore}
                totalIdeas={totalIdeas}
                showLoadMoreButton={hasMoreIdeasToLoad}
                ideaStatusStepper={ideaStatusStepper}
                showSmallCardCircle
              />
            </NxtCircularLoader>
          </div>
        </div>
      </Fragment>
    )
  }
}

PrivateIdeas.propTypes = {
  getPrivateIdeas: PropTypes.func.isRequired,
  navigateToIdeaDetail: PropTypes.func.isRequired,
  ideas: PropTypes.arrayOf(
    PropTypes.shape({
      challenged: PropTypes.bool,
      textWithoutTags: PropTypes.string,
      id: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          bucket: PropTypes.string,
          key: PropTypes.string,
          name: PropTypes.string,
          size: PropTypes.number,
          url: PropTypes.string,
        }),
      ),
      investors: PropTypes.string,
      goal: PropTypes.string,
      pledged: PropTypes.string,
      profit: PropTypes.string,
      sponsored: PropTypes.bool,
      ownerName: PropTypes.string,
      title: PropTypes.string,
      created: PropTypes.number,
      countdownEnds: PropTypes.number,
      status: PropTypes.string,
    }),
  ).isRequired,
  isIdeasLoading: PropTypes.bool.isRequired,
}

export default withStyles(styles)(PrivateIdeas)

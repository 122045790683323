export const CONFIG_STARTED = 'CONFIG_STARTED'
export const CONFIG_SUCCESS = 'CONFIG_SUCCESS'
export const CONFIG_FAILURE = 'CONFIG_FAILURE'

export const FULL_INFO_STARTED = 'FULL_INFO_STARTED'
export const FULL_INFO_SUCCESS = 'FULL_INFO_SUCCESS'
export const FULL_INFO_FAILURE = 'FULL_INFO_FAILURE'

export const WHITELIST_DOMAIN_STARTED = 'WHITELIST_DOMAIN_STARTED'
export const WHITELIST_DOMAIN_SUCCESS = 'WHITELIST_DOMAIN_SUCCESS'
export const WHITELIST_DOMAIN_FAILURE = 'WHITELIST_DOMAIN_FAILURE'


export const NOTIFICATION_ME_STARTED = 'NOTIFICATION_ME_STARTED'
export const NOTIFICATION_ME_SUCCESS = 'NOTIFICATION_ME_SUCCESS'
export const NOTIFICATION_ME_FAILURE = 'NOTIFICATION_ME_FAILURE'

export const REJECT_REASONS_STARTED = 'REJECT_REASONS_STARTED'
export const REJECT_REASONS_SUCCESS = 'REJECT_REASONS_SUCCESS'
export const REJECT_REASONS_FAILURE = 'REJECT_REASONS_FAILURE'

export const APPROVED_REASONS_STARTED = 'APPROVED_REASONS_STARTED'
export const APPROVED_REASONS_SUCCESS = 'APPROVED_REASONS_SUCCESS'
export const APPROVED_REASONS_FAILURE = 'APPROVED_REASONS_FAILURE'

export const NOTIFICATIONS_READ_STARTED = 'NOTIFICATIONS_READ_STARTED'
export const NOTIFICATIONS_READ_SUCCESS = 'NOTIFICATIONS_READ_SUCCESS'
export const NOTIFICATIONS_READ_FAILURE = 'NOTIFICATIONS_READ_FAILURE'

import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import styles from './View.Style'

const SimpleDivider = props => {
  const { classes, classNameProp = {}, text = '' } = props
  const className = {
    line: classNameProp.line || classes.line,
    lineColor: classNameProp.lineColor || classes.lineColor,
    root: classNameProp.root || '',
    textDivider: classNameProp.textDivider || classes.textDivider,
  }
  return (
    <div className={clsx(classes.root, className.root)}>
      {text.length > 0 && <span className={className.textDivider}>{text}</span>}
      <hr className={clsx({ [classes.lineWithText]: text }, className.line, className.lineColor)} />
    </div>
  )
}

SimpleDivider.propTypes = {
  classes: PropTypes.shape({
    line: PropTypes.string.isRequired,
    lineColor: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
    textDivider: PropTypes.string.isRequired,
  }).isRequired,
  classNameProp: PropTypes.shape({
    line: PropTypes.string,
    lineColor: PropTypes.string,
    root: PropTypes.string,
    textDivider: PropTypes.string,
  }),
  text: PropTypes.string,
}

SimpleDivider.defaultProps = {
  classNameProp: {},
  text: '',
}

export default withStyles(styles)(SimpleDivider)

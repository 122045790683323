const styles = theme => ({
  root: {
    '& > span': {
      marginLeft: props => (props.hasIconLeftSide ? '0px' : theme.spacing(1 / 2)),
      marginRight: props => (props.hasIconLeftSide ? theme.spacing(1 / 2) : '0px'),
    },
  },
})

export default styles

const styles = theme => ({
  totalsInfo: {
    display: 'flex',
    '& > div': {
      padding: theme.spacing(2, 3),
      width: '125px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > span:first-child': {
        fontWeight: 'bold',
        fontSize: '2.75rem',
      },
      '& > span:last-child': {
        color: '#9e9e9e',
        fontSize: '0.75rem',
      },
    },
    '& > div:nth-child(2)': {
      borderRight: '1px solid #dad7d7',
      borderLeft: '1px solid #dad7d7',
    },
  },
  decimals: {
    fontSize: '2rem',
  },
  totalUsers: {
    color: '#333',
  },
  infoParticipationAvg: {
    color: '#fa5d7f',
  },
  infoSuccessAvg: {
    color: '#00d24c',
  },
  zoomInfo: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: '#9E9E9E',
    fontSize: '0.875rem',
    '& > span:first-child': {
      marginRight: theme.spacing(1),
    },
  },
})

export default styles

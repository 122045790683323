import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    button: {
      gridArea: 'chooseButton',
      color: theme.palette.common.white,
      width: '18rem',
      height: '3rem',
      margin: '0 auto',
    },
    choose: {
      gridArea: 'chooseContent',
      padding: '0 3rem',
      display: 'grid',
      gridTemplateColumns: '1fr 35rem 1fr',
      gridTemplateRows: 'auto 20px 1fr 5rem',
      gridTemplateAreas: '". chooseTitle ." ". error ." ". chooseList ." ". chooseButton ."',
      '@media (max-width:767px)':{
        display:'block',
        padding:'1rem'
      }
    },
    content: {
      gridArea: 'content',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '9rem 1fr',
      gridTemplateAreas: '"tryIt" "chooseContent"',
      '@media (max-width:767px)':{
        display:'block'
      }
    },
    identityButton: {
      height: '100%',
      width: '100%',
      padding: '1rem',
      '& span': {
        ...theme.flexRowCenter,
        justifyContent: 'space-between',
      },
    },
    list: {
      ...theme.flexColumnCenter,
      gridArea: 'chooseList',
      listStyle: 'none',
      maxWidth: '33rem',
      overflowY: 'auto',
      padding: 0,
    },
    listItem: {
      backgroundColor: '#F5F6F6',
      boxShadow: 'inset 0 -4px 0 0 rgba(0, 0, 0, 0.09)',
      borderRadius: '4px',
      marginBottom: '1rem',
      boxSizing: 'border-box',
      width: '100%',
    },
    media: {
      ...theme.flexRowCenter,
    },
    mediaBody: {
      '& h2': {
        fontSize: '17px',
        marginBottom: 0,
        marginTop: 0,
      },
      '& span': {
        margin: 0,
        '& b': {
          marginLeft: '0.2rem',
        },
      },
    },
    mediaPhoto: {
      border: '4px solid #DDDEDE',
      borderRadius: '50%',
      width: '52px',
      height: '52px',
      lineWidth: '52px',
      '@media (max-width:767px)':{
        display:'none'
      }
    },
    mediaLogo: {
      width: '65px',
      height: '45px',
    },
    title: {
      gridArea: 'chooseTitle',
      fontFamily: '"Open Sans", open-sans, sans-serif',
      fontSize: '28px',
      fontWeight: '400',
      color: '#434C5F',
      marginBottom: '50px',
      '& small': {
        display: 'block',
        marginTop: '10px',
        fontSize: '16px',
        color: '#596377',
      },
    },
  })

export default styles

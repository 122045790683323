export const VZAAR_STATES = {
  READY: 'ready',
  PROCESSING: 'processing',
  FAILED: 'failed',
}

export const VIDEO_STATE_TYPE = {
  IDEA: 'idea',
  CHALLENGE: 'challenge',
}

export const VIDEO_TYPE = {
  NONE: 'NONE',
  YOUTUBE: 'YOUTUBE',
  UPLOAD: 'UPLOAD',
}

import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Paper } from '@material-ui/core'
import Image from 'components/image/View'
import defaultPhoto from 'img/default-photo.png'
import i18n from 'support/i18n'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import styles from './View.Style'

const UserDetailSection = props => {
  const { classes, fullName, email, children, photoSrc, balance, invested, portfolio } = props
  return (
    <div className={classes.root}>
      <span className={classes.fullName}>{fullName}</span>
      <div className={classes.secondLineContainer}>
        <span>{email}</span>
        {children}
      </div>
      <div className={classes.detailSection}>
        <Image src={photoSrc} defaultSrc={defaultPhoto} />
        <Paper className={classes.fundsDetail} elevation={3}>
          <div className={classes.fundDetailBalance}>
            <span>{i18n.t('PROFILE.GLOBAL_BALANCE')}</span>
            <NxtVirtualCurrency>{balance}</NxtVirtualCurrency>
          </div>
          <div className={classes.fundDetail}>
            <div>
              <span className="icon-funds" />
              <span>{i18n.t('PROFILE.INVESTED')}</span>
            </div>
            <NxtVirtualCurrency>{invested}</NxtVirtualCurrency>
          </div>
          <div className={classes.fundDetail}>
            <div>
              <span className="icon-profile" />
              <span>{i18n.t('PROFILE.PORTFOLIO_VALUE')}</span>
            </div>
            <NxtVirtualCurrency>{portfolio}</NxtVirtualCurrency>
          </div>
        </Paper>
      </div>
    </div>
  )
}

UserDetailSection.propTypes = {
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  photoSrc: PropTypes.string.isRequired,
  balance: PropTypes.string.isRequired,
  invested: PropTypes.string.isRequired,
  portfolio: PropTypes.string.isRequired,
}

export default withStyles(styles)(UserDetailSection)

import React from 'react'
import { connect } from 'react-redux'
import {
  getMyProfile,
  getUserProfile,
  getUserIdeas,
  getUserIdeasTeam,
  getUserOrganisations,
  getUserIdeasPilotTeam,
  getUserContributions,
  putUserOrganisations,
  getUserTimeline,
} from 'services/user_profile'
import { getMyIdeasToInvestIn } from 'services/ideas'
import { resetUserTimeline, setUserOrganisations } from 'actions/sync/user_profile/index'
import { getInvestments } from 'services/user'
import { getActiveOrganizations } from 'services/organizations'
import { showPopupErrorSnackbar, showPopupDialog } from 'support/popup_dialogs'
import i18n from 'support/i18n'
import Navigation from 'support/navigation'
import EditMyProfileDialog from 'pages/profile/components/user_detail_section/components/edit_my_profile_dialog'
import RequestInvestment from 'pages/profile/components/popups/request_investment'
import View from './View'

const mapStateToProps = state => ({
  hasUserProfileLoaded: state.data.userProfile.hasLoaded,
  hasDomainLoaded: state.data.domain.dataLoaded,
  myEmail: state.data.user.userEmail,
  email: state.data.userProfile.email,
  fullName: state.data.userProfile.fullName,
  photoSrc: state.data.userProfile.photoSrc,
  balance: state.data.userProfile.balance,
  invested: state.data.userProfile.invested,
  portfolio: state.data.userProfile.portfolio,
  userBadges: state.data.userProfile.badges,
  allBadges: state.data.domain.badges,
  ideas: state.data.userProfile.ideas,
  teamIdeas: state.data.userProfile.teamIdeas,
  teamPilotIdeas: state.data.userProfile.teamPilotIdeas,
  contributions: state.data.userProfile.contributions,
  ideasInvestedIn: state.data.user.investments.ideas,
  organisationsToBeShown: state.data.userProfile.organisations,
  currencyIcon: state.data.domain.domainConfiguration.currencyIcon,
  ideaStatuses: state.data.domain.domainConfiguration.ideaStatusStepper,
  domainOrganisations: state.data.domain.organizations,
  isOrganizationsActive: state.data.domain.domainConfiguration.isOrganizationsActive,
  organizationsName: state.data.domain.domainConfiguration.organizationsName,
  timeline: state.data.userProfile.timeline,
  isTimelineLoading: state.data.userProfile.isTimelineLoading,
  isTimelineComplete: state.data.userProfile.isTimelineComplete,
})

const mapDispatchToProps = dispatch => {
  return {
    getMyIdeasToInvestIn: () => dispatch(getMyIdeasToInvestIn()),
    getUserContributions: email => dispatch(getUserContributions(email)),
    onOrganisationClicked: (organisations, ids) => {
      const onFailure = () => {
        dispatch(getUserOrganisations())
        showPopupErrorSnackbar(i18n.t('PROFILE.ERROR_PUT_ORGANISATIONS'), dispatch)
      }
      dispatch(setUserOrganisations(organisations))
      dispatch(putUserOrganisations(ids, onFailure))
    },
    getUserIdeasPilotTeam: email => dispatch(getUserIdeasPilotTeam(email)),
    getUserIdeasInvestedIn: email => dispatch(getInvestments(email)),
    getOrganisations: () => dispatch(getActiveOrganizations()),
    getUserOrganisations: () => dispatch(getUserOrganisations()),
    getUserIdeasTeam: email => dispatch(getUserIdeasTeam(email)),
    navigateToIdea: id => dispatch(Navigation.navigateToIdea(id)),
    getUserIdeas: email => dispatch(getUserIdeas(email)),
    getMyProfile: () => {
      const onError = () => {
        showPopupErrorSnackbar(i18n.t('PROFILE.ERROR_GETTING_PROFILE'), dispatch)
      }
      dispatch(getMyProfile(onError))
    },
    getUserProfile: email => {
      const onError = () => {
        showPopupErrorSnackbar(i18n.t('PROFILE.ERROR_GETTING_PROFILE'), dispatch)
      }
      dispatch(getUserProfile(email, onError))
    },
    getTimeline: email => dispatch(getUserTimeline(email)),
    resetTimeline: () => dispatch(resetUserTimeline()),
    onEditProfile: () => showPopupDialog(<EditMyProfileDialog />, dispatch),
    onRequestInvestmentClick: investorEmail =>
      showPopupDialog(<RequestInvestment investorEmail={investorEmail} />, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

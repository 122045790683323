import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    container: {
      padding: '3rem',
    },
    media: {
      margin: "10px auto 0",
      width: "95%",
      height: 400,
      borderRadius: "4px",
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)",
      position: "relative",
      zIndex: 1000
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        width: '87%',
        height: 340,
      },
      tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        padding: 5,
        margin: 5,
      },
  })

export default styles

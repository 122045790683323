import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import defaultPhoto from 'img/default-photo.png'
import Image from 'components/image/View'
import styles from './View.Style'

const Tooltip = props => {
  const {
    classes,
    userFullName,
    userEmail,
    photoSrc,
    participation,
    success,
    ideas,
    teams,
    investments,
    comments,
    funded,
    teamsFunded,
    successInvestments,
    likedComments,
  } = props

  return (
    <div className={classes.root}>
      <div className={classes.userDetailContainer}>
        <a href={`profile/${userEmail}`}>
          <Image defaultSrc={defaultPhoto} src={photoSrc} />
        </a>
        <div className={classes.userLinksContainer}>
          <a href={`profile/${userEmail}`}>{userFullName}</a>
          <a href={`profile/${userEmail}`}>{userEmail}</a>
        </div>
      </div>
      <div className={classes.participationSuccessContainer}>
        <div>
          <span>{participation}%</span>
          <span>{i18n.t('DASHBOARD.PARTICIPATION')}</span>
        </div>
        <div>
          <span>{success}%</span>
          <span>{i18n.t('DASHBOARD.SUCCESS_LEVEL')}</span>
        </div>
      </div>
      <div className={classes.userActivityContainer}>
        <div className={classes.detailsColumn}>
          <div>
            <span className={classes.yellowText}>{ideas}</span>
            <span>{i18n.t('DASHBOARD.IDEAS')}</span>
          </div>
          <div>
            <span className={classes.brownText}>{teams}</span>
            <span>{i18n.t('DASHBOARD.TEAMS')}</span>
          </div>
          <div>
            <span className={classes.lightBlueText}>{investments}</span>
            <span>{i18n.t('DASHBOARD.INVESTMENTS')}</span>
          </div>
          <div>
            <span className={classes.blueText}>{comments}</span>
            <span>{i18n.t('DASHBOARD.COMMENTS')}</span>
          </div>
        </div>
        <div className={classes.detailsColumn}>
          <div>
            <span className={classes.yellowText}>{funded}</span>
            <span>{i18n.t('DASHBOARD.FUNDED')}</span>
          </div>
          <div>
            <span className={classes.brownText}>{teamsFunded}</span>
            <span>{i18n.t('DASHBOARD.TEAMS_FUNDED')}</span>
          </div>
          <div>
            <span className={classes.lightBlueText}>{successInvestments}</span>
            <span>{i18n.t('DASHBOARD.INVESTMENTS_SUCCESS')}</span>
          </div>
          <div>
            <span className={classes.blueText}>{likedComments}</span>
            <span>{i18n.t('DASHBOARD.COMMENTS_LIKED')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

Tooltip.propTypes = {
  userFullName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  photoSrc: PropTypes.string.isRequired,
  participation: PropTypes.number.isRequired,
  success: PropTypes.number.isRequired,
  ideas: PropTypes.number.isRequired,
  teams: PropTypes.number.isRequired,
  investments: PropTypes.number.isRequired,
  comments: PropTypes.number.isRequired,
  funded: PropTypes.number.isRequired,
  teamsFunded: PropTypes.number.isRequired,
  successInvestments: PropTypes.number.isRequired,
  likedComments: PropTypes.number.isRequired,
}

export default withStyles(styles)(Tooltip)

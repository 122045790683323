import i18n from 'support/i18n'

export const buildMarkers = (current, min, convertToNumber = false, postFix = '') => {
  const marks = []
  for (let step = 0; step < 5; step += 1) {
    let coefficient
    if (min === 0) {
      coefficient = step * 2
    } else {
      coefficient = step > 0 ? step * 2 : step + 1
    }

    const value = Math.ceil((current * (25 * coefficient)) / 100)
    let label = `${value}${postFix}`
    if (convertToNumber) {
      label = `${i18n.toNumber(value, { precision: 0 })}${postFix}`
    }
    marks.push({
      label,
      value: `${value}`,
    })
  }
  return marks
}

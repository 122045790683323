import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Image from 'components/image/View'
import i18n from 'support/i18n'
import rocket from 'img/bulb_rocket.png'
import styles from './View.Style'

const NxtEmptyCards = props => {
  const { classes } = props
  return (
    <div className={classes.container}>
      <Image defaultSrc={rocket} alt="company rocket" />
      <div>
        <h1>{i18n.t('CHALLENGES.EMPTY_TITLE')}</h1>
        <p>{i18n.t('NO_IDEAS_FOUND')}</p>
      </div>
    </div>
  )
}

export default withStyles(styles)(NxtEmptyCards)

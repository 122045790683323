import createStyles from '@material-ui/core/styles/createStyles'

const styles = theme =>
  createStyles({
    bottom: {
      gridArea: 'bottom',
      ...theme.gridMiddleColumnCenter,
      height: '25rem',
      color: '#fff',
    },
    center: {
      gridArea: 'center',
    },
    circleWithNoPadding: {
      padding: 0,
    },
    topCenter: {
      gridArea: 'center',
      '& > h1': {
        textAlign: 'center',
      },
    },
    centerDark: {
      backgroundColor: '#1c1c1c',
      color: '#fff',
    },
    exploreBottom: {
      gridArea: 'center',
    },
    fill1: {
      gridColumn: 1,
      gridRow: 2,
      backgroundColor: '#f1f1f1',
    },
    fill2: {
      gridColumn: 3,
      gridRow: 2,
      backgroundColor: '#f1f1f1',
    },
    gridCenter: {
      gridArea: 'center',
    },
    ideasFilter: {
      marginBottom: '1rem',
    },
    mainTitle: {
      margin: '2rem 0',
      fontFamily: '"HelveticaNeueCondensedBold", "Helvetica Neue", sans-serif',
      textAlign: 'center',
      fontWeight: 'normal',
    },
    middle: {
      gridArea: 'middle',
      ...theme.gridMiddleColumnCenter,
      gridTemplateColumns: 'auto 960px auto',
    },
    top: {
      gridArea: 'top',
      ...theme.gridMiddleColumnCenter,
      gridTemplateColumns: 'auto 960px auto',
    },
  })

export default styles

const styles = () => ({
  container: {
    display: 'flex',
    marginTop: '20px',
    marginLeft: '20px',
  },
  check: {
    maxWidth: '80px',
  },
  span: {
    marginTop: '8px',
  },
  spanTitle: {
    fontSize: '14px',
    fontWeight: '700',
    textAlign: 'start',
  },
  spanDesc: {
    fontSize: '14px',
    borderTop: '1px solid #b3b3b3',
  },
})

export default styles

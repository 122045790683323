import React from 'react'
import './View.Style.css'

const Flip = props => {
  const { children } = props
  return (
    <div className="card-container">
      <div className="card">{children}</div>
    </div>
  )
}

export default Flip

import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import SideLogin from 'components/side_login/View'
import ResetPasswordForm from './components/reset_password_form/View'
import styles from './View.Style'

const ResetPassword = props => {
  const {
    classes,
    onSubmit,
    useCreateAccount,
    messageType,
    messageText,
    isChangingPassword,
    setAuthMessageBox,
    resetAuthMessageBox,
  } = props
  useEffect(() => resetAuthMessageBox, [])
  return (
    <div className={classes.base}>
      <SideLogin />
      <ResetPasswordForm
        setAuthMessageBox={setAuthMessageBox}
        isChangingPassword={isChangingPassword}
        messageType={messageType}
        messageText={messageText}
        onSubmit={onSubmit}
        useCreateAccount={useCreateAccount}
      />
    </div>
  )
}

ResetPassword.defaultProps = {
  useCreateAccount: false,
}

ResetPassword.propTypes = {
  useCreateAccount: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setAuthMessageBox: PropTypes.func.isRequired,
  resetAuthMessageBox: PropTypes.func.isRequired,
  isChangingPassword: PropTypes.bool.isRequired,
  messageType: PropTypes.number.isRequired,
  messageText: PropTypes.string.isRequired,
}

export default withStyles(styles)(ResetPassword)

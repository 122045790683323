import * as types from './Constants'

export const setImages = images => ({
  type: types.SET_IDEA_FORM_IMAGES,
  images,
})
export const onUploadImageFailure = value => ({
  type: types.ADD_IMAGE_FAILURE,
  value,
})
export const resetForm = value => ({
  type: types.RESET_IDEA_FORM,
  value,
})
export const uploadDocumentStarted = value => ({
  type: types.UPLOAD_DOCUMENT_STARTED,
  value,
})
export const uploadDocumentSuccess = value => ({
  type: types.UPLOAD_DOCUMENT_SUCCESS,
  value,
})
export const uploadDocumentFailure = value => ({
  type: types.UPLOAD_DOCUMENT_FAILURE,
  value,
})

export const setContributionTime = value => ({
  type: types.SET_CONTRIBUTION_TIME,
  value,
})

export const setContributionBudget = value => ({
  type: types.SET_CONTRIBUTION_BUDGET,
  value,
})

export const setContributionOther = value => ({
  type: types.SET_CONTRIBUTION_OTHER,
  value,
})

export const resetIdeaState = () => ({
  type: types.RESET_IDEA_STATE,
})

import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import styles from './View.Style'

const NxtCardCorner = props => {
  const { classes, sponsored, challenged } = props
  return (
    <div className={classes.badgeBox}>
      {(sponsored || challenged) && (
        <div className={classes.badgeBoxCorner}>
          {sponsored && !challenged && (
            <p>
              <i className="icon-star" />
              <span>{i18n.t('SPONSORED')}</span>
            </p>
          )}
          {challenged && !sponsored && (
            <p>
              <i className="icon-target" />
              <span>{i18n.t('CHALLENGED')}</span>
            </p>
          )}
        </div>
      )}
    </div>
  )
}

NxtCardCorner.defaultProps = {
  challenged: false,
  sponsored: false,
}

NxtCardCorner.propTypes = {
  classes: PropTypes.shape({
    badgeBox: PropTypes.string.isRequired,
    badgeBoxCorner: PropTypes.string.isRequired,
  }).isRequired,
  challenged: PropTypes.bool,
  sponsored: PropTypes.bool,
}

export default withStyles(styles)(NxtCardCorner)

import i18n from 'support/i18n'
import { getLocaleShortMonth } from 'support/date_fns_locale'

function getValuePercentageAndType(val1, val2) {
  const value = val2 - val1
  const percentage = `${Math.round((value * 100) / val1)}%`
  let type = 'equal'
  if (value > 0) type = 'positive'
  if (value < 0) type = 'negative'
  return {
    value: value || '=',
    percentage: value ? percentage : '',
    type,
  }
}

function getComparisonFromArrays(previousArray, currentArray) {
  const comparisonArray = []
  previousArray.forEach((item, index) => {
    comparisonArray.push(getValuePercentageAndType(item, currentArray[index]))
  })
  return comparisonArray
}

export function transformActivityTable(jsonResponse, fromDate, toDate) {
  const { previous } = jsonResponse.items[0]
  const { current } = jsonResponse.items[0]

  let comparisonRow = []

  const returnObject = {
    dataRows: [
      {
        firstColumn: [
          {
            mainText: fromDate.getDate(),
            subText: `${getLocaleShortMonth(fromDate)} ${fromDate.getFullYear()}`,
          },
        ],
        data: [
          previous && previous.ideas,
          previous && previous.investments,
          previous && previous.idea_comments,
          previous && previous.idea_visits,
          previous && previous.system_access,
        ],
      },
      {
        firstColumn: [
          {
            mainText: toDate.getDate(),
            subText: `${getLocaleShortMonth(toDate)} ${toDate.getFullYear()}`,
          },
        ],
        data: [
          current && current.ideas,
          current && current.investments,
          current && current.idea_comments,
          current && current.idea_visits,
          current && current.system_access,
        ],
      },
    ],
  }

  comparisonRow = getComparisonFromArrays(
    returnObject.dataRows[0].data,
    returnObject.dataRows[1].data,
  )
  returnObject.comparisonRow = {
    firstColumn: [{}],
    data: comparisonRow,
  }
  return returnObject
}

export function transformRequestReportResponse(jsonResponse) {
  return {
    success: jsonResponse.success,
    mailTo: jsonResponse.mailTo,
  }
}

export function transformIdeasTable(jsonResponse, fromDate, toDate) {
  const { previous } = jsonResponse.items[0]
  const { current } = jsonResponse.items[0]

  let comparisonRow = []

  const returnObject = {
    dataRows: [
      {
        firstColumn: [
          {
            mainText: fromDate.getDate(),
            subText: `${getLocaleShortMonth(fromDate)} ${fromDate.getFullYear()}`,
          },
          {
            mainText: previous && previous.ideas,
            subText: i18n.t('DASHBOARD.TOTALS'),
          },
        ],
        data: [
          previous && previous.ideas_raising,
          previous && previous.ideas_supported,
          previous && previous.ideas_round_closed,
          previous && previous.ideas_approved,
          previous && previous.ideas_pilot,
          previous && previous.ideas_implemented,
          previous && previous.ideas_discarded,
        ],
      },
      {
        firstColumn: [
          {
            mainText: toDate.getDate(),
            subText: `${getLocaleShortMonth(toDate)} ${toDate.getFullYear()}`,
          },
          {
            mainText: current && current.ideas,
            subText: i18n.t('DASHBOARD.TOTALS'),
          },
        ],
        data: [
          current && current.ideas_raising,
          current && current.ideas_supported,
          current && current.ideas_round_closed,
          current && current.ideas_approved,
          current && current.ideas_pilot,
          current && current.ideas_implemented,
          current && current.ideas_discarded,
        ],
      },
    ],
  }

  comparisonRow = getComparisonFromArrays(
    returnObject.dataRows[0].data,
    returnObject.dataRows[1].data,
  )

  const { value, percentage, type } = getValuePercentageAndType(
    returnObject.dataRows[0].firstColumn[1].mainText,
    returnObject.dataRows[1].firstColumn[1].mainText,
  )
  returnObject.comparisonRow = {
    firstColumn: [
      { mainText: '', subText: i18n.t('DASHBOARD.CHANGE') },
      {
        mainText: value,
        subText: percentage,
        type,
      },
    ],
    data: comparisonRow,
  }
  return returnObject
}

export function transformPeopleTable(jsonResponse, fromDate, toDate) {
  const { previous } = jsonResponse.items[0]
  const { current } = jsonResponse.items[0]

  let comparisonRow = []

  const returnObject = {
    dataRows: [
      {
        firstColumn: [
          {
            mainText: fromDate.getDate(),
            subText: `${getLocaleShortMonth(fromDate)} ${fromDate.getFullYear()}`,
          },
          {
            mainText: previous && previous.au,
            subText: i18n.t('DASHBOARD.TOTALS'),
          },
        ],
        data: [
          previous && previous.user_w_investments,
          previous && previous.user_w_comments,
          previous && previous.user_w_ideas,
          previous && previous.user_w_isupported,
          previous && previous.user_w_iapproved,
          previous && previous.user_w_ipilot,
          previous && previous.user_w_iimplemented,
        ],
      },
      {
        firstColumn: [
          {
            mainText: toDate.getDate(),
            subText: `${getLocaleShortMonth(toDate)} ${toDate.getFullYear()}`,
          },
          {
            mainText: current && current.au,
            subText: i18n.t('DASHBOARD.TOTALS'),
          },
        ],
        data: [
          current && current.user_w_investments,
          current && current.user_w_comments,
          current && current.user_w_ideas,
          current && current.user_w_isupported,
          current && current.user_w_iapproved,
          current && current.user_w_ipilot,
          current && current.user_w_iimplemented,
        ],
      },
    ],
  }

  comparisonRow = getComparisonFromArrays(
    returnObject.dataRows[0].data,
    returnObject.dataRows[1].data,
  )

  const { value, percentage, type } = getValuePercentageAndType(
    returnObject.dataRows[0].firstColumn[1].mainText,
    returnObject.dataRows[1].firstColumn[1].mainText,
  )
  returnObject.comparisonRow = {
    firstColumn: [
      { mainText: '', subText: i18n.t('DASHBOARD.CHANGE') },
      {
        mainText: value,
        subText: percentage,
        type,
      },
    ],
    data: comparisonRow,
  }
  return returnObject
}

export function transformPrivateIdeasTable(jsonResponse, fromDate, toDate) {
  const { previous } = jsonResponse.items[0]
  const { current } = jsonResponse.items[0]

  let comparisonRow = []

  const returnObject = {
    dataRows: [
      {
        firstColumn: [
          {
            mainText: fromDate.getDate(),
            subText: `${getLocaleShortMonth(fromDate)} ${fromDate.getFullYear()}`,
          },
          {
            mainText: previous && previous.private_ideas,
            subText: i18n.t('DASHBOARD.TOTALS'),
          },
        ],
        data: [
          previous && previous.private_ideas_round_closed,
          previous && previous.private_ideas_approved,
          previous && previous.private_ideas_pilot,
          previous && previous.private_ideas_implemented,
          previous && previous.private_ideas_discarded,
        ],
      },
      {
        firstColumn: [
          {
            mainText: toDate.getDate(),
            subText: `${getLocaleShortMonth(toDate)} ${toDate.getFullYear()}`,
          },
          {
            mainText: current && current.private_ideas,
            subText: i18n.t('DASHBOARD.TOTALS'),
          },
        ],
        data: [
          current && current.private_ideas_round_closed,
          current && current.private_ideas_approved,
          current && current.private_ideas_pilot,
          current && current.private_ideas_implemented,
          current && current.private_ideas_discarded,
        ],
      },
    ],
  }

  comparisonRow = getComparisonFromArrays(
    returnObject.dataRows[0].data,
    returnObject.dataRows[1].data,
  )

  const { value, percentage, type } = getValuePercentageAndType(
    returnObject.dataRows[0].firstColumn[1].mainText,
    returnObject.dataRows[1].firstColumn[1].mainText,
  )
  returnObject.comparisonRow = {
    firstColumn: [
      { mainText: '', subText: i18n.t('DASHBOARD.CHANGE') },
      {
        mainText: value,
        subText: percentage,
        type,
      },
    ],
    data: comparisonRow,
  }
  return returnObject
}

export function transformSystemAccessesResponse(jsonResponse) {
  // TODO: Remove this mock

  // const mockResponse = {
  //   size: 156,
  //   totalItems: 0,
  //   offset: 0,
  //   resource: 'Report Data',
  //   apiVersion: 'v1',
  //   items: [
  //     [1564444800000, 5607],
  //     [1564531200000, 5607],
  //     [1564617600000, 5608],
  //     [1564704000000, 5609],
  //     [1564790400000, 5609],
  //     [1564876800000, 5609],
  //     [1564963200000, 5611],
  //     [1565049600000, 5612],
  //     [1565136000000, 5613],
  //     [1565222400000, 5615],
  //     [1565308800000, 5615],
  //     [1565395200000, 5615],
  //     [1565481600000, 5615],
  //     [1565568000000, 5615],
  //     [1565654400000, 5615],
  //     [1565740800000, 5615],
  //     [1565827200000, 5615],
  //     [1565913600000, 5615],
  //     [1566000000000, 5615],
  //     [1566086400000, 5615],
  //     [1566172800000, 5615],
  //     [1566259200000, 5615],
  //     [1566345600000, 5620],
  //     [1566432000000, 5621],
  //     [1566518400000, 5622],
  //     [1566604800000, 5622],
  //     [1566691200000, 5622],
  //     [1566777600000, 5622],
  //     [1566864000000, 5624],
  //     [1566950400000, 5625],
  //     [1567036800000, 5626],
  //     [1567123200000, 5626],
  //     [1567209600000, 5626],
  //     [1567296000000, 5627],
  //     [1567382400000, 5627],
  //     [1567468800000, 5628],
  //     [1567555200000, 5629],
  //     [1567641600000, 5629],
  //     [1567728000000, 5629],
  //     [1567814400000, 5629],
  //     [1567900800000, 5629],
  //     [1567987200000, 5633],
  //     [1568073600000, 5637],
  //     [1568160000000, 5641],
  //     [1568246400000, 5647],
  //     [1568332800000, 5647],
  //     [1568419200000, 5647],
  //     [1568505600000, 5647],
  //     [1568592000000, 5649],
  //     [1568678400000, 5649],
  //     [1568764800000, 5651],
  //     [1568851200000, 5651],
  //     [1568937600000, 5651],
  //     [1569024000000, 5651],
  //     [1569110400000, 5651],
  //     [1569196800000, 5652],
  //     [1569283200000, 5654],
  //     [1569369600000, 5656],
  //     [1569456000000, 5658],
  //     [1569542400000, 5662],
  //     [1569628800000, 5662],
  //     [1569715200000, 5662],
  //     [1569801600000, 5668],
  //     [1569888000000, 5672],
  //     [1569974400000, 5676],
  //     [1570060800000, 5680],
  //     [1570147200000, 5682],
  //     [1570233600000, 5682],
  //     [1570320000000, 5684],
  //     [1570406400000, 5685],
  //     [1570492800000, 5687],
  //     [1570579200000, 5688],
  //     [1570665600000, 5689],
  //     [1570752000000, 5690],
  //     [1570838400000, 5690],
  //     [1570924800000, 5690],
  //     [1571011200000, 5696],
  //     [1571097600000, 5700],
  //     [1571184000000, 5703],
  //     [1571270400000, 5707],
  //     [1571356800000, 5708],
  //     [1571443200000, 5708],
  //     [1571529600000, 5708],
  //     [1571616000000, 5708],
  //     [1571702400000, 5710],
  //     [1571788800000, 5711],
  //     [1571875200000, 5714],
  //     [1571961600000, 5714],
  //     [1572048000000, 5714],
  //     [1572134400000, 5714],
  //     [1572220800000, 5718],
  //     [1572393600000, 5725],
  //     [1572480000000, 5728],
  //     [1572652800000, 5728],
  //     [1572739200000, 5728],
  //     [1572825600000, 5731],
  //     [1572912000000, 5734],
  //     [1572998400000, 5734],
  //     [1573084800000, 5738],
  //     [1573171200000, 5739],
  //     [1573257600000, 5739],
  //     [1573344000000, 5739],
  //     [1573516800000, 5743],
  //     [1573603200000, 5746],
  //     [1573689600000, 5751],
  //     [1573776000000, 5751],
  //     [1573948800000, 5751],
  //     [1574035200000, 5755],
  //     [1574121600000, 5757],
  //     [1574208000000, 5766],
  //     [1574294400000, 5770],
  //     [1574380800000, 5773],
  //     [1574467200000, 5773],
  //     [1574553600000, 5773],
  //     [1574640000000, 5780],
  //     [1574726400000, 5789],
  //     [1574812800000, 5794],
  //     [1574899200000, 5800],
  //     [1574985600000, 5803],
  //     [1575072000000, 5803],
  //     [1575158400000, 5803],
  //     [1575244800000, 5809],
  //     [1575331200000, 5812],
  //     [1575417600000, 5819],
  //     [1575504000000, 5826],
  //     [1575590400000, 5826],
  //     [1575676800000, 5826],
  //     [1575849600000, 5827],
  //     [1575936000000, 5832],
  //     [1576022400000, 5834],
  //     [1576108800000, 5838],
  //     [1576281600000, 5841],
  //     [1576368000000, 5841],
  //     [1576454400000, 5846],
  //     [1576540800000, 5851],
  //     [1576627200000, 5853],
  //     [1576713600000, 5859],
  //     [1576800000000, 5862],
  //     [1576886400000, 5862],
  //     [1576972800000, 5862],
  //     [1577059200000, 5864],
  //     [1577145600000, 5865],
  //     [1577232000000, 5865],
  //     [1577318400000, 5866],
  //     [1577491200000, 5866],
  //     [1577577600000, 5866],
  //     [1577664000000, 5867],
  //     [1577750400000, 5868],
  //     [1577836800000, 5868],
  //     [1577923200000, 5869],
  //     [1578009600000, 5870],
  //     [1578096000000, 5870],
  //     [1578182400000, 5870],
  //     [1578268800000, 5870],
  //     [1578355200000, 5877],
  //     [1578441600000, 5881],
  //   ],
  // }
  return [
    {
      name: i18n.t('DASHBOARD.ACCESS'),
      data: jsonResponse.items,
    },
  ]
}

export function transformIdeasSeriesResponse(jsonResponse) {
  // TODO: Remove this mock

  // const mockResponse = {
  //   size: 8,
  //   totalItems: 0,
  //   offset: 0,
  //   resource: 'Report Data',
  //   apiVersion: 'v1',
  //   items: [
  //     {
  //       ideas: [
  //         [1564444800000, 7],
  //         [1564531200000, 7],
  //         [1564617600000, 7],
  //         [1564704000000, 7],
  //         [1564790400000, 7],
  //         [1564876800000, 7],
  //         [1564963200000, 7],
  //         [1565049600000, 7],
  //         [1565136000000, 8],
  //         [1565222400000, 10],
  //         [1565308800000, 10],
  //         [1565395200000, 10],
  //         [1565481600000, 10],
  //         [1565568000000, 10],
  //         [1565654400000, 10],
  //         [1565740800000, 10],
  //         [1565827200000, 10],
  //         [1565913600000, 10],
  //         [1566000000000, 10],
  //         [1566086400000, 10],
  //         [1566172800000, 10],
  //         [1566259200000, 10],
  //         [1566345600000, 10],
  //         [1566432000000, 11],
  //         [1566518400000, 11],
  //         [1566604800000, 11],
  //         [1566691200000, 11],
  //         [1566777600000, 11],
  //         [1566864000000, 11],
  //         [1566950400000, 11],
  //         [1567036800000, 11],
  //         [1567123200000, 11],
  //         [1567209600000, 11],
  //         [1567296000000, 11],
  //         [1567382400000, 11],
  //         [1567468800000, 11],
  //         [1567555200000, 11],
  //         [1567641600000, 11],
  //         [1567728000000, 11],
  //         [1567814400000, 11],
  //         [1567900800000, 11],
  //         [1567987200000, 11],
  //         [1568073600000, 14],
  //         [1568160000000, 16],
  //         [1568246400000, 23],
  //         [1568332800000, 23],
  //         [1568419200000, 23],
  //         [1568505600000, 23],
  //         [1568592000000, 24],
  //         [1568678400000, 24],
  //         [1568764800000, 21],
  //         [1568851200000, 21],
  //         [1568937600000, 22],
  //         [1569024000000, 22],
  //         [1569110400000, 22],
  //         [1569196800000, 22],
  //         [1569283200000, 22],
  //         [1569369600000, 22],
  //         [1569456000000, 24],
  //         [1569542400000, 25],
  //         [1569628800000, 25],
  //         [1569715200000, 25],
  //         [1569801600000, 27],
  //         [1569888000000, 29],
  //         [1569974400000, 29],
  //         [1570060800000, 30],
  //         [1570147200000, 31],
  //         [1570233600000, 31],
  //         [1570320000000, 31],
  //         [1570406400000, 31],
  //         [1570492800000, 31],
  //         [1570579200000, 32],
  //         [1570665600000, 33],
  //         [1570752000000, 33],
  //         [1570838400000, 33],
  //         [1570924800000, 33],
  //         [1571011200000, 41],
  //         [1571097600000, 42],
  //         [1571184000000, 43],
  //         [1571270400000, 31],
  //         [1571356800000, 31],
  //         [1571443200000, 31],
  //         [1571529600000, 31],
  //         [1571616000000, 31],
  //         [1571702400000, 31],
  //         [1571788800000, 31],
  //         [1571875200000, 31],
  //         [1571961600000, 31],
  //         [1572048000000, 31],
  //         [1572134400000, 31],
  //         [1572220800000, 34],
  //         [1572393600000, 39],
  //         [1572480000000, 41],
  //         [1572652800000, 41],
  //         [1572739200000, 41],
  //         [1572825600000, 42],
  //         [1572912000000, 42],
  //         [1572998400000, 42],
  //         [1573084800000, 43],
  //         [1573171200000, 45],
  //         [1573257600000, 45],
  //         [1573344000000, 45],
  //         [1573516800000, 45],
  //         [1573603200000, 45],
  //         [1573689600000, 47],
  //         [1573776000000, 47],
  //         [1573948800000, 47],
  //         [1574035200000, 48],
  //         [1574121600000, 49],
  //         [1574208000000, 50],
  //         [1574294400000, 52],
  //         [1574380800000, 52],
  //         [1574467200000, 52],
  //         [1574553600000, 52],
  //         [1574640000000, 55],
  //         [1574726400000, 56],
  //         [1574812800000, 58],
  //         [1574899200000, 61],
  //         [1574985600000, 61],
  //         [1575072000000, 61],
  //         [1575158400000, 61],
  //         [1575244800000, 62],
  //         [1575331200000, 63],
  //         [1575417600000, 66],
  //         [1575504000000, 66],
  //         [1575590400000, 70],
  //         [1575676800000, 70],
  //         [1575849600000, 70],
  //         [1575936000000, 72],
  //         [1576022400000, 71],
  //         [1576108800000, 73],
  //         [1576281600000, 78],
  //         [1576368000000, 78],
  //         [1576454400000, 78],
  //         [1576540800000, 81],
  //         [1576627200000, 92],
  //         [1576713600000, 92],
  //         [1576800000000, 93],
  //         [1576886400000, 93],
  //         [1576972800000, 93],
  //         [1577059200000, 95],
  //         [1577145600000, 95],
  //         [1577232000000, 95],
  //         [1577318400000, 95],
  //         [1577491200000, 95],
  //         [1577577600000, 95],
  //         [1577664000000, 95],
  //         [1577750400000, 95],
  //         [1577836800000, 95],
  //         [1577923200000, 96],
  //         [1578009600000, 98],
  //         [1578096000000, 98],
  //         [1578182400000, 98],
  //         [1578268800000, 98],
  //         [1578355200000, 101],
  //         [1578441600000, 101],
  //       ],
  //       ideas_raising: [
  //         [1564444800000, 4],
  //         [1564531200000, 4],
  //         [1564617600000, 4],
  //         [1564704000000, 4],
  //         [1564790400000, 4],
  //         [1564876800000, 4],
  //         [1564963200000, 4],
  //         [1565049600000, 4],
  //         [1565136000000, 5],
  //         [1565222400000, 7],
  //         [1565308800000, 7],
  //         [1565395200000, 7],
  //         [1565481600000, 7],
  //         [1565568000000, 7],
  //         [1565654400000, 7],
  //         [1565740800000, 7],
  //         [1565827200000, 7],
  //         [1565913600000, 7],
  //         [1566000000000, 7],
  //         [1566086400000, 7],
  //         [1566172800000, 7],
  //         [1566259200000, 7],
  //         [1566345600000, 7],
  //         [1566432000000, 8],
  //         [1566518400000, 8],
  //         [1566604800000, 8],
  //         [1566691200000, 8],
  //         [1566777600000, 8],
  //         [1566864000000, 8],
  //         [1566950400000, 8],
  //         [1567036800000, 8],
  //         [1567123200000, 8],
  //         [1567209600000, 8],
  //         [1567296000000, 8],
  //         [1567382400000, 8],
  //         [1567468800000, 8],
  //         [1567555200000, 8],
  //         [1567641600000, 8],
  //         [1567728000000, 8],
  //         [1567814400000, 8],
  //         [1567900800000, 8],
  //         [1567987200000, 8],
  //         [1568073600000, 10],
  //         [1568160000000, 12],
  //         [1568246400000, 19],
  //         [1568332800000, 19],
  //         [1568419200000, 19],
  //         [1568505600000, 19],
  //         [1568592000000, 20],
  //         [1568678400000, 20],
  //         [1568764800000, 17],
  //         [1568851200000, 17],
  //         [1568937600000, 17],
  //         [1569024000000, 15],
  //         [1569110400000, 15],
  //         [1569196800000, 15],
  //         [1569283200000, 15],
  //         [1569369600000, 15],
  //         [1569456000000, 17],
  //         [1569542400000, 18],
  //         [1569628800000, 18],
  //         [1569715200000, 18],
  //         [1569801600000, 20],
  //         [1569888000000, 22],
  //         [1569974400000, 22],
  //         [1570060800000, 23],
  //         [1570147200000, 24],
  //         [1570233600000, 23],
  //         [1570320000000, 23],
  //         [1570406400000, 23],
  //         [1570492800000, 23],
  //         [1570579200000, 24],
  //         [1570665600000, 25],
  //         [1570752000000, 25],
  //         [1570838400000, 25],
  //         [1570924800000, 25],
  //         [1571011200000, 33],
  //         [1571097600000, 34],
  //         [1571184000000, 34],
  //         [1571270400000, 22],
  //         [1571356800000, 22],
  //         [1571443200000, 22],
  //         [1571529600000, 22],
  //         [1571616000000, 22],
  //         [1571702400000, 22],
  //         [1571788800000, 22],
  //         [1571875200000, 20],
  //         [1571961600000, 18],
  //         [1572048000000, 15],
  //         [1572134400000, 15],
  //         [1572220800000, 18],
  //         [1572393600000, 17],
  //         [1572480000000, 17],
  //         [1572652800000, 15],
  //         [1572739200000, 14],
  //         [1572825600000, 14],
  //         [1572912000000, 14],
  //         [1572998400000, 14],
  //         [1573084800000, 14],
  //         [1573171200000, 14],
  //         [1573257600000, 12],
  //         [1573344000000, 11],
  //         [1573516800000, 11],
  //         [1573603200000, 10],
  //         [1573689600000, 9],
  //         [1573776000000, 9],
  //         [1573948800000, 8],
  //         [1574035200000, 8],
  //         [1574121600000, 8],
  //         [1574208000000, 9],
  //         [1574294400000, 11],
  //         [1574380800000, 11],
  //         [1574467200000, 10],
  //         [1574553600000, 10],
  //         [1574640000000, 7],
  //         [1574726400000, 7],
  //         [1574812800000, 8],
  //         [1574899200000, 9],
  //         [1574985600000, 9],
  //         [1575072000000, 9],
  //         [1575158400000, 9],
  //         [1575244800000, 8],
  //         [1575331200000, 9],
  //         [1575417600000, 12],
  //         [1575504000000, 12],
  //         [1575590400000, 16],
  //         [1575676800000, 16],
  //         [1575849600000, 16],
  //         [1575936000000, 16],
  //         [1576022400000, 11],
  //         [1576108800000, 10],
  //         [1576281600000, 9],
  //         [1576368000000, 9],
  //         [1576454400000, 10],
  //         [1576540800000, 12],
  //         [1576627200000, 23],
  //         [1576713600000, 22],
  //         [1576800000000, 22],
  //         [1576886400000, 22],
  //         [1576972800000, 21],
  //         [1577059200000, 23],
  //         [1577145600000, 23],
  //         [1577232000000, 23],
  //         [1577318400000, 23],
  //         [1577491200000, 23],
  //         [1577577600000, 23],
  //         [1577664000000, 23],
  //         [1577750400000, 23],
  //         [1577836800000, 23],
  //         [1577923200000, 22],
  //         [1578009600000, 24],
  //         [1578096000000, 23],
  //         [1578182400000, 23],
  //         [1578268800000, 23],
  //         [1578355200000, 25],
  //         [1578441600000, 25],
  //       ],
  //       ideas_supported: [
  //         [1564444800000, 0],
  //         [1564531200000, 0],
  //         [1564617600000, 0],
  //         [1564704000000, 0],
  //         [1564790400000, 0],
  //         [1564876800000, 0],
  //         [1564963200000, 0],
  //         [1565049600000, 0],
  //         [1565136000000, 0],
  //         [1565222400000, 0],
  //         [1565308800000, 0],
  //         [1565395200000, 0],
  //         [1565481600000, 0],
  //         [1565568000000, 0],
  //         [1565654400000, 0],
  //         [1565740800000, 0],
  //         [1565827200000, 0],
  //         [1565913600000, 0],
  //         [1566000000000, 0],
  //         [1566086400000, 0],
  //         [1566172800000, 0],
  //         [1566259200000, 0],
  //         [1566345600000, 0],
  //         [1566432000000, 0],
  //         [1566518400000, 0],
  //         [1566604800000, 0],
  //         [1566691200000, 0],
  //         [1566777600000, 0],
  //         [1566864000000, 0],
  //         [1566950400000, 0],
  //         [1567036800000, 0],
  //         [1567123200000, 0],
  //         [1567209600000, 0],
  //         [1567296000000, 0],
  //         [1567382400000, 0],
  //         [1567468800000, 0],
  //         [1567555200000, 0],
  //         [1567641600000, 0],
  //         [1567728000000, 0],
  //         [1567814400000, 0],
  //         [1567900800000, 0],
  //         [1567987200000, 0],
  //         [1568073600000, 0],
  //         [1568160000000, 0],
  //         [1568246400000, 0],
  //         [1568332800000, 0],
  //         [1568419200000, 0],
  //         [1568505600000, 0],
  //         [1568592000000, 0],
  //         [1568678400000, 0],
  //         [1568764800000, 0],
  //         [1568851200000, 0],
  //         [1568937600000, 0],
  //         [1569024000000, 0],
  //         [1569110400000, 0],
  //         [1569196800000, 0],
  //         [1569283200000, 0],
  //         [1569369600000, 0],
  //         [1569456000000, 0],
  //         [1569542400000, 0],
  //         [1569628800000, 0],
  //         [1569715200000, 0],
  //         [1569801600000, 0],
  //         [1569888000000, 0],
  //         [1569974400000, 0],
  //         [1570060800000, 0],
  //         [1570147200000, 0],
  //         [1570233600000, 0],
  //         [1570320000000, 0],
  //         [1570406400000, 0],
  //         [1570492800000, 0],
  //         [1570579200000, 0],
  //         [1570665600000, 0],
  //         [1570752000000, 0],
  //         [1570838400000, 0],
  //         [1570924800000, 0],
  //         [1571011200000, 0],
  //         [1571097600000, 0],
  //         [1571184000000, 0],
  //         [1571270400000, 0],
  //         [1571356800000, 0],
  //         [1571443200000, 0],
  //         [1571529600000, 0],
  //         [1571616000000, 0],
  //         [1571702400000, 0],
  //         [1571788800000, 0],
  //         [1571875200000, 0],
  //         [1571961600000, 0],
  //         [1572048000000, 0],
  //         [1572134400000, 0],
  //         [1572220800000, 0],
  //         [1572393600000, 1],
  //         [1572480000000, 1],
  //         [1572652800000, 1],
  //         [1572739200000, 1],
  //         [1572825600000, 1],
  //         [1572912000000, 1],
  //         [1572998400000, 1],
  //         [1573084800000, 1],
  //         [1573171200000, 1],
  //         [1573257600000, 1],
  //         [1573344000000, 1],
  //         [1573516800000, 1],
  //         [1573603200000, 0],
  //         [1573689600000, 0],
  //         [1573776000000, 0],
  //         [1573948800000, 0],
  //         [1574035200000, 0],
  //         [1574121600000, 0],
  //         [1574208000000, 0],
  //         [1574294400000, 0],
  //         [1574380800000, 0],
  //         [1574467200000, 0],
  //         [1574553600000, 0],
  //         [1574640000000, 1],
  //         [1574726400000, 1],
  //         [1574812800000, 1],
  //         [1574899200000, 1],
  //         [1574985600000, 1],
  //         [1575072000000, 1],
  //         [1575158400000, 1],
  //         [1575244800000, 1],
  //         [1575331200000, 1],
  //         [1575417600000, 1],
  //         [1575504000000, 1],
  //         [1575590400000, 1],
  //         [1575676800000, 1],
  //         [1575849600000, 1],
  //         [1575936000000, 1],
  //         [1576022400000, 1],
  //         [1576108800000, 1],
  //         [1576281600000, 1],
  //         [1576368000000, 1],
  //         [1576454400000, 1],
  //         [1576540800000, 1],
  //         [1576627200000, 1],
  //         [1576713600000, 1],
  //         [1576800000000, 1],
  //         [1576886400000, 1],
  //         [1576972800000, 1],
  //         [1577059200000, 1],
  //         [1577145600000, 0],
  //         [1577232000000, 0],
  //         [1577318400000, 0],
  //         [1577491200000, 0],
  //         [1577577600000, 0],
  //         [1577664000000, 0],
  //         [1577750400000, 0],
  //         [1577836800000, 0],
  //         [1577923200000, 0],
  //         [1578009600000, 0],
  //         [1578096000000, 0],
  //         [1578182400000, 0],
  //         [1578268800000, 0],
  //         [1578355200000, 0],
  //         [1578441600000, 0],
  //       ],
  //       ideas_round_closed: [
  //         [1564444800000, 0],
  //         [1564531200000, 0],
  //         [1564617600000, 0],
  //         [1564704000000, 0],
  //         [1564790400000, 0],
  //         [1564876800000, 0],
  //         [1564963200000, 0],
  //         [1565049600000, 0],
  //         [1565136000000, 0],
  //         [1565222400000, 0],
  //         [1565308800000, 0],
  //         [1565395200000, 0],
  //         [1565481600000, 0],
  //         [1565568000000, 0],
  //         [1565654400000, 0],
  //         [1565740800000, 0],
  //         [1565827200000, 0],
  //         [1565913600000, 0],
  //         [1566000000000, 0],
  //         [1566086400000, 0],
  //         [1566172800000, 0],
  //         [1566259200000, 0],
  //         [1566345600000, 0],
  //         [1566432000000, 0],
  //         [1566518400000, 0],
  //         [1566604800000, 0],
  //         [1566691200000, 0],
  //         [1566777600000, 0],
  //         [1566864000000, 0],
  //         [1566950400000, 0],
  //         [1567036800000, 0],
  //         [1567123200000, 0],
  //         [1567209600000, 0],
  //         [1567296000000, 0],
  //         [1567382400000, 0],
  //         [1567468800000, 0],
  //         [1567555200000, 0],
  //         [1567641600000, 0],
  //         [1567728000000, 0],
  //         [1567814400000, 0],
  //         [1567900800000, 0],
  //         [1567987200000, 0],
  //         [1568073600000, 0],
  //         [1568160000000, 0],
  //         [1568246400000, 0],
  //         [1568332800000, 0],
  //         [1568419200000, 0],
  //         [1568505600000, 0],
  //         [1568592000000, 0],
  //         [1568678400000, 0],
  //         [1568764800000, 0],
  //         [1568851200000, 0],
  //         [1568937600000, 0],
  //         [1569024000000, 0],
  //         [1569110400000, 0],
  //         [1569196800000, 0],
  //         [1569283200000, 0],
  //         [1569369600000, 0],
  //         [1569456000000, 0],
  //         [1569542400000, 0],
  //         [1569628800000, 0],
  //         [1569715200000, 0],
  //         [1569801600000, 0],
  //         [1569888000000, 0],
  //         [1569974400000, 0],
  //         [1570060800000, 0],
  //         [1570147200000, 0],
  //         [1570233600000, 0],
  //         [1570320000000, 0],
  //         [1570406400000, 0],
  //         [1570492800000, 0],
  //         [1570579200000, 0],
  //         [1570665600000, 0],
  //         [1570752000000, 0],
  //         [1570838400000, 0],
  //         [1570924800000, 0],
  //         [1571011200000, 0],
  //         [1571097600000, 0],
  //         [1571184000000, 0],
  //         [1571270400000, 0],
  //         [1571356800000, 0],
  //         [1571443200000, 0],
  //         [1571529600000, 0],
  //         [1571616000000, 0],
  //         [1571702400000, 0],
  //         [1571788800000, 0],
  //         [1571875200000, 0],
  //         [1571961600000, 0],
  //         [1572048000000, 0],
  //         [1572134400000, 0],
  //         [1572220800000, 0],
  //         [1572393600000, 2],
  //         [1572480000000, 0],
  //         [1572652800000, 0],
  //         [1572739200000, 0],
  //         [1572825600000, 0],
  //         [1572912000000, 0],
  //         [1572998400000, 0],
  //         [1573084800000, 0],
  //         [1573171200000, 0],
  //         [1573257600000, 0],
  //         [1573344000000, 0],
  //         [1573516800000, 0],
  //         [1573603200000, 1],
  //         [1573689600000, 0],
  //         [1573776000000, 0],
  //         [1573948800000, 0],
  //         [1574035200000, 0],
  //         [1574121600000, 0],
  //         [1574208000000, 0],
  //         [1574294400000, 0],
  //         [1574380800000, 0],
  //         [1574467200000, 0],
  //         [1574553600000, 0],
  //         [1574640000000, 0],
  //         [1574726400000, 0],
  //         [1574812800000, 0],
  //         [1574899200000, 0],
  //         [1574985600000, 0],
  //         [1575072000000, 0],
  //         [1575158400000, 0],
  //         [1575244800000, 0],
  //         [1575331200000, 0],
  //         [1575417600000, 0],
  //         [1575504000000, 0],
  //         [1575590400000, 0],
  //         [1575676800000, 0],
  //         [1575849600000, 0],
  //         [1575936000000, 0],
  //         [1576022400000, 0],
  //         [1576108800000, 0],
  //         [1576281600000, 0],
  //         [1576368000000, 0],
  //         [1576454400000, 0],
  //         [1576540800000, 0],
  //         [1576627200000, 0],
  //         [1576713600000, 0],
  //         [1576800000000, 0],
  //         [1576886400000, 0],
  //         [1576972800000, 0],
  //         [1577059200000, 0],
  //         [1577145600000, 1],
  //         [1577232000000, 1],
  //         [1577318400000, 1],
  //         [1577491200000, 1],
  //         [1577577600000, 1],
  //         [1577664000000, 1],
  //         [1577750400000, 1],
  //         [1577836800000, 1],
  //         [1577923200000, 1],
  //         [1578009600000, 1],
  //         [1578096000000, 1],
  //         [1578182400000, 1],
  //         [1578268800000, 1],
  //         [1578355200000, 1],
  //         [1578441600000, 1],
  //       ],
  //       ideas_approved: [
  //         [1564444800000, 2],
  //         [1564531200000, 2],
  //         [1564617600000, 2],
  //         [1564704000000, 2],
  //         [1564790400000, 2],
  //         [1564876800000, 2],
  //         [1564963200000, 2],
  //         [1565049600000, 2],
  //         [1565136000000, 2],
  //         [1565222400000, 2],
  //         [1565308800000, 2],
  //         [1565395200000, 2],
  //         [1565481600000, 2],
  //         [1565568000000, 2],
  //         [1565654400000, 2],
  //         [1565740800000, 2],
  //         [1565827200000, 2],
  //         [1565913600000, 2],
  //         [1566000000000, 2],
  //         [1566086400000, 2],
  //         [1566172800000, 2],
  //         [1566259200000, 2],
  //         [1566345600000, 2],
  //         [1566432000000, 2],
  //         [1566518400000, 2],
  //         [1566604800000, 2],
  //         [1566691200000, 2],
  //         [1566777600000, 2],
  //         [1566864000000, 2],
  //         [1566950400000, 2],
  //         [1567036800000, 2],
  //         [1567123200000, 2],
  //         [1567209600000, 2],
  //         [1567296000000, 2],
  //         [1567382400000, 2],
  //         [1567468800000, 2],
  //         [1567555200000, 2],
  //         [1567641600000, 2],
  //         [1567728000000, 2],
  //         [1567814400000, 2],
  //         [1567900800000, 2],
  //         [1567987200000, 2],
  //         [1568073600000, 3],
  //         [1568160000000, 3],
  //         [1568246400000, 3],
  //         [1568332800000, 3],
  //         [1568419200000, 3],
  //         [1568505600000, 3],
  //         [1568592000000, 3],
  //         [1568678400000, 3],
  //         [1568764800000, 3],
  //         [1568851200000, 3],
  //         [1568937600000, 3],
  //         [1569024000000, 3],
  //         [1569110400000, 3],
  //         [1569196800000, 3],
  //         [1569283200000, 3],
  //         [1569369600000, 3],
  //         [1569456000000, 3],
  //         [1569542400000, 3],
  //         [1569628800000, 3],
  //         [1569715200000, 3],
  //         [1569801600000, 3],
  //         [1569888000000, 3],
  //         [1569974400000, 3],
  //         [1570060800000, 3],
  //         [1570147200000, 3],
  //         [1570233600000, 3],
  //         [1570320000000, 3],
  //         [1570406400000, 3],
  //         [1570492800000, 3],
  //         [1570579200000, 3],
  //         [1570665600000, 3],
  //         [1570752000000, 3],
  //         [1570838400000, 3],
  //         [1570924800000, 3],
  //         [1571011200000, 3],
  //         [1571097600000, 3],
  //         [1571184000000, 3],
  //         [1571270400000, 3],
  //         [1571356800000, 3],
  //         [1571443200000, 3],
  //         [1571529600000, 3],
  //         [1571616000000, 3],
  //         [1571702400000, 3],
  //         [1571788800000, 3],
  //         [1571875200000, 3],
  //         [1571961600000, 3],
  //         [1572048000000, 3],
  //         [1572134400000, 3],
  //         [1572220800000, 3],
  //         [1572393600000, 5],
  //         [1572480000000, 5],
  //         [1572652800000, 5],
  //         [1572739200000, 5],
  //         [1572825600000, 5],
  //         [1572912000000, 5],
  //         [1572998400000, 5],
  //         [1573084800000, 5],
  //         [1573171200000, 5],
  //         [1573257600000, 5],
  //         [1573344000000, 5],
  //         [1573516800000, 5],
  //         [1573603200000, 5],
  //         [1573689600000, 5],
  //         [1573776000000, 5],
  //         [1573948800000, 5],
  //         [1574035200000, 5],
  //         [1574121600000, 6],
  //         [1574208000000, 6],
  //         [1574294400000, 6],
  //         [1574380800000, 6],
  //         [1574467200000, 6],
  //         [1574553600000, 6],
  //         [1574640000000, 7],
  //         [1574726400000, 8],
  //         [1574812800000, 9],
  //         [1574899200000, 11],
  //         [1574985600000, 11],
  //         [1575072000000, 11],
  //         [1575158400000, 11],
  //         [1575244800000, 12],
  //         [1575331200000, 12],
  //         [1575417600000, 12],
  //         [1575504000000, 12],
  //         [1575590400000, 12],
  //         [1575676800000, 12],
  //         [1575849600000, 12],
  //         [1575936000000, 12],
  //         [1576022400000, 13],
  //         [1576108800000, 14],
  //         [1576281600000, 17],
  //         [1576368000000, 17],
  //         [1576454400000, 17],
  //         [1576540800000, 18],
  //         [1576627200000, 18],
  //         [1576713600000, 17],
  //         [1576800000000, 17],
  //         [1576886400000, 17],
  //         [1576972800000, 17],
  //         [1577059200000, 17],
  //         [1577145600000, 17],
  //         [1577232000000, 17],
  //         [1577318400000, 17],
  //         [1577491200000, 17],
  //         [1577577600000, 17],
  //         [1577664000000, 17],
  //         [1577750400000, 17],
  //         [1577836800000, 17],
  //         [1577923200000, 17],
  //         [1578009600000, 17],
  //         [1578096000000, 17],
  //         [1578182400000, 17],
  //         [1578268800000, 17],
  //         [1578355200000, 18],
  //         [1578441600000, 18],
  //       ],
  //       ideas_pilot: [
  //         [1564444800000, 1],
  //         [1564531200000, 1],
  //         [1564617600000, 1],
  //         [1564704000000, 1],
  //         [1564790400000, 1],
  //         [1564876800000, 1],
  //         [1564963200000, 1],
  //         [1565049600000, 1],
  //         [1565136000000, 1],
  //         [1565222400000, 1],
  //         [1565308800000, 1],
  //         [1565395200000, 1],
  //         [1565481600000, 1],
  //         [1565568000000, 1],
  //         [1565654400000, 1],
  //         [1565740800000, 1],
  //         [1565827200000, 1],
  //         [1565913600000, 1],
  //         [1566000000000, 1],
  //         [1566086400000, 1],
  //         [1566172800000, 1],
  //         [1566259200000, 1],
  //         [1566345600000, 1],
  //         [1566432000000, 1],
  //         [1566518400000, 1],
  //         [1566604800000, 1],
  //         [1566691200000, 1],
  //         [1566777600000, 1],
  //         [1566864000000, 1],
  //         [1566950400000, 1],
  //         [1567036800000, 1],
  //         [1567123200000, 1],
  //         [1567209600000, 1],
  //         [1567296000000, 1],
  //         [1567382400000, 1],
  //         [1567468800000, 1],
  //         [1567555200000, 1],
  //         [1567641600000, 1],
  //         [1567728000000, 1],
  //         [1567814400000, 1],
  //         [1567900800000, 1],
  //         [1567987200000, 1],
  //         [1568073600000, 1],
  //         [1568160000000, 1],
  //         [1568246400000, 1],
  //         [1568332800000, 1],
  //         [1568419200000, 1],
  //         [1568505600000, 1],
  //         [1568592000000, 1],
  //         [1568678400000, 1],
  //         [1568764800000, 1],
  //         [1568851200000, 1],
  //         [1568937600000, 1],
  //         [1569024000000, 1],
  //         [1569110400000, 1],
  //         [1569196800000, 1],
  //         [1569283200000, 1],
  //         [1569369600000, 1],
  //         [1569456000000, 1],
  //         [1569542400000, 1],
  //         [1569628800000, 1],
  //         [1569715200000, 1],
  //         [1569801600000, 1],
  //         [1569888000000, 1],
  //         [1569974400000, 1],
  //         [1570060800000, 1],
  //         [1570147200000, 1],
  //         [1570233600000, 1],
  //         [1570320000000, 1],
  //         [1570406400000, 1],
  //         [1570492800000, 1],
  //         [1570579200000, 1],
  //         [1570665600000, 1],
  //         [1570752000000, 1],
  //         [1570838400000, 1],
  //         [1570924800000, 1],
  //         [1571011200000, 1],
  //         [1571097600000, 1],
  //         [1571184000000, 1],
  //         [1571270400000, 1],
  //         [1571356800000, 1],
  //         [1571443200000, 1],
  //         [1571529600000, 1],
  //         [1571616000000, 1],
  //         [1571702400000, 1],
  //         [1571788800000, 1],
  //         [1571875200000, 1],
  //         [1571961600000, 1],
  //         [1572048000000, 1],
  //         [1572134400000, 1],
  //         [1572220800000, 1],
  //         [1572393600000, 1],
  //         [1572480000000, 1],
  //         [1572652800000, 1],
  //         [1572739200000, 1],
  //         [1572825600000, 1],
  //         [1572912000000, 1],
  //         [1572998400000, 1],
  //         [1573084800000, 1],
  //         [1573171200000, 1],
  //         [1573257600000, 1],
  //         [1573344000000, 1],
  //         [1573516800000, 1],
  //         [1573603200000, 1],
  //         [1573689600000, 1],
  //         [1573776000000, 1],
  //         [1573948800000, 1],
  //         [1574035200000, 1],
  //         [1574121600000, 1],
  //         [1574208000000, 1],
  //         [1574294400000, 1],
  //         [1574380800000, 1],
  //         [1574467200000, 1],
  //         [1574553600000, 1],
  //         [1574640000000, 1],
  //         [1574726400000, 1],
  //         [1574812800000, 1],
  //         [1574899200000, 1],
  //         [1574985600000, 1],
  //         [1575072000000, 1],
  //         [1575158400000, 1],
  //         [1575244800000, 2],
  //         [1575331200000, 2],
  //         [1575417600000, 2],
  //         [1575504000000, 2],
  //         [1575590400000, 2],
  //         [1575676800000, 2],
  //         [1575849600000, 2],
  //         [1575936000000, 2],
  //         [1576022400000, 4],
  //         [1576108800000, 5],
  //         [1576281600000, 5],
  //         [1576368000000, 5],
  //         [1576454400000, 5],
  //         [1576540800000, 5],
  //         [1576627200000, 5],
  //         [1576713600000, 7],
  //         [1576800000000, 7],
  //         [1576886400000, 7],
  //         [1576972800000, 7],
  //         [1577059200000, 7],
  //         [1577145600000, 7],
  //         [1577232000000, 7],
  //         [1577318400000, 7],
  //         [1577491200000, 7],
  //         [1577577600000, 7],
  //         [1577664000000, 7],
  //         [1577750400000, 7],
  //         [1577836800000, 7],
  //         [1577923200000, 7],
  //         [1578009600000, 7],
  //         [1578096000000, 7],
  //         [1578182400000, 7],
  //         [1578268800000, 7],
  //         [1578355200000, 7],
  //         [1578441600000, 7],
  //       ],
  //       ideas_implemented: [
  //         [1564444800000, 0],
  //         [1564531200000, 0],
  //         [1564617600000, 0],
  //         [1564704000000, 0],
  //         [1564790400000, 0],
  //         [1564876800000, 0],
  //         [1564963200000, 0],
  //         [1565049600000, 0],
  //         [1565136000000, 0],
  //         [1565222400000, 0],
  //         [1565308800000, 0],
  //         [1565395200000, 0],
  //         [1565481600000, 0],
  //         [1565568000000, 0],
  //         [1565654400000, 0],
  //         [1565740800000, 0],
  //         [1565827200000, 0],
  //         [1565913600000, 0],
  //         [1566000000000, 0],
  //         [1566086400000, 0],
  //         [1566172800000, 0],
  //         [1566259200000, 0],
  //         [1566345600000, 0],
  //         [1566432000000, 0],
  //         [1566518400000, 0],
  //         [1566604800000, 0],
  //         [1566691200000, 0],
  //         [1566777600000, 0],
  //         [1566864000000, 0],
  //         [1566950400000, 0],
  //         [1567036800000, 0],
  //         [1567123200000, 0],
  //         [1567209600000, 0],
  //         [1567296000000, 0],
  //         [1567382400000, 0],
  //         [1567468800000, 0],
  //         [1567555200000, 0],
  //         [1567641600000, 0],
  //         [1567728000000, 0],
  //         [1567814400000, 0],
  //         [1567900800000, 0],
  //         [1567987200000, 0],
  //         [1568073600000, 0],
  //         [1568160000000, 0],
  //         [1568246400000, 0],
  //         [1568332800000, 0],
  //         [1568419200000, 0],
  //         [1568505600000, 0],
  //         [1568592000000, 0],
  //         [1568678400000, 0],
  //         [1568764800000, 0],
  //         [1568851200000, 0],
  //         [1568937600000, 0],
  //         [1569024000000, 0],
  //         [1569110400000, 0],
  //         [1569196800000, 0],
  //         [1569283200000, 0],
  //         [1569369600000, 0],
  //         [1569456000000, 0],
  //         [1569542400000, 0],
  //         [1569628800000, 0],
  //         [1569715200000, 0],
  //         [1569801600000, 0],
  //         [1569888000000, 0],
  //         [1569974400000, 0],
  //         [1570060800000, 0],
  //         [1570147200000, 0],
  //         [1570233600000, 0],
  //         [1570320000000, 0],
  //         [1570406400000, 0],
  //         [1570492800000, 0],
  //         [1570579200000, 0],
  //         [1570665600000, 0],
  //         [1570752000000, 0],
  //         [1570838400000, 0],
  //         [1570924800000, 0],
  //         [1571011200000, 0],
  //         [1571097600000, 0],
  //         [1571184000000, 0],
  //         [1571270400000, 0],
  //         [1571356800000, 0],
  //         [1571443200000, 0],
  //         [1571529600000, 0],
  //         [1571616000000, 0],
  //         [1571702400000, 0],
  //         [1571788800000, 0],
  //         [1571875200000, 0],
  //         [1571961600000, 0],
  //         [1572048000000, 0],
  //         [1572134400000, 0],
  //         [1572220800000, 0],
  //         [1572393600000, 1],
  //         [1572480000000, 1],
  //         [1572652800000, 1],
  //         [1572739200000, 1],
  //         [1572825600000, 1],
  //         [1572912000000, 1],
  //         [1572998400000, 1],
  //         [1573084800000, 1],
  //         [1573171200000, 1],
  //         [1573257600000, 1],
  //         [1573344000000, 1],
  //         [1573516800000, 1],
  //         [1573603200000, 1],
  //         [1573689600000, 1],
  //         [1573776000000, 1],
  //         [1573948800000, 1],
  //         [1574035200000, 1],
  //         [1574121600000, 1],
  //         [1574208000000, 1],
  //         [1574294400000, 1],
  //         [1574380800000, 1],
  //         [1574467200000, 1],
  //         [1574553600000, 1],
  //         [1574640000000, 1],
  //         [1574726400000, 1],
  //         [1574812800000, 1],
  //         [1574899200000, 1],
  //         [1574985600000, 1],
  //         [1575072000000, 1],
  //         [1575158400000, 1],
  //         [1575244800000, 1],
  //         [1575331200000, 1],
  //         [1575417600000, 1],
  //         [1575504000000, 1],
  //         [1575590400000, 1],
  //         [1575676800000, 1],
  //         [1575849600000, 1],
  //         [1575936000000, 4],
  //         [1576022400000, 4],
  //         [1576108800000, 4],
  //         [1576281600000, 5],
  //         [1576368000000, 5],
  //         [1576454400000, 5],
  //         [1576540800000, 5],
  //         [1576627200000, 5],
  //         [1576713600000, 5],
  //         [1576800000000, 5],
  //         [1576886400000, 5],
  //         [1576972800000, 5],
  //         [1577059200000, 5],
  //         [1577145600000, 5],
  //         [1577232000000, 5],
  //         [1577318400000, 5],
  //         [1577491200000, 5],
  //         [1577577600000, 5],
  //         [1577664000000, 5],
  //         [1577750400000, 5],
  //         [1577836800000, 5],
  //         [1577923200000, 5],
  //         [1578009600000, 5],
  //         [1578096000000, 5],
  //         [1578182400000, 5],
  //         [1578268800000, 5],
  //         [1578355200000, 5],
  //         [1578441600000, 5],
  //       ],
  //       ideas_discarded: [
  //         [1564444800000, 0],
  //         [1564531200000, 0],
  //         [1564617600000, 0],
  //         [1564704000000, 0],
  //         [1564790400000, 0],
  //         [1564876800000, 0],
  //         [1564963200000, 0],
  //         [1565049600000, 0],
  //         [1565136000000, 0],
  //         [1565222400000, 0],
  //         [1565308800000, 0],
  //         [1565395200000, 0],
  //         [1565481600000, 0],
  //         [1565568000000, 0],
  //         [1565654400000, 0],
  //         [1565740800000, 0],
  //         [1565827200000, 0],
  //         [1565913600000, 0],
  //         [1566000000000, 0],
  //         [1566086400000, 0],
  //         [1566172800000, 0],
  //         [1566259200000, 0],
  //         [1566345600000, 0],
  //         [1566432000000, 0],
  //         [1566518400000, 0],
  //         [1566604800000, 0],
  //         [1566691200000, 0],
  //         [1566777600000, 0],
  //         [1566864000000, 0],
  //         [1566950400000, 0],
  //         [1567036800000, 0],
  //         [1567123200000, 0],
  //         [1567209600000, 0],
  //         [1567296000000, 0],
  //         [1567382400000, 0],
  //         [1567468800000, 0],
  //         [1567555200000, 0],
  //         [1567641600000, 0],
  //         [1567728000000, 0],
  //         [1567814400000, 0],
  //         [1567900800000, 0],
  //         [1567987200000, 0],
  //         [1568073600000, 0],
  //         [1568160000000, 0],
  //         [1568246400000, 0],
  //         [1568332800000, 0],
  //         [1568419200000, 0],
  //         [1568505600000, 0],
  //         [1568592000000, 0],
  //         [1568678400000, 0],
  //         [1568764800000, 0],
  //         [1568851200000, 0],
  //         [1568937600000, 1],
  //         [1569024000000, 3],
  //         [1569110400000, 3],
  //         [1569196800000, 3],
  //         [1569283200000, 3],
  //         [1569369600000, 3],
  //         [1569456000000, 3],
  //         [1569542400000, 3],
  //         [1569628800000, 3],
  //         [1569715200000, 3],
  //         [1569801600000, 3],
  //         [1569888000000, 3],
  //         [1569974400000, 3],
  //         [1570060800000, 3],
  //         [1570147200000, 3],
  //         [1570233600000, 4],
  //         [1570320000000, 4],
  //         [1570406400000, 4],
  //         [1570492800000, 4],
  //         [1570579200000, 4],
  //         [1570665600000, 4],
  //         [1570752000000, 4],
  //         [1570838400000, 4],
  //         [1570924800000, 4],
  //         [1571011200000, 4],
  //         [1571097600000, 4],
  //         [1571184000000, 5],
  //         [1571270400000, 5],
  //         [1571356800000, 5],
  //         [1571443200000, 5],
  //         [1571529600000, 5],
  //         [1571616000000, 5],
  //         [1571702400000, 5],
  //         [1571788800000, 5],
  //         [1571875200000, 7],
  //         [1571961600000, 9],
  //         [1572048000000, 12],
  //         [1572134400000, 12],
  //         [1572220800000, 12],
  //         [1572393600000, 12],
  //         [1572480000000, 16],
  //         [1572652800000, 18],
  //         [1572739200000, 19],
  //         [1572825600000, 20],
  //         [1572912000000, 20],
  //         [1572998400000, 20],
  //         [1573084800000, 21],
  //         [1573171200000, 23],
  //         [1573257600000, 25],
  //         [1573344000000, 26],
  //         [1573516800000, 26],
  //         [1573603200000, 27],
  //         [1573689600000, 31],
  //         [1573776000000, 31],
  //         [1573948800000, 32],
  //         [1574035200000, 33],
  //         [1574121600000, 33],
  //         [1574208000000, 33],
  //         [1574294400000, 33],
  //         [1574380800000, 33],
  //         [1574467200000, 34],
  //         [1574553600000, 34],
  //         [1574640000000, 38],
  //         [1574726400000, 38],
  //         [1574812800000, 38],
  //         [1574899200000, 38],
  //         [1574985600000, 38],
  //         [1575072000000, 38],
  //         [1575158400000, 38],
  //         [1575244800000, 38],
  //         [1575331200000, 38],
  //         [1575417600000, 38],
  //         [1575504000000, 38],
  //         [1575590400000, 38],
  //         [1575676800000, 38],
  //         [1575849600000, 38],
  //         [1575936000000, 37],
  //         [1576022400000, 38],
  //         [1576108800000, 39],
  //         [1576281600000, 41],
  //         [1576368000000, 41],
  //         [1576454400000, 40],
  //         [1576540800000, 40],
  //         [1576627200000, 40],
  //         [1576713600000, 40],
  //         [1576800000000, 41],
  //         [1576886400000, 41],
  //         [1576972800000, 42],
  //         [1577059200000, 42],
  //         [1577145600000, 42],
  //         [1577232000000, 42],
  //         [1577318400000, 42],
  //         [1577491200000, 42],
  //         [1577577600000, 42],
  //         [1577664000000, 42],
  //         [1577750400000, 42],
  //         [1577836800000, 42],
  //         [1577923200000, 44],
  //         [1578009600000, 44],
  //         [1578096000000, 45],
  //         [1578182400000, 45],
  //         [1578268800000, 45],
  //         [1578355200000, 45],
  //         [1578441600000, 45],
  //       ],
  //     },
  //   ],
  // }
  const seriesAsObject = jsonResponse.items[0] || {}
  return Object.keys(seriesAsObject).map(key => ({
    name: i18n.t(`DASHBOARD.${key.replace('private_', '').toUpperCase()}`),
    data: seriesAsObject[key],
  }))
}

export function transformUsersTableResponse(jsonResponse) {
  return {
    users: jsonResponse.userstats || [],
    totalSearchItems: jsonResponse.total,
  }
}

/* eslint-disable */
// The following code is a legacy code taken from iris in order to calculate the
// participation/success percentage.
export function transformUsersParticipationResponse(jsonResponse, state, offset) {
  const p_data = jsonResponse.userstats
  var totals = {
    engage: 0,
    success: 0,
    ideas: 0,
    teams: 0,
    comments: 0,
    investments: 0,
    funded: 0,
    teamsFunded: 0,
    commentsLiked: 0,
    investmentsSucess: 0,
    ideasAvg: 0,
    fundedAvg: 0,
    teamsAvg: 0,
    teamsFundedAvg: 0,
    commentsAvg: 0,
    commentsLikedAvg: 0,
    investmentsAvg: 0,
    investmentsSucessAvg: 0,
    userParticipate: 0,
    userParticipation: 0,
  }
  var userseries = []

  if (p_data !== null && p_data.length > 0) {
    var userL = p_data
    var maxIdeas = 0
    var maxTeams = 0
    var maxComments = 0
    var maxInvestments = 0

    //first we need to find the max tresholds
    for (var i = 0; i < userL.length; i++) {
      var user = userL[i]

      if (maxIdeas < user.ideasOwned) maxIdeas = user.ideasOwned
      if (maxTeams < user.teamsInvolved) maxTeams = user.teamsInvolved
      if (maxComments < user.comments) maxComments = user.comments
      if (maxInvestments < user.investments) maxInvestments = user.investments
    }

    //with max tresholds, we can now calculate each user
    for (var i = 0; i < userL.length; i++) {
      var user = userL[i]

      var ideas = user.ideasOwned
      var funded = user.ideasFunded
      var teams = user.teamsInvolved
      var teamsFunded = user.teamsFunded
      var comments = user.comments
      var commentsLiked = user.commentsLiked
      var investments = user.investments
      var investmentsSucess = user.investmentsSuccess

      totals.ideas += ideas
      totals.funded += funded
      totals.teams += teams
      totals.teamsFunded += teamsFunded
      totals.comments += comments
      totals.commentsLiked += commentsLiked
      totals.investments += investments
      totals.investmentsSucess += investmentsSucess

      //PARTICIPATION
      var ideasW = 0.6
      var teamsW = 0.5
      var investW = 0.5
      var commentW = 0.2

      var wIdeas = (ideas / maxIdeas) * ideasW
      var wTeams = (teams / maxTeams) * teamsW
      var wInvestments = (investments / maxInvestments) * investW
      var wComments = (comments / maxComments) * commentW

      var xValue = Math.ceil(
        ((!isFinite(wIdeas) ? 0 : wIdeas) +
          (!isFinite(wTeams) ? 0 : wTeams) +
          (!isFinite(wInvestments) ? 0 : wInvestments) +
          (!isFinite(wComments) ? 0 : wComments)) *
          100,
      )

      xValue = xValue > 100 ? 100 : xValue
      totals.userParticipation += xValue

      if (xValue > 0) totals.userParticipate++

      totals.engage += xValue

      //SUCCESS
      ideasW = 0.3
      teamsW = 0.3
      investW = 0.3
      commentW = 0.1

      wIdeas = (funded / ideas) * ideasW
      wTeams = (teamsFunded / teams) * teamsW
      wInvestments = (investmentsSucess / investments) * investW
      wComments = (commentsLiked / comments) * commentW

      var yValue = Math.ceil(
        ((funded + teamsFunded + investmentsSucess + commentsLiked) /
          (ideas + teams + investments + comments)) *
          100,
      )

      yValue = isNaN(yValue) ? 0 : yValue

      yValue = yValue > 100 ? 100 : yValue
      totals.success += yValue

      userseries[i] = {
        name: user.user.fullname,
        email: user.user.email,
        photo: user.user.photo,
        ideas: ideas,
        funded: funded,
        teams: teams,
        teamsFunded: teamsFunded,
        comments: comments,
        commentsLiked: commentsLiked,
        investments: investments,
        investmentsSuccess: investmentsSucess,
        x: xValue,
        y: yValue,
      }
    }

    totals.successAvg = (totals.success / userL.length).toFixed(2)
    totals.userParticipationAvg = ((totals.userParticipate / userL.length) * 100).toFixed(2)
  }
  const {
    totalUsers,
    participationAvg,
    successAvg,
    series,
  } = state.data.dashboard.people.usersParticipation
  let oldSeries = []
  if (offset !== 0 && series.length > 0) {
    oldSeries = series[0].data
  }
  let totalLength = oldSeries.length + userseries.length
  if (totalLength === 0) {
    totalLength = 1
  }
  const participatingWeightedAverage =
    (1 / totalLength) *
    (Number(participationAvg) * oldSeries.length +
      Number(totals.userParticipationAvg) * userseries.length)
  const successWeightedAverage =
    (1 / totalLength) *
    (Number(successAvg) * oldSeries.length + Number(totals.successAvg) * userseries.length)

  let numberOfUsers = p_data.length
  if (offset !== 0) {
    numberOfUsers = totalUsers + p_data.length
  }
  return {
    hasMore: jsonResponse.more,
    totalUsers: numberOfUsers,
    participationAvg: participatingWeightedAverage.toFixed(2),
    successAvg: successWeightedAverage.toFixed(2),
    series: [
      {
        data: oldSeries.concat(userseries),
      },
    ],
  }
}

const styles = theme => ({
  notVisible: {
    visibility: 'hidden',
  },
  base: {
    ...theme.loginBase,
    gridTemplateColumns: props => (props.useCustomLogin ? '1fr 1fr' : '460px 1fr'),
    gridTemplateRows: '1fr',
    backgroundColor: props =>
      props.useCustomLogin && props.useCustomColor && props.customColor ? props.customColor : '',
    backgroundImage: props =>
      props.useCustomLogin && props.useCustomBgImg ? `url(${props.customBgImg})` : '',
    backgroundSize: props => (props.useCustomLogin && props.useCustomBgImg ? 'cover' : 'unset'),
    backgroundPosition: props =>
      props.useCustomLogin && props.useCustomBgImg ? 'bottom' : 'unset',
    color: props =>
      props.useCustomLogin && props.useCustomTextColor && props.customTextColor
        ? props.customTextColor
        : theme.palette.common.black,
  },
  customize: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > h1': {
      marginBottom: 0,
    },
  },
})

export default styles

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  noBadgesPaper: {
    width: '100%',
    padding: theme.spacing(2, 3),
    textAlign: 'center',
    fontSize: '0.875rem',
  },
  isHidden: {
    display: 'none',
  },
  isNotActive: {
    opacity: 0.4,
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3, 0),
    '& > div > span:first-child': {
      fontSize: '2rem',
      marginRight: theme.spacing(1),
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  titleText: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  showAll: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#666',
  },
  badgesSection: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div > img': {
      width: '84px',
      height: '84px',
    },
  },
})

export default styles

import { connect } from 'react-redux'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import VideoUploadDialog from 'components/video_upload_dialog/View'
import { showPopupErrorSnackbar } from 'support/popup_dialogs'
import { VIDEO_STATE_TYPE } from 'common_constants/VzaarVideo'
import i18n from 'support/i18n'


const mapStateToProps = (state, ownProps) => {
  let videoStateData
  if (ownProps.stateType === VIDEO_STATE_TYPE.CHALLENGE) {
    videoStateData = state.data.challengeSteps.challengeDescription
  } else {
    videoStateData = state.data.createIdea.videos
  }
  return {
    fileVideoSrc: videoStateData.fileVideo,
    fileVideoId: videoStateData.fileVideoId,
    isVideoLoading: videoStateData.isVideoLoading,
    isVideoReady: videoStateData.isVideoReady,
    isVideoRemoving: videoStateData.isVideoRemoving,
    hasVideoError: videoStateData.hasVideoError,
    isLoadingVideo : videoStateData.isLoadingVideo
  }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  onUploadVideo: video => {
    // console.log("ownproid===>",ownProps)
    // console.log('video==>uppopup ',video)
    const onFailure = errorResponse => {
      let message = i18n.t('VIDEO.VIDEO_ERROR')
      if (errorResponse.status === 413) {
        message = i18n.t('VIDEO.VIDEO_TOO_LARGE_UPLOAD_ERROR')
      }
      showPopupErrorSnackbar(message, dispatch)
    }
    dispatch(ownProps.onOuterUploadVideo(ownProps.parentId, video, onFailure))
  },
  onDeleteVideo: fileVideoId => dispatch(ownProps.onOuterDeleteVideo(fileVideoId)),
  onCancel: () => dispatch(hideDialog()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoUploadDialog)

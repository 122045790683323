const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  select: {
    fontSize: '14px',
    width: '8rem',
    '& > div:first-child': {
      padding: '0.7rem',
    },
  },
  totalUsers: {
    fontWeight: 'bold',
    paddingRight: theme.spacing(1),
  },
})

export default styles

const styles = theme => ({
  root: {
    padding: theme.spacing(3, 0, 3, 0),
    borderTop: '1px solid #b3b3b3',
  },
  infoMessageBox: {
    backgroundColor: '#d9edf7',
    color: '#3a87ad',
    fontWeight: '700',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: theme.spacing(1.75),
    '& > span > p': {
      margin: 0,
    },
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '26px',
    fontWeight: 500,
    '& > span': {
      fontSize: '32px',
      marginRight: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  listContainer: {
    width: '100%',
    maxHeight: '250px',
    overflow: 'auto',
    border: '1px solid #eeeeee',
    marginTop: theme.spacing(1),
    paddingLeft: 0,
    '& > li:first-child': {
      borderTop: 'unset',
    },
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: '0.8fr 0.3fr 0.2fr',
    gridTemplateAreas: '"left middle right"',
    borderTop: 'solid #eeeeee 1px',
    padding: theme.spacing(0.5, 0),
  },
  leftContent: {
    gridArea: 'left',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    '& > span': {
      paddingLeft: theme.spacing(1),
    },
  },
  middleContent: {
    gridArea: 'middle',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
  },
  rightContent: {
    gridArea: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    '& > span': {
      fontSize: '12px',
    },
  },
  iconPadded: {
    padding: theme.spacing(2),
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    fontSize: '12px',
  },
})

export default styles

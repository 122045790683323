const styles = theme => ({
  root: {
    gridColumnStart: 2,
  },
  bannerSecondLine: {
    '& > button': {
      fontSize: theme.spacing(1.75),
    },
  },
})

export default styles

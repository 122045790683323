import { createStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    line: {
      display: 'block',
      height: '1px',
      border: 0,
      borderTopStyle: 'solid',
      borderTop: '1px',
      margin: '1em 0',
      padding: 0,
      width: '100%',
    },
    lineColor: {
      borderTopColor: 'rgba(0, 0, 0, 0.18)',
    },
    lineWithText: {
      width: '60%',
    },
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 10%',
    },
    textDivider: {
      color: 'rgba(0, 0, 0, 0.18)',
    },
  })

export default styles

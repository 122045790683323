import { connect } from 'react-redux'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import { postRequestInvestment } from 'services/user'
import { showPopupErrorSnackbar, showPopupSuccessSnackbar } from 'support/popup_dialogs'
import i18n from 'support/i18n'
import View from './View'

const mapStateToProps = state => ({
  hasMyIdeasLoaded: state.data.userProfile.hasMyIdeasLoaded,
  isSendingInvestmentRequest: state.data.userProfile.isSendingInvestmentRequest,
  myIdeasToInvestIn: state.data.userProfile.myIdeasToInvestIn,
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSendRequestClick: (ideaId, messageContent) => {
      const onSuccess = () => {
        dispatch(hideDialog())
        dispatch(showPopupSuccessSnackbar(i18n.t('PROFILE.REQUEST_INVEST_SENT'), dispatch))
      }
      const onFailure = () => {
        dispatch(hideDialog())
        dispatch(showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.REQUEST_ERROR'), dispatch))
      }
      dispatch(
        postRequestInvestment(ownProps.investorEmail, ideaId, messageContent, onSuccess, onFailure),
      )
    },
    onCancelClick: () => {
      dispatch(hideDialog())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

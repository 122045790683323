import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { formatLocaleDistanceToNow } from 'support/date_fns_locale'
import clsx from 'clsx'
import styles from './View.Style'
import EventItem from './components/event_item/View'

const lessThanAMinute = formatLocaleDistanceToNow(Date.now())

const EventRow = props => {
  const { classes, event, position, navigateToIdea, badges } = props
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        {position === 'left' && (
          <EventItem
            badges={badges}
            event={event}
            isRight={position === 'right'}
            navigateToIdea={navigateToIdea}
            labelColorPrimary={event.timeAgo === lessThanAMinute}
          />
        )}
      </div>
      <div className={classes.middle}>
        <div
          className={clsx(
            classes.ball,
            event.timeAgo === lessThanAMinute && classes.primaryBackground,
          )}
        />
      </div>
      <div className={classes.right}>
        {position === 'right' && (
          <EventItem
            badges={badges}
            event={event}
            isRight={position === 'right'}
            navigateToIdea={navigateToIdea}
            labelColorPrimary={event.timeAgo === lessThanAMinute}
          />
        )}
      </div>
    </div>
  )
}

EventRow.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  badges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default withStyles(styles)(EventRow)

const styles = theme => ({
  base: {
    ...theme.loginBase,
    gridTemplateColumns: '460px 1fr',
    gridTemplateRows: '1fr',
    backgroundSize: 'cover',
    background: props => {
      return props.useCustomLogin && props.useCustomBgImg
        ? `url(${props.customBgImg}) ${props.customColor} repeat-x bottom`
        : 'unset'
    },
    color: props =>
      props.useCustomLogin && props.useCustomTextColor ? props.customTextColor : 'black',
  },
  paper: {
    ...theme.loginBase,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > p': {
      color: '#959595',
    },
    '& > p:nth-child(4)': {
      fontWeight: '500',
    },
  },
})

export default styles

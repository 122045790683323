const styles = () => ({
  dialGraphCircle: {
    display: 'inline-block',
    borderRadius: '100%',
    padding: '12px',
    backgroundColor: 'unset',
  },
  dialGraphCircleLight: {
    backgroundColor: 'white',
  },
  dialGraphCircleDark: {
    backgroundColor: '#333',
  },
  sizeSmall: {
    width: '80px',
    height: '80px',
  },
  sizeBig: {
    width: '96px',
    height: '96px',
  },
})

export default styles

export const transformAboutus = data => {
  return {
    aboutUsQ1: data.aboutUsQ1,
    aboutUsAns1: data.aboutUsAns1,
    aboutUsQ2: data.aboutUsQ2,
    aboutUsAns2: data.aboutUsAns2,
    aboutUsQ3: data.aboutUsQ3,
    aboutUsAns3: data.aboutUsAns3,
  }
}

export const transformAboutusDetails = jsonResponse => {
  // console.log(`Data response1: ${JSON.stringify(jsonResponse)}`)
  return {
    aboutUsData: transformAboutus(jsonResponse.aboutUsData),
  }
}

import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import styles from './View.Style'

const FileUploader = props => {
  const { classes, dropText, onFileSelect } = props
  const onDrop = useCallback(acceptedFiles => {
    onFileSelect(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  return (
    <div className={classes.root} {...getRootProps()}>
      <input {...getInputProps()} />
      <span className="icon-upload" />
      <p>{dropText}</p>
    </div>
  )
}

FileUploader.defaultProps = {
  dropText: '',
}

FileUploader.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  dropText: PropTypes.string,
  onFileSelect: PropTypes.func.isRequired,
}

export default withStyles(styles)(FileUploader)

import React from 'react'
import * as PropTypes from 'prop-types'
import { FormControl, InputBase, MenuItem, Select, withStyles } from '@material-ui/core'
import styles from './View.Style'

const InnerSelect = ({ classes, value, handleChange, options = [] }) => (
  <FormControl className={classes.margin}>
    <Select
      value={value}
      onChange={event => handleChange(event.target.value)}
      input={<InputBase />}
    >
      {options.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

InnerSelect.defaultProps = {
  value: '',
}

InnerSelect.propTypes = {
  classes: PropTypes.shape({
    margin: PropTypes.string,
  }).isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
}

export default withStyles(styles)(InnerSelect)

import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import styles from './AlertModalView.Style'

const AlertModalView = props => {
  const {
    classes,
    title,
    description,
    mainButtonAction,
    mainButtonText,
    cancelable,
    cancelButtonAction,
    cancelButtonText,
    alternative,
    alternativeButtonAction,
    alternativeButtonText,
  } = props
  return (
    <div className={classes.dialogBox}>
      {cancelable && (
        <IconButton
          className={classes.iconClose}
          aria-label="close"
          onClick={cancelButtonAction}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <span className="icon-close" />
        </IconButton>
      )}
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <span
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={mainButtonAction} color="primary" variant="contained" autoFocus>
          {mainButtonText}
        </Button>
        {alternative && (
          <Button onClick={alternativeButtonAction} color="primary">
            {alternativeButtonText}
          </Button>
        )}
        {cancelable && cancelButtonText && (
          <Button onClick={cancelButtonAction} color="primary">
            {cancelButtonText}
          </Button>
        )}
      </DialogActions>
    </div>
  )
}

AlertModalView.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  mainButtonText: PropTypes.string,
  mainButtonAction: PropTypes.func,
  cancelable: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  cancelButtonAction: PropTypes.func,
  alternative: PropTypes.bool,
  alternativeButtonText: PropTypes.string,
  alternativeButtonAction: PropTypes.func,
}

AlertModalView.defaultProps = {
  title: '',
  description: '',
  mainButtonText: '',
  mainButtonAction: undefined,
  cancelable: false,
  cancelButtonText: undefined,
  cancelButtonAction: undefined,
  alternative: false,
  alternativeButtonText: undefined,
  alternativeButtonAction: undefined,
}

export default withStyles(styles)(AlertModalView)

import { ServiceCall } from 'support/network/ServiceCallAction'
import {
  GET_RANKING_STARTED,
  GET_RANKING_SUCCESS,
  GET_RANKING_FAILURE,
} from 'actions/sync/ranking/Constants'
import { transformRankingServiceResponseDataWithState } from './ResponseUtils'

export const GET_RANKING_URL = '/s/ranking/?full=true'

export function getRankingService() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_RANKING_STARTED
  apiCall.successReqType = GET_RANKING_SUCCESS
  apiCall.failureReqType = GET_RANKING_FAILURE
  apiCall.setEndpointFromState = () => GET_RANKING_URL
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = transformRankingServiceResponseDataWithState
  apiCall.auth = true
  return apiCall.getAction()
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2, 3),
    '& > p': {
      fontSize: '14px',
    },
  },
})

export default styles

const styles = () => ({
  dialogBox: {
    width: '530px',
    padding: '10px',
  },
  iconClose: {
    float: 'right',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

export default styles

import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { Avatar, Divider, List, ListItem, ListItemText, withStyles } from '@material-ui/core'
import styles from './View.Style'

const SuggestionList = props => {
  const { classes, suggestions, lastSuggestionIndex, onSuggestionSelect, listWidth } = props
  return (
    <List component="nav" className={classes.suggestionsList} style={{ width: listWidth }}>
      {suggestions.map((suggestion, index) => (
        <Fragment key={suggestion.email}>
          <ListItem button onClick={() => onSuggestionSelect(suggestion)}>
            <Avatar src={suggestion.photoSrc} />
            <div className={classes.nameEmailSection}>
              <ListItemText className={classes.itemText} primary={suggestion.fullName} />
              <ListItemText className={classes.itemText} secondary={suggestion.email} />
            </div>
          </ListItem>
          {index !== lastSuggestionIndex && <Divider />}
        </Fragment>
      ))}
    </List>
  )
}

SuggestionList.defaultProps = {
  listWidth: '',
}

SuggestionList.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSuggestionSelect: PropTypes.func.isRequired,
  lastSuggestionIndex: PropTypes.number.isRequired,
  listWidth: PropTypes.string,
}

export default withStyles(styles)(SuggestionList)

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { Button } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import AnalyticsService from 'support/analytics'
import ideaStatusList from 'common_constants/Selects'
import SelectWithChecks from './components/select_with_checks/View'
import SelectWithChecksOrg from './components/select_with_checks_org/View'
import ActiveFilters from './components/active_filters/View'
import styles from './View.Style'

class ExploreFilterFields extends Component {
  constructor(props) {
    super(props)
    const { organizationsName, typeTitle } = props
    this.state = {
      organizationSelected: [],
      selectedChallenge: '',
      status: [],
      typeSelected: [],
      organizationDefaultText: `${i18n.t('HOME.FILTER.TEXT.SELECT',)}${organizationsName || i18n.t('ORGANIZATION.TITLE')}`,
      typesDefaultText: typeTitle ? `${i18n.t('HOME.FILTER.TEXT.SELECT')}${typeTitle}` : i18n.t('HOME.FILTER.TEXT.SELECT_TYPES'),
      globalIdeas: true,
    }
    this.challengeDefaultText = i18n.t('HOME.FILTER.TEXT.SELECT_CHALLENGES')
    this.statusDefaultText = i18n.t('HOME.FILTER.TEXT.SELECT_STATUS')

    this.applyFilter = this.applyFilter.bind(this)
    this.onValueChange = this.onValueChange.bind(this)
    this.onChallengeChange = this.onChallengeChange.bind(this)
    this.getOrganizationData = this.getOrganizationData.bind(this)
    this.getTypesData = this.getTypesData.bind(this)
    this.handleFilterClick = this.handleFilterClick.bind(this)
    this.handleChangeGlobalIdeas = this.handleChangeGlobalIdeas.bind(this)
  }

  componentDidMount() {
    const { isOrganizationsActive, isTypesActive } = this.props
    if (isOrganizationsActive) this.getOrganizationData()
    if (isTypesActive) this.getTypesData()
  }

  componentDidUpdate(prevProps) {
    const {
      organizationsApplied,
      isOrganizationsActive,
      isTypesActive,
      organizationsName,
      typeTitle
    } = this.props
    if (isOrganizationsActive && prevProps.isOrganizationsActive !== isOrganizationsActive) {
      this.getOrganizationData()
    }
    if (isTypesActive && isTypesActive !== prevProps.isTypesActive) {
      this.getTypesData()
    }
    if (organizationsApplied !== prevProps.organizationsApplied) {
      this.setState({
        organizationSelected: organizationsApplied.map(org => org.name),
      })
    }
    if (organizationsName !== prevProps.organizationsName) {
      this.setState({
        organizationDefaultText: `${i18n.t(
          'HOME.FILTER.TEXT.SELECT_ORGANIZATIONS',
        )}${organizationsName || i18n.t('ORGANIZATION.TITLE')}`,
      })
    }
    if ( typeTitle !== prevProps.typeTitle){
      this.setState({
        typesDefaultText: typeTitle ? `${i18n.t('HOME.FILTER.TEXT.SELECT')}${typeTitle}` : i18n.t('HOME.FILTER.TEXT.SELECT_TYPES'),
      })
    }
  }

  onValueChange(event, callback) {
    const _callback = callback instanceof Function ? callback : () => {}
    this.setState({ [event.target.name]: event.target.value }, _callback)
  }

  onChallengeChange(event) {
    const { selectedChallenge } = this.state
    if (event.target.value === selectedChallenge || event.target.value==="") {
      this.setState({ [event.target.name?"":"selectedChallenge"]: '' })
     // this.setState({ ["selectedChallenge"]: '' })
    } else {
      this.setState({ [event.target.name]: event.target.value })
    }
  }

  getOrganizationData() {
    const { getOrganization } = this.props
    getOrganization()
  }

  getTypesData() {
    const { getTypes } = this.props
    getTypes()
  }

  handleChangeGlobalIdeas = event => {
    // if (event.target.checked) {
    //   this.applyFilter('global')
    // } else {
    //   this.applyFilter('local')
    // }
    // this.setState({ globalIdeas: event.target.checked })

    const { globalIdeas } = this.state
    if (globalIdeas) {
      this.applyFilter('local')
    } else {
      this.applyFilter('global')
    }
    this.setState({ globalIdeas: !globalIdeas })
  }

  challengeCheckFunction = (value, element) => {
    return value === element.id
  }

  textCheckFunction = (value, element) => {
    return value.indexOf(element.name) > -1
  }

  idCheckFunction = (value, element) => {
    return value.indexOf(element.id) > -1
  }

  idCheckFunctionOrg = (value, element) => {
    return value.indexOf(element.name) > -1
  }

  parseArray = (array, prop) => {
    const result = []
    array.forEach(state => result.push(this.parseIdToText(state, prop)))
    return result
  }

  parseIdToText = (string, list) => {
    if (list.length > 0 && string.length > 0) {
      const result = list.find(e => e.id === string)
      return result ? result.name : result
    }

    return ''
  }

  applyFilter(ideasLocation) {
    const {
      organizationSelected,
      selectedChallenge,
      status,
      typeSelected,
      globalIdeas,
    } = this.state
    // console.log("What are these props:")
    // console.log(this.props)
    const { challengeDetail, challenges, handleActiveFilters, organizations, folderOrganizations, types } = this.props
    let challengeId = selectedChallenge
    if (challengeDetail) {
      challengeId = challengeDetail
    }
    // console.log("before active filters")
    // console.log(organizations)
    // console.log(folderOrganizations)
    handleActiveFilters({
      activeFilters: {
        status,
        challenge: challenges.find(challenge => challenge.id === challengeId) || {},
        organizations: organizations.filter(org => organizationSelected.includes(`${org.folder}`)),
        folderOrganizations: folderOrganizations.filter(org => organizationSelected.includes(`${org.name}`)),
        types: types.filter(type => typeSelected.includes(`${type.id}`)),
        globalIdeas,
        ideasLocation,
      },
    })
  }

  handleFilterClick() {
    AnalyticsService.trackEvent({ category: 'swiper', action: 'click', label: 'apply new filter' })
    this.applyFilter('local')
  }

  renderChallengeValue = selected => {
    const { selectedChallenge } = this.state
    const { challenges } = this.props

    if (selected.length === 0) {
      return this.challengeDefaultText
    }
    return this.parseIdToText(selectedChallenge, challenges)
  }

  renderStatusValue = selected => {
    const selectedLength = selected.length
    switch (selectedLength) {
      case 0:
        return this.statusDefaultText
      case 1:
        return i18n.t(`HOME.FILTER.OPTIONS.${selected}`)
      default:
        return `${i18n.t('HOME.FILTER.TEXT.SELECTED')}: ${selectedLength}`
    }
  }

  renderOrganizationsValue = selected => {
    const { folderOrganizations } = this.props
    const { organizationDefaultText } = this.state
    const selectedLength = selected.length
    switch (selectedLength) {
      case 0:
        return organizationDefaultText
      case 1:
        console.log(folderOrganizations.find(org => org.name === selected[0]) || { name: organizationDefaultText })
        return (
          folderOrganizations.find(org => org.name === selected[0]) || { name: organizationDefaultText }
        ).name
      default:
        return `${i18n.t('HOME.FILTER.TEXT.SELECTED')}: ${selectedLength}`
    }
  }

  renderTypesValue = selected => {
    const { types } = this.props
    const { typesDefaultText } = this.state
    const selectedLength = selected.length

    switch (selectedLength) {
      case 0:
        return typesDefaultText
      case 1:
        return types.find(type => type.id === selected[0]).name
      default:
        return `${i18n.t('HOME.FILTER.TEXT.SELECTED')}: ${selectedLength}`
    }
  }

  render() {
    const {
      activeFilters,
      challenges,
      classes,
      organizations,
      folderOrganizations,
      types,
      isOrganizationsActive,
      isTypesActive,
    } = this.props
    const {
      organizationSelected,
      selectedChallenge,
      status,
      typeSelected,
      typesDefaultText,
      organizationDefaultText,
      globalIdeas,
      typeTitle,
    } = this.state
    // console.log(`organizationSelected::: ${JSON.stringify(organizationSelected)}`)
    // console.log(
    //   `organizationSelected2::: ${JSON.stringify(
    //     organizations.filter(org => organizationSelected.includes(`${org.folder}`)),
    //   )}`,
    // )

        
        
    return (
      <div className={classes.container}>
        <div className={classes.flexRowSpaceBetween}>
          {/* <Button
            href=""
            color={globalIdeas ? 'primary' : 'default'}
            onClick={this.handleChangeGlobalIdeas}
            variant="contained"
          >
            Global Ideas
          </Button> */}
          <SelectWithChecks
            checkFunction={this.textCheckFunction}
            name="status"
            defaultText={this.statusDefaultText}
            onValueChange={this.onValueChange}
            list={ideaStatusList}
            multiple
            renderValue={this.renderStatusValue}
            value={status}
          />
          {challenges.length > 0 && (
            <SelectWithChecks
              checkFunction={this.challengeCheckFunction}
              name="selectedChallenge"
              defaultText={this.challengeDefaultText}
              onValueChange={this.onChallengeChange}
              list={challenges}
              renderValue={this.renderChallengeValue}
              value={selectedChallenge}
            />
          )}
          {isOrganizationsActive && (
            // <SelectWithChecks
            //   // checkFunction={this.idCheckFunction}
            //   checkFunction={this.idCheckFunctionOrg}
            //   name="organizationSelected"
            //   defaultText={organizationDefaultText}
            //   onValueChange={this.onValueChange}
            //   list={organizations}
            //   multiple
            //   renderValue={this.renderOrganizationsValue}
            //   value={organizationSelected}
            // />
            <SelectWithChecksOrg
              // checkFunction={this.idCheckFunction}
              checkFunction={this.idCheckFunctionOrg}
              name="organizationSelected"
              defaultText={organizationDefaultText}
              onValueChange={this.onValueChange}
              list={folderOrganizations}
              multiple
              renderValue={this.renderOrganizationsValue}
              value={organizationSelected}
            />
          )}
          {isTypesActive && (
            <SelectWithChecks
              checkFunction={this.idCheckFunction}
              name="typeSelected"
              defaultText={typesDefaultText}
              onValueChange={this.onValueChange}
              list={types}
              multiple
              renderValue={this.renderTypesValue}
              value={typeSelected}
            />
          )}
          <Button href="" color="primary" onClick={this.handleFilterClick} variant="contained">
            {i18n.t('HOME.FILTER.TEXT.BTN')}
          </Button>
          <Button
            href=""
            color={globalIdeas ? 'primary' : 'default'}
            onClick={this.handleChangeGlobalIdeas}
            variant="contained"
          >
            Global Ideas
          </Button>
        </div>
        {/* <div>
          Global Ideas
          <Checkbox
            checked={globalIdeas}
            onChange={this.handleChangeGlobalIdeas}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div> */}

        <ActiveFilters
          activeFilters={activeFilters}
          showChallenges={challenges.length > 0}
          globalIdeas={globalIdeas}
        />
      </div>
    )
  }
}
ExploreFilterFields.defaultProps = {
  challenges: [],
  organizationsName: '',
  folderOrganizations: [],
  organizations: [],
  organizationsApplied: [],
  types: [],
  isOrganizationsActive: false,
  isTypesActive: false,
  // typesApplied: []
}

ExploreFilterFields.propTypes = {
  challenges: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      ideas: PropTypes.array.isRequired,
      mainPhoto: PropTypes.string.isRequired,
      numIdeas: PropTypes.string.isRequired,
      proposerName: PropTypes.string.isRequired,
      proposerPhoto: PropTypes.string.isRequired,
      sponsorName: PropTypes.string.isRequired,
      sponsorPhoto: PropTypes.string.isRequired,
    }),
  ),
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    flexRowSpaceBetween: PropTypes.string.isRequired,
  }).isRequired,
  getOrganization: PropTypes.func.isRequired,
  getTypes: PropTypes.func.isRequired,
  organizationsName: PropTypes.string,
  folderOrganizations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  organizationsApplied: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  isOrganizationsActive: PropTypes.bool,
  isTypesActive: PropTypes.bool,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
}

export default withStyles(styles)(ExploreFilterFields)

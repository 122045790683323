import React from 'react'
import * as PropTypes from 'prop-types'
import { Divider, Button, withStyles, CircularProgress } from '@material-ui/core'
import i18n from 'i18n-js'
import clsx from 'clsx'
import styles from './View.Style'

const StepNavigator = props => {
  const { classes, onBackClick, onNextClick, isLoading, canGoBack, nextLabel } = props
  return (
    <div className={classes.root}>
      <Divider />
      <div className={clsx(classes.buttonsContainer, !canGoBack && classes.buttonContainer)}>
        {canGoBack && (
          <Button color="primary" className={classes.previousButton} onClick={onBackClick}>
            <span className="icon-left" />
            {i18n.t('CHALLENGES.PREVIOUS_STEP')}
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={onNextClick} disabled={isLoading}>
          {nextLabel}
          {isLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />
          )}
        </Button>
      </div>
    </div>
  )
}
StepNavigator.defaultProps = {
  canGoBack: true,
  nextLabel: i18n.t('CHALLENGES.SAVE_AND_CONTINUE'),
  isLoading: false,
}
StepNavigator.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  canGoBack: PropTypes.bool,
  nextLabel: PropTypes.string,
  isLoading: PropTypes.bool,
}
export default withStyles(styles)(StepNavigator)

import * as PropTypes from 'prop-types'
import { Tooltip, withStyles } from '@material-ui/core'

const MainTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip)

MainTooltip.defaultProps = {
  placement: 'right',
}

MainTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  // rest of the props from materialUI Tooltip
}

export default MainTooltip

import { ServiceCall } from 'support/network/ServiceCallAction'
import * as types from 'actions/sync/evaluation/Constants'
import {
  transformEvaluationDraft,
  transformFilterIdeasServiceResponseDataWithState,
  transformFilterIdeasArchivedServiceResponseDataWithState,
  transformFilterIdeasPendingServiceResponseDataWithState,
  transformGetEvaluationIdeaByIdResponse,
  transformGetEvaluationArchivedIdeaByIdResponse,
  transformGetExpertGroupsResponse,
  transformGetUsersOnExpertGroupResponse,
  transformGetEvaluationExpertCommentFromMail,
} from './ResponseUtils'

import {
  setEndpointEvaluationIdea,
  setEvaluationBody,
  setEvaluationStatusBody,
  setSaveEvaluationBody,
  setExpertCommentStatusBody,
  getIdGroupUrl,
  getShareIdeaUrl,
} from './RequestUtils'

import { transformGetIdeaResponse } from '../create_idea/ResponseUtils'

const EVALUATION_IDEA_URL = '/api/v1/idea/evaluationidea'
const EVALUATION_ARCHIVED_IDEA_URL = '/api/v1/idea/archivedIdea'
const EVALUATION_DRAFT_IDEA_URL = '/api/v1/idea/evalutationidea/dashboard'
const EVALUATION_POST_IDEA_URL = '/s/idea/update/evaluation/IdeaEvaluation'
const EVALUATION_POST_IDEA_STATUS_URL = '/s/idea/evaluationIdea/status'
const GET_EVALUATION_IDEA_TITLE_URL = '/s/idea/'
const GET_EVALUATION_IDEA_BY_ID = 's/idea/adminevaluation/'
const GET_EVALUATION_ARCHIVED_IDEA_BY_ID = 'api/v1/idea/evalutationidea/archive/'
const RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID = '/s/idea/adminevaluation/restoreidea/'
const SAVE_EVALUATION_IDEA_BY_ID = 's/idea/adminevaluation/save/'
const SHARE_EVALUATION_IDEA_BY_ID = 's/idea/adminevaluation/sharefeedback/'

const EXPERT_COMMENT_URL = '/api/v1/expertpool/evaluation/idea/expertcomment'
export const ExpertPool = '/api/v1/expertpool'
export const ExpertExpertPool = 'api/v1/expertpool/expert'
export const GET_EXPERT_USERS = '/expert/idea/invite/users'
export const GET_EXPERT_USERS1 = 'api/v1/expertpool/expert/'
export const GET_EXPERT_USERS2 = '/idea/invite/users'
export const ExpertShareIdea = 'api/v1/expertpool/evaluationidea/inviteforidea'

export const GET_EXPERT_COMMENTS_MAIL = '/api/v1/expertpool/adminevaluation/expert/comments/'

export const getEvaluationIdea = (limit, offset, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_EVALUATION_IDEA_STARTED
  apiCall.successReqType = types.GET_EVALUATION_IDEA_SUCCESS
  apiCall.failureReqType = types.GET_EVALUATION_IDEA_FAILURE
  apiCall.setEndpointFromState = () =>
    setEndpointEvaluationIdea({
      baseUrl: EVALUATION_IDEA_URL,
      limit,
      offset,
    })
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformFilterIdeasServiceResponseDataWithState
  return apiCall.getAction()
}

export const getEvaluationArchivedIdea = (limit, offset, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_EVALUATION_IDEA_ARCHIVED_STARTED
  apiCall.successReqType = types.GET_EVALUATION_IDEA_ARCHIVED_SUCCESS
  apiCall.failureReqType = types.GET_EVALUATION_IDEA_ARCHIVED_FAILURE
  // //apiCall.setEndpointFromState = () => `${EVALUATION_ARCHIVED_IDEA_URL}`
  apiCall.setEndpointFromState = () =>
    setEndpointEvaluationIdea({
      baseUrl: EVALUATION_ARCHIVED_IDEA_URL,
      limit,
      offset,
    })
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformFilterIdeasArchivedServiceResponseDataWithState
  return apiCall.getAction()
}
export const getEvaluationPendingIdea = (limit, offset, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_EVALUATION_IDEA_PENDING_STARTED
  apiCall.successReqType = types.GET_EVALUATION_IDEA_PENDING_SUCCESS
  apiCall.failureReqType = types.GET_EVALUATION_IDEA_PENDING_FAILURE
  // apiCall.setEndpointFromState = () => `${EVALUATION_IDEA_URL}`
  apiCall.setEndpointFromState = () =>
    setEndpointEvaluationIdea({
      baseUrl: EVALUATION_IDEA_URL,
      limit,
      offset,
    })
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformFilterIdeasPendingServiceResponseDataWithState
  return apiCall.getAction()
}

export const getEvaluationIdeaTitle = (id, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_EVALUATION_IDEA_TITLE_STARTED
  apiCall.successReqType = types.GET_EVALUATION_IDEA_TITLE_SUCCESS
  apiCall.failureReqType = types.GET_EVALUATION_IDEA_TITLE_FAILURE
  apiCall.setEndpointFromState = () => `${GET_EVALUATION_IDEA_TITLE_URL}${id}`
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformGetIdeaResponse
  return apiCall.getAction()
}

export const getEvaluationIdeaById = id => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_EVALUATION_IDEA_BY_ID_STARTED
  apiCall.successReqType = types.GET_EVALUATION_IDEA_BY_ID_SUCCESS
  apiCall.failureReqType = types.GET_EVALUATION_IDEA_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => `${GET_EVALUATION_IDEA_BY_ID}${id}`
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformGetEvaluationIdeaByIdResponse
  return apiCall.getAction()
}
export const getEvaluationArchivedIdeaById = (id, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_EVALUATION_ARCHIVED_IDEA_BY_ID_STARTED
  apiCall.successReqType = types.GET_EVALUATION_ARCHIVED_IDEA_BY_ID_SUCCESS
  apiCall.failureReqType = types.GET_EVALUATION_ARCHIVED_IDEA_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => `${GET_EVALUATION_ARCHIVED_IDEA_BY_ID}${id}`
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformGetEvaluationArchivedIdeaByIdResponse
  return apiCall.getAction()
}
export const restoreEvaluationArchivedIdeaById = (id, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID_STARTED
  apiCall.successReqType = types.RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID_SUCCESS
  apiCall.failureReqType = types.RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => `${RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID}${id}`
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformGetEvaluationArchivedIdeaByIdResponse
  return apiCall.getAction()
}

export const saveEvaluationIdeaById = (
  id,
  boardReviewDate,
  ideaPitchDate,
  boardStatus,
  pitchStatus,
  feedbackForIdeaOwner,
  boardComments,
  onSuccess,
  onFailure,
) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.SAVE_EVALUATION_BY_ID_STARTED
  apiCall.successReqType = types.SAVE_EVALUATION_BY_ID_SUCCESS
  apiCall.failureReqType = types.SAVE_EVALUATION_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => `${SAVE_EVALUATION_IDEA_BY_ID}${id}`
  apiCall.setBodyFromState = () =>
    setSaveEvaluationBody(
      boardReviewDate,
      ideaPitchDate,
      boardStatus,
      pitchStatus,
      feedbackForIdeaOwner,
      boardComments,
    )
  apiCall.method = 'PUT'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  // // //apiCall.transformResponseDataWithState = transformEvaluationIdeaPost
  return apiCall.getAction()
}
export const shareEvaluationIdeaById = (id, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.SHARE_EVALUATION_BY_ID_STARTED
  apiCall.successReqType = types.SHARE_EVALUATION_BY_ID_SUCCESS
  apiCall.failureReqType = types.SHARE_EVALUATION_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => `${SHARE_EVALUATION_IDEA_BY_ID}${id}`
  // //apiCall.setBodyFromState = () => setSaveEvaluationBody(boardStatus, pitchStatus, feedbackForIdeaOwner)
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  // //apiCall.transformResponseDataWithState = transformEvaluationIdeaPost
  return apiCall.getAction()
}

export const draftEvaluationIdea = (title, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_EVALUATION_DRAFT_STARTED
  apiCall.successReqType = types.GET_EVALUATION_DRAFT_SUCCESS
  apiCall.failureReqType = types.GET_EVALUATION_DRAFT_FAILURE
  apiCall.setEndpointFromState = () => `${EVALUATION_DRAFT_IDEA_URL}/${title}`
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformEvaluationDraft
  return apiCall.getAction()
}

export const postEvaluationIdea = (title, q1c, q1m, q2c, q2m, q3c, q3m, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_EVALUATION_DRAFT_STARTED
  apiCall.successReqType = types.POST_EVALUATION_DRAFT_SUCCESS
  apiCall.failureReqType = types.POST_EVALUATION_DRAFT_FAILURE
  apiCall.setEndpointFromState = () => `${EVALUATION_POST_IDEA_URL}`
  apiCall.setBodyFromState = () => setEvaluationBody(title, q1c, q1m, q2c, q2m, q3c, q3m)
  apiCall.method = 'PUT'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  // //apiCall.transformResponseDataWithState = transformEvaluationIdeaPost
  return apiCall.getAction()
}

export const postEvaluationIdeaStatus = (title, status, email, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_EVALUATION_STATUS_STARTED
  apiCall.successReqType = types.POST_EVALUATION_STATUS_SUCCESS
  apiCall.failureReqType = types.POST_EVALUATION_STATUS_FAILURE
  apiCall.setEndpointFromState = () => `${EVALUATION_POST_IDEA_STATUS_URL}`
  apiCall.setBodyFromState = () => setEvaluationStatusBody(title, status, email)
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  // //apiCall.transformResponseDataWithState = transformEvaluationIdeaPost
  return apiCall.getAction()
}
export const postExpertComments = (id, rating, comment, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.POST_EXPERT_COMMENT_STARTED
  apiCall.successReqType = types.POST_EXPERT_COMMENT_SUCCESS
  apiCall.failureReqType = types.POST_EXPERT_COMMENT_FAILURE
  apiCall.setEndpointFromState = () => `${EXPERT_COMMENT_URL}`
  apiCall.setBodyFromState = () => setExpertCommentStatusBody(id, rating, comment)
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export const getExpertPool = () => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_EXPERT_GROUP_STARTED
  apiCall.successReqType = types.GET_EXPERT_GROUP_SUCCESS
  apiCall.failureReqType = types.GET_EXPERT_GROUP_FAILURE
  apiCall.setEndpointFromState = () => `${ExpertPool}`
  apiCall.transformResponseDataWithState = transformGetExpertGroupsResponse
  apiCall.method = 'GET'
  apiCall.auth = true
  return apiCall.getAction()
}

export function getUsersOnExpertService(groupId, ideaId, offset, limit) {
  // console.log(`${offset} ${limit}`)
  const serviceCall = new ServiceCall()
  serviceCall.startedReqType = types.GET_USERS_EXPERT_GROUP_STARTED
  serviceCall.successReqType = types.GET_USERS_EXPERT_GROUP_SUCCESS
  serviceCall.failureReqType = types.GET_USERS_EXPERT_GROUP_FAILURE
  serviceCall.auth = true
  // serviceCall.setEndpointFromState = () => `${GET_EXPERT_USERS}?offset=${offset}&limit=${limit}`
  serviceCall.setEndpointFromState = () => `${GET_EXPERT_USERS1}${groupId}${GET_EXPERT_USERS2}`
  serviceCall.setBodyFromState = () => getIdGroupUrl(groupId, ideaId)
  serviceCall.transformResponseDataWithState = transformGetUsersOnExpertGroupResponse
  serviceCall.method = 'POST'
  return serviceCall.getAction()
}

// export function postUsersOnExpertService(groupId, ideaId, offset, limit) {
//   const serviceCall = new ServiceCall()
//   serviceCall.startedReqType = types.GET_USERS_EXPERT_GROUP_STARTED
//   serviceCall.successReqType = types.GET_USERS_EXPERT_GROUP_SUCCESS
//   serviceCall.failureReqType = types.GET_USERS_EXPERT_GROUP_FAILURE
//   serviceCall.auth = true
//   //serviceCall.setEndpointFromState = () => `${GET_EXPERT_USERS}?offset=${offset}&limit=${limit}`
//   serviceCall.setEndpointFromState = () => `${GET_EXPERT_USERS}`
//   serviceCall.setBodyFromState = () => getIdGroupUrl(groupId, ideaId)
//   serviceCall.transformResponseDataWithState = transformGetUsersOnExpertGroupResponse
//   serviceCall.method = 'POST'
//   return serviceCall.getAction()
// }
export function postShareIdeaExpertService(
  id,
  groupUserData,
  emailMessage,
  manualEmail,
  onSuccess,
  onFailure,
) {
  const serviceCall = new ServiceCall()
  serviceCall.startedReqType = types.POST_SHARE_IDEA_STARTED
  serviceCall.successReqType = types.POST_SHARE_IDEA_SUCCESS
  serviceCall.failureReqType = types.POST_SHARE_IDEA_FAILURE
  serviceCall.auth = true
  serviceCall.onSuccess = onSuccess
  serviceCall.onFailure = onFailure
  serviceCall.setEndpointFromState = () => `${ExpertShareIdea}`
  serviceCall.setBodyFromState = () => getShareIdeaUrl(id, groupUserData, emailMessage, manualEmail)
  serviceCall.transformResponseDataWithState = transformGetUsersOnExpertGroupResponse
  serviceCall.method = 'POST'
  return serviceCall.getAction()
}

export const getExpertCommentsMail = (id, onSuccess, onFailure) => {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_EVALUATION_IDEA_EXPERT_COMMENTS_MAIL_STARTED
  apiCall.successReqType = types.GET_EVALUATION_IDEA_EXPERT_COMMENTS_MAIL_SUCCESS
  apiCall.failureReqType = types.GET_EVALUATION_IDEA_EXPERT_COMMENTS_MAIL_FAILURE
  apiCall.setEndpointFromState = () => `${GET_EXPERT_COMMENTS_MAIL}${id}`
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformGetEvaluationExpertCommentFromMail
  return apiCall.getAction()
}

import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Button, CircularProgress } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'
import EventRow from './components/event_row/View'

const TimelineSection = props => {
  const {
    classes,
    timeline,
    navigateToIdea,
    onLoadClick,
    badges,
    isTimelineComplete,
    isTimelineLoading,
  } = props
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <span className="icon-time" />
        <span className={classes.titleText}>{i18n.t('PROFILE.TIMELINE')}</span>
      </div>
      {timeline.map((event, index) => (
        <EventRow
          key={event.id}
          position={index % 2 ? 'right' : 'left'}
          event={event}
          navigateToIdea={navigateToIdea}
          badges={badges}
        />
      ))}

      <div className={classes.buttonWrapper}>
        <span className={classes.bar} />
        <Button
          disabled={isTimelineComplete || isTimelineLoading}
          variant="contained"
          color="primary"
          onClick={onLoadClick}
          size="large"
        >
          {isTimelineComplete ? i18n.t('TIMELINE.NO_MORE_EVENTS') : i18n.t('TIMELINE.LOAD_MORE')}
          {isTimelineLoading && <CircularProgress size={25} className={classes.loading} />}
        </Button>
      </div>
    </div>
  )
}

TimelineSection.propTypes = {
  timeline: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  onLoadClick: PropTypes.func.isRequired,
  badges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isTimelineComplete: PropTypes.bool.isRequired,
  isTimelineLoading: PropTypes.bool.isRequired,
}

export default withStyles(styles)(TimelineSection)

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Paper, Step, StepLabel, Stepper, Button } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'

class ChallengeHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInfoMessage: true,
    }
    this.firstStep = i18n.t('CHALLENGES.STEP_DESCRIPTION')
    this.secondStep = i18n.t('CHALLENGES.STEP_PEOPLE')
    this.thirdStep = i18n.t('CHALLENGES.STEP_GAMING')
    this.fourthStep = i18n.t('CHALLENGES.STEP_REWARD')
    this.fifthStep = i18n.t('CHALLENGES.STEP_PREVIEW')

    this.steps = [this.firstStep, this.secondStep, this.thirdStep, this.fourthStep, this.fifthStep]
    this.handleShowInfoMessageChange = this.handleShowInfoMessageChange.bind(this)
  }

  handleShowInfoMessageChange() {
    const { showInfoMessage } = this.state
    this.setState({
      showInfoMessage: !showInfoMessage,
    })
  }

  render() {
    const { activeStep, classes, bannerContent, title, showStepper } = this.props
    const { showInfoMessage } = this.state

    return (
      <div className={classes.root}>
        <h1 className={classes.title}>{title}</h1>
        {showStepper && (
          <Stepper alternativeLabel activeStep={activeStep} className={classes.stepper}>
            {this.steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        {showInfoMessage && activeStep === 0 && (
          <Paper id="info-message-box" className={classes.infoMessage}>
            <div className={classes.infoMessageContainer}>{bannerContent}</div>
            <Button
              disableFocusRipple
              disableTouchRipple
              disableRipple
              className={classes.closeButton}
              component="span"
              onClick={this.handleShowInfoMessageChange}
            >
              <span className="icon-close" />
            </Button>
          </Paper>
        )}
      </div>
    )
  }
}

ChallengeHeader.defaultProps = {}
ChallengeHeader.propTypes = {
  showStepper: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  activeStep: PropTypes.number.isRequired,
  // it's being used in the styles file
  // eslint-disable-next-line react/no-unused-prop-types
  bannerColor: PropTypes.string.isRequired,
  bannerContent: PropTypes.node.isRequired,
}
export default withStyles(styles)(ChallengeHeader)

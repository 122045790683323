const styles = theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    '& > div': {
      height: theme.spacing(67),
      width: theme.spacing(38.75),
      margin: 4,
    },
  },
  top: {
    gridArea: 'top',
    gridColumn: 2,
    gridRow: 2,
    textAlign: 'center',
  },
  middle: {
    gridArea: 'middle',
    gridColumn: 2,
    gridRow: 2,
    marginBottom: '3rem',
  },
  submitIdea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed #999',
    boxShadow: 'none',
    width: '314px',
    height: '536px',
    backgroundColor: '#e8e8e8',
    '& > button': {
      width: '8rem',
      height: '8rem',
      borderRadius: '4rem',
      background: 'linear-gradient(to bottom, #eeeeee 0%, #cccccc 100%)',
      '& > span': {
        color: theme.palette.common.black,
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        '& > i': {
          fontSize: '40px',
          marginBottom: '10px',
        },
        '& > span': {
          fontSize: '16px',
          lineHeight: '20px',
          textTransform: 'initial',
        },
      },
    },
  },
})

export default styles

import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import defaultPrivateBanner from 'img/ideasEmpty.jpg'
import { Image, ButtonLink } from 'components'
import styles from './View.Style'

const PrivateIdeasBanner = props => {
  const { classes, imageSrc, onBannerClick } = props
  return (
    <div className={classes.root}>
      <ButtonLink onLinkClick={onBannerClick}>
        <Image defaultSrc={defaultPrivateBanner} src={imageSrc} alt="create-idea" />
      </ButtonLink>
    </div>
  )
}

PrivateIdeasBanner.defaultProps = {
  onBannerClick: () => {},
}

PrivateIdeasBanner.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  onBannerClick: PropTypes.func,
}

export default withStyles(styles)(PrivateIdeasBanner)

const styles = () => ({
  cardGroup: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingBottom: '1rem',
    '& > div': {
      marginRight: '2%',
    },
    '& > div:last-child': {
      marginRight: 0,
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '1rem 0',
    justifyContent: 'space-between',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  formControl: {
    width: '10rem',
    marginLeft: '1rem',
    '& > div > div:first-child': {
      padding: '9px 14px',
      textAlign: 'start',
    },
  },
  listItem: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  middle: {
    gridArea: 'middle',
    gridColumn: 2,
    gridRow: 2,
    marginBottom: '3rem',
  },
  userCard: {
    width: '100%',
  },
  top: {
    gridArea: 'top',
    gridColumn: 2,
    gridRow: 2,
    textAlign: 'center',
  },
})

export default styles

import createStyles from '@material-ui/core/styles/createStyles'

const styles = theme =>
  createStyles({
    root: {
      gridArea: 'header',
      display: 'grid',
      gridTemplateColumns: 'auto 960px auto',
      gridTemplateRows: '1fr',
      gridTemplateAreas: '". nav ."',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    nav: {
      gridArea: 'nav',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    button: {
      margin: theme.spacing(1),
      color: 'white',
      borderColor: 'white',
      fontWeight: 'bold',
      marginTop: 0,
    },
    submit: {
      gridArea: 'nav',
      display:  'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '5px'
    }

  },)

export default styles

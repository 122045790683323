import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/Button/Button'
import i18n from 'support/i18n'
import styles from './View.Style'
import SimpleDivider from '../simple_divider/View'

const SimpleMenu = props => {
  const {
    classes,
    showAdmin,
    isInnovator,
    localAdmin,
    localInnovator,
    evaluator,
    localEvaluator,
    navigateToAdmin,
    navigateToDashboard,
    navigateToPendingValidation,
    navigateToPrivateIdeas,
    navigateToMyProfile,
    navigateToRanking,
    navigateToSettings,
    navigateToLogout,
    isPrivateFlowActive,
    navigateToInvestments,
    navigateToEvaluationBoard,
  } = props
  return (
    <ul className={classes.container}>
      {(showAdmin ||
        isInnovator ||
        localAdmin ||
        localInnovator ||
        evaluator ||
        localEvaluator) && (
        <Fragment>
          <SimpleDivider text="Admin" />
          <li>
            <ButtonBase component="a" onClick={navigateToAdmin}>
              <span className="icon-owner" />
              {showAdmin && <b>{i18n.t('SCREEN.ADMIN')}</b>}
              {isInnovator && <b>{i18n.t('SCREEN.INNOVATOR')}</b>}
              {localAdmin && <b>{i18n.t('SCREEN.LOCAL_ADMIN')}</b>}
              {localInnovator && <b>{i18n.t('SCREEN.LOCAL_INNOVATOR')}</b>}
              {evaluator && <b>{i18n.t('SCREEN.EVALUATOR')}</b>}
              {localEvaluator && <b>{i18n.t('SCREEN.LOCAL_EVALUATOR')}</b>}
            </ButtonBase>
          </li>
          <li>
            <ButtonBase component="a" onClick={navigateToDashboard}>
              <span className="icon-portfolio" />
              <b>{i18n.t('SCREEN.DASHBOARD')}</b>
            </ButtonBase>
          </li>
          {(showAdmin || isInnovator || localAdmin || localInnovator) && (
            <li>
              <ButtonBase component="a" onClick={navigateToPendingValidation}>
                <span className="icon-rocket" />
                <b>{i18n.t('SCREEN.PENDING_VALIDATION')}</b>
              </ButtonBase>
            </li>
          )}
          {(showAdmin || localAdmin || evaluator || localEvaluator) && (
            <li>
              <ButtonBase component="a" onClick={navigateToEvaluationBoard}>
                <span className="icon-rocket" />
                <b>{i18n.t('SCREEN.EVALUATION_BOARD')}</b>
              </ButtonBase>
            </li>
          )}
          <li>
            <ButtonBase component="a" onClick={navigateToInvestments}>
              <b className="icon-evolution" />
              <b>{i18n.t('SCREEN.INVESTMENTS')}</b>
            </ButtonBase>
          </li>
          {isPrivateFlowActive && (
            <li>
              <ButtonBase component="a" onClick={navigateToPrivateIdeas}>
                <span className="icon-lock" />
                <b>{i18n.t('SCREEN.PRIVATE_IDEAS')}</b>
              </ButtonBase>
            </li>
          )}

          <SimpleDivider text="User" />
        </Fragment>
      )}
      <li>
        <ButtonBase component="a" onClick={navigateToMyProfile}>
          <span className="icon-vcard" />
          <b>{i18n.t('SCREEN.PROFILE')}</b>
        </ButtonBase>
      </li>
      <li>
        <ButtonBase component="a" onClick={navigateToRanking}>
          <span className="icon-ranking" />
          <b>{i18n.t('SCREEN.RANKING')}</b>
        </ButtonBase>
      </li>
      <li>
        <ButtonBase component="a" onClick={navigateToSettings}>
          <span className="icon-settings" />
          <b>{i18n.t('SCREEN.SETTINGS')}</b>
        </ButtonBase>
      </li>
      <li>
        <ButtonBase component="a" onClick={navigateToLogout}>
          <span className="icon-logout" />
          <b>{i18n.t('SCREEN.LOGOUT')}</b>
        </ButtonBase>
      </li>
    </ul>
  )
}

SimpleMenu.propTypes = {
  showAdmin: PropTypes.bool.isRequired,
  isPrivateFlowActive: PropTypes.bool.isRequired,
  navigateToAdmin: PropTypes.func.isRequired,
  navigateToDashboard: PropTypes.func.isRequired,
  navigateToPendingValidation: PropTypes.func.isRequired,
  navigateToPrivateIdeas: PropTypes.func.isRequired,
  navigateToMyProfile: PropTypes.func.isRequired,
  navigateToRanking: PropTypes.func.isRequired,
  navigateToSettings: PropTypes.func.isRequired,
  navigateToLogout: PropTypes.func.isRequired,
}

export default withStyles(styles)(SimpleMenu)

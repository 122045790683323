import React from 'react'
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
// import logoBlack from '../../img/nextinit-logo.png'
import logoBlack from '../../img/nova-logo.png'
// import clientLogos from '../../img/clientes-logos.png'

const SideLogin = props => {
  const { classes } = props

  return (
    <div className={classes.side}>
      <Link to="/">
        <img className={classes.header} src={logoBlack} alt="Logo" />
      </Link>

      <div className="content">
        <h2>
          {/* <small>Nextinit</small> */}
          Your gamified and collaborative innovation platform
        </h2>
        {/* <p>Transform your Business, your people know how!</p>
        <a
          href="https://www.nextinit.com"
          target="_blank"
          rel="noopener noreferrer"
          className="enterprise-panel__button"
        >
          Learn More
        </a> */}
      </div>
      <div className="footer">
        <p>TRUSTED BY THE WORLD&apos;S MOST INNOVATIVE COMPANIES</p>
        {/* <img
          src={clientLogos}
          alt="Trusted by the world's most innovative companies like BMW, BBVA, Vodafone, Cartier..."
        /> */}
      </div>
    </div>
  )
}

SideLogin.propTypes = {
  classes: PropTypes.shape({
    side: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(SideLogin)

import React from 'react'
import { withStyles } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'
import * as PropTypes from 'prop-types'
import styles, { customSliderStyles } from './View.Styles'

const CustomSlider = withStyles(customSliderStyles)(Slider)

const NxtSlider = props => {
  const {
    valueLabelDisplay,
    classes,
    min,
    max,
    valueLabelFormat,
    onChangeCommitted,
    marks,
    step,
    minLabel,
    maxLabel,
    value,
  } = props
  return (
    <div className={classes.root}>
      <span>{minLabel}%</span>
      <CustomSlider
        value={value}
        valueLabelDisplay={valueLabelDisplay}
        min={min}
        max={max}
        valueLabelFormat={valueLabelFormat}
        onChange={onChangeCommitted}
        marks={marks}
        step={step}
      />
      <span>{maxLabel}%</span>
    </div>
  )
}

NxtSlider.defaultProps = {
  valueLabelDisplay: 'off',
  min: 0,
  max: 100,
  minLabel: '0',
  maxLabel: '100',
  valueLabelFormat: value => value,
  marks: [],
  step: 1,
}
NxtSlider.propTypes = {
  onChangeCommitted: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  valueLabelDisplay: PropTypes.oneOf(['on', 'off', 'auto']),
  min: PropTypes.number,
  max: PropTypes.number,
  valueLabelFormat: PropTypes.func,
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ),
  step: PropTypes.number,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
}
export default withStyles(styles)(NxtSlider)

import React from 'react'

import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Prototype from './img/prototype_diagram.png'
import NOVAInnovatonChallenge from '../docs/eRIS_Team_Innovation_Challenge.pdf'
import styles from './View.Style'

const Faq = props => {
  const { classes } = props

  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      <h3 className={classes.name}>FAQ</h3>

      <p className={classes.name}>
        Can't find the answer you are lookingg for? We've shared some of our most frequently asked
        questions to help you out!
      </p>

      <p className={classes.topic}>General Questions</p>

      <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>How do I sign up to NOVA?</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Signing up is very simple, just head over to NOVA and send an email requesting access.
            Once you are given access you are good to go and can start submitting ideas and
            investing. However, if your function has not been invited yet to take part in NOVA you
            will have to talk to your manager in order to get approval. NOVA has been expanding
            organically and new functions are invited to join regularly, you can accelerate this
            process by contacting us and notifying your manager.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Can I access NOVA from my phone?</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            NOVA is accessible from any browser as well as from you mobile device. There is an iOS
            and Android app available to download from the vStore, allowing you to keep up with your
            ideas even when you are away from your computer.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            How soon can I expect to do my pitch after my idea has been funded?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography component='span'>
            <span>This depends on a couple of factors:</span>
            <div><ol>
              <li>
                After your idea gets funded it still needs to be approved by the NOVA Board. This
                usually takes a couple of weeks.
              </li>
              <li>
                After approval you will get an email with next steps and resources to prepare for
                the pitch. The pitches take place every month and you will need to submit your
                presentation roughly a week prior to the date.
              </li>
            </ol>
            </div>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            I have an idea for a challenge, can I create one myself?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Currently challenges can only be created by NOVA Admins, if you have a challenge in mind
            for a particular area or Vodafone in general get in contact with us at{' '}
            <a href="mailto:nova@vodafone.com">nova@vodafone.com</a>. We then can
            support in shaping the challenge and publishing it to the right audience.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            {' '}
            I still have a question, who do I contact for more information?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            If you still have a question try posting on our 
            <a href="https://www.yammer.com/vodafone.com/#/threads/inGroup?type=in_group&feedId=8633561&view=all">
              Yammer
            </a>
             or sending us an email at{' '}
            <a href="mailto:nova@vodafone.com">nova@vodafone.com</a>.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      {/* <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>General Questions</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <p className={classes.question}>How do I sign up to NOVA?</p>
            Signing up is very simple, just head over to NOVA and send an email requesting access.
            Once you are given access you are good to go and can start submitting ideas and
            investing. However, if your function has not been invited yet to take part in NOVA you
            will have to talk to your manager in order to get approval. NOVA has been expanding
            organically and new functions are invited to join regularly, you can accelerate this
            process by contacting us and notifying your manager .
            <Divider variant="full-width" />
            <p className={classes.question}>Can I access NOVA from my phone?</p>
            NOVA is accessible from any browser as well as from you mobile device. There is an iOS
            and Android app available to download from the vStore, allowing you to keep up with your
            ideas even when you are away from your computer.
            <p className={classes.question}>
              How soon can I expect to do my pitch after my idea has been funded?
            </p>
            <p>This depends on a couple of factors:</p>
            <ol>
              <li>
                After your idea gets funded it still needs to be approved by the NOVA Board. This
                usually takes a couple of weeks.
              </li>
              <li>
                After approval you will get an email with next steps and resources to prepare for
                the pitch. The pitches take place every month and you will need to submit your
                presentation roughly a week prior to the date.
              </li>
            </ol>
            <p className={classes.question}>
              I have an idea for a challenge, can I create one myself?
            </p>
            Currently challenges can only be created by NOVA Admins, if you have a challenge in mind
            for a particular area or Vodafone in general get in contact with us at{' '}
            <a href="mailto:nova@vodafone.com">nova@vodafone.com</a>. We then can
            support in shaping the challenge and publishing it to the right audience.
            <p className={classes.question}>
              I still have a question, who do I contact for more information?
            </p>
            If you still have a question try posting on our 
            <a href="https://www.yammer.com/vodafone.com/#/threads/inGroup?type=in_group&feedId=8633561&view=all">
              Yammer
            </a>
             or sending us an email at{' '}
            <a href="mailto:nova@vodafone.com">nova@vodafone.com</a>.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel> */}

      <p className={classes.topic}>Coming up with an idea</p>

      <ExpansionPanel expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Coming up with an idea</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            This is covered in detail on the How can I come up with an Idea? page
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            What sort of ideas can I submit to NOVA?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography component='span'>
            NOVA is open to any kind of idea as long as it is able to create business value for the
            company. From there the sky is the limit! We also have some criteria where we would
            normally check before we validate your idea on NOVA, please try to make sure your idea
            suits the below criteria. We are not expecting your idea to be in a mature stage at the
            time of-submission, but you would need to highlight how are you planning to address the
            below points at least to highlight the value of your idea:
            <ol>
              <li>Is the idea detailed enough?</li>
              The idea should have more than a few lines describing what it is, preferably with
              collateral further explaining the concept e.g. ppts or pdfs attached.{' '}
              <li>
                Does the idea have a clear Business Value? <br></br>a. Is there a clear problem
                statement that the idea is addressing? <br></br>b. Does implementing the idea
                address the problem statement? <br></br>c. Is the value brought to the company worth
                the investment and effort needed to implement?
              </li>
              <li>
                Implementation method clear? <br></br>a. What tools will be used to implement the
                idea? <br></br>b. How will the idea be prototyped, use cases provided? <br></br>c.
                Are any resources required mentioned in the description? E.g. hardware, data
                sources, expertise, physical location, lab etc.{' '}
              </li>
              <li>
                Is it Innovative? Generally not suitable: <br></br>a. HR complaint. <br></br>b.
                Workplace complaint or request. <br></br>c. No innovation? <br></br>d. False
                information. <br></br>e. Not work related or inappropriate Generally suitable:{' '}
                <ol>
                  <li>Process improvement</li>
                  <li>New product </li>
                  <li>Simplification </li>
                  <li>Re-purpose existing technology</li>
                </ol>
              </li>
            </ol>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>How can NOVA help me?</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography component='span'>
            <ol>
              <li>Design Thinking Sessions</li> We help organise design thinking workshops, and will
              be able to support you on framing your problem statement which you need to solve. Feel
              free to get in touch with us and we will be more than happy to arrange a design
              thinking session, and run the session helping you and your team to generate ideas.
              This can be a day workshop or may be more or less depending on the context of the
              problem and what is the goal you would like to achieve. <li>Challenges</li> We can
              also launch a challenge with your problem statement on NOVA platform for all of our
              users. This will be launched as a challenge on our platform, where we invite all of
              our users to try to solve your problem. This will also be a great way of getting
              inspiration on ideas from different parts of the business. In case, you would like to
              launch it only for your team we can also do a closed challenge specifically for your
              team. Feel free to contact us and we are happy to set it up for you.{' '}
              <li>NOVA Drop-in sessions</li> Additionally, if you have an idea and you are not sure
              whether it is ready to be submitted or not. Feel free to drop-in to our NOVA Drop-in
              sessions to get feedback from our Innovation coaches. You can follow us on Workplace
              for more updates on the next session.
            </ol>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>What is a Challenge?</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            If you have a problem statement you would like to solve and generate ideas towards, a
            challenge can be created. We can launch a challenge with your problem statement on NOVA
            platform for all of our users. This will be launched as a challenge on our platform,
            where we invite all of our users to try to solve your problem. This will also be a great
            way of getting inspiration on ideas from different parts of the business. In case, you
            would like to launch it only for your team we can also do a closed challenge
            specifically for your team. Feel free to contact us and we are happy to set it up for
            you. You can find a powerpoint that explains in detail what a challenge is 
            {/* <a href="https://workspace2.vodafone.com/Group/NOVA/SitePages/Assets/docs/NOVA_Team_Innovation_Challenge.pptx"> */}
            <a href={NOVAInnovatonChallenge} target="_blank" rel="noopener noreferrer">
              here
            </a>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      {/* <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Coming up with an idea</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <p className={classes.question}>Where can I get inspiration from?</p>
            This is covered in detail on the How can I come up with an Idea? page
            <p className={classes.question}>What sort of ideas can I submit to NOVA?</p>
            NOVA is open to any kind of idea as long as it is able to create business value for the
            company. From there the sky is the limit! We also have some criteria where we would
            normally check before we validate your idea on NOVA, please try to make sure your idea
            suits the below criteria. We are not expecting your idea to be in a mature stage at the
            time of-submission, but you would need to highlight how are you planning to address the
            below points at least to highlight the value of your idea:
            <ol>
              <li>Is the idea detailed enough?</li>
              The idea should have more than a few lines describing what it is, preferably with
              collateral further explaining the concept e.g. ppts or pdfs attached.{' '}
              <li>
                Does the idea have a clear Business Value? <br></br>a. Is there a clear problem
                statement that the idea is addressing? <br></br>b. Does implementing the idea
                address the problem statement? <br></br>c. Is the value brought to the company worth
                the investment and effort needed to implement?
              </li>
              <li>
                Implementation method clear? <br></br>a. What tools will be used to implement the
                idea? <br></br>b. How will the idea be prototyped, use cases provided? <br></br>c.
                Are any resources required mentioned in the description? E.g. hardware, data
                sources, expertise, physical location, lab etc.{' '}
              </li>
              <li>
                Is it Innovative? Generally not suitable: <br></br>a. HR complaint. <br></br>b.
                Workplace complaint or request. <br></br>c. No innovation? <br></br>d. False
                information. <br></br>e. Not work related or inappropriate Generally suitable:{' '}
                <ol>
                  <li>Process improvement</li>
                  <li>New product </li>
                  <li>Simplification </li>
                  <li>Re-purpose existing technology</li>
                </ol>
              </li>
            </ol>
            <p className={classes.question}>How can NOVA help me?</p>
            <ol>
              <li>Design Thinking Sessions</li> We help organise design thinking workshops, and will
              be able to support you on framing your problem statement which you need to solve. Feel
              free to get in touch with us and we will be more than happy to arrange a design
              thinking session, and run the session helping you and your team to generate ideas.
              This can be a day workshop or may be more or less depending on the context of the
              problem and what is the goal you would like to achieve. <li>Challenges</li> We can
              also launch a challenge with your problem statement on NOVA platform for all of our
              users. This will be launched as a challenge on our platform, where we invite all of
              our users to try to solve your problem. This will also be a great way of getting
              inspiration on ideas from different parts of the business. In case, you would like to
              launch it only for your team we can also do a closed challenge specifically for your
              team. Feel free to contact us and we are happy to set it up for you.{' '}
              <li>NOVA Drop-in sessions</li> Additionally, if you have an idea and you are not sure
              whether it is ready to be submitted or not. Feel free to drop-in to our NOVA Drop-in
              sessions to get feedback from our Innovation coaches. You can follow us on Workplace
              for more updates on the next session.
            </ol>
            <p className={classes.question}>What is a Challenge?</p>
            If you have a problem statement you would like to solve and generate ideas towards, a
            challenge can be created. We can launch a challenge with your problem statement on NOVA
            platform for all of our users. This will be launched as a challenge on our platform,
            where we invite all of our users to try to solve your problem. This will also be a great
            way of getting inspiration on ideas from different parts of the business. In case, you
            would like to launch it only for your team we can also do a closed challenge
            specifically for your team. Feel free to contact us and we are happy to set it up for
            you. You can find a powerpoint that explains in detail what a challenge is 
            <a href="https://workspace2.vodafone.com/Group/NOVA/SitePages/Assets/docs/NOVA_Team_Innovation_Challenge.pptx">
              here
            </a>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel> */}

      <p className={classes.topic}>Submitting an idea</p>

      <ExpansionPanel expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            How do I successfully achieve the funding goal during the crowd-sourcing stage on NOVA?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography component='span'>
            <ol>
              <li>
                Share the story of your idea with your colleges before submitting it and get
                feedback about it before submitting it. You can also join NOVA Drop-in sessions to
                get feedback on your idea from our innovation coaches before you submit it.
              </li>
              <li>
                Promote your idea with people who liked your idea. Ask them to join NOVA and invest
                using NOVA virtual coins in your idea. You can also share your idea on internal
                social media such as workplace. It will help for others to invest in your idea.
              </li>
            </ol>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            How does NOVA team categorize the submitted ideas?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            NOVA accepts both enhancement as well as disruptive ideas. Enhancement ideas can request
            a funding up to €2 k and disruptive can request up to € 15 k.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            What is the criteria of a disruptive idea?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography component='span'>
            In NOVA we define a disruptive ideas based on the below criteria. The Idea can be an
            Internal Innovation or External Innovation.{' '}
            <ol>
              {' '}
              <li>Is it a new product or service Vodafone didn’t offer before?</li>{' '}
              <li>Is there a new business model behind the idea?</li>{' '}
              <li>Is it targeting a new market segment?</li>
              <li>Is it using a new technology?</li>
              <li>Is it replacing an old product/service/process?</li>
            </ol>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            What's the funding target for my idea?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            The funding target may vary depending on the moment that you submit your idea. The exact
            numbers changes depending on some parameters behind the scenes of NOVA; the target for
            your idea will be displayed on its page.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            Why do ideas have different funding targets?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            The funding target may vary depending on the moment that you submit your idea. The exact
            numbers changes depending on some parameters behind the scenes of NOVA; these are there
            in order to maintain a fair balance as the number of users grow and the amount of money
            in the system changes.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            What happens when my idea gets funded?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            After your idea gets funded it still needs to be approved by the NOVA Board. This
            usually takes a couple of weeks. After approval you will get an email with next steps
            and resources to prepare for the pitch. The pitches take place every month and you will
            need to submit your presentation roughly a week prior to the date.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            How do I know whether an idea has been submitted before?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            There is no formal process in place at the moment, the recommended method is by
            searching past ideas using the search function.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            What do the different investment rounds mean?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            The rounds are a means of encouraging members to invest in ideas early. The earlier you
            put your money into the idea the higher your returns. Seed rounds (the idea owners) get
            the highest returns, followed by 1st round, then 2nd and finally 3rd.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            I have a problem, how do I know how to solve it?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Generally, we recommend setting-up cross-functional teams workshops to brainstorm ideas
            on how to resolve the issues. Working as a team helps in building on each other’s’ ideas
            as well as getting inspiration from different inputs or perspectives. However, if you
            have a problem that you would like to solve yourself you can discuss your idea with
            different relevant stakeholders to get feedback so that it can help you develop the idea
            further too. Additionally, if you have an idea and you are not sure whether it is ready
            to be submitted or not feel free to drop-in to our NOVA Drop-in sessions to get feedback
            from our Innovation coaches. You can follow us on Workplace for more updates on the next
            session.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            What happens if my prototype is very similar to another product already on the Vodafone
            roadmap?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography></Typography>
        </ExpansionPanelDetails>
        <Card className={classes.root_card}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography variant="body2" gutterBottom>
                After being approved by the Olympians, an idea going into the prototype stage needs
                to be assessed on whether there are similar projects on the Vodafone roadmap. The
                diagram illustrates the decision making process as well as various paths available.
              </Typography>
            </CardContent>
          </div>
          <CardMedia className={classes.cover} image={Prototype} title="IoT Biometrics" />
        </Card>
      </ExpansionPanel>

      {/* <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Submitting an idea</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <p className={classes.question}>
              How do I successfully achieve the funding goal during the crowd-sourcing stage on
              NOVA?
            </p>
            <ol>
              <li>
                Share the story of your idea with your colleges before submitting it and get
                feedback about it before submitting it. You can also join NOVA Drop-in sessions to
                get feedback on your idea from our innovation coaches before you submit it.
              </li>
              <li>
                Promote your idea with people who liked your idea. Ask them to join NOVA and invest
                using NOVA virtual coins in your idea. You can also share your idea on internal
                social media such as workplace. It will help for others to invest in your idea.
              </li>
            </ol>
            <p className={classes.question}>How does NOVA team categorize the submitted ideas?</p>
            NOVA accepts both enhancement as well as disruptive ideas. Enhancement ideas can request
            a funding up to €2 k and disruptive can request up to € 15 k.
            <p className={classes.question}>What is the criteria of a disruptive idea?</p>
            In NOVA we define a disruptive ideas based on the below criteria. The Idea can be an
            Internal Innovation or External Innovation.{' '}
            <ol>
              {' '}
              <li>Is it a new product or service Vodafone didn’t offer before?</li>{' '}
              <li>Is there a new business model behind the idea?</li>{' '}
              <li>Is it targeting a new market segment?</li>
              <li>Is it using a new technology?</li>
              <li>Is it replacing an old product/service/process?</li>
            </ol>
            <p className={classes.question}>What's the funding target for my idea?</p>
            The funding target may vary depending on the moment that you submit your idea. The exact
            numbers changes depending on some parameters behind the scenes of NOVA; the target for
            your idea will be displayed on its page.
            <p className={classes.question}>Why do ideas have different funding targets?</p>
            The funding target may vary depending on the moment that you submit your idea. The exact
            numbers changes depending on some parameters behind the scenes of NOVA; these are there
            in order to maintain a fair balance as the number of users grow and the amount of money
            in the system changes.
            <p className={classes.question}>What happens when my idea gets funded?</p>
            After your idea gets funded it still needs to be approved by the NOVA Board. This
            usually takes a couple of weeks. After approval you will get an email with next steps
            and resources to prepare for the pitch. The pitches take place every month and you will
            need to submit your presentation roughly a week prior to the date.
            <p className={classes.question}>
              How do I know whether an idea has been submitted before?
            </p>
            There is no formal process in place at the moment, the recommended method is by
            searching past ideas using the search function.
            <p className={classes.question}>What do the different investment rounds mean?</p>
            The rounds are a means of encouraging members to invest in ideas early. The earlier you
            put your money into the idea the higher your returns. Seed rounds (the idea owners) get
            the highest returns, followed by 1st round, then 2nd and finally 3rd.
            <p className={classes.question}>I have a problem, how do I know how to solve it?</p>
            Generally, we recommend setting-up cross-functional teams workshops to brainstorm ideas
            on how to resolve the issues. Working as a team helps in building on each other’s’ ideas
            as well as getting inspiration from different inputs or perspectives. However, if you
            have a problem that you would like to solve yourself you can discuss your idea with
            different relevant stakeholders to get feedback so that it can help you develop the idea
            further too. Additionally, if you have an idea and you are not sure whether it is ready
            to be submitted or not feel free to drop-in to our NOVA Drop-in sessions to get feedback
            from our Innovation coaches. You can follow us on Workplace for more updates on the next
            session.
            <p className={classes.question}>
              What happens if my prototype is very similar to another product already on the
              Vodafone roadmap?
            </p>
          </Typography>
        </ExpansionPanelDetails>
        <Card className={classes.root_card}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography variant="body2" gutterBottom>
                After being approved by the Olympians, an idea going into the prototype stage needs
                to be assessed on whether there are similar projects on the Vodafone roadmap. The
                diagram illustrates the decision making process as well as various paths available.
              </Typography>
            </CardContent>
          </div>
          <CardMedia className={classes.cover} image={Prototype} title="IoT Biometrics" />
        </Card>
      </ExpansionPanel> */}

      <p className={classes.topic}>Investing in an idea</p>

      {/* <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}>Investing in an idea</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <p className={classes.question}>How do I get return on my investments?</p>
            To get any returns on your investments you need to make sure you invest in high quality
            ideas that will progress through the NOVA platform. Any money you invest in ideas that
            don't reach their funding target is lost! However, if an idea gets all the way to the
            Implemented stage you will be rewarded with very high profits!
            <p className={classes.question}>Is there a limit to how much I can invest?</p>
            You are only allowed to invest up 5% of the idea funding target, so for ideas with a
            target of 1,000,000 coins each individual user will only be able to invest up to 50,000
            <p className={classes.question}>
              Can I get my coins back if I no longer want to invest in an idea?
            </p>
            Once an investment is made it is non-refundable! Make sure to invest wisely and
            carefully, only support ideas you think are beneficial and have provide business value
            to the company. Once you invest in an idea you become an ambassador for it and can try
            and convince others to invest in it as well.
            <p className={classes.question}>
              What is the maximum/minimum investment for each product?
            </p>
            There is no minimum investment however there is a maximum. You are only allowed to
            invest up 5% of the idea funding target, so for ideas with a target of 1,000,000 coins
            each individual user will only be able to invest up to 50,000
            <p className={classes.question}>What happens when I run out of virtual coins?</p>
            If you run out of coins you will have to try and regain some back by participating on
            NOVA (each comment will net you a bonus amount), try gaining badges (these bonuses are
            even higher) or submit ideas!
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel> */}

      <ExpansionPanel expanded={expanded === 'panel20'} onChange={handleChange('panel20')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            How do I get return on my investments?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            To get any returns on your investments you need to make sure you invest in high quality
            ideas that will progress through the NOVA platform. Any money you invest in ideas that
            don't reach their funding target is lost! However, if an idea gets all the way to the
            Implemented stage you will be rewarded with very high profits!
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel21'} onChange={handleChange('panel21')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            Is there a limit to how much I can invest?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            You are only allowed to invest up 5% of the idea funding target, so for ideas with a
            target of 1,000,000 coins each individual user will only be able to invest up to 50,000
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel22'} onChange={handleChange('panel22')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            Can I get my coins back if I no longer want to invest in an idea?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Once an investment is made it is non-refundable! Make sure to invest wisely and
            carefully, only support ideas you think are beneficial and have provide business value
            to the company. Once you invest in an idea you become an ambassador for it and can try
            and convince others to invest in it as well.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel23'} onChange={handleChange('panel23')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            What is the maximum/minimum investment for each product?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            There is no minimum investment however there is a maximum. You are only allowed to
            invest up 5% of the idea funding target, so for ideas with a target of 1,000,000 coins
            each individual user will only be able to invest up to 50,000
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel24'} onChange={handleChange('panel24')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            What happens when I run out of virtual coins?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            If you run out of coins you will have to try and regain some back by participating on
            NOVA (each comment will net you a bonus amount), try gaining badges (these bonuses are
            even higher) or submit ideas!
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

Faq.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(Faq)

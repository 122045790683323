import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
// import clsx from 'clsx'
import i18n from 'support/i18n'
import { withStyles } from '@material-ui/core'
import styles from './View.Style'

const NxtCircleContent = props => {
  const { classes, percentage, days } = props
  let displayDays = 0
  if (days === 0) displayDays = '~1'
  else if (days < 0) displayDays = '0'
  else displayDays = days

  return (
    <Fragment>
      <div className={classes.percentage}>
        {percentage && (
          <Fragment>
            <span>{percentage}%</span>
            <span>funded</span>
          </Fragment>
        )}
      </div>
      {/* <div className={clsx(percentage ? classes.daysPercentage : classes.days)}> */}
      <div className={classes.days}>
        <span>{displayDays}</span>
        <span>{i18n.t('DAYS')}</span>
      </div>
    </Fragment>
  )
}

NxtCircleContent.defaultProps = {
  percentage: '',
  days: '',
}

NxtCircleContent.propTypes = {
  percentage: PropTypes.node,
  days: PropTypes.node,
}

export default withStyles(styles)(NxtCircleContent)

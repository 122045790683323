import React from 'react'
import * as PropTypes from 'prop-types'
import { Paper, withStyles } from '@material-ui/core'
import clsx from 'clsx'
import { MESSAGE_TYPES } from './View.Utils'
import styles from './View.Style'

const AuthMessageBox = props => {
  const { classes, messageType, messageText } = props
  return (
    messageText && (
      <Paper
        className={clsx(
          classes.messageBox,
          messageType === MESSAGE_TYPES.ERROR && classes.errorMessage,
          messageType === MESSAGE_TYPES.SUCCESS && classes.successMessage,
          messageType === MESSAGE_TYPES.WARNING && classes.warningMessage,
        )}
      >
        {messageText}
      </Paper>
    )
  )
}
AuthMessageBox.defaultProps = {
  messageType: MESSAGE_TYPES.SUCCESS,
}

AuthMessageBox.propTypes = {
  messageType: PropTypes.oneOf([...Object.values(MESSAGE_TYPES)]),
  messageText: PropTypes.node.isRequired,
}
export default withStyles(styles)(AuthMessageBox)

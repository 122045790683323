import React, { Component, Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import InvestmentsTable from 'components/investments_table/View'
import TotalInvestments from './components/total_investments/View'
import styles from './View.Style'

class Investments extends Component {
  componentDidMount() {
    const { fullInfoLoaded, getInvestments, userEmail } = this.props
    if (fullInfoLoaded) {
      getInvestments(userEmail)
    }
  }

  componentDidUpdate(prevProps) {
    const { fullInfoLoaded, getInvestments, userEmail } = this.props
    if (fullInfoLoaded !== prevProps.fullInfoLoaded && fullInfoLoaded) {
      getInvestments(userEmail)
    }
  }

  render() {
    const { classes, totalInvested, totalProfit, ideas, onTitleClick } = this.props
    return (
      <Fragment>
        <div className={classes.top}>
          <div className={classes.topContainer}>
            <h1>
              <i className="icon-evolution" /> {i18n.t('SCREEN.INVESTMENTS')}
            </h1>
          </div>
          <TotalInvestments totalProfit={totalProfit} totalInvested={totalInvested} />
        </div>
        <InvestmentsTable ideas={ideas} onTitleClick={onTitleClick} />
      </Fragment>
    )
  }
}

Investments.defaultProps = {
  ideas: [],
  totalInvested: 0,
  totalProfit: 0,
  fullInfoLoaded: false,
  userEmail: '',
}

Investments.propTypes = {
  ideas: PropTypes.arrayOf(PropTypes.shape),
  totalInvested: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalProfit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullInfoLoaded: PropTypes.bool,
  userEmail: PropTypes.string,
  getInvestments: PropTypes.func.isRequired,
  onTitleClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(Investments)

const styles = () => ({
  container: {
    margin: '30px auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    width: '60%',
    'grid-column-start': '1',
    'grid-column-end': '4',
  },
})

export default styles

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    width: theme.spacing(28),
    margin: 0,
    marginRight: '1rem',
  },
})

export default styles

const styles = theme => ({
  root: {},
  buttonsContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  previousButton: {
    '& span.icon-left': {
      paddingRight: theme.spacing(1.5),
    },
  },
  buttonProgress: {
    position: 'absolute',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})

export default styles

import React, { Component } from 'react'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { IconButton, Input, InputAdornment } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

class SearchInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searched: false,
    }
    this.applySearch = this.applySearch.bind(this)
    this.resetSearch = this.resetSearch.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
  }

  onKeyDown(event) {
    if (event.keyCode === 13) this.applySearch()
  }

  resetSearch() {
    const { handleChange, resetSearch } = this.props
    handleChange({ target: { name: 'search', value: '' } })
    resetSearch()
    this.setState({ searched: false })
  }

  applySearch() {
    const { applySearch, value } = this.props
    if (value.length > 0) {
      applySearch(value)
      this.setState({ searched: true })
    }
    if (value.length === 0) {
      this.resetSearch()
      this.setState({ searched: false })
    }
  }

  render() {
    const { classes, handleChange, value } = this.props
    const { searched } = this.state

    const iconName = searched ? 'icon-close' : 'icon-search'
    const handleClick = searched ? this.resetSearch : this.applySearch

    return (
      <div className={classes.container}>
        <Input
          className={classes.searchField}
          type="text"
          value={value}
          onChange={handleChange}
          onKeyDown={this.onKeyDown}
          placeholder={i18n.t('HOME.FILTER.TEXT.FILTER_BY_TEXT')}
          inputProps={{
            name: 'search',
          }}
          startAdornment={
            <InputAdornment position="start">
              <IconButton
                className={clsx(iconName, classes.buttonSearch)}
                disableRipple
                onClick={handleClick}
                size="small"
              />
            </InputAdornment>
          }
        />
      </div>
    )
  }
}

SearchInput.propTypes = {
  applySearch: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    buttonSearch: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    searchField: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.string.isRequired,
}

export default withStyles(styles)(SearchInput)

import React, { Component } from 'react'
import { withStyles, Button, Paper } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import styles from './View.Style'
import NxtGroupsList from './components/nxt_groups_list/View'

class GroupsLimit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      leftGroups: [...props.leftGroups],
      hasBeenClickedFirst: false,
    }
    this.handleCheckLeftGroup = this.handleCheckLeftGroup.bind(this)
    this.handleCheckRightGroup = this.handleCheckRightGroup.bind(this)
    this.handleFromLeftToRight = this.handleFromLeftToRight.bind(this)
    this.handleFromRightToLeft = this.handleFromRightToLeft.bind(this)
  }

  handleCheckLeftGroup(leftGroupClicked) {
    const { leftGroups } = this.state
    this.setState({
      leftGroups: leftGroups.map(group => ({
        ...group,
        checked: group.name === leftGroupClicked.name ? !group.checked : group.checked,
      })),
      hasBeenClickedFirst: true,
    })
  }

  handleFromLeftToRight() {
    const { leftGroups } = this.state
    const { onMoveToRight } = this.props
    const toSendToRight = leftGroups.filter(group => group.checked)
    const toStayInTheLeft = leftGroups.filter(group => !group.checked)
    this.setState({
      leftGroups: toStayInTheLeft,
    })
    onMoveToRight(toSendToRight.map(group => ({ ...group, checked: false })))
  }

  handleCheckRightGroup(rightGroupClicked) {
    const { rightGroups, onRightGroupClick } = this.props
    const rightGroupsToSet = rightGroups.map(group => ({
      ...group,
      checked: rightGroupClicked.name === group.name ? !rightGroupClicked.checked : group.checked,
    }))
    onRightGroupClick(rightGroupsToSet)
  }

  handleFromRightToLeft() {
    const { leftGroups } = this.state
    const { rightGroups, onMoveToLeft } = this.props
    const toSendToLeft = rightGroups.filter(group => group.checked)
    const toStayInRight = rightGroups.filter(group => !group.checked)
    this.setState({
      leftGroups: [
        ...leftGroups,
        ...toSendToLeft.map(group => ({
          ...group,
          checked: false,
        })),
      ],
    })
    onMoveToLeft(toStayInRight)
  }

  render() {
    const { leftGroups, hasBeenClickedFirst } = this.state
    const { classes, rightGroups } = this.props
    return (
      <div className={classes.root}>
        {hasBeenClickedFirst && (
          <Paper className={classes.dangerPaper}>
            <span className="icon-alert"></span>
            <div className={classes.textsContainer}>
              <p className={classes.dangerTitle}>{i18n.t('CHALLENGES.BE_CAREFUL')}</p>
              <p className={classes.dangerMessage}>
                {i18n.t('CHALLENGES.SELECTED_GROUP_CANNOT_BE_CHANGED')}
              </p>
            </div>
          </Paper>
        )}
        <div className={classes.infoContainer}>{i18n.t('CHALLENGES.NOT_SELECTED_GROUP')}</div>
        <div className={classes.groupsContainer}>
          <div className={classes.groupsTextContainer}>
            <p>{i18n.t('CHALLENGES.SELECT_GROUP')}</p>
            <p>{i18n.t('CHALLENGES.GROUP_HELP_TEXT')}</p>
          </div>
          <NxtGroupsList
            classes={{ root: classes.leftGroup }}
            group={leftGroups}
            onToggle={this.handleCheckLeftGroup}
          />
          <div className={classes.actionsButtons}>
            <Button
              variant="contained"
              disabled={!leftGroups.find(group => group.checked === true)}
              className={classes.button}
              color="primary"
              onClick={this.handleFromLeftToRight}
            >
              <span className="icon-right"></span>
            </Button>
            <Button
              variant="contained"
              disabled={!rightGroups.find(group => group.checked === true)}
              className={classes.button}
              color="primary"
              onClick={this.handleFromRightToLeft}
            >
              <span className="icon-left"></span>
            </Button>
          </div>
          <NxtGroupsList
            classes={{ root: classes.rightGroup }}
            group={rightGroups}
            onToggle={this.handleCheckRightGroup}
          />
        </div>
      </div>
    )
  }
}
GroupsLimit.defaultProps = {
  classes: {
    root: '',
    dangerPaper: '',
    textsContainer: '',
    dangerTitle: '',
    dangerMessage: '',
    infoContainer: '',
    groupsContainer: '',
    groupsTextContainer: '',
    leftGroup: '',
    actionsButtons: '',
    rightGroup: '',
  },
  leftGroups: [],
  rightGroups: [],
}
GroupsLimit.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  onMoveToRight: PropTypes.func.isRequired,
  onMoveToLeft: PropTypes.func.isRequired,
  leftGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.string,
      id: PropTypes.string,
      checked: PropTypes.bool,
    }),
  ),
  rightGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  onRightGroupClick: PropTypes.func.isRequired,
}
export default withStyles(styles)(GroupsLimit)

import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import defaultPhoto from 'img/nxt-inverted-image.png'
import Image from 'components/image/View'
import i18n from 'support/i18n'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import styles from './View.Style'

const NxtMiniCard = props => {
  const { classes, imageSrc, title, pledged, investors } = props
  return (
    <div className={classes.root}>
      <Image defaultSrc={defaultPhoto} src={imageSrc} alt="The image of the idea" />
      <div className={classes.compactIdeaDetail}>
        <div className={classes.titleContainer}>
          <span className={classes.ideaTitle}>{title}</span>
        </div>
        <div className={classes.ideaFinance}>
          <div className={classes.pledgedFinance}>
            <NxtVirtualCurrency>{pledged}</NxtVirtualCurrency>
            <span>{i18n.t('PLEDGED')}</span>
          </div>
          <div className={classes.investorsFinance}>
            <span>{investors}</span>
            <span>{i18n.t('INVESTORS')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

NxtMiniCard.defaultProps = {
  imageSrc: '',
}

NxtMiniCard.propTypes = {
  imageSrc: PropTypes.string,
  title: PropTypes.string.isRequired,
  pledged: PropTypes.string.isRequired,
  investors: PropTypes.string.isRequired,
}

export default withStyles(styles)(NxtMiniCard)

export function setProfileBody(first, last, photo, oldPass, newPass) {
  let profile = `${encodeURIComponent('first')}=${encodeURIComponent(first)}`
  profile += `&${encodeURIComponent('last')}=${encodeURIComponent(last)}`
  if (photo) profile += `&${encodeURIComponent('photo')}=${encodeURIComponent(photo)}`
  if (oldPass) profile += `&${encodeURIComponent('old_pass')}=${encodeURIComponent(oldPass)}`
  if (newPass) profile += `&${encodeURIComponent('new_pass')}=${encodeURIComponent(newPass)}`
  return profile
}

export function setUserPrefsBody(key, value) {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
}

export function setRequestInvestmentBody(ideaId, messageContent) {
  return `${encodeURIComponent('ideaK')}=${encodeURIComponent(ideaId)}&${encodeURIComponent(
    'content',
  )}=${encodeURIComponent(messageContent)}`
}



export const setAdditionalFilters = ({
  organizations,
  types,
  status,
  limit = 11,
  offset = 0,
}) => {
  let url = ''
  url += `?${encodeURIComponent('limit')}=${limit}`
  url += `&${encodeURIComponent('offset')}=${offset}`
  if (organizations && organizations.length) {
    url += `&${encodeURIComponent('organizations')}=${organizations}`
  }
  if (types && types.length) {
    url += `&${encodeURIComponent('types')}=${types}`
  }
  if (status && status.length) {
    url += `&${encodeURIComponent('status')}=${status}`
  }
  return url
}

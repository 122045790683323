import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Typography, Divider } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'
import RoiConfiguration from './components/roi_configuration/View'
import GamingConfiguration from './components/gaming_configuration/View'

const Gaming = props => {
  const title = i18n.t('CHALLENGES.GAMMING')
  const {
    classes,
    onSettingChange,
    selectedGamingSetting,
    onSliderChangeCommitted,
    approveMult,
    implementMult,
    supportMult,
    currentGoal,
    defaultValues,
    onChangeTab,
    activeTab,
  } = props

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.mainTitle}>
        {title}
      </Typography>
      <Divider />
      <div>
        <RoiConfiguration />
      </div>
      <div className={classes.tabsSection}>
        <GamingConfiguration
          onChangeTab={onChangeTab}
          onSettingChange={onSettingChange}
          selectedGamingSetting={selectedGamingSetting}
          onSliderChangeCommitted={onSliderChangeCommitted}
          approveMult={approveMult}
          implementMult={implementMult}
          supportMult={supportMult}
          currentGoal={currentGoal}
          defaultValues={defaultValues}
          activeTab={activeTab}
        />
      </div>
    </div>
  )
}
Gaming.defaultProps = {
  selectedGamingSetting: '',
}
Gaming.propTypes = {
  onSettingChange: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  selectedGamingSetting: PropTypes.string,
  onSliderChangeCommitted: PropTypes.func.isRequired,
  approveMult: PropTypes.number.isRequired,
  implementMult: PropTypes.number.isRequired,
  supportMult: PropTypes.number.isRequired,
  currentGoal: PropTypes.number.isRequired,
  activeTab: PropTypes.number.isRequired,
  defaultValues: PropTypes.shape({}).isRequired,
}
export default withStyles(styles)(Gaming)

import { ideaStatusMapper, ideaStatusTypeMapper } from './Constants'

export const fromWidthToColor = (width, minOpacity = 20) => {
  return (Math.round(((width <= minOpacity ? minOpacity : width) / 100) * 255) + 0x10000)
    .toString(16)
    .substr(-2)
    .toUpperCase()
}

export const getTypeFromStatus = (stepOrder, currentStatus) => {
  if (stepOrder > ideaStatusMapper[currentStatus]) {
    return ideaStatusTypeMapper.next
  }
  if (stepOrder < ideaStatusMapper[currentStatus]) {
    return ideaStatusTypeMapper.previous
  }
  return ideaStatusTypeMapper.current
}

export function getCurrentStatusProps(statusStepperProps, currentStatus) {
  switch (ideaStatusMapper[currentStatus]) {
    case ideaStatusMapper.PENDING_SUPPORT:
      return {
        text: statusStepperProps.pendingSupportLabel,
        color: statusStepperProps.pendingSupportColor,
      }
    case ideaStatusMapper.SUPPORTED:
      return {
        text: statusStepperProps.supportedLabel,
        color: statusStepperProps.supportedColor,
      }
    case ideaStatusMapper.ROUND_CLOSED:
      return {
        text: statusStepperProps.roundClosedLabel,
        color: statusStepperProps.roundClosedColor,
      }
    case ideaStatusMapper.APPROVED_BY_IT:
      return {
        text: statusStepperProps.approvedLabel,
        color: statusStepperProps.approvedColor,
      }
    case ideaStatusMapper.PILOT_ACTIVE:
      return {
        text: statusStepperProps.pilotActiveLabel,
        color: statusStepperProps.pilotActiveColor,
      }
    case ideaStatusMapper.IMPLEMENTED:
      return {
        text: statusStepperProps.implementedLabel,
        color: statusStepperProps.implementedColor,
      }
    case ideaStatusMapper.DISCARDED:
      return {
        text: statusStepperProps.discardedLabel,
        color: statusStepperProps.discardedColor,
      }
    default:
      return {
        text: '',
        color: '',
      }
  }
}

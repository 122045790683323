import React from 'react'
import * as PropTypes from 'prop-types'
import { Button, InputAdornment, TextField, withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import SimpleDivider from 'components/simple_divider/View'
import styles from './View.Style'

const ButtonsFooter = props => {
  const {
    classes,
    isPreviousValidationEnabled,
    onInitialInvestmentChange,
    currencyIcon,
    onSubmitDraft,
    onSubmitIdea,
    onCancel,
    onSaveIdea,
    onResubmitIdea,
    edit,
    isSubmitting,
    isSaveButtonEnable,
  } = props
  return (
    <div className={classes.root}>
      {!edit && (
        <div className={classes.submitSection}>
          <Button
            className={classes.formButtons}
            disabled={isSubmitting}
            onClick={onSubmitDraft}
            color="primary"
            variant="contained"
          >
            {i18n.t('IDEA.CREATE')}
          </Button>
          <div>
            {!isPreviousValidationEnabled && (
              <TextField
                className={classes.investmentInput}
                disabled={isSubmitting}
                variant="outlined"
                placeholder={i18n.t('IDEA.INVESTMENT')}
                onChange={event => onInitialInvestmentChange(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className={`icon-${currencyIcon}`} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <Button
              className={classes.formButtons}
              disabled={isSubmitting}
              onClick={onSubmitIdea}
              variant="contained"
              color="primary"
            >
              Submit Now
            </Button>
          </div>
        </div>
      )}
      {edit && (
        <div className={classes.submitSection}>
          <Button
            className={classes.formButtons}
            disabled={isSubmitting}
            onClick={onCancel}
            variant="outlined"
          >
            {i18n.t('IDEA.CANCEL')}
          </Button>
          {isSaveButtonEnable && (
            <Button
              className={classes.formButtons}
              disabled={isSubmitting}
              onClick={onSaveIdea}
              variant="contained"
              color="primary"
            >
              {i18n.t('IDEA.SAVE')}
            </Button>
          )}
          {!isSaveButtonEnable && (
            <div>
              <Button
                className={classes.formButtons}
                disabled={isSubmitting}
                onClick={onResubmitIdea}
                variant="contained"
                color="primary"
              >
                {i18n.t('IDEA.RESUBMIT')}
              </Button>
              {/* <Button
                className={classes.formButtons}
                disabled={isSubmitting}
                onClick={onSaveIdea}
                variant="contained"
                color="primary"
              >
                {i18n.t('IDEA.SAVE')}
              </Button> */}
            </div>
          )}
        </div>
      )}
      <SimpleDivider classNameProp={{ lineColor: classes.dividerColor }} />
      <div className={classes.backToTopContainer}>
        <Button
          fullWidth
          disableFocusRipple
          disableRipple
          className={classes.backToTop}
          onClick={() => window.scrollTo(0, 0)}
        >
          <span className="icon-up" />
          <p>{i18n.t('PROFILE.UP')}</p>
        </Button>
      </div>
    </div>
  )
}

ButtonsFooter.defaultProps = {
  isPreviousValidationEnabled: false,
  edit: false,
  isSubmitting: false,
}

ButtonsFooter.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    submitSection: PropTypes.string,
    investmentInput: PropTypes.string,
    backToTopContainer: PropTypes.string,
    backToTop: PropTypes.string,
    formButtons: PropTypes.string,
    dividerColor: PropTypes.string,
  }).isRequired,
  edit: PropTypes.bool,
  isPreviousValidationEnabled: PropTypes.bool,
  onInitialInvestmentChange: PropTypes.func.isRequired,
  currencyIcon: PropTypes.string.isRequired,
  onSubmitDraft: PropTypes.func.isRequired,
  onSubmitIdea: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaveIdea: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
}

export default withStyles(styles)(ButtonsFooter)

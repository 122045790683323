import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import i18n from 'support/i18n'
import NxtCardListItems from 'components/nxt_card/nxt_card_list_items/View'
import Flip from 'components/animations/flip/View'
import FlipCard from 'components/animations/flip/components/flip_card/View'
import Description from 'components/description/View'
import Overlay from 'components/overlay/View'
import CircleGraph from 'components/circle_graph/View'
import Image from 'components/image/View'
import NxtCardCorner from 'components/nxt_card/nxt_card_corner/View'
import ButtonLink from 'components/button_link/View'
import imgDefault from 'img/nxt-inverted-image.png'
import styles from './View.Style'

class NxtSmallCard extends Component {
  constructor(props) {
    super(props)
    this.onClickMainButton = this.onClickMainButton.bind(this)
    this.onClickSecondaryButton = this.onClickSecondaryButton.bind(this)
    this.renderDescription = this.renderDescription.bind(this)
    this.renderSubmittedBy = this.renderSubmittedBy.bind(this)
    this.renderListItem = this.renderListItem.bind(this)
    this.renderTitle = this.renderTitle.bind(this)
  }

  onClickMainButton() {
    const { id, onClickMainButton } = this.props
    onClickMainButton(id)
  }

  onClickSecondaryButton() {
    const { id, onClickSecondaryButton } = this.props
    onClickSecondaryButton(id)
  }

  renderDescription() {
    const { description } = this.props
    if (description) {
      return <div dangerouslySetInnerHTML={{ __html: description }} />
    }
    return null
  }

  renderSubmittedBy() {
    const { submittedBy, classes, onSubmitterClick, isAnonymous } = this.props
    if (submittedBy) {
      return (
        <small>
          {i18n.t('SUBMITTED_BY')}
          {!isAnonymous && (
            <ButtonLink
              classes={{ linkButton: classes.submitterLinkButton }}
              onLinkClick={onSubmitterClick}
            >
              {submittedBy}
            </ButtonLink>
          )}
          {isAnonymous && <span className={classes.submitterSpan}>{submittedBy}</span>}
        </small>
      )
    }
    return null
  }

  renderListItem() {
    const { investors, pledged } = this.props
    if (investors || pledged) {
      return <NxtCardListItems primaryValue={pledged} secondaryValue={investors} />
    }
    return null
  }

  renderTitle() {
    const { alt, defaultSrc, img, title } = this.props
    if (title) {
      return (
        <Overlay alt={alt} img={img} defaultSrc={defaultSrc}>
          <Description text={title} color="white" />
        </Overlay>
      )
    }
    return null
  }

  render() {
    const {
      alt,
      mainButtonText,
      children,
      classes,
      challenged,
      onClickSecondaryButton,
      defaultSrc,
      img,
      sponsored,
      theme,
      circleContent,
      circleValue,
      circleMaxValue,
      circleColor,
      showCircle,
    } = this.props
    return (
      <div className={classes.container}>
        <Flip>
          <FlipCard side="front">
            <NxtCardCorner challenged={challenged} sponsored={sponsored} />
            {showCircle && (
              <div className={classes.graph}>
                <CircleGraph
                  className={{ base: classes.circleBase }}
                  value={circleValue}
                  maxValue={circleMaxValue}
                  themeColor={theme}
                  pathColor={circleColor}
                >
                  {circleContent}
                </CircleGraph>
              </div>
            )}
            <Image alt={alt} src={img} defaultSrc={defaultSrc} />
            {this.renderTitle()}
          </FlipCard>
          <FlipCard side="back" theme={theme}>
            <div className={classes.descriptionBlock}>
              {this.renderDescription()}
              {this.renderSubmittedBy()}
            </div>
            <ul
              className={clsx(
                classes.list,
                theme === 'light' && classes.listLight,
                theme === 'dark' && classes.listDark,
              )}
            >
              {children}
            </ul>
            <div className={classes.buttonBlock}>
              <Button
                className={classes.button}
                color="primary"
                fullWidth
                onClick={this.onClickMainButton}
                variant="contained"
              >
                {mainButtonText}
              </Button>
              {onClickSecondaryButton && (
                <Button
                  className={classes.button}
                  color="primary"
                  fullWidth
                  onClick={this.onClickSecondaryButton}
                  variant="contained"
                >
                  {i18n.t('CHALLENGES.VIEW')}
                </Button>
              )}
            </div>
          </FlipCard>
        </Flip>
      </div>
    )
  }
}

NxtSmallCard.defaultProps = {
  alt: 'default image',
  mainButtonText: i18n.t('GO_TO_IDEA'),
  description: '',
  img: '',
  pledged: '',
  title: '',
  submittedBy: '',
  investors: '',
  id: '',
  sponsored: false,
  challenged: false,
  theme: 'light',
  onClickSecondaryButton: null,
  onSubmitterClick: () => {},
  circleValue: '0',
  circleMaxValue: '100',
  circleColor: '',
  defaultSrc: imgDefault,
  showCircle: false,
  isAnonymous: false,
}

NxtSmallCard.propTypes = {
  alt: PropTypes.string,
  mainButtonText: PropTypes.node,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
  circleValue: PropTypes.string,
  circleMaxValue: PropTypes.string,
  circleColor: PropTypes.string,
  defaultSrc: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  pledged: PropTypes.string,
  title: PropTypes.string,
  submittedBy: PropTypes.string,
  investors: PropTypes.string,
  id: PropTypes.string,
  onClickMainButton: PropTypes.func.isRequired,
  onSubmitterClick: PropTypes.func,
  onClickSecondaryButton: PropTypes.func,
  challenged: PropTypes.bool,
  showCircle: PropTypes.bool,
  sponsored: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
  isAnonymous: PropTypes.bool,
}

export default withStyles(styles)(NxtSmallCard)

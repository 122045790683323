const styles = theme => ({
  root: {
    display: 'flex',
    // width: '100%',
    // justifyContent: 'center',
  },
  sliderContainer: {
    width: '500px',
    alignSelf: 'center',
  },
  radioImages: {
    '& > div:first-child': {
      width: '500px',
    },
  },
  imageContainer: {
    float: 'right',
    flexGrow: 1,
    position: 'relative',
    width: '100%',
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
  },
  input: {
    display: 'none',
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
  },
  button: {
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
  },
  stepperRoot: {
    float: 'right',
    width: '484px',
  },
  radioButtonMain: {
    paddingLeft: theme.spacing(1.5),
  },
})

export default styles

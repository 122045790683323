const styles = theme => ({
  root: {
    backgroundColor: 'white',
    height: theme.spacing(9),
  },
  nameEmailSection: {
    paddingLeft: theme.spacing(2),
  },
  suggestionsList: {
    overflowY: 'auto',
    zIndex: 2,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 5px 5px -4px rgba(0,0,0,0.75)',
    position: 'absolute',
    padding: theme.spacing(0, 2, 2, 2),
    maxHeight: '150px',
  },
  inputWrapper: {
    height: '100%',
    padding: theme.spacing(0, 2),
  },
})

export default styles

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Paper } from '@material-ui/core'
import i18n from 'support/i18n'
import RankingSection from 'pages/ranking/components/ranking_section/View'
import styles from './View.Style'

class Ranking extends Component {
  componentDidMount() {
    const { getRanking } = this.props
    getRanking()
  }

  render() {
    const {
      classes,
      topInvestors,
      topPosts,
      topPotentialInvestors,
      topIdeas,
      topIdeasInvestors,
      navigateToProfile,
      navigateToIdea,
      subTitle,
    } = this.props
    return (
      <Paper className={classes.container}>
        <RankingSection
          icon="icon-ranking"
          title={i18n.t('RANKING.INVESTORS')}
          subTitle={i18n.t('RANKING.SUBINVESTOR')}
          rows={topInvestors}
          linkText="name"
          linkAttribute="email"
          amount="amountInvested"
          amountText={i18n.t('INVESTED')}
          hasCurrency
          navigateToLink={navigateToProfile}
        />
        <RankingSection
          icon="icon-avatar"
          title={i18n.t('RANKING.ENTREPENEURS')}
          subTitle={i18n.t('RANKING.SUBENTREPENEURS')}
          rows={topPosts}
          linkText="name"
          linkAttribute="email"
          amount="ideas"
          amountText={i18n.t('RANKING.IDEAS_CREATED')}
          navigateToLink={navigateToProfile}
        />
        <RankingSection
          icon="icon-funds"
          title={i18n.t('RANKING.LIQUID')}
          subTitle={i18n.t('RANKING.SUBLIQUID')}
          rows={topPotentialInvestors}
          linkText="name"
          linkAttribute="email"
          amount="balance"
          amountText={i18n.t('RANKING.ACTUAL_BALANCE')}
          hasCurrency
          navigateToLink={navigateToProfile}
        />
        <RankingSection
          icon="icon-idea"
          title={i18n.t('RANKING.MORE_INVESTED')}
          subTitle={i18n.t('RANKING.SUBMORE_INVESTED')}
          rows={topIdeas}
          linkText="title"
          linkAttribute="id"
          amount="investment"
          amountText={i18n.t('INVESTED')}
          hasCurrency
          navigateToLink={navigateToIdea}
        />
        <RankingSection
          icon="icon-more-investors"
          title={i18n.t('RANKING.MORE_INVESTORS')}
          subTitle={i18n.t('RANKING.SUBMORE_INVESTORS')}
          rows={topIdeasInvestors}
          linkText="title"
          linkAttribute="id"
          amount="investors"
          amountText={i18n.t('IDEA_INVESTORS')}
          navigateToLink={navigateToIdea}
        />
      </Paper>
    )
  }
}

Ranking.defaultProps = {
  topInvestors: [],
  topPosts: [],
  topPotentialInvestors: [],
  topIdeas: [],
  topIdeasInvestors: [],
}

Ranking.propTypes = {
  topInvestors: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      amountInvested: PropTypes.number,
    }),
  ),
  topPosts: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      ideas: PropTypes.number,
    }),
  ),
  topPotentialInvestors: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      balance: PropTypes.number,
    }),
  ),
  topIdeas: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
      investment: PropTypes.number,
    }),
  ),
  topIdeasInvestors: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
      investors: PropTypes.number,
    }),
  ),
  getRanking: PropTypes.func.isRequired,
  navigateToProfile: PropTypes.func.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
}

export default withStyles(styles)(Ranking)

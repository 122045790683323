const styles = theme => ({
  root: {
    width: 500,
    padding: theme.spacing(2),
  },
  inputContainer: {
    margin: theme.spacing(2, 0),
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button:first-child': {
      marginRight: theme.spacing(1),
    },
  },
})

export default styles

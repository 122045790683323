const styles = theme => ({
  boldText: {
    fontWeight: 'bold',
  },
  linkToInfo: {
    width: 115,
    padding: 0,
    marginLeft: theme.spacing(-2.75),
    marginTop: theme.spacing(-0.375),
    textAlign: 'left',
    textTransform: 'none',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

export default styles

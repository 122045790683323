import i18n from 'i18n-js'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import nl from './nl.json'
import { setI18nToReload } from './redux/Actions'

i18n.defaultLocale = 'en'
i18n.locale = 'en'
i18n.fallbacks = true
i18n.translations = { en, es, fr, nl }
i18n.currentLocale()

export function setI18nLang(lang, dispatch) {
  i18n.locale = lang
  i18n.currentLocale()
  dispatch(setI18nToReload(lang))
}

export default i18n

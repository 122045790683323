import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl, Select, MenuItem, Button } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import styles from './View.Style'

class ReportSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectValue: 0,
      selectedReport: 'ideas',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleMainButtonClick = this.handleMainButtonClick.bind(this)
  }

  handleChange(event) {
    const { value } = event.target
    switch (value) {
      case 0:
        return this.setState({ selectedReport: 'ideas', selectValue: value })
      case 1:
        return this.setState({ selectedReport: 'users', selectValue: value })
      case 2:
        return this.setState({ selectedReport: 'challenges', selectValue: value })
      case 3:
        return this.setState({ selectedReport: 'investments', selectValue: value })
      default:
        return this.setState({ selectedReport: 'comments', selectValue: value })
    }
  }

  handleMainButtonClick() {
    const { onMainButtonAction } = this.props
    const { selectedReport } = this.state

    onMainButtonAction(selectedReport)
  }

  render() {
    const { classes } = this.props
    const { selectValue } = this.state
    return (
      <div className={classes.container}>
        <span>{i18n.t('DASHBOARD.DOWNLOAD_REPORTS')}: </span>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select value={selectValue} onChange={this.handleChange}>
            <MenuItem value={0}>{i18n.t('DASHBOARD.IDEAS')}</MenuItem>
            <MenuItem value={1}>{i18n.t('DASHBOARD.USERS')}</MenuItem>
            <MenuItem value={2}>{i18n.t('DASHBOARD.CHALLENGES')}</MenuItem>
            <MenuItem value={3}>{i18n.t('DASHBOARD.INVESTMENTS')}</MenuItem>
            <MenuItem value={4}>{i18n.t('DASHBOARD.COMMENTS')}</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={this.handleMainButtonClick}>
          {i18n.t('DASHBOARD.DOWNLOAD_EXCEL')}
        </Button>
      </div>
    )
  }
}

ReportSelector.propTypes = {
  onMainButtonAction: PropTypes.func.isRequired,
}

export default withStyles(styles)(ReportSelector)

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import * as PropTypes from 'prop-types'
import Item from './components/domain_row/View'
import styles from './View.Style'

const DomainMenu = props => {
  const { classes, domains, navigateToDomain } = props
  return (
    <ul className={classes.container}>
      {domains.map((domain, index) => (
        <Item
          key={domain.id}
          id={domain.id}
          name={domain.name}
          backgroundColor={domain.corporateColor}
          color={domain.corporateColorFG}
          navigateToDomain={navigateToDomain}
          hasDivider={index !== domains.length - 1}
        />
      ))}
    </ul>
  )
}

DomainMenu.propTypes = {
  navigateToDomain: PropTypes.func.isRequired,
  domains: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default withStyles(styles)(DomainMenu)

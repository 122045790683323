import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const Description = props => {
  const { classes, color, text } = props

  return (
    <p className={classes.text} style={{ color }}>
      {text}
    </p>
  )
}

Description.defaultProps = {
  color: 'black',
  text: '',
}

Description.propTypes = {
  classes: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }).isRequired,
  color: PropTypes.oneOf(['white', 'black']),
  text: PropTypes.string,
}

export default withStyles(styles)(Description)

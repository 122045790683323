import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, ButtonGroup, Button } from '@material-ui/core'
import i18n from 'support/i18n'
import { typesViewAs } from 'pages/dashboard/Constants'
import clsx from 'clsx'
import CustomTable from 'components/custom_table/View'
import TableHeader from './components/table_header/View'
import TableBody from './components/table_body/View'
import { headerCells } from './utils/header/headerCells'
import ComparisonTable from '../comparison_table/View'
import ParticipationSuccessScatterChart from './components/participation_success_scatter_chart/View'
import PeopleSectionBar from './components/people_section_bar/View'
import ListFilter from './components/list_filter/View'
import styles from './View.Style'

const comparisonTableHeaders = [
  { text: i18n.t('DASHBOARD.W_INVESTMENTS'), icon: 'earn-money', borderColor: '#ffcd40' },
  { text: i18n.t('DASHBOARD.W_COMMENTS'), icon: 'comment', borderColor: '#d19600' },
  { text: i18n.t('DASHBOARD.W_IDEASOWNED'), icon: 'idea', borderColor: '#0070a7' },
  { text: i18n.t('DASHBOARD.W_IDEASFUNDED'), icon: 'success', borderColor: '#0097e2' },
  { text: i18n.t('DASHBOARD.W_IDEASAPPROVED'), icon: 'approved', borderColor: '#63ffe4' },
  { text: i18n.t('DASHBOARD.W_IDEASPILOT'), icon: 'prototype', borderColor: '#00d24c' },
  { text: i18n.t('DASHBOARD.W_IDEASIMPLEMENTED'), icon: 'ranking', borderColor: '#fa5d7f' },
]

class ActivityPeople extends Component {
  constructor(props) {
    super(props)
    this.state = { viewAs: 0 }

    this.handleViewAsClick = this.handleViewAsClick.bind(this)
  }

  handleViewAsClick(viewAs) {
    const { onViewAsClick } = this.props
    this.setState({
      viewAs,
    })
    onViewAsClick(viewAs)
  }

  render() {
    const {
      classes,
      tableData,
      tableUsersData,
      isTableLoading,
      navigateToUserProfile,
      onFilterByText,
      onFilterByType,
      isTableUsersDataLoading,
      series,
      isUsersParticipationSeriesLoading,
      totalUsers,
      participationAvg,
      successAvg,
      onTooltipEmailClick,
      onTooltipUserNameClick,
      onSendReportClick,
      totalSearchItems,
      onPageChange,
      rowsPerPage,
      page,
    } = this.props
    const { viewAs } = this.state
    return (
      <div className={classes.root}>
        <ComparisonTable
          header={comparisonTableHeaders}
          dataRows={tableData.dataRows}
          comparisonRow={tableData.comparisonRow}
          isLoading={isTableLoading}
        />
        <div className={classes.bottomTableTitle}>
          <h3>{i18n.t('DASHBOARD.PEOPLE')}</h3>
          <div className={classes.viewAsContainer}>
            <span>{i18n.t('DASHBOARD.DISPLAY_AS')}</span>
            <ButtonGroup color="secondary" aria-label="outlined primary button group" size="small">
              <Button
                color={viewAs === 0 ? 'primary' : ''}
                onClick={() => this.handleViewAsClick(typesViewAs.QUADRANT)}
                variant="contained"
              >
                {i18n.t('DASHBOARD.QUADRANT')}
              </Button>
              <Button
                color={viewAs === 2 ? 'primary' : ''}
                onClick={() => this.handleViewAsClick(typesViewAs.LIST)}
                variant="contained"
              >
                {i18n.t('DASHBOARD.LIST')}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div className={clsx(viewAs !== typesViewAs.LIST && classes.hide)}>
          <PeopleSectionBar onSendButtonClick={onSendReportClick}>
            <ListFilter onSelectChange={onFilterByType} totalUsers={totalSearchItems} />
          </PeopleSectionBar>
          <CustomTable
            isLoading={isTableUsersDataLoading}
            renderHeaderComponent={
              <TableHeader onFilterByText={onFilterByText} headRows={headerCells} />
            }
            renderBodyComponent={
              <TableBody bodyRows={tableUsersData} navigateToUserProfile={navigateToUserProfile} />
            }
            paginated
            fullCount={totalSearchItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPaginationChanged={onPageChange}
          />
        </div>
        <div className={clsx(viewAs !== typesViewAs.QUADRANT && classes.hide)}>
          <PeopleSectionBar onSendButtonClick={onSendReportClick}>
            <span>{i18n.t('DASHBOARD.QUADRANT_TITLE')}</span>
          </PeopleSectionBar>
          <ParticipationSuccessScatterChart
            series={series}
            totalUsers={totalUsers}
            participationAvg={participationAvg}
            successAvg={successAvg}
            isLoading={isUsersParticipationSeriesLoading}
            onTooltipEmailClick={onTooltipEmailClick}
            onTooltipUserNameClick={onTooltipUserNameClick}
          />
        </div>
      </div>
    )
  }
}

ActivityPeople.propTypes = {
  tableData: PropTypes.shape({
    dataRows: PropTypes.arrayOf(
      PropTypes.shape({
        firstColumn: PropTypes.arrayOf(
          PropTypes.shape({
            mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        ),
        data: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
    comparisonRow: PropTypes.shape({
      firstColumn: PropTypes.arrayOf(
        PropTypes.shape({
          mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
      data: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          type: PropTypes.string,
        }),
      ),
    }),
  }).isRequired,
  isTableLoading: PropTypes.bool.isRequired,
  series: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isUsersParticipationSeriesLoading: PropTypes.bool.isRequired,
  onViewAsClick: PropTypes.func.isRequired,
  onFilterByText: PropTypes.func.isRequired,
  onFilterByType: PropTypes.func.isRequired,
  totalUsers: PropTypes.number.isRequired,
  participationAvg: PropTypes.string.isRequired,
  successAvg: PropTypes.string.isRequired,
  onSendReportClick: PropTypes.func.isRequired,
  totalSearchItems: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
}

export default withStyles(styles)(ActivityPeople)

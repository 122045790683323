import React, { Component, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import i18n from 'support/i18n'
import { differenceInCalendarDays } from 'date-fns'
import { ButtonBase, withStyles, Button } from '@material-ui/core'
import IdeasGrid from 'components/show_filter/components/ideas_grid/View'
import IdeasSlider from 'components/show_filter/components/ideas_slide/View'
import NxtExpandedCard from 'components/nxt_expanded_card/View'
import NxtEmptyCards from 'components/nxt_empty_cards/View'
import NxtSmallCard from 'components/nxt_small_card/View'
import NxtCardListItems from 'components/nxt_card/nxt_card_list_items/View'
import { calcPercentage } from 'support/utils/circleGraph'
import { arrayToGroups } from 'support/utils/array'
import AnalyticsService from 'support/analytics'
import { ideaStatusIconMapper, ideaStatusMapper } from 'pages/idea/detail/Constants'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import NxtCircleContent from 'components/nxt_circle_content/View'
import { getCurrentStatusProps } from 'pages/idea/detail/View.Utils'
import styles from './View.Style'

class ShowFilter extends Component {
  constructor(props) {
    super(props)
    this.renderCards = this.renderCards.bind(this)
    this.renderCircle = this.renderCircle.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
  }

  renderListItem = (investors, pledged) => {
    if (investors || pledged) {
      return <NxtCardListItems primaryValue={pledged} secondaryValue={investors} />
    }
    return null
  }

  handleNext = () => {
    AnalyticsService.trackEvent({ category: 'swiper', action: 'click', label: 'next idea' })
  }

  handlePrevious = () => {
    AnalyticsService.trackEvent({ category: 'swiper', action: 'click', label: 'prev idea' })
  }

  renderCards() {
    const {
      classes,
      cards,
      gridComponent,
      onClick,
      showSlider,
      slideComponent,
      onSubmitterClick,
      totalIdeas,
      ideaStatusStepper,
      showSmallCardCircle,
    } = this.props

    let currentStatusProps = {}
    const GROUPS_AMOUNT = 3
    if (showSlider) {
      const cardsGrouped = arrayToGroups(cards, GROUPS_AMOUNT)
      return (
        slideComponent || (
          <IdeasSlider onNextClick={this.handleNext} onPreviousClick={this.handlePrevious}>
            {cardsGrouped.length > 0 &&
              cardsGrouped.map((group, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className={classes.cardGroup}>
                    {group.map((card, groupIndex) => {
                      const {
                        anonymous,
                        challenged,
                        created,
                        countdownEnds,
                        goal,
                        id,
                        images,
                        investors,
                        ownerName,
                        owner,
                        pledged,
                        sponsored,
                        status,
                        title,
                      } = card
                      const totalDays = differenceInCalendarDays(countdownEnds, created)
                      const days = differenceInCalendarDays(Date.now(), created)
                      const percentage = calcPercentage(pledged, goal)

                      if (ideaStatusStepper && status) {
                        currentStatusProps = getCurrentStatusProps(ideaStatusStepper, status)
                      }
                      return (
                        <NxtExpandedCard
                          isAnonymous={anonymous}
                          challenged={challenged}
                          days={differenceInCalendarDays(Date.now(), created)}
                          id={id}
                          img={images.length > 0 && images[0].url}
                          investors={investors}
                          key={id}
                          onMainButtonClick={() =>
                            onClick(id, index * GROUPS_AMOUNT + groupIndex + 1)
                          }
                          percentage={calcPercentage(pledged, goal)}
                          pledged={pledged}
                          sponsored={sponsored}
                          submittedBy={ownerName}
                          title={title}
                          totalDays={differenceInCalendarDays(countdownEnds, created)}
                          onSubmitterClick={() => onSubmitterClick(owner)}
                          ideaStatusStepper={ideaStatusStepper}
                          status={status}
                          circleColor={currentStatusProps.color}
                          circleValue={
                            ideaStatusMapper[status] !== ideaStatusMapper.PENDING_SUPPORT
                              ? '100'
                              : percentage
                          }
                          circleContent={this.renderCircle(status, totalDays, days, percentage)}
                          showCircle={
                            ideaStatusMapper[status] !== ideaStatusMapper.DRAFT &&
                            ideaStatusMapper[status] !== ideaStatusMapper.PENDING_VALIDATION
                          }
                        >
                          {this.renderListItem(investors, pledged)}
                        </NxtExpandedCard>
                      )
                    })}
                  </div>
                )
              })}
          </IdeasSlider>
        )
      )
    }
    return (
      gridComponent || (
        <IdeasGrid length={totalIdeas}>
          {cards.length > 0 &&
            cards.map(card => {
              const {
                anonymous,
                challenged,
                countdownEnds,
                created,
                goal,
                id,
                images,
                investors,
                ownerName,
                owner,
                pledged,
                sponsored,
                status,
                textWithoutTags,
                title,
              } = card
              const totalDays = differenceInCalendarDays(countdownEnds, created)
              const days = differenceInCalendarDays(Date.now(), created)
              const percentage = calcPercentage(pledged, goal)
              if (ideaStatusStepper && status) {
                currentStatusProps = getCurrentStatusProps(ideaStatusStepper, status)
              }
              return (
                <NxtSmallCard
                  isAnonymous={anonymous}
                  challenged={challenged}
                  description={textWithoutTags}
                  id={id}
                  img={images.length > 0 && images[0].url}
                  investors={investors}
                  key={id}
                  onClickMainButton={onClick}
                  pledged={pledged}
                  sponsored={sponsored}
                  submittedBy={ownerName}
                  title={title}
                  onSubmitterClick={() => onSubmitterClick(owner)}
                  ideaStatusStepper={ideaStatusStepper}
                  status={status}
                  circleColor={currentStatusProps.color}
                  circleValue={ideaStatusMapper[status] > 0 ? '100' : percentage}
                  circleContent={this.renderCircle(status, totalDays, days, percentage)}
                  showCircle={showSmallCardCircle}
                >
                  <NxtCardListItems
                    primaryValue={pledged}
                    secondaryValue={investors}
                    variant="black"
                  />
                </NxtSmallCard>
              )
            })}
        </IdeasGrid>
      )
    )
  }

  renderCircle(status, totalDays, days, percentage) {
    const { classes } = this.props
    if (status && ideaStatusMapper[status] > 0) {
      return (
        <span
          className={clsx(`icon-${ideaStatusIconMapper[ideaStatusMapper[status]]}`, classes.icon)}
        />
      )
    }
    return (
      <div className={classes.secondCircle}>
        <CircularProgressbarWithChildren
          value={totalDays - days}
          maxValue={totalDays}
          strokeWidth={3}
          styles={buildStyles({
            textColor: 'black',
            strokeLinecap: 'butt',
            pathColor: '#999999',
            trailColor: '#f0f0f0',
          })}
        >
          <NxtCircleContent percentage={percentage} days={totalDays - days} />
        </CircularProgressbarWithChildren>
      </div>
    )
  }

  render() {
    const {
      cards,
      classes,
      onCardTypeChange,
      showLoadMoreButton,
      showSlider,
      onLoadMore,
    } = this.props
    return (
      <Fragment>
        {cards && cards.length > 0 && (
          <div className={classes.container}>
            <div className={classes.selectors}>
              <ButtonBase
                className={clsx(!showSlider && classes.selected, showSlider && classes.unselected)}
                onClick={() => onCardTypeChange(false)}
                value
                variant="contained"
              >
                <i className="icon-grid" />
              </ButtonBase>
              <ButtonBase
                className={clsx(showSlider && classes.selected, !showSlider && classes.unselected)}
                onClick={() => onCardTypeChange(true)}
                variant="contained"
              >
                <i className="icon-carousel" />
              </ButtonBase>
            </div>
            <div className={classes.slide}>{this.renderCards()}</div>
            {showLoadMoreButton && (
              <div className={classes.loadMoreWrapper}>
                <Button variant="contained" color="primary" size="large" onClick={onLoadMore}>
                  {i18n.t('HOME.FILTER.LOAD_MORE')}
                </Button>
              </div>
            )}
          </div>
        )}
        {cards && cards.length === 0 && <NxtEmptyCards />}
      </Fragment>
    )
  }
}

ShowFilter.defaultProps = {
  cards: [],
  showSlider: false,
  slideComponent: null,
  gridComponent: null,
  onSubmitterClick: null,
  showSmallCardCircle: false,
}

ShowFilter.propTypes = {
  classes: PropTypes.shape({
    cardGroup: PropTypes.string,
    selected: PropTypes.string,
    slide: PropTypes.string,
    unselected: PropTypes.string,
  }).isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      challenged: PropTypes.bool,
      textWithoutTags: PropTypes.string,
      id: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          bucket: PropTypes.string,
          key: PropTypes.string,
          name: PropTypes.string,
          size: PropTypes.number,
          url: PropTypes.string,
        }),
      ),
      investors: PropTypes.string,
      goal: PropTypes.string,
      pledged: PropTypes.string,
      sponsored: PropTypes.bool,
      ownerName: PropTypes.string,
      owner: PropTypes.string,
      title: PropTypes.string,
      created: PropTypes.number,
      countdownEnds: PropTypes.number,
    }),
  ),
  showSlider: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onSubmitterClick: PropTypes.func,
  onCardTypeChange: PropTypes.func.isRequired,
  slideComponent: PropTypes.element,
  showSmallCardCircle: PropTypes.bool,
  gridComponent: PropTypes.element,
}

export default withStyles(styles)(ShowFilter)

import React, { Component, Fragment } from 'react'
import ReactDOMServer from 'react-dom/server'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Boost from 'highcharts/modules/boost'
import NoData from 'highcharts/modules/no-data-to-display'
import i18n from 'support/i18n'
import styles from './View.Style'
import Tooltip from './components/tooltip/View'

Boost(Highcharts)
NoData(Highcharts)

class ParticipationSuccessScatterChart extends Component {
  constructor(props) {
    super(props)
    this.chartRef = null
    const { title, series, isLoading } = props
    this.state = {
      options: {
        colors: ['rgba(0,146,230, .70)'],
        chart: {
          plotShadow: false,
          backgroundColor: 'transparent',
          style: {
            fontFamily: 'Helvetica, sans-serif',
            cursor: 'crosshair',
          },
          type: 'scatter',
          zoomType: 'xy',
          resetZoomButton: {
            theme: {
              fill: '#fff',
              stroke: '#BBC2C6',
              r: 2,
              states: {
                hover: {
                  fill: '#BBC2C6',
                  stroke: '#BBC2C6',
                  style: {
                    color: '#fff',
                    cursor: 'pointer',
                  },
                },
              },
            },
          },
          events: {
            load() {
              this.myTooltip = new Highcharts.Tooltip(this, this.options.tooltip)
            },
            click() {
              this.myTooltip.hide()
            },
          },
        },
        credits: { enabled: false },
        legend: { enabled: false },
        title: {
          text: title,
        },
        lang: {
          noData: isLoading ? '' : i18n.t('DASHBOARD.DATA_NOT_AVAILABLE_FOR_PERIOD'),
        },
        noData: {
          style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030',
          },
        },
        plotOptions: {
          scatter: {
            marker: {
              radius: 4,
              states: {
                hover: {
                  enabled: true,
                  lineColor: 'rgb(100,100,100)',
                },
              },
            },
            states: {
              hover: {
                marker: {
                  enabled: false,
                },
              },
            },
          },
          series: {
            turboThreshold: 0,
            shadow: false,
            events: {
              click(evt) {
                this.chart.myTooltip.options.enabled = true
                this.chart.myTooltip.refresh(evt.point, evt)
                this.chart.myTooltip.options.enabled = false
              },
            },
          },
        },
        tooltip: {
          borderRadius: 4,
          borderColor: '#BBC2C6',
          style: {
            padding: '0',
            pointerEvents: 'auto',
          },
          useHTML: true,
          enabled: false,
          formatter() {
            const { point } = this
            return ReactDOMServer.renderToStaticMarkup(
              <Tooltip
                photoSrc={point.photo}
                userFullName={point.name}
                userEmail={point.email}
                teamsFunded={point.teamsFunded}
                successInvestments={point.investmentsSuccess}
                success={point.y}
                participation={point.x}
                likedComments={point.commentsLiked}
                investments={point.investments}
                ideas={point.ideas}
                funded={point.funded}
                comments={point.comments}
                teams={point.teams}
              />,
            )
          },
          hideDelay: 5,
        },
        xAxis: {
          title: {
            text: i18n.t('DASHBOARD.PARTICIPATION'),
            style: {
              color: '#9E9E9E',
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            },
          },
          min: 0,
          max: 100,
          tickInterval: 10,
          gridLineColor: '#DCDCDC',
          gridLineDashStyle: 'shortdash',
          lineColor: '#9E9E9E',
          lineWidth: 1,
          gridLineWidth: 1,
          tickColor: '#fff',
          labels: {
            style: {
              color: '#9E9E9E',
              fontWeight: 'bold',
              fontSize: '12px',
            },
          },
          plotLines: [
            {
              value: 50,
              color: '#9D9D9D',
              width: 1,
              zIndex: 2,
            },
            {
              value: 100,
              color: '#fff',
              width: 2,
              zIndex: 2,
            },
          ],
        },
        yAxis: {
          title: {
            text: i18n.t('DASHBOARD.SUCCESS_LEVEL'),
            style: {
              color: '#9E9E9E',
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            },
          },
          min: 0,
          max: 100,
          tickInterval: 10,
          gridLineColor: '#DCDCDC',
          gridLineDashStyle: 'shortdash',
          lineColor: '#9E9E9E',
          lineWidth: 1,
          labels: {
            style: {
              color: '#9E9E9E',
              fontWeight: 'bold',
              fontSize: '12px',
            },
          },
          plotLines: [
            {
              value: 50,
              color: '#9D9D9D',
              width: 1,
              zIndex: 2,
            },
            {
              value: 100,
              color: '#fff',
              width: 2,
              zIndex: 2,
            },
          ],
        },
        series,
      },
    }
    this.handleChartCreated = this.handleChartCreated.bind(this)
  }

  componentDidMount() {
    const { isLoading } = this.props
    if (this.chartRef) {
      if (isLoading) {
        this.chartRef.showLoading()
      } else {
        this.chartRef.hideLoading()
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { isLoading, series } = this.props
    const { options } = this.state

    if (prevProps.isLoading !== isLoading && isLoading) {
      this.setState({
        options: {
          ...options,
          lang: {
            noData: '',
          },
        },
      })
      this.chartRef.showLoading()
    }
    if (prevProps.isLoading !== isLoading && !isLoading) {
      this.setState({
        options: {
          ...options,
          lang: {
            noData: i18n.t('DASHBOARD.DATA_NOT_AVAILABLE_FOR_PERIOD'),
          },
        },
      })
      this.chartRef.hideLoading()
    }
    if (prevProps.series !== series) {
      this.setState({
        options: {
          ...options,
          series,
        },
      })
    }
  }

  handleChartCreated(chartReference) {
    this.chartRef = chartReference
  }

  render() {
    const { classes, totalUsers, participationAvg, successAvg } = this.props
    const { options } = this.state
    return (
      <Fragment>
        <div className={classes.totalsInfo}>
          <div>
            <span className={classes.totalUsers}>{totalUsers}</span>
            <span>{i18n.t('DASHBOARD.QUADRANT_TOTAL_USERS')}</span>
          </div>
          <div>
            <span className={classes.infoParticipationAvg}>
              {participationAvg.split('.')[0]},
              <span className={classes.decimals}>{participationAvg.split('.')[1]}%</span>
            </span>
            <span>{i18n.t('DASHBOARD.QUADRANT_AVG')}</span>
          </div>
          <div>
            <span className={classes.infoSuccessAvg}>
              {successAvg.split('.')[0]},
              <span className={classes.decimals}>{successAvg.split('.')[1]}%</span>
            </span>
            <span>{i18n.t('DASHBOARD.QUADRANT_AVG_SUCCESS')}</span>
          </div>
        </div>
        <div className={classes.zoomInfo}>
          <span className="icon-zoom-in" />
          <span>{i18n.t('DASHBOARD.DRAW_TO_ZOOM')}</span>
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          callback={this.handleChartCreated}
        />
      </Fragment>
    )
  }
}

ParticipationSuccessScatterChart.defaultProps = {
  title: '',
  isLoading: false,
}

ParticipationSuccessScatterChart.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  series: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalUsers: PropTypes.number.isRequired,
  participationAvg: PropTypes.string.isRequired,
  successAvg: PropTypes.string.isRequired,
}

export default withStyles(styles)(ParticipationSuccessScatterChart)

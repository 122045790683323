import React from 'react'
import { Radio, withStyles } from '@material-ui/core'
import styles from './View.Styles'

const NxtRadio = ({ classes, value }) => {
  return (
    <Radio
      className={classes.root}
      color="default"
      disableRipple
      disableFocusRipple
      value={value}
      icon={<span className={classes.radioNotChecked} />}
      checkedIcon={<span className="icon-approved" />}
    />
  )
}

export default withStyles(styles)(NxtRadio)

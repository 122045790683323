import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    submitterLinkButton: {
      fontSize: 'inherit',
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(0.625),
    },
    submitterSpan: {
      fontSize: 'inherit',
      marginLeft: theme.spacing(0.6),
    },
    borderRight: {
      borderRight: '1px',
      borderRightColor: '#666',
      borderRightStyle: 'solid',
    },
    colorLight: {
      color: 'black',
    },
    colorBlack: {
      color: 'white',
    },
    container: {
      width: '32%',
      position: 'relative',
    },
    graph: {
      position: 'absolute',
      top: '-20px',
      right: 0,
    },
    headerBlock: {
      width: '100%',
      position: 'relative',
      paddingTop: '1rem',
    },
    headerBlockBlack: {
      color: 'white',
    },
    investors: {
      display: 'flex',
      flexFlow: 'column nowrap',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    img: {
      width: '100%',
      height: '12rem',
      display: 'block',
      overflow: 'hidden',
      objectFit: 'cover',
    },
    list: {
      padding: '0.75rem',
      height: 'auto',
      margin: 0,
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      listStyle: 'none',
      '& > li': {
        borderBottom: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#9a9a9a',
        padding: '0.8rem 0',
      },
      '& > li:first-child': {
        paddingTop: 0,
      },
      '& > li:last-child': {
        borderBottom: 0,
        paddingBottom: 0,
      },
    },
    listLight: {
      backgroundColor: '#f0f0f0',
      color: 'black',
    },
    listDark: {
      backgroundColor: 'unset',
      color: 'white',
    },
    paper: {
      padding: '0.7rem',
      height: '28rem',
      boxSizing: 'border-box',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: props =>
        props.onGoToButtonClick ? '280px 30px 1fr 80px' : '280px 30px 1fr 32px',
      gridTemplateAreas: '"header" "info" "list" "button"',
    },
    paperBlack: {
      backgroundColor: '#333',
    },
    gridButton: {
      gridArea: 'button',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
      '& i': {
        marginRight: '0.2rem',
      },
    },
    gridHeader: {
      gridArea: 'header',
    },
    gridInfo: {
      gridArea: 'info',
    },
    gridList: {
      gridArea: 'list',
    },
    pledged: {
      display: 'flex',
      flexFlow: 'column nowrap',
      width: '60%',
      '& > div': {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        '& > i': {
          fontSize: '1rem',
        },
        '& > span': {
          fontSize: '1.2rem',
          fontWeight: 'bold',
        },
      },
      '& > span': {
        fontSize: '0.75rem',
      },
    },
    relative: {
      position: 'relative',
    },
    subText: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      '& p': {
        fontSize: '12px',
        lineHeight: '16px',
        margin: 0,
      },
    },
    subTextLeft: {
      width: '75%',
    },
    subTextRight: {
      textAlign: 'right',
      width: '25%',
      paddingRight: theme.spacing(1.5),
    },
    textBlock: {
      width: '60%',
    },
    title: {
      height: '54px',
      fontSize: '18px',
      lineHeight: '18px',
      margin: 0,
      marginBottom: '0.5rem',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  })

export default styles

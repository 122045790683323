import i18n from 'support/i18n'

export const timelineConceptMap = {
  DELETE_COMMENT: i18n.t('TIMELINE.COMMENT_DELETE'),
  NEW_USER: i18n.t('TIMELINE.USER_JOINED'),
  NEW_COMMENT: i18n.t('TIMELINE.NEW_COMMENT'),
  INVESTMENT: i18n.t('TIMELINE.INVESTMENT'),
  ADMIN_REFUND: i18n.t('TIMELINE.ADMIN_REFUND'),
  ADMIN_WITHDRAW: i18n.t('TIMELINE.ADMIN_WITHDRAW'),
  ADMIN_SET_FUNDS: i18n.t('TIMELINE.ADMIN_SET_FUNDS'),
  BADGE_WIN: i18n.t('TIMELINE.BADGE_WIN'),
  CONCEPT_INVEST_REWARD: i18n.t('TIMELINE.INVEST_REWARD'),
  IDEA_SUPPORTED: i18n.t('TIMELINE.IDEA_SUPPORTED'),
  IDEA_APROVED: i18n
    .t('TIMELINE.IDEA_APPROVED')
    .replace('@@IDEA_STATUS.APPROVED_BY_IT@@', i18n.t('IDEA_STATUS.APPROVED_BY_IT')),
  IDEA_PROTOTYPED: i18n
    .t('TIMELINE.IDEA_PROTOTYPED')
    .replace('@@IDEA_STATUS.PILOT_ACTIVE@@', i18n.t('IDEA_STATUS.PILOT_ACTIVE')),
  PUBLISH_IDEA: i18n.t('TIMELINE.PUBLISH_IDEA'),
  USE_LOSE_IT: i18n.t('TIMELINE.USE_LOSE_IT'),
}

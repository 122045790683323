import { createStyles } from '@material-ui/core'

const parseColor = (theme, color) => {
  switch (color) {
    case 'primary': {
      return theme.palette.primary.main
    }
    case 'secondary': {
      return theme.palette.secondary.main
    }
    default: {
      return theme.palette.common.black
    }
  }
}

const styles = theme =>
  createStyles({
    linkButton: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      // textDecoration: 'underline',
      display: 'inline',
      margin: '0',
      padding: '0',
      '&:hover, &:focus': {
        // textDecoration: 'underline',
        outline: 'none',
      },
      color: props => parseColor(theme, props.color),
    },
    flat: {
      fontWeight: '700',
      textDecoration: 'none',
      fontSize: theme.spacing(1.75),
    },
  })

export default styles

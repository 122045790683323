const styles = theme => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: theme.spacing(1.75),
  },
  withError: {
    color: theme.palette.error.main,
  },
})

export default styles

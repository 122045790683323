const styles = theme => ({
  container: {
    padding: '4%',
    width: 350,
    '& p': {
      textAlign: 'right',
      fontSize: 12,
    },
  },
  list: {
    overflow: 'auto',
    height: 340,
    width: '370px',
  },
  photo: {
    height: 35,
    width: 35,
    borderRadius: '100%',
    marginRight: theme.spacing(1),
  },
  link: {
    display: 'flex',
    fontSize: 14,
    width: 100,
    textTransform: 'none',
    textDecoration: 'underline',
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      justifyContent: 'unset',
      display: 'unset',
      textAlign: 'left',
    },
    '&:hover': {
      color: '#0088cc',
      backgroundColor: 'transparent',
    },
  },
  row: {
    borderTop: 1,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 10,
    height: 70,
    width: 350,
  },
  best: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 45,
  },
  iconTitle: {
    fontSize: 28,
    marginRight: 15,
  },
  iconTop: {
    fontSize: 25,
    marginRight: 5,
  },
  icon: {
    marginRight: 10,
  },
  description:{
    textAlign: "left !important",
  }
})

export default styles

import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import styles from './View.Style'

const SuggestionTitles = props => {
  const { classes, mainLabel, secondaryLabel, hasError } = props

  return (
    <div className={clsx(classes.root, hasError && classes.withError)}>
      <Typography variant="h6" dangerouslySetInnerHTML={{ __html: mainLabel }} />
      <p className={classes.subtitle}>{secondaryLabel}</p>
    </div>
  )
}

SuggestionTitles.defaultProps = {
  hasError: false,
}
SuggestionTitles.propTypes = {
  mainLabel: PropTypes.string.isRequired,
  secondaryLabel: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
}
export default withStyles(styles)(SuggestionTitles)

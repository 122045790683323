const styles = () => ({
  root: {},
  title: {},
  body: {},
  badgeDescription: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
  },
  badge: {
    width: '84px',
    height: '84px',
  },
})

export default styles

const parseInvestmentIdeas = data => {
  return data.map(row => {
    const { id, images, status, title,
      text, 
      pledged, 
      investors, } = row.idea
    const imageUrl =
      typeof images !== 'undefined' && images.length > 0 && typeof images[0].url !== 'undefined'
        ? images[0].url
        : ''
    // console.log('HEREHERRE')
    // console.log(row.idea)
    return {
      id,
      invested: row.invested || 0,
      image: imageUrl,
      profit: row.profit || 0,
      status,
      title,
      images,
      textWithoutTags: text.replace(/<\/?[^>]+(>|$)/g, ""), 
      pledged, 
      investors,
    }
  })
}

const parseTotalInvestedFromData = data => {
  return data.reduce(
    (accumulator, currentValue) => ({
      invested: accumulator.invested + currentValue.invested,
    }),
    { invested: 0 },
  ).invested
}

const parseTotalProfitFromData = data => {
  return data.reduce(
    (accumulator, currentValue) => ({
      profit: accumulator.profit + currentValue.profit,
    }),
    { profit: 0 },
  ).profit
}

const parseUserLastName = (name, fullName) => {
  if (!name || !fullName) return ''
  return fullName.replace(name, '').trim()
}

export const transformGetInvestmentsServiceResponseDataWithState = jsonResponse => {
  return {
    ideas: parseInvestmentIdeas(jsonResponse.data),
    totalInvested: parseTotalInvestedFromData(jsonResponse.data),
    totalProfit: parseTotalProfitFromData(jsonResponse.data),
  }
}


export const transformFilterInvestmentsIdeasServiceResponseDataWithState = jsonResponse => {
  return {
    ideas: parseInvestmentIdeas(jsonResponse.data),
    totalInvested: parseTotalInvestedFromData(jsonResponse.data),
    totalProfit: parseTotalProfitFromData(jsonResponse.data),
  }
}

export const transformEditProfileServiceResponseDataWithState = jsonResponse => {
  return {
    userName: jsonResponse.name,
    userLastName: parseUserLastName(jsonResponse.name, jsonResponse.fullname),
    userPhoto: jsonResponse.photo,
  }
}

export const transformUserPrefsServiceResponseWithState = jsonResponse => ({
  challenge: jsonResponse.challenge,
  ideastatus: jsonResponse.ideastatus,
  invest: jsonResponse.invest,
  newcoment: jsonResponse.newcoment,
  newidea: jsonResponse.newidea,
  splash: jsonResponse.splash,
  week: jsonResponse.week,
})

import i18n from 'support/i18n'
import { MESSAGE_TYPES } from 'components/auth_message_box/View.Utils'

export const parseDomainInfo = domainInfo => {
  return domainInfo.map(domain => {
    return {
      avatarUrl: domain.userPhoto,
      domain: domain.account,
      logoUrl: domain.logoUrl,
      nextinitName: domain.domainName,
      userName: domain.name,
      email: domain.email,
    }
  })
}

export const transformDomainServiceResponseDataWithState = jsonResponse => ({
  domainInfo: parseDomainInfo(jsonResponse.userDomains),
  sid: jsonResponse.SID,
  csrf: jsonResponse['nxt-csrf'],
})

export const transformRecoverPasswordResponse = () => ({
  messageType: MESSAGE_TYPES.SUCCESS,
  messageText: i18n.t('COMMON_LOGIN.HEADER.FORGOT_PASSWORD_EMAIL_SENT'),
})

import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { Avatar, TableCell, TableRow } from '@material-ui/core'
import { format } from 'date-fns'
import Image from 'components/image/View'
import defaultImg from 'img/default-photo.png'
import ButtonLink from 'components/button_link/View'
import i18n from 'support/i18n'

const TableBody = props => {
  const { bodyRows, onClick, showFilters } = props

  const parseStatus = status => {
    switch (status) {
      case 'APPROVED_BY_IT': {
        return i18n.t('IDEA_STATUS.APPROVED_BY_IT')
      }
      case 'DELETED': {
        return i18n.t('IDEA_STATUS.DELETED')
      }
      case 'DISCARDED': {
        return i18n.t('IDEA_STATUS.DISCARDED')
      }
      case 'IMPLEMENTED': {
        return i18n.t('IDEA_STATUS.IMPLEMENTED')
      }
      case 'PENDING_SUPPORT': {
        return i18n.t('IDEA_STATUS.PENDING_SUPPORT')
      }
      case 'PILOT_ACTIVE': {
        return i18n.t('IDEA_STATUS.PILOT_ACTIVE')
      }
      case 'ROUND_CLOSED': {
        return i18n.t('IDEA_STATUS.ROUND_CLOSED')
      }
      case 'SUPPORTED': {
        return i18n.t('IDEA_STATUS.SUPPORTED')
      }
      default: {
        return 'STATUS'
      }
    }
  }

  return (
    <Fragment>
      {bodyRows.length > 0 &&
        bodyRows.map(row => {
          return (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
              <TableCell align="center" scope="row">
                <Avatar onClick={() => onClick(row.id)}>
                  <Image
                    style={{ width: '100%' }}
                    defaultSrc={defaultImg}
                    src={(row.images[0] && row.images[0].url) || ''}
                  />
                </Avatar>
              </TableCell>

              <TableCell align="left">
                <ButtonLink color="primary" flat onLinkClick={() => onClick(row.id)}>
                  {row.title}
                </ButtonLink>
              </TableCell>

              {!showFilters && (
                <TableCell align="left">
                  <span>{parseStatus(row.status)}</span>
                </TableCell>
              )}

              <TableCell align="right">{format(new Date(row.created), 'dd/MM/yyyy')}</TableCell>
              <TableCell align="right">{row.pledged}%</TableCell>
            </TableRow>
          )
        })}
      {bodyRows.length === 0 && (
        <TableRow hover tabIndex={-1}>
          <TableCell align="left" />

          <TableCell align="left" scope="row">
            {i18n.t('PEOPLE_SELECTOR.NO_RESULTS')}
          </TableCell>

          {!showFilters && <TableCell align="left" />}
          <TableCell align="right" />
          <TableCell align="right" />
        </TableRow>
      )}
    </Fragment>
  )
}

TableBody.propTypes = {
  bodyRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default TableBody

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Snackbar from '@material-ui/core/Snackbar'
import { MySnackbarContentWrapper } from 'components'
import Popups from './ModalDataService'

class ModalAlertControllerView extends Component {
  constructor(props) {
    super(props)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleClosedDialog = this.handleClosedDialog.bind(this)
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this)
  }

  handleCloseDialog() {
    const { hideDialog } = this.props
    hideDialog()
  }

  handleClosedDialog() {
    const { resetDialog } = this.props
    resetDialog()
  }

  handleCloseSnackbar() {
    const { hideSnackbar } = this.props
    hideSnackbar()
  }

  render() {
    const { children, showedDialogs, showSnackbar } = this.props
    const fullViews = Popups.getDialogFullViews()
    const snackbarData = Popups.getSnackbarData()
    return (
      <div style={{ flex: 1, backgroundColor: 'transparent' }}>
        {fullViews.map((item, index) => (
          <Dialog
            key={`modal${fullViews.indexOf(item)}`}
            open={index < showedDialogs}
            onClose={this.handleCloseDialog}
            onExited={this.handleClosedDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={false}
            disableBackdropClick
            disableEscapeKeyDown
          >
            {item}
          </Dialog>
        ))}

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={this.handleCloseSnackbar}
        >
          <MySnackbarContentWrapper
            onClose={this.handleCloseSnackbar}
            variant={snackbarData.type}
            message={snackbarData.message}
          />
        </Snackbar>

        <div style={{ flex: 1, backgroundColor: 'transparent' }}>{children}</div>
      </div>
    )
  }
}

ModalAlertControllerView.propTypes = {
  showedDialogs: PropTypes.number.isRequired,
  showSnackbar: PropTypes.bool.isRequired,
  hideDialog: PropTypes.func.isRequired,
  resetDialog: PropTypes.func.isRequired,
  hideSnackbar: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default ModalAlertControllerView

const styles = theme => ({
  root: {
    height: theme.spacing(25),
  },
  groupsList: {
    height: '100%',
    width: '100%',
  },
})

export default styles

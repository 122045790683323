import React from 'react'

const Error = () => {
  return (
    <div>
      {/* <h1>Error</h1>
      <p>UPS! You are in Error page. Sorry :(</p> */}
       <center>
        <h1 >404</h1>
        <h2>Page not Found</h2>
        <p>UPS! You are in Error page. Sorry :(</p>
      </center>
    </div>
  )
}

export default Error

import { ServiceCall } from '../../support/network/ServiceCallAction'
import {
  HELP_SECTION_FAILURE,
  HELP_SECTION_STARTED,
  HELP_SECTION_SUCCESS,
  RANKING_FAILURE,
  RANKING_STARTED,
  RANKING_SUCCESS,
  HEADER_SECTION_STARTED,
  HEADER_SECTION_SUCCESS,
  HEADER_SECTION_FAILURE,
  HELP_SECTION_CONTRY_TEST_SUCCESS,
  HELP_SECTION_CONTRY_TEST_STARTED,
  HELP_SECTION_CONTRY_TEST_FAILURE
} from '../../actions/sync/explore/exploreActionConstans'
import { prepareHeadersFromState } from '../HeadersUtils'
import {
  transformHelpSectionServiceResponseDataWithState,
  transformRankingServiceResponseDataWithState,
  transformHeaderSectionServiceResponseDataWithState,
  transformHelpTestSectionServiceResponseDataWithState,
} from './ResponseUtils'

export const HelpSectionUrl = '/s/domain/helpSections'
export const RankingUrl = '/s/ranking'
export const HeaderSectionUrl = '/s/domain/getHeaderSection'

export const apiResponseCountry = 'https://api.eris.vodafone.com/erisdemo/country'

export const apiResponseCountryIN = 'https://api.eris.vodafone.com/erisdemo/country/IN'

export const apiResponseEris = 'https://api.eris.vodafone.com/'

export function getApiResponseEris() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = HELP_SECTION_CONTRY_TEST_STARTED
  apiCall.successReqType = HELP_SECTION_CONTRY_TEST_SUCCESS
  apiCall.failureReqType = HELP_SECTION_CONTRY_TEST_FAILURE
  apiCall.setEndpointFromState = () => apiResponseEris
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformHelpTestSectionServiceResponseDataWithState
  return apiCall.getAction()
}

export function getTestCountrySection() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = HELP_SECTION_CONTRY_TEST_STARTED
  apiCall.successReqType = HELP_SECTION_CONTRY_TEST_SUCCESS
  apiCall.failureReqType = HELP_SECTION_CONTRY_TEST_FAILURE
  apiCall.setEndpointFromState = () => apiResponseCountry
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformHelpTestSectionServiceResponseDataWithState
  return apiCall.getAction()
}

export function getTestCountryINSection() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = HELP_SECTION_CONTRY_TEST_STARTED
  apiCall.successReqType = HELP_SECTION_CONTRY_TEST_SUCCESS
  apiCall.failureReqType = HELP_SECTION_CONTRY_TEST_FAILURE
  apiCall.setEndpointFromState = () => apiResponseCountryIN
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformHelpTestSectionServiceResponseDataWithState
  return apiCall.getAction()
}


export function getHelpSection() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = HELP_SECTION_STARTED
  apiCall.successReqType = HELP_SECTION_SUCCESS
  apiCall.failureReqType = HELP_SECTION_FAILURE
  apiCall.setEndpointFromState = () => HelpSectionUrl
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformHelpSectionServiceResponseDataWithState
  return apiCall.getAction()
}

export function getRankings() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = RANKING_STARTED
  apiCall.successReqType = RANKING_SUCCESS
  apiCall.failureReqType = RANKING_FAILURE
  apiCall.setEndpointFromState = () => RankingUrl
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformRankingServiceResponseDataWithState
  return apiCall.getAction()
}

export function getHeaderSection() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = HEADER_SECTION_STARTED
  apiCall.successReqType = HEADER_SECTION_SUCCESS
  apiCall.failureReqType = HEADER_SECTION_FAILURE
  apiCall.setEndpointFromState = () => HeaderSectionUrl
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformHeaderSectionServiceResponseDataWithState
  return apiCall.getAction()
}

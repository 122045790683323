import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { ButtonLink, Image } from 'components'
import i18n from 'support/i18n'
import DataProtection from 'components/data_protection/View'
import UserDetailsForm from './components/user_details_form/View'
import SignUpForm from './components/sign_up_form/View'
import SignUpComplete from './components/sign_up_complete/View'
import styles from './View.Style'

const SignUp = props => {
  const {
    classes,
    onSubmit,
    useCreateAccount,
    useCustomColor,
    customColor,
    useCustomTextColor,
    customTextColor,
    useCustomImg,
    customImg,
    useCustomText,
    customText,
    useCustomBgImg,
    customBgImg,
    useCustomLogin,
    messageType,
    messageText,
    isSigning,
    setAuthMessageBox,
    resetAuthMessageBox,
    isUserAlreadyExisting,
    onForgotPasswordClick,
    signUpStep,
    isSavingUserDetails,
    onSaveUserDetails,
    onImageSelected,
    onClose,
    onLoad,
  } = props
  const [email, setEmail] = React.useState('')
  const [isEmailFixed, setEmailFixed] = React.useState(false)

  useEffect(() => {
    onLoad()
    const queryParams = new URLSearchParams(window.location.search)
    const emailParam = queryParams.get('email')
    setEmailFixed(!!emailParam)
    if (emailParam) setEmail(emailParam)
    resetAuthMessageBox()
  }, [])

  function renderUserAlreadyExisting() {
    return (
      <div>
        <span>{i18n.t('COMMON_LOGIN.HEADER.USER_ALREADY_EXISTING')}</span>
        <ButtonLink
          classes={{ linkButton: classes.forgotPassword }}
          onLinkClick={() => onForgotPasswordClick(email)}
        >
          {i18n.t('COMMON_LOGIN.HEADER.FORGOT_PWD')}
        </ButtonLink>
      </div>
    )
  }

  return (
    <div className={classes.base}>
      <div className={classes.customize}>
        {useCustomImg && customImg && (
          <Image defaultSrc="" src={customImg} alt="custom company logo" />
        )}
        {useCustomText && customText && <h1>{customText}</h1>}
      </div>
      <div className={classes.signUpContainer}>
        {signUpStep === 0 && (
          <SignUpForm
            email={email}
            onEmailChange={setEmail}
            setAuthMessageBox={setAuthMessageBox}
            isSigning={isSigning}
            messageType={messageType}
            messageText={isUserAlreadyExisting ? renderUserAlreadyExisting() : messageText}
            onSubmit={onSubmit}
            useCreateAccount={useCreateAccount}
            useCustomColor={useCustomColor}
            customColor={customColor}
            useCustomTextColor={useCustomTextColor}
            customTextColor={customTextColor}
            useCustomImg={useCustomImg}
            customImg={customImg}
            useCustomText={useCustomText}
            customText={customText}
            useCustomBgImg={useCustomBgImg}
            customBgImg={customBgImg}
            useCustomLogin={useCustomLogin}
            isEmailFixed={isEmailFixed}
          />
        )}
        {signUpStep === 1 && (
          <UserDetailsForm
            useCustomColor={useCustomColor}
            customColor={customColor}
            useCustomTextColor={useCustomTextColor}
            customTextColor={customTextColor}
            useCustomImg={useCustomImg}
            customImg={customImg}
            useCustomText={useCustomText}
            customText={customText}
            useCustomBgImg={useCustomBgImg}
            customBgImg={customBgImg}
            useCustomLogin={useCustomLogin}
            setAuthMessageBox={setAuthMessageBox}
            isSavingUserDetails={isSavingUserDetails}
            messageType={messageType}
            messageText={isUserAlreadyExisting ? renderUserAlreadyExisting() : messageText}
            onSubmit={onSaveUserDetails}
            onImageSelected={onImageSelected}
            onClose={onClose}
          />
        )}
        {signUpStep === 2 && <SignUpComplete />}
        <div className={classes.dataProtectionContainer}>
          <DataProtection />
        </div>
      </div>
    </div>
  )
}

SignUp.defaultProps = {
  useCreateAccount: false,
  useCustomColor: false,
  customColor: '',
  useCustomTextColor: false,
  customTextColor: '',
  useCustomImg: false,
  customImg: '',
  useCustomText: false,
  customText: '',
  useCustomBgImg: false,
  customBgImg: '',
  useCustomLogin: false,
}

SignUp.propTypes = {
  signUpStep: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSaveUserDetails: PropTypes.func.isRequired,
  setAuthMessageBox: PropTypes.func.isRequired,
  resetAuthMessageBox: PropTypes.func.isRequired,
  useCreateAccount: PropTypes.bool,
  useCustomColor: PropTypes.bool,
  customColor: PropTypes.string,
  useCustomTextColor: PropTypes.bool,
  customTextColor: PropTypes.string,
  useCustomImg: PropTypes.bool,
  customImg: PropTypes.string,
  useCustomText: PropTypes.bool,
  customText: PropTypes.string,
  useCustomBgImg: PropTypes.bool,
  customBgImg: PropTypes.string,
  useCustomLogin: PropTypes.bool,
  isSigning: PropTypes.bool.isRequired,
  isUserAlreadyExisting: PropTypes.bool.isRequired,
  isSavingUserDetails: PropTypes.bool.isRequired,
  messageType: PropTypes.number.isRequired,
  messageText: PropTypes.node.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default withStyles(styles)(SignUp)

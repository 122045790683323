import { ServiceCall } from 'support/network/ServiceCallAction'
import * as howItWorks from 'actions/sync/how_it_works/Constants'
import {
  GET_DOMAIN_LOGIN_INFO_FAILURE,
  GET_DOMAIN_LOGIN_INFO_STARTED,
  GET_DOMAIN_LOGIN_INFO_SUCCESS,
} from 'actions/sync/domain/Constants'
import {
  transformApprovedReasonsResponseDataWithState,
  transformConfigResponseDataWithState,
  transformFullInfoResponseDataWithState,
  transformRejectedReasonsResponseDataWithState,
  transformInfoPointsResponse,
} from './ResponseUtils'
import { prepareHeadersFromState } from '../HeadersUtils'
import {
  APPROVED_REASONS_FAILURE,
  APPROVED_REASONS_STARTED,
  APPROVED_REASONS_SUCCESS,
  CONFIG_FAILURE,
  CONFIG_STARTED,
  CONFIG_SUCCESS,
  FULL_INFO_FAILURE,
  FULL_INFO_STARTED,
  FULL_INFO_SUCCESS,
  REJECT_REASONS_FAILURE,
  REJECT_REASONS_STARTED,
  REJECT_REASONS_SUCCESS,
  WHITELIST_DOMAIN_FAILURE,
  WHITELIST_DOMAIN_STARTED,
  WHITELIST_DOMAIN_SUCCESS,

} from '../TypesConstants'
import { setWhitelistDomainBodyFromState } from './RequestUtils'

export const FULL_INFO_URL = '/s/domain/fullinfo'
export const CONFIG_URL = '/s/domain/conf'
export const REJECT_REASONS_URL = '/s/domain/reasons'
export const APPROVED_REASONS_URL = '/s/domain/approveReasons'
export const NOTIFICATION_URL = '/s/v1.0/notifications/me'
const POINTS_INFO_URL = '/s/domain/infosteps'
const GET_DOMAIN_LOGIN_INFO_URL = '/b/domainLoginInfo/##domain##'
const WHITELIST_DOMAIN_URL = 's/domain/whitelist/domain/add'


export function afterLoginWhiteListDomainService(userEmail,requestForWhiteListDomain,onSuccess,onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = WHITELIST_DOMAIN_STARTED
  apiCall.successReqType = WHITELIST_DOMAIN_SUCCESS
  apiCall.failureReqType = WHITELIST_DOMAIN_FAILURE
  apiCall.setEndpointFromState = () => WHITELIST_DOMAIN_URL
  apiCall.method = 'PUT'
  apiCall.auth = true
  apiCall.setBodyFromState = () => setWhitelistDomainBodyFromState(userEmail, requestForWhiteListDomain)
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}


export function fullInfoService(onSuccess = () => {}) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = FULL_INFO_STARTED
  apiCall.successReqType = FULL_INFO_SUCCESS
  apiCall.failureReqType = FULL_INFO_FAILURE
  apiCall.setEndpointFromState = () => FULL_INFO_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformFullInfoResponseDataWithState
  apiCall.onSuccess = onSuccess
  return apiCall.getAction()
}

export function configService() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = CONFIG_STARTED
  apiCall.successReqType = CONFIG_SUCCESS
  apiCall.failureReqType = CONFIG_FAILURE
  apiCall.setEndpointFromState = () => CONFIG_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformConfigResponseDataWithState
  return apiCall.getAction()
}

export function getRejectedReasons() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = REJECT_REASONS_STARTED
  apiCall.successReqType = REJECT_REASONS_SUCCESS
  apiCall.failureReqType = REJECT_REASONS_FAILURE
  apiCall.setEndpointFromState = () => REJECT_REASONS_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformRejectedReasonsResponseDataWithState
  return apiCall.getAction()
}

export function getApprovedReasons() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = APPROVED_REASONS_STARTED
  apiCall.successReqType = APPROVED_REASONS_SUCCESS
  apiCall.failureReqType = APPROVED_REASONS_FAILURE
  apiCall.setEndpointFromState = () => APPROVED_REASONS_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformApprovedReasonsResponseDataWithState
  return apiCall.getAction()
}

export function getHowItWorksInfoPointsService() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = howItWorks.POINTS_INFO_STARTED
  apiCall.successReqType = howItWorks.POINTS_INFO_SUCCESS
  apiCall.failureReqType = howItWorks.POINTS_INFO_FAILURE
  apiCall.setEndpointFromState = () => POINTS_INFO_URL
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformInfoPointsResponse
  return apiCall.getAction()
}

export function getDomainLoginInfo(domain) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_DOMAIN_LOGIN_INFO_STARTED
  apiCall.successReqType = GET_DOMAIN_LOGIN_INFO_SUCCESS
  apiCall.failureReqType = GET_DOMAIN_LOGIN_INFO_FAILURE
  apiCall.setEndpointFromState = () => GET_DOMAIN_LOGIN_INFO_URL.replace('##domain##', domain)
  apiCall.method = 'GET'
  return apiCall.getAction()
}

import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, TextField } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'

const FilterByText = props => {
  const { classes, onFilterByText } = props
  const [value, setValue] = useState('')

  const handleSubmit = event => {
    event.preventDefault()
    onFilterByText(value)
  }

  return (
    <form noValidate autoComplete="off" className={classes.container} onSubmit={handleSubmit}>
      <TextField
        className={classes.textField}
        margin="dense"
        label={i18n.t('DASHBOARD.FILTER_USER')}
        variant="outlined"
        value={value}
        onChange={event => setValue(event.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        {i18n.t('SEARCH')}
      </Button>
    </form>
  )
}

FilterByText.propTypes = {
  onFilterByText: PropTypes.func.isRequired,
}

export default withStyles(styles)(FilterByText)

import desktop from 'img/desktop.svg'
import mobile from 'img/mobile.svg'
import ios from 'img/ios.svg'
import android from 'img/android.svg'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  firstRow: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderBottom: 'solid 1px #ddd',
    '& > div:first-child': {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '75%',
      padding: theme.spacing(1.5, 0),
    },
    '& > div:last-child': {
      padding: theme.spacing(1.5, 0),
      width: '25%',
      borderLeft: 'dashed 1px #ddd',
    },
  },
  device: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  secondRow: {
    display: 'flex',
    padding: theme.spacing(1.5, 0),
  },
  firstColumn: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: 'dashed 1px #ddd',
    width: '50%',
  },
  secondColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  iconForm: {
    position: 'relative',
    '& > div': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  desktopShape: {
    width: '89px',
    height: '78px',
    '& > div:first-child': {
      background: `url(${desktop}) no-repeat`,
    },
  },
  mobileShape: {
    width: '43px',
    height: '72px',
    '& > div:first-child': {
      background: `url(${mobile}) no-repeat`,
    },
  },
  iosShape: {
    width: '36px',
    height: '43px',
    '& > div:first-child': {
      background: `url(${ios}) no-repeat`,
    },
  },
  androidShape: {
    width: '36px',
    height: '43px',
    '& > div:first-child': {
      background: `url(${android}) no-repeat`,
    },
  },
  shape: {
    backgroundSize: 'cover',
    zIndex: '2',
  },
  fill: {
    backgroundColor: '#0096e2',
    zIndex: '1',
  },
  background: {
    backgroundColor: '#ccc',
    zIndex: '0',
  },
  statisticRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
    '& > span:first-child': {
      fontSize: '28px',
      width: '70px',
      textAlign: 'right',
      fontWeight: '600',
    },
    '& > span:last-child': {
      padding: theme.spacing(0, 1),
      fontSize: '12px',
      width: '88px',
      textAlign: 'left',
    },
  },
  bigNumber: {
    fontWeight: 600,
    fontSize: '32px',
    color: '#0096e2',
  },
  smallNumber: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#0096e2',
  },
  description: {
    fontSize: '12px',
  },
  firstItem: {
    color: '#ffcd40',
  },
  secondItem: {
    color: '#9e9e9e',
  },
  thirdItem: {
    color: '#63ffe4',
  },
  fourthItem: {
    color: '#fa5d7f',
  },
  fifthItem: {
    color: '#d19600',
  },
  sixthItem: {
    color: '#00d24c',
  },
})

export default styles

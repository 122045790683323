import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Typography, Divider } from '@material-ui/core'
import i18n from 'support/i18n'
import AutoSuggestion from 'components/autosuggestion/View'
import SuggestionSelected from 'components/suggestion_selected/View'
import styles from './View.Style'
import SuggestionTitles from './components/suggestion_titles/View'

class People extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: i18n.t('CHALLENGES.PEOPLE_ON_CHALLENGE'),
    }
    this.handleSponsorSuggestionSelected = this.handleSponsorSuggestionSelected.bind(this)
    this.handleProposerSuggestionSelected = this.handleProposerSuggestionSelected.bind(this)
  }

  handleSponsorSuggestionSelected(suggestion) {
    const { onSponsorSelected } = this.props
    onSponsorSelected(suggestion)
  }

  handleProposerSuggestionSelected(suggestion) {
    const { onProposerSelected } = this.props
    onProposerSelected(suggestion)
  }

  renderSponsorSuggestion() {
    const {
      sponsorSuggestions,
      getSponsorSuggestions,
      sponsorStarted,
      selectedSponsor,
      onRemoveSponsor,
      hasSponsorError,
    } = this.props
    const isSponsorSelected = Object.keys(selectedSponsor).length > 0
    if (!isSponsorSelected) {
      return (
        <AutoSuggestion
          suggestions={sponsorSuggestions}
          getSuggestions={getSponsorSuggestions}
          onSuggestionSelected={this.handleSponsorSuggestionSelected}
          isLoading={sponsorStarted}
          inputLabel={i18n.t('CHALLENGES.SEARCH_USER')}
          hasError={hasSponsorError}
        />
      )
    }
    return <SuggestionSelected suggestion={selectedSponsor} onRemoveSuggestion={onRemoveSponsor} />
  }

  renderProposerSuggestion() {
    const {
      proposerSuggestions,
      getProposerSuggestions,
      proposerStarted,
      selectedProposer,
      onRemoveProposer,
    } = this.props
    const isProposerSelected = Object.keys(selectedProposer).length > 0
    if (!isProposerSelected) {
      return (
        <AutoSuggestion
          suggestions={proposerSuggestions}
          getSuggestions={getProposerSuggestions}
          onSuggestionSelected={this.handleProposerSuggestionSelected}
          isLoading={proposerStarted}
          inputLabel={i18n.t('CHALLENGES.SEARCH_USER')}
        />
      )
    }
    return (
      <SuggestionSelected suggestion={selectedProposer} onRemoveSuggestion={onRemoveProposer} />
    )
  }

  render() {
    const { title } = this.state
    const { classes, hasSponsorError } = this.props
    return (
      <div className={classes.root}>
        <Typography variant="h5" className={classes.mainTitle}>
          {title}
        </Typography>
        <Divider />
        <div className={classes.autosuggestionsWrapper}>
          <div className={classes.suggestion}>
            <SuggestionTitles
              mainLabel={i18n.t('CHALLENGES.SPONSOR')}
              secondaryLabel={i18n.t('CHALLENGES.SPONSOR_INFO')}
              hasError={hasSponsorError}
            />
            {this.renderSponsorSuggestion()}
          </div>
          <div className={classes.suggestion}>
            <SuggestionTitles
              mainLabel={i18n.t('CHALLENGES.PROPOSER')}
              secondaryLabel={i18n.t('CHALLENGES.PROPOSER_INFO')}
            />
            {this.renderProposerSuggestion()}
          </div>
        </div>
      </div>
    )
  }
}
People.defaultProps = {
  sponsorStarted: false,
  proposerStarted: false,
  selectedSponsor: {},
  selectedProposer: {},
  hasSponsorError: false,
}
People.propTypes = {
  sponsorSuggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  proposerSuggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getSponsorSuggestions: PropTypes.func.isRequired,
  onRemoveSponsor: PropTypes.func.isRequired,
  onSponsorSelected: PropTypes.func.isRequired,
  selectedSponsor: PropTypes.shape({}),
  sponsorStarted: PropTypes.bool,
  getProposerSuggestions: PropTypes.func.isRequired,
  onProposerSelected: PropTypes.func.isRequired,
  proposerStarted: PropTypes.bool,
  selectedProposer: PropTypes.shape({}),
  onRemoveProposer: PropTypes.func.isRequired,
  hasSponsorError: PropTypes.bool,
}
export default withStyles(styles)(People)

export const parseTypes = types => {
  if (!types) return []
  return types.map(type => {
    return {
      desc: type.desc,
      name: type.name,
      id: `${type.id || ''}`,
      order: type.order,
    }
  })
}

export const transformTypeServiceResponseDataWithState = jsonResponse => {
  const parsedTypes = parseTypes(jsonResponse)
  return {
    types: parsedTypes,
  }
}

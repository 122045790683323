import React from 'react'
import { connect } from 'react-redux'
import { showPopupDialog } from 'support/popup_dialogs'
import SplashDialog from './components/splash_dialog'
import View from './View'

const mapStateToProps = state => ({
  dataLoaded: state.data.domain.dataLoaded,
  isSplashActiveUser: state.data.user.prefs.splash,
  isSplashActiveOrg: state.data.domain.domainConfiguration.isWelcomeScreenEnable,
})

const mapDispatchToProps = dispatch => {
  return {
    showSplashModal: () => {
      showPopupDialog(<SplashDialog />, dispatch)
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

import React from 'react'
import * as PropTypes from 'prop-types'
import {
  TextField,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import TextEditor from 'components/text_editor/View'
import ImageUploader from 'components/image_uploader/View'
import NxtDatePicker from 'components/nxt_date_picker/View'
import VideoForm from 'components/video_form/View'
import NxtCheckbox from 'components/nxt_checkbox/View'
import styles from './View.Style'
import { FileUploader } from 'components/index'
import DocumentSection from 'components/document_section/View'

const MainFormSection = props => {
  const {
    //id,
    classes,
    titleValue,
    imageValues,
    descriptionValue,
    externalVideoValue,
    onExternalVideoChange,
    fileVideoValue,
    onSelectFileVideo,
    onRemoveFileVideo,
    isFileVideoLoading,
    isFileVideoReady,
    isVideoRemoving,
    expireDateValue,
    minDate,
    titleError,
    imageError,
    onTitleChange,
    descriptionError,
    onDescriptionChange,
    onChangeExpiredDate,
    titleRef,
    descriptionRef,
    imageRef,
    isTitleLoading,
    onSelectImage,
    deleteImage,
    missingImageError,
    externalVideoRef,
    // invalidYoutubeUrlError,
    hasVideoError,
    onExternalVideoError,
    canEditTitle,
    handleGlobleIdea,
    globalIdeaCheck,
    onFileSelect,
    csrf,
    documents,
    isDocumentLoading,
    handleDocumentUpload,
    handleDeleteDocument,
    isAdmin,
    isInnovator,
    localAdmin,
    localInnovator,
    isOwner,
    serviceLineActivated,
    domainName,
  } = props
  // console.log(`serviceLineActivated:::: ${serviceLineActivated}`)
  // console.log(`fileVideoValue videForm:::: `,fileVideoValue)
  return (
    <div>
      <div className={classes.formItem}>
        <h3>{i18n.t('IDEA.TITLE')}*</h3>
        <div className={classes.inputContainer} ref={titleRef}>
          <TextField
            disabled={canEditTitle}
            className={classes.inputItem}
            placeholder={i18n.t('IDEA.TITLE')}
            value={titleValue}
            margin="normal"
            fullWidth
            InputProps={{
              endAdornment: isTitleLoading ? (
                <InputAdornment position="start">
                  <CircularProgress size={20} />
                </InputAdornment>
              ) : null,
            }}
            onChange={event => {
              onTitleChange(event.target.value)
            }}
            error={!!titleError}
          />

          {/* {!status && (
            <TextField
              className={classes.inputItem}
              placeholder={i18n.t('IDEA.TITLE')}
              value={titleValue}
              margin="normal"
              fullWidth
              InputProps={{
                endAdornment: isTitleLoading ? (
                  <InputAdornment position="start">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ) : null,
              }}
              onChange={event => {
                onTitleChange(event.target.value)
              }}
              error={!!titleError}
            />
          )} */}
          {/* {status && (
            <TextField
              disabled
              className={classes.inputItem}
              placeholder={i18n.t('IDEA.TITLE')}
              value={titleValue}
              margin="normal"
              fullWidth
              InputProps={{
                endAdornment: isTitleLoading ? (
                  <InputAdornment position="start">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ) : null,
              }}
              onChange={event => {
                onTitleChange(event.target.value)
              }}
              error={!!titleError}
            />
          )} */}
          <span>{titleError}</span>
        </div>
      </div>
      <div className={classes.formItem}>
        <h3>{i18n.t('IDEA.IDEA_IMAGES')}*</h3>
        <div className={classes.imageContainer} ref={imageRef}>
          <ImageUploader
            images={imageValues}
            imageUploadError={imageError}
            onSelectImage={onSelectImage}
            deleteImage={deleteImage}
          />
          <span>{missingImageError}</span>
        </div>
      </div>
      <div className={classes.formItem}>
        <h3>{i18n.t('IDEA.DESC')}*</h3>
        <div className={classes.inputContainer}>
          <TextEditor
            classes={{ description: classes.inputItem }}
            reference={descriptionRef}
            onTextChange={onDescriptionChange}
            value={descriptionValue}
            showDescription={true}
            domain={domainName}
          />
          <span>{descriptionError}</span>
        </div>
      </div>

      <div className={classes.formItem} ref={externalVideoRef}>
        <h3>{i18n.t('IDEA.VIDEO')}</h3>
        <div className={classes.inputItem}>
          <VideoForm
            onExternalVideoError={onExternalVideoError}
            externalVideoSrc={externalVideoValue}
            fileVideoSrc={fileVideoValue}
            onChangeExternalVideo={onExternalVideoChange}
            onSelectFileVideo={onSelectFileVideo}
            onRemoveFileVideo={onRemoveFileVideo}
            isVideoLoading={isFileVideoLoading}
            isVideoReady={isFileVideoReady}
            isVideoRemoving={isVideoRemoving}
            hasVideoError={hasVideoError}
          />
        </div>
      </div>

      {/* <div className={classes.formItem}>
        <h3>Idea document</h3>
        <div className={classes.documentUpload}>
          <FileUploader
            dropText={i18n.t('EXPLORE_IDEA.FILE_MANAGER.UPLOAD_FILES')}
            onFileSelect={onFileSelect}
          />
        </div>
      </div> */}

      <div className={classes.formItem}>
        <h3>Idea document</h3>
        <div className={classes.inputItem}>
          <DocumentSection
            csrfToken={csrf}
            documents={documents}
            isDocumentLoading={isDocumentLoading}
            onFileSelect={handleDocumentUpload}
            onDeleteDocument={handleDeleteDocument}
            canDelete={isAdmin || isInnovator || isOwner || localAdmin || localInnovator}
          />
        </div>
      </div>
      {!serviceLineActivated && (
        <div className={classes.formItem} ref={externalVideoRef}>
          <div className={classes.titleAndDescription}>
            <h3>{i18n.t('IDEA.GLOBAL_IDEA')}</h3>
            <p>{i18n.t('IDEA.GLOBAL_IDEA_DESC')}</p>
          </div>

          <div className={classes.inputContainer}>
            <FormControlLabel
              className={classes.check}
              control={<NxtCheckbox isChecked={globalIdeaCheck} />}
              onClick={handleGlobleIdea}
            />
          </div>

          {/* {id ? (
          <div className={classes.inputItem}>
            <VideoForm
              onExternalVideoError={onExternalVideoError}
              externalVideoSrc={externalVideoValue}
              fileVideoSrc={fileVideoValue}
              onChangeExternalVideo={onExternalVideoChange}
              onSelectFileVideo={onSelectFileVideo}
              onRemoveFileVideo={onRemoveFileVideo}
              isVideoLoading={isFileVideoLoading}
              isVideoReady={isFileVideoReady}
              isVideoRemoving={isVideoRemoving}
              hasVideoError={hasVideoError}
            />
          </div>
        ) : (
          <div className={classes.externalVideoContainer}>
            <TextField
              required
              className={classes.inputItem}
              margin="normal"
              value={externalVideoValue}
              onChange={event => {
                onExternalVideoChange(event.target.value)
              }}
              helperText={
                <Fragment>
                  <span dangerouslySetInnerHTML={{ __html: i18n.t('IDEA.YOUTUBE_LINK') }} />
                  <span dangerouslySetInnerHTML={{ __html: i18n.t('IDEA.VIDEO_UPLOAD_CHOICE') }} />
                </Fragment>
              }
              error={!!invalidYoutubeUrlError}
            />
            <span>{invalidYoutubeUrlError}</span>
          </div>
        )} */}
        </div>
      )}
      {expireDateValue !== undefined && (
        <div className={classes.formItem}>
          <h3>{i18n.t('IDEA.WHEN_ACTIVE')}</h3>
          <NxtDatePicker
            className={classes.dateInputItem}
            id="date-picker-from"
            onDateChange={onChangeExpiredDate}
            value={expireDateValue}
            endIconName="icon-th"
            minDate={minDate}
          />
        </div>
      )}
    </div>
  )
}

MainFormSection.defaultProps = {
  id: '',
  titleValue: '',
  descriptionValue: '',
  externalVideoValue: '',
  fileVideoValue: '',
  expireDateValue: undefined,
  minDate: new Date(),
  titleRef: null,
  imageRef: null,
  descriptionRef: null,
  externalVideoRef: null,
  titleError: '',
  descriptionError: '',
  missingImageError: '',
  invalidYoutubeUrlError: '',
  isTitleLoading: false,
  hasVideoError: false,
}

MainFormSection.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    formItem: PropTypes.string,
    inputItem: PropTypes.string,
    inputContainer: PropTypes.string,
  }).isRequired,
  id: PropTypes.string,
  titleValue: PropTypes.string,
  descriptionValue: PropTypes.string,
  externalVideoValue: PropTypes.string,
  onExternalVideoChange: PropTypes.func.isRequired,
  fileVideoValue: PropTypes.string,
  onSelectFileVideo: PropTypes.func.isRequired,
  onRemoveFileVideo: PropTypes.func.isRequired,
  isFileVideoLoading: PropTypes.bool.isRequired,
  isFileVideoReady: PropTypes.bool.isRequired,
  isVideoRemoving: PropTypes.bool.isRequired,
  expireDateValue: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  imageValues: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      src: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ).isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  onChangeExpiredDate: PropTypes.func.isRequired,
  titleRef: PropTypes.shape({}),
  descriptionRef: PropTypes.shape({}),
  imageRef: PropTypes.shape({}),
  missingImageError: PropTypes.string,
  externalVideoRef: PropTypes.shape({}),
  invalidYoutubeUrlError: PropTypes.string,
  titleError: PropTypes.string,
  descriptionError: PropTypes.string,
  isTitleLoading: PropTypes.bool,
  hasVideoError: PropTypes.bool,
  onFileSelect: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      size: PropTypes.string,
      src: PropTypes.string,
    }),
  ).isRequired,
  onDocumentUpload: PropTypes.func.isRequired,
  isDocumentLoading: PropTypes.bool.isRequired,
  onDocumentDelete: PropTypes.func.isRequired,
}

export default withStyles(styles)(MainFormSection)

const styles = theme => ({
  root: {
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    '& > div:first-child': {
      width: '65%',
    },
  },
})

export default styles

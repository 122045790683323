import { VZAAR_STATES } from 'common_constants/VzaarVideo'

export const transformConfirmationVideoResponse = jsonResponse => ({
  isVideoReady: jsonResponse.state === VZAAR_STATES.READY,
  hasVideoError: jsonResponse.state === VZAAR_STATES.FAILED,
  videoId: jsonResponse.id,
  video: jsonResponse.playerUrl,
  isLoadingVideo : jsonResponse.state === VZAAR_STATES.READY,
})

export const transformUploadVideoResponse = jsonResponse => ({
  video: jsonResponse.playerUrl,
  videoId: `${jsonResponse.id}`,
  isVideoReady: jsonResponse.state === VZAAR_STATES.READY,
  videoIdeaId: jsonResponse.ideaId,
})

import { createStyles } from '@material-ui/core'
// import { color } from 'highcharts'

const styles = () =>
  createStyles({
    container: {
      padding: '3rem',
      backgroundColor: '#0d0d0d',
      color: '#FFFFFF',
    },
  })

export default styles

import React from 'react'
import * as PropTypes from 'prop-types'
import { Table, TableBody } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import TableHead from '@material-ui/core/TableHead'
import styles from './View.Style'
import CustomPagination from './components/pagination/View'
import NxtCircularLoader from '../nxt_circular_loader/View'

const CustomTable = props => {
  const {
    classes,
    renderHeaderComponent,
    renderBodyComponent,
    page,
    fullCount,
    rowsPerPage,
    paginated,
    onPaginationChanged,
    isLoading,
  } = props
  return (
    <div className={classes.paper}>
      <div className={classes.tableWrapper}>
        <NxtCircularLoader isLoading={isLoading}>
          <Table size="small" className={classes.table} aria-labelledby="tableTitle">
            <TableHead>{renderHeaderComponent}</TableHead>
            <TableBody>{renderBodyComponent}</TableBody>
          </Table>
        </NxtCircularLoader>
      </div>
      {paginated && (
        <CustomPagination
          fullCount={fullCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPaginationChange={onPaginationChanged}
        />
      )}
    </div>
  )
}

CustomTable.propTypes = {
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired,
    tableWrapper: PropTypes.string.isRequired,
  }).isRequired,
  renderHeaderComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  renderBodyComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  fullCount: PropTypes.number,
  paginated: PropTypes.bool,
  onPaginationChanged: PropTypes.func,
  isLoading: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
}

CustomTable.defaultProps = {
  paginated: false,
  fullCount: 0,
  isLoading: false,
  rowsPerPage: 5,
  page: 1,
  renderHeaderComponent: () => {},
  renderBodyComponent: () => {},
  onPaginationChanged: () => {},
}

export default withStyles(styles)(CustomTable)

const styles = theme => ({
  root: {
    '& > :first-child': {
      margin: theme.spacing(2, 0),
    },
  },
  graphContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderBottom: 'solid 1px #ddd',
    '& > div:first-child': {
      borderRight: 'solid 1px #ddd',
    },
  },
})

export default styles

import { connect } from 'react-redux'
import DataInitializerControllerView from './View'

const mapStateToProps = state => {
  return {
    loggedIn: state.auth.loggedIn,
    domain: state.data.domain.currentDomain,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onInit: () => {
      dispatch(ownProps.onInit())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataInitializerControllerView)

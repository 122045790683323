import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const LegalAcceptance = () => {
  return (
    <div>
      <p>LegalAcceptance</p>
    </div>
  )
}

LegalAcceptance.propTypes = {}

export default withStyles(styles)(LegalAcceptance)

import { createStyles } from '@material-ui/core'

const styles = theme => {
  return createStyles({
    root: {
      color: theme.palette.secondary.contrastText,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      float: 'right',
      textAlign: 'right',
      maxWidth: '200px',
      justifyContent: 'center',
      //backgroundColor:'#f63f40',
      padding:'8px',
      '@media(max-width:767px)':{
        display:'none'
      }
    },
    amount: {
      display: 'block',
      fontSize: '20px',
      width: '100%',
      fontFamily: '"HelveticaNeueCondensedBold", "Helvetica Neue", sans-serif',
      fontStretch: 'condensed',
      fontWeight: 'bold',
      '-webkit-font-smoothing': 'antialiased',
      margin: '0',
    },
    arrow: {
      fontSize: '14px',
    },
    balance: {
      fontSize: '12px',
      marginTop: '3px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    userBalance: {
      fontSize: '20px',
      margin: '0 0.1rem',
    },
    userName: {
      fontWeight: 'bold',
      fontSize: '16px',
      margin: 0,
    },
  })
}

export default styles

import { useEffect } from 'react'
import * as PropTypes from 'prop-types'

const DataInitializerControllerView = props => {
  const { children, loggedIn, onInit, domain } = props

  useEffect(() => {
    if (loggedIn) {
      onInit()
    }
  })

  useEffect(() => {
    document.title = domain ? `NOVA - ${domain}` : 'NOVA'
  }, [domain])

  return children
}

DataInitializerControllerView.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  domain: PropTypes.string.isRequired,
  onInit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

export default DataInitializerControllerView

import { connect } from 'react-redux'
import Navigation from 'support/navigation'
import View from './View'

const mapDispatchToProps = dispatch => {

  return {
    goBack: ()=>{
      dispatch(Navigation.navigateBack)
    }
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(View)

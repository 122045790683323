const styles = () => ({
  root: {
    '& > button': {
      width: '100%',
      '& > img': {
        width: '100%',
      },
    },
  },
})

export default styles

import React from 'react'
import {TermsOfService, PrivacyCookies, LegalNotice} from 'components'
// import PrivacyCookies from '''
// import LegalNotice from 'components/terms_of_service/View'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#0d0d0d',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function TermsDialog(props) {
  const { openDialog, closeDialog, title, termsCondtions, privacyCookies, legalNotice } = props
  const classes = useStyles()

  const handleClose = () => {
    closeDialog()
  }

  return (
    <div>
      <Dialog fullScreen open={openDialog} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        {termsCondtions && (<TermsOfService />)}
        {privacyCookies && (<PrivacyCookies />)}
        {legalNotice && (<LegalNotice />)}
      </Dialog>
    </div>
  )
}

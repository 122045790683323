import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import defaultLogo from 'img/nextinit-logo.png'
import defaultBulb from 'img/bulb_rocket.png'
import defaultAppleAppStore from 'img/app-store.svg'
import defaultAndroidAppStore from 'img/google-play.svg'
import Image from 'components/image/View'
import ButtonLink from 'components/button_link/View'
import styles from './View.Style'

const Maintenance = props => {
  const {
    classes,
    maintenanceTitle,
    maintenanceText,
    onLogoClick,
    androidAppUrl,
    iosAppUrl,
  } = props
  return (
    <Fragment>
      <div className={classes.header}>
        <div className={classes.logoContainer}>
          <ButtonLink onLinkClick={onLogoClick}>
            <Image
              alt="nextinit logo"
              className={classes.nextInitLogo}
              src=""
              defaultSrc={defaultLogo}
            />
          </ButtonLink>
        </div>
      </div>
      <div className={classes.titleSection}>
        <div className={classes.titleContainer}>
          <span className={classes.titleSpan}>
            {maintenanceTitle || i18n.t('MAINTENANCE.DEFAULT_TITLE')}
          </span>
          <div className={classes.subLine} />
        </div>
      </div>
      <div className={classes.textContainer}>
        <span className={classes.textSpan}>
          {maintenanceText || i18n.t('MAINTENANCE.DEFAULT_TEXT')}
        </span>
      </div>
      <div className={classes.footerContainer}>
        <div className={classes.logoAndMobileContainer}>
          <ButtonLink classes={{ linkButton: classes.logoButton }} onLinkClick={onLogoClick}>
            <div>
              <Image src="" defaultSrc={defaultBulb} />
              <p>Nextinit</p>
            </div>
            <p>Transform your business, your people know how</p>
          </ButtonLink>
          <div className={classes.mobileStores}>
            <ul>
              <li>
                <a href={iosAppUrl}>
                  <Image src="" defaultSrc={defaultAppleAppStore} />
                </a>
              </li>
              <li>
                <a href={androidAppUrl}>
                  <Image src="" defaultSrc={defaultAndroidAppStore} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr className={classes.footerDivider} />
        <ul className={classes.socialLinks}>
          <li>
            <a href="https://twitter.com/Nextinit">
              <span className="icon-twitter" />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/Nextinit">
              <span className="icon-facebook" />
            </a>
          </li>
        </ul>
      </div>
    </Fragment>
  )
}

Maintenance.propTypes = {
  maintenanceTitle: PropTypes.string.isRequired,
  maintenanceText: PropTypes.string.isRequired,
  androidAppUrl: PropTypes.string.isRequired,
  iosAppUrl: PropTypes.string.isRequired,
  onLogoClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(Maintenance)

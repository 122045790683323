import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    container: {
      display: 'flex',
      flexFlow: 'row wrap',
      '& > div': {
        height: '231px',
        width: '231px',
        margin: 4,
      },
    },
    countCard: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& > i': {
        fontSize: '65px',
        paddingTop: '25px',
      },
      '& > h1': {
        fontSize: '31px',
        padding: '10px',
        margin: 0,
      },
    },
  })

export default styles

const styles = theme => ({
  root: {
    lineHeight: 0,
    position: 'relative',
  },
  badge: {
    padding: '3px',
    borderRadius: '20px',
    lineHeight: '20px',
    backgroundColor: '#000000',
    color: theme.palette.common.white,
  },
  baseLogo: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-between',
    alignItems: 'center',
    top: '70px',
    width: '100%',
    '& > div:first-child': {
      paddingLeft: theme.spacing(0.5),
    },
    '& > div:last-child': {
      paddingRight: theme.spacing(0.5),
    },
  },
  burger: {
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    padding: theme.spacing(0.5),
    fontSize: '17px',
  },
  button: {
    padding: 0,
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
  },
  image: {
    width: '99px',
    height: '99px',
    filter: 'grayscale(1)',
  },
  notifications: {
    padding: theme.spacing(0.5, 0.75),
    minWidth: '20px',
    maxWidth: '25px',
    fontWeight: 'bold',
    fontSize: '12px',
  },
})

export default styles

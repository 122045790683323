const styles = () => ({
  top: {
    gridArea: 'top',
    gridColumn: 2,
    gridRow: 2,
    textAlign: 'center',
  },
  listContainter: {
    marginBottom: '50px',
  },
  listIcon: {
    width: '60px',
    height: '60px',
    border: '1px dashed #666',
    borderRadius: '70px',
    display: 'flex',
    justifyContent: 'center',
  },
  listLabel: {
    fontSize: '30px',
    marginBottom: '12px',
    lineHeight: '55px',
    color: '#666',
    display: 'flex',
    alignItems: 'center',
  },
  items: {
    margin: '0px',
    padding: '0px',
  },
  item: {
    listStyleType: 'none',
  },
  number: {
    fontSize: '42px',
    textAlign: 'left',
    paddingLeft: '25px',
    color: '#0096e2',
  },
  dashedIcon: {
    height: '35px',
    marginLeft: '30px',
    borderLeft: '1px dashed #666',
  },
  dashed: {
    borderLeft: '1px dashed #666',
    marginLeft: '30px',
    wordBreak: 'break-word',
  },
  listTitle: {
    float: 'left',
    width: '160px',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '28px',
    position: 'relative',
    paddingLeft: '30px',
    paddingRight: '10px',
    bottom: '8px',
    textAlign: 'left',
  },
  listText: {
    fontSize: '14px',
    color: '#666',
    position: 'relative',
    display: 'grid',
    bottom: '2px',
    borderBottom: '1px solid #dad7d7',
    lineHeight: '17px',
    paddingBottom: '30px',
    textAlign: 'justify',
    marginLeft: '20px',
  },
})

export default styles

const styles = theme => ({
  back: {
    background: theme.palette.common.white,
    padding: '0.5rem',
    zIndex: '3',
    transform: 'rotateY(180deg)',
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
  },
  card: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    fontFamily: '"Roboto", sans-serif',
    color: '#444',
    backfaceVisibility: 'hidden',
    transformStyle: 'preserve-3d',
    boxShadow:
      '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
  },
  front: { background: '#fff', zIndex: '2' },
  light: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  dark: {
    background: '#333',
    color: theme.palette.common.white,
  },
})

export default styles

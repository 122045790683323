const styles = theme => {
  return {
    container: {
      position: 'relative',
    },
    loader: {
      zIndex: theme.zIndex.drawer,
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      opacity: '0.7',
    },
  }
}

export default styles

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, AppBar, Tabs, Tab } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'
import EasyMode from './components/easy_mode/View'
import AdvancedMode from './components/advanced_mode/View'

class GamingConfiguration extends Component {
  constructor(props) {
    super(props)
    this.firstTabText = i18n.t('CHALLENGES.ROI_EASY')
    this.secondTabText = i18n.t('CHALLENGES.ROI_ADVANCED')
  }

  render() {
    const {
      classes,
      onSettingChange,
      selectedGamingSetting,
      onSliderChangeCommitted,
      approveMult,
      implementMult,
      supportMult,
      currentGoal,
      defaultValues,
      onChangeTab,
      activeTab,
    } = this.props
    return (
      <div className={classes.root}>
        <AppBar position="static" color="inherit">
          <Tabs
            value={activeTab}
            onChange={onChangeTab}
            aria-label="simple tabs example"
            indicatorColor="primary"
          >
            <Tab label={this.firstTabText} />
            <Tab label={this.secondTabText} />
          </Tabs>
        </AppBar>
        <div role="tabpanel" hidden={activeTab !== 0}>
          <EasyMode
            onSettingChange={onSettingChange}
            selectedGamingSetting={selectedGamingSetting}
            defaultValues={defaultValues}
          />
        </div>
        <div role="tabpanel" hidden={activeTab !== 1}>
          <AdvancedMode
            onSliderChangeCommitted={onSliderChangeCommitted}
            approveMult={approveMult}
            implementMult={implementMult}
            supportMult={supportMult}
            currentGoal={currentGoal}
            defaultValues={defaultValues}
          />
        </div>
      </div>
    )
  }
}
GamingConfiguration.defaultProps = {}
GamingConfiguration.propTypes = {
  onSettingChange: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  selectedGamingSetting: PropTypes.string.isRequired,
  onSliderChangeCommitted: PropTypes.func.isRequired,
  approveMult: PropTypes.number.isRequired,
  implementMult: PropTypes.number.isRequired,
  supportMult: PropTypes.number.isRequired,
  currentGoal: PropTypes.number.isRequired,
  activeTab: PropTypes.number.isRequired,
  defaultValues: PropTypes.shape({}).isRequired,
}
export default withStyles(styles)(GamingConfiguration)

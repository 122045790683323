import i18n from 'support/i18n'

export const headerCells = [
  {
    id: 'TITLE',
    label: i18n.t('IDEAS_WALL.TITLE'),
    numeric: false,
    showIfFilterOpen: true,
  },
  {
    id: 'STATUS',
    label: i18n.t('IDEAS_WALL.STATUS'),
    numeric: false,
    showIfFilterOpen: false,
  },
  {
    id: 'CREATED',
    label: i18n.t('IDEAS_WALL.CREATED'),
    numeric: true,
    showIfFilterOpen: true,
  },
  {
    id: 'PLEDGED',
    label: i18n.t('IDEAS_WALL.PLEDGED'),
    numeric: true,
    showIfFilterOpen: true,
  },
]

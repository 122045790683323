import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import { ButtonLink } from 'components'
import styles from './View.Style'

const EditMyProfile = props => {
  const { classes, onEditProfileClick } = props
  return (
    <ButtonLink onLinkClick={onEditProfileClick} classes={{ linkButton: classes.linkButton }}>
      <span>{i18n.t('PROFILE.EDIT_MY_PROFILE')}</span>
      <span className="icon-edit" />
    </ButtonLink>
  )
}

EditMyProfile.propTypes = {
  onEditProfileClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(EditMyProfile)

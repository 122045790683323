const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 0),
    width: '100%',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(3),
  },
  inputLabel: {
    flex: 1,
    textAlign: 'right',
    padding: theme.spacing(1, 4, 0, 0),
  },
  imageWrapper: {
    flex: 3,
  },
  videoWrapper: {
    flex: 3,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  error: {
    color: theme.palette.error.main,
  },
})

export default styles

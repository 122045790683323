import React, { Component, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Button, TextField, CircularProgress } from '@material-ui/core'
import i18n from 'support/i18n'
import { transformToEmbedUrl } from 'support/utils/file'
import styles from './View.Style'
import { checkYoutubeUrl } from './View.Utils'

class VideoForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      externalVideo: '',
      externalVideoError: false,
      showExternalVideo: false,
      isVideoLoading: props.isVideoLoading,
    }

    this.handleUploadExternalVideo = this.handleUploadExternalVideo.bind(this)
    this.onRemoveVideo = this.onRemoveVideo.bind(this)
    this.handleCancelClick = this.handleCancelClick.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { isVideoLoading } = this.props
    if (prevProps.isVideoLoading !== isVideoLoading) {
      this.setState({ isVideoLoading })
    }
  }

  onRemoveVideo() {
    const { externalVideoSrc, fileVideoSrc, onChangeExternalVideo, onRemoveFileVideo } = this.props
    if (externalVideoSrc) {
      onChangeExternalVideo('')
      this.setState({ externalVideo: '' })
    }
    if (fileVideoSrc) onRemoveFileVideo()
  }

  handleUploadExternalVideo() {
    const { onChangeExternalVideo, onExternalVideoError } = this.props
    const { externalVideo } = this.state
    let error = false
    if (checkYoutubeUrl(externalVideo)) {
      const url = transformToEmbedUrl(externalVideo)
      onChangeExternalVideo(url)
    } else {
      error = true
    }
    onExternalVideoError(error)
    this.setState({ externalVideoError: error })
  }

  handleCancelClick() {
    const { onExternalVideoError } = this.props
    onExternalVideoError(false)
    this.setState({ showExternalVideo: false, externalVideoError: false, externalVideo: '' })
  }

  render() {
    const {
      classes,
      externalVideoSrc,
      fileVideoSrc,
      onSelectFileVideo,
      isVideoReady,
      isVideoRemoving,
      hasVideoError,
    } = this.props
    const { externalVideo, showExternalVideo, externalVideoError, isVideoLoading } = this.state
    const areAnyVideo = !!(externalVideoSrc || fileVideoSrc)
    const isVideoEncoding = fileVideoSrc && !isVideoReady



    return (
      <div className={classes.videoFormContainer}>
        <div className={classes.mainButtons}>
          {areAnyVideo && (
            <Button variant="contained" onClick={this.onRemoveVideo} disabled={isVideoRemoving}>
              {isVideoRemoving && (
                <CircularProgress className={classes.spinner} size={20} color="primary" />
              )}
              {i18n.t('VIDEO.REMOVE')}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={areAnyVideo || isVideoLoading}
            onClick={() => this.setState({ showExternalVideo: true })}
          >
            {i18n.t('VIDEO.NEW_YOUTUBE_VIDEO')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSelectFileVideo}
            disabled={areAnyVideo || showExternalVideo || isVideoLoading}
          >
            {i18n.t('VIDEO.UPLOAD_TITLE')}
          </Button>
        </div>
        <div>
          {showExternalVideo && !areAnyVideo && (
            <Fragment>
              <TextField
                className={classes.inputItem}
                margin="normal"
                fullWidth
                value={externalVideo}
                onChange={event => this.setState({ externalVideo: event.target.value })}
                helperText={
                  <span dangerouslySetInnerHTML={{ __html: i18n.t('IDEA.YOUTUBE_LINK') }} />
                }
                error={externalVideoError}
              />
              <div className={classes.buttonsWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleUploadExternalVideo}
                >
                  {i18n.t('VIDEO.UPLOAD_TITLE')}
                </Button>
                <Button variant="contained" onClick={this.handleCancelClick}>
                  {i18n.t('VIDEO.CANCEL')}
                </Button>
              </div>
            </Fragment>
          )}
          {externalVideoSrc && (
            <iframe
              className={classes.iframe}
              title="ytVideo"
              width="560"
              height="315"
              src={externalVideoSrc}
              frameBorder="0"
              allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
          {/* {console.log("fileVideoSrc VideForm==>",fileVideoSrc)} */}
          {fileVideoSrc && isVideoReady && (
            <Fragment>
              {/* <video className={classes.video} controls>
                <source src={fileVideoSrc} type="video/mp4" />
                <track label="video_challenge" src="captions_en.vtt" kind="captions" srcLang="en" />
              </video> */}

            <iframe
              className={classes.iframeVideo}
              title="ytVideo"
              width="560"
              height="315"
              src={fileVideoSrc}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            </Fragment>
          )}
          {isVideoLoading && <p>{i18n.t('VIDEO.UPLOAD_WAIT')}</p>}
          {isVideoEncoding && !hasVideoError && <p>{i18n.t('VIDEO.VIDEO_ENCODING')}</p>}
          {hasVideoError && <p>{i18n.t('VIDEO.VIDEO_ENCODING_ERROR')}</p>}
        </div>
      </div>
    )
  }
}

VideoForm.defaultProps = {
  externalVideoSrc: '',
  fileVideoSrc: '',
}

VideoForm.propTypes = {
  externalVideoSrc: PropTypes.string,
  fileVideoSrc: PropTypes.string,
  onChangeExternalVideo: PropTypes.func.isRequired,
  onExternalVideoError: PropTypes.func.isRequired,
  onSelectFileVideo: PropTypes.func.isRequired,
  onRemoveFileVideo: PropTypes.func.isRequired,
  isVideoLoading: PropTypes.bool.isRequired,
  isVideoReady: PropTypes.bool.isRequired,
  isVideoRemoving: PropTypes.bool.isRequired,
  hasVideoError: PropTypes.bool.isRequired,
}

export default withStyles(styles)(VideoForm)

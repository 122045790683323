import { showDialog, showSnackbar } from './modal_alert_controller/ModalActions'
import Popups from './modal_alert_controller/ModalDataService'
import {
  SNACKBAR_ERROR_TYPE,
  SNACKBAR_INFO_TYPE,
  SNACKBAR_SUCCESS_TYPE,
  SNACKBAR_WARNING_TYPE,
} from './views/components/customized_snackbar/Constants'

function resetPopupDialogToCurrentDialog() {
  Popups.resetDialogToLastDialog()
}

function showPopupDialog(view, dispatchFunction) {
  Popups.setDialogFullView(view)
  dispatchFunction(showDialog())
}

function showAnotherPopupDialog(view, dispatchFunction) {
  Popups.addDialogFullView(view)
  dispatchFunction(showDialog())
}

function showPopupSuccessSnackbar(message, dispatchFunction) {
  Popups.setSnackbarData(message, SNACKBAR_SUCCESS_TYPE)
  dispatchFunction(showSnackbar())
}

function showPopupWarningSnackbar(message, dispatchFunction) {
  Popups.setSnackbarData(message, SNACKBAR_WARNING_TYPE)
  dispatchFunction(showSnackbar())
}

function showPopupErrorSnackbar(message, dispatchFunction) {
  Popups.setSnackbarData(message, SNACKBAR_ERROR_TYPE)
  dispatchFunction(showSnackbar())
}

function showPopupInfoSnackbar(message, dispatchFunction) {
  Popups.setSnackbarData(message, SNACKBAR_INFO_TYPE)
  dispatchFunction(showSnackbar())
}

// -------------------

export {
  showPopupDialog,
  showAnotherPopupDialog,
  resetPopupDialogToCurrentDialog,
  showPopupSuccessSnackbar,
  showPopupWarningSnackbar,
  showPopupErrorSnackbar,
  showPopupInfoSnackbar,
}

import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { Tab, Tabs, withStyles, Paper, Button } from '@material-ui/core'
import clsx from 'clsx'
import i18n from 'support/i18n'
import NotificationItem from 'components/header/components/header_user_logo/components/notifications_menu/components/notification_item/View'
import styles from './View.Style'

const NotificationsMenu = props => {
  const {
    classes,
    activities,
    notifications,
    unreadActivities,
    unreadNotifications,
    markNotificationsRead,
  } = props
  const [activeTab, setActiveTab] = useState(0)
  return (
    <Paper className={classes.root}>
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        aria-label="simple tabs example"
        indicatorColor="primary"
      >
        <Tab
          className={clsx(classes.tab, activeTab === 1 && classes.inActiveTab)}
          label={
            <div className={classes.tabsLabel}>
              <span>{i18n.t('NOTIFICATION.NOTIFICATIONS')}</span>
              <span className={classes.unread}>{unreadNotifications}</span>
            </div>
          }
        />
        <Tab
          className={clsx(classes.tab, activeTab === 0 && classes.inActiveTab)}
          label={
            <div className={classes.tabsLabel}>
              <span>{i18n.t('NOTIFICATION.ACTIVITY')}</span>
              <span className={classes.unread}>{unreadActivities}</span>
            </div>
          }
        />
      </Tabs>
      <div className={classes.content}>
        <section role="tabpanel" hidden={activeTab !== 0}>
          <ul className={classes.notificationList}>
            {!notifications.isLoading && !notifications.length && (
              <li className={classes.noItems}>{i18n.t('NOTIFICATION.NOTI_NO_ITEMS')}</li>
            )}
            {notifications.map(notification => (
              <li key={notification.id}>
                <NotificationItem
                  text={notification.text}
                  timeAgo={notification.timeAgo}
                  isRead={notification.isRead}
                  icon={notification.icon}
                  imageSrc={notification.imageSrc}
                />
              </li>
            ))}
          </ul>
        </section>
        <section role="tabpanel" hidden={activeTab !== 1}>
          <ul className={classes.notificationList}>
            {!notifications.isLoading && !activities.length && (
              <li className={classes.noItems}>{i18n.t('NOTIFICATION.ACTIVITY_NO_ITEMS')}</li>
            )}
            {activities.map(activity => (
              <li key={activity.id}>
                <NotificationItem
                  text={activity.text}
                  timeAgo={activity.timeAgo}
                  isRead={activity.isRead}
                  icon={activity.icon}
                  imageSrc={activity.imageSrc}
                />
              </li>
            ))}
          </ul>
        </section>
      </div>
      <Button variant="contained" color="primary" onClick={markNotificationsRead}>
        {i18n.t('NOTIFICATION.MARK_AS_READ')}
      </Button>
    </Paper>
  )
}

NotificationsMenu.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  unreadActivities: PropTypes.number.isRequired,
  unreadNotifications: PropTypes.number.isRequired,
  markNotificationsRead: PropTypes.func.isRequired,
}

export default withStyles(styles)(NotificationsMenu)

export const GET_ACTIVE_CHALLENGES_STARTED = 'GET_ACTIVE_CHALLENGES_STARTED'
export const GET_ACTIVE_CHALLENGES_SUCCESS = 'GET_ACTIVE_CHALLENGES_SUCCESS'
export const GET_ACTIVE_CHALLENGES_FAILURE = 'GET_ACTIVE_CHALLENGES_FAILURE'

export const GET_CHALLENGE_BY_ID_STARTED = 'GET_CHALLENGE_BY_ID_STARTED'
export const GET_CHALLENGE_BY_ID_SUCCESS = 'GET_CHALLENGE_BY_ID_SUCCESS'
export const GET_CHALLENGE_BY_ID_FAILURE = 'GET_CHALLENGE_BY_ID_FAILURE'

export const GET_CHALLENGE_VISITORS_BY_ID_STARTED = 'GET_CHALLENGE_VISITORS_BY_ID_STARTED'
export const GET_CHALLENGE_VISITORS_BY_ID_SUCCESS = 'GET_CHALLENGE_VISITORS_BY_ID_SUCCESS'
export const GET_CHALLENGE_VISITORS_BY_ID_FAILURE = 'GET_CHALLENGE_VISITORS_BY_ID_FAILURE'

export const POST_FINISH_CHALLENGE_BY_ID_STARTED = 'POST_FINISH_CHALLENGE_BY_ID_STARTED'
export const POST_FINISH_CHALLENGE_BY_ID_SUCCESS = 'POST_FINISH_CHALLENGE_BY_ID_SUCCESS'
export const POST_FINISH_CHALLENGE_BY_ID_FAILURE = 'POST_FINISH_CHALLENGE_BY_ID_FAILURE'

export const DELETE_CHALLENGE_BY_ID_STARTED = 'DELETE_CHALLENGE_BY_ID_STARTED'
export const DELETE_CHALLENGE_BY_ID_SUCCESS = 'DELETE_CHALLENGE_BY_ID_SUCCESS'
export const DELETE_CHALLENGE_BY_ID_FAILURE = 'DELETE_CHALLENGE_BY_ID_FAILURE'

export const APPLY_FILTER_CHALLENGE_FAILURE = 'APPLY_FILTER_CHALLENGE_FAILURE'
export const APPLY_FILTER_CHALLENGE_STARTED = 'APPLY_FILTER_CHALLENGE_STARTED'
export const APPLY_FILTER_CHALLENGE_SUCCESS = 'APPLY_FILTER_CHALLENGE_SUCCESS'

export const FIRST_SEARCH_APPLY_FILTER_STARTED = 'FIRST_SEARCH_APPLY_FILTER_STARTED'
export const FIRST_SEARCH_APPLY_FILTER_SUCCESS = 'FIRST_SEARCH_APPLY_FILTER_SUCCESS'
export const FIRST_SEARCH_APPLY_FILTER_FAILURE = 'FIRST_SEARCH_APPLY_FILTER_FAILURE'

export const GET_FIRST_SEARCH_APPLY_FILTER_STARTED = 'GET_FIRST_SEARCH_APPLY_FILTER_STARTED'
export const GET_FIRST_SEARCH_APPLY_FILTER_SUCCESS = 'GET_FIRST_SEARCH_APPLY_FILTER_SUCCESS'
export const GET_FIRST_SEARCH_APPLY_FILTER_FAILURE = 'GET_FIRST_SEARCH_APPLY_FILTER_FAILURE' 

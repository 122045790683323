import { connect } from 'react-redux'
import Navigation from 'support/navigation'
import View from './View'

const mapDispatchToProps = () => ({
  onOkClick: () => {
    Navigation.navigateToLogin()
  },
})

export default connect(
  null,
  mapDispatchToProps,
)(View)

import React, { Component } from 'react'
import i18n from 'support/i18n'
import * as PropTypes from 'prop-types'
import { Button, withStyles } from '@material-ui/core'
import Image from 'components/image/View'
import ImageCropper from 'components/image_uploader/components/image_cropper/View'
import defaultPhoto from 'img/default-photo.png'
import styles from './View.Style'

class ProfileImagePicker extends Component {
  constructor(props) {
    super(props)
    this.handleUploadImage = this.handleUploadImage.bind(this)
  }

  handleUploadImage({ target }) {
    const { onSelectImage, setImageBase64, onClose } = this.props
    if (!target.files.length) return
    const reader = new FileReader()
    reader.readAsDataURL(target.files[0])

    reader.onload = event => {
      if (event.target.readyState === FileReader.DONE) {
        const src = event.target.result
        onSelectImage(
          <ImageCropper
            aspect={1}
            imageFile={{ src }}
            onSelectCrop={(_, croppedImage) => {
              setImageBase64(croppedImage)
              onClose()
            }}
            onClose={onClose}
          />,
        )
        if (this.fileInput) {
          this.fileInput.value = ''
        }
      }
    }
  }

  render() {
    const { classes, image, title, showTitle } = this.props
    return (
      <div className={classes.container}>
        {showTitle && <h3>{title}</h3>}
        <div className={classes.imageContainer}>
          <Image src={image} defaultSrc={defaultPhoto} />
          <div className={classes.imageInfo}>
            <label htmlFor="contained-button-file">
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                ref={ref => {
                  this.fileInput = ref
                }}
                onChange={this.handleUploadImage}
              />
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.pictureButton}
              >
                {i18n.t('PICK_PICTURE')}
              </Button>
            </label>
            <span
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: i18n.t('PROFILE.PICK_PICTURES_RESTRICT'),
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

ProfileImagePicker.defaultProps = {
  title: i18n.t('PROFILE.YOUR_PHOTO'),
  image: defaultPhoto,
  showTitle: true,
}

ProfileImagePicker.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  showTitle: PropTypes.bool,
  setImageBase64: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default withStyles(styles)(ProfileImagePicker)

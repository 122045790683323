const styles = theme => ({
  root: {
    display: 'flex',
    '& > p': {
      fontSize: '12px',
      width: theme.spacing(30),
      marginRight: theme.spacing(5),
    },
  },
  sliderContainer: {
    width: '100%',
    paddingTop: theme.spacing(1.5),
  },
  sliderTitle: {
    fontSize: '12px',
    '& > span': {
      marginLeft: theme.spacing(0.5),
    },
  },
})

export default styles

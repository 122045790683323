import { connect } from 'react-redux'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import { postUserPrefsService } from 'services/user'
import View from './View'

const mapStateToProps = state => ({
  textTitle: state.data.domain.domainConfiguration.splashConfiguration.welcomeTitle,
  textContent: state.data.domain.domainConfiguration.splashConfiguration.splashHTML,
  splashLogo: state.data.domain.domainConfiguration.splashConfiguration.splashLogo,
  splashImage: state.data.domain.domainConfiguration.splashConfiguration.splashImage,
})

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: isSplashActive => {
      dispatch(postUserPrefsService('splash', isSplashActive))
      dispatch(hideDialog())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

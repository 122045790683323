import React, { Component, createRef, useEffect, useState } from 'react'
import { Button, CircularProgress, IconButton, withStyles } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import styles from './View.Style'

class VideoUploadDialog extends Component {
  constructor(props) {
  
    super(props)
    this.fileInput = createRef()
    this.handleUpload = this.handleUpload.bind(this)

  }

  handleUpload(event) {
    // console.log("popup event =>",event.target)
    const { onUploadVideo } = this.props
    onUploadVideo(event.target.files[0])
    // console.log("popup event.target.files[0] =>",event.target.files[0])
    this.fileInput = null
  }


  render() {
    const {
      classes,
      ideaType,
      fileVideoId,
      isVideoLoading,
      isVideoReady,
      isVideoRemoving,
      onDeleteVideo,
      onCancel,
      fileVideoSrc,
      hasVideoError,
      isLoadingVideo
    } = this.props
    const isVideoEncoding = fileVideoSrc && !isVideoReady
    // console.log("isVideoEncoding",this.props)
    let fileVideoSrcCheck = fileVideoSrc !== '' ? true : false
    
    // console.log("video source", fileVideoSrcCheck,isLoadingVideo) 
    // console.log("fileVideoSrc dailog component =>",fileVideoSrc,"isVideoReady",isVideoReady,"isVideoLoading",isVideoLoading)
    return (
      <div className={classes.root}>
        <div className={classes.modalTitle}>
          <h2>UploadVideo</h2>
          <div className={classes.icon}>
            <IconButton
              aria-label="delete"
              onClick={onCancel}
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              <span className="icon-close" />
            </IconButton>
          </div>
        </div>
        <div className={classes.modalBody}>
          {fileVideoSrc && isVideoReady ? (
            // <video className={classes.video} controls>
            //   <source src={fileVideoSrc} type="video/mp4" />
            //   <track label="video_challenge" src="captions_en.vtt" kind="captions" srcLang="en" />
            // </video>
            <iframe
              className={classes.videoiframe}
              title="ytVideo"
              width="560"
              height="315"
              src={fileVideoSrc}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : (
            <div className={classes.noVideo}>
              <span className="icon-video" />
              <div className={classes.textWrapper}>
                <p>{i18n.t('VIDEO.NO_VIDEO')}</p>
                <p>{i18n.t('VIDEO.VIDEO_LIMIT')}</p>
                <p>
                  {ideaType
                    ? i18n.t('VIDEO.NO_VIDEO_TEXT')
                    : i18n.t('VIDEO.NO_VIDEO_TEXT_CHALLENGE')}
                </p>
              </div>
            </div>
          )}
        </div>

        <div className={classes.buttonsWrapper}>
          <div>
            <label htmlFor="contained-button-video">
              <input
                disabled={!!fileVideoId}
                accept="video/mp4,video/x-m4v,video/*"
                className={classes.input}
                id="contained-button-video"
                type="file"
                ref={ref => {
                  this.fileInput = ref
                }}
                onChange={this.handleUpload}
              />
              <Button
                variant="contained"
                color="primary"
                component="span"
                // disabled={ isVideoLoading || !!fileVideoSrc}
                disabled={isLoadingVideo}
              >
                {isLoadingVideo  && (
                  <CircularProgress className={classes.spinner} size={20} color="primary" />
                )}
                {i18n.t('VIDEO.UPLOAD_TITLE')}
              </Button>
            </label>
            <Button onClick={onCancel}>{isVideoReady ? i18n.t('VIDEO.DONE'): i18n.t('VIDEO.CANCEL')}</Button>
          </div>
          {fileVideoId && (!isVideoLoading || hasVideoError) && (
            <Button color="secondary" onClick={() => onDeleteVideo(fileVideoId)}>
              {isVideoRemoving && (
                <CircularProgress className={classes.spinner} size={20} color="primary" />
              )}
              {i18n.t('VIDEO.DELETE')}
            </Button>
          )}
        </div>
        { (isLoadingVideo  ) &&   <p>{i18n.t('VIDEO.UPLOAD_WAIT')}</p>}
        {isVideoEncoding && !hasVideoError && <p>{i18n.t('VIDEO.VIDEO_ENCODING')}</p>}
        {hasVideoError && <p>{i18n.t('VIDEO.VIDEO_ENCODING_ERROR')}</p>}
      </div>
    )
  }
}

VideoUploadDialog.defaultProps = {
  isVideoLoading: false,
  isVideoReady: false,
  isVideoRemoving: false,
  hasVideoError: false,
  fileVideoId: '',
  fileVideoSrc: '',
}

VideoUploadDialog.propTypes = {
  fileVideoSrc: PropTypes.string,
  fileVideoId: PropTypes.string,
  isVideoLoading: PropTypes.bool,
  isVideoReady: PropTypes.bool,
  isVideoRemoving: PropTypes.bool,
  hasVideoError: PropTypes.bool,
  onUploadVideo: PropTypes.func.isRequired,
  onDeleteVideo: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default withStyles(styles)(VideoUploadDialog)

import { connect } from 'react-redux'
import I18n from '../../support/i18n'
import { showPopupErrorSnackbar, showPopupSuccessSnackbar } from '../../support/popup_dialogs'
import View from './View'
import { getAboutusDetails } from '../../services/about_us'

const mapStateToProps = state => ({
  aboutus: state.data.aboutus,
})

const mapDispatchToProps = dispatch => {
  return {
    getAboutUsDetails: () => {
      const onSuccess = () => {
        // showPopupSuccessSnackbar(I18n.t('CUSTOM_FOOTER.SAVE_OK'), dispatch)
      }
      const onFailure = () => {
        showPopupErrorSnackbar(I18n.t('CUSTOM_FOOTER.SAVE_KO'), dispatch)
      }
      // dispatch(getAboutUsDetails())
      dispatch(getAboutusDetails(onSuccess, onFailure))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

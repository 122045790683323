import { createStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    span: {
      fontSize: '14px',
      textTransform: 'none',
    },
    icon: {
      backgroundColor: props => props.backgroundColor,
      color: props => props.color,
      width: '20px',
      height: '20px',
      borderRadius: '100%',
      textAlign: 'center',
      lineHeight: '20px',
      fontSize: '11px',
      marginRight: '8px',
      boxShadow: '0 1px 0 rgba(0,0,0,.2)',
    },
  })

export default styles

class Analytics {
  constructor() {
    if (!Analytics.instance) {
      Analytics.instance = this
      this.id = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACK_ID
      this.initialised = false
      this.queue = []
      /* eslint-disable */
      // prettier-ignore
      const createGa = function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      }
      createGa(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')

      window.ga('create', this.id, 'auto')
    }
    return Analytics.instance
  }

  initialise(id) {
    this.rollupId = id

    if (window.ga.rollup === false && this.rollupId) {
      window.ga('create', this.rollupId, 'auto', { name: 'rollup' })
      window.ga.rollup = true
    }
    this.initialised = !!this.id
    this.processQueue()
  }

  trackEvent({ category, action, label, value, nonInteraction, transport }) {
    this.queue.push({ event: 'event', category, action, label, value, nonInteraction, transport })
    if (this.initialised) {
      this.processQueue()
    }
  }

  trackPageView(page) {
    this.queue.push({ event: 'pageView', page })
    if (this.initialised) {
      this.processQueue()
    }
  }

  processQueue() {
    this.queue.forEach(({ event, category, action, label, value, nonInteraction, transport }) => {
      window.ga &&
        window.ga('send', event, category, action, label, value, nonInteraction, transport)
      if (window.ga.rollup === true) {
        ga('rollup.send', event, category, action, label, nonInteraction, transport)
      }
    })
    this.queue = []
  }
}

export default new Analytics()

const styles = theme => ({
  root: {
    '& > :first-child': {
      padding: theme.spacing(2, 0),
    },
  },
  viewAsContainer: {
    '& > span': {
      fontSize: '0.9rem',
      marginRight: theme.spacing(),
    },
  },
  bottomTableTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  userTableContainer: {
    display: 'flex',
  },
  filterPanel: {
    flex: '0 0 200px',
    flexGrow: '0',
    flexShrink: '0',
    flexBasis: '200px',
    paddingRight: '19px',
    paddingTop: '15px',
    borderRight: '1px solid hsla(0,0%,59.2%,.28)',
    position: 'relative',
    transition: 'flex .4s',
  },
  userTable: {
    paddingLeft: '20px',
    overflow: 'hidden',
    flex: '1',
    paddingTop: '30px',
    transition: 'flex .4s',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    width: theme.spacing(24),
    marginTop: theme.spacing(2),
  },
})

export default styles

import { connect } from 'react-redux'
import { getHowItWorksInfoPointsService } from 'services/domain'
import View from './View'

const mapStateToProps = state => {
  return {
    domainDataLoaded: state.data.domain.dataLoaded,
    infoPoints: state.data.howItWorks.infoPoints,
    infoPointsLoaded: state.data.howItWorks.infoPointsLoaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getHowItWorksInfo: () => {
      dispatch(getHowItWorksInfoPointsService())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

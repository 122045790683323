import { connect } from 'react-redux'
import Navigation from 'support/navigation'
import { applySearchService, applyFilterService } from 'services/ideas'
import { getActiveOrganizations } from 'services/organizations'
import { getActiveTypes } from 'services/types'
import { FIRST_LIMIT_CONSTANT } from 'common_constants/Ideas'
import View from './View'

const mapStateToProps = state => ({
  challenge: state.data.challenge.challengeById,
  isAdmin: state.data.user.admin,
  isInnovator: state.data.user.isInnovator,
  isOrganizationsActive: state.data.domain.domainConfiguration.isOrganizationsActive,
  isTypesActive: state.data.domain.domainConfiguration.isTypesActive,
  ideas: state.data.idea.data,
  totalIdeas: state.data.idea.total,
  organizations: state.data.domain.organizations,
  folderOrganizations: state.data.domain.folderOrganizations,
  organizationsName: state.data.domain.domainConfiguration.organizationsName,
  types: state.data.domain.types,
  hasMoreIdeasToLoad: state.data.idea.hasMore,
  isIdeasLoading: state.data.idea.ideasLoading,
})

const mapDispatchToProps = dispatch => {
  return {
    getPrivateIdeas: ({ challenge, organizations, status, types, offset } = {}) => {
      dispatch(
        applyFilterService({
          challenge,
          organizations,
          status,
          types,
          limit: FIRST_LIMIT_CONSTANT,
          offset,
          shouldLoadPrivateIdeas: true,
        }),
      )
    },
    applySearch: search => {
      dispatch(applySearchService({ search, shouldLoadPrivateIdeas: true }))
    },
    getOrganization: () => {
      dispatch(getActiveOrganizations())
    },
    getTypes: () => {
      dispatch(getActiveTypes())
    },
    navigateToIdeaDetail: id => {
      dispatch(Navigation.navigateToIdea(id))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { ButtonLink } from 'components'

const IdeasColumnListItem = props => {
  const { classes, id, header, ico, navigateTo, name, desc } = props

  return (
    <Fragment>
      {!header && (
        <li key={id.toString()} className={classes.helpItem}>
          <b className={ico} />
          <div>
            <ButtonLink onLinkClick={navigateTo}>{name}</ButtonLink>
            {desc && <small>{desc}</small>}
          </div>
        </li>
      )}
    </Fragment>
  )
}

IdeasColumnListItem.propTypes = {
  classes: PropTypes.shape({
    helpItem: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number.isRequired,
  header: PropTypes.bool.isRequired,
  ico: PropTypes.string.isRequired,
  navigateTo: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
}

export default IdeasColumnListItem

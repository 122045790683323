import React, { Component, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, IconButton, Menu, MenuItem, Button } from '@material-ui/core'
import { Filter, Slider } from 'components'
import ShowFilter from 'components/show_filter/View'
import PrivateIdeasBanner from 'pages/explore/components/private_ideas_banner/View'
import AnalyticsService from 'support/analytics'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import i18n from 'support/i18n'
import { FIRST_LIMIT_CONSTANT, LIMIT_CONSTANT } from 'common_constants/Ideas'
import SliderCard from './components/slider_card/View'
import ExploreBottom from './components/explore_bottom/View'
import ExploreFilterFields from './components/explore_filter_fields/View'
import SearchInput from './components/search_input/View'
import style from './View.Style'
import Image from 'components/image/View'
import rocketNotification from 'img/bulb_rocket.png'
import { differenceInCalendarDays } from 'date-fns'

class Explore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      activeFilters: {
        status: [],
        challenge: {},
        organizations: [],
        folderOrganizations: [],
        types: [],
      },
      search: '',
      showSlider: false,
      offset: 0,
      anchorElExpiring: null,
    }
    this.handleChange = this.handleChange.bind(this)
    this.setActiveStep = this.setActiveStep.bind(this)
    this.handleCardTypeChange = this.handleCardTypeChange.bind(this)
    this.handleActiveFilters = this.handleActiveFilters.bind(this)
    this.handleOnLoadMore = this.handleOnLoadMore.bind(this)
    this.handleNavigateToNewIdea = this.handleNavigateToNewIdea.bind(this)
    this.handleResetSearchClick = this.handleResetSearchClick.bind(this)
    this.getIdeas = this.getIdeas.bind(this)
    this.handleExpiringIdeasClick = this.handleExpiringIdeasClick.bind(this)
    this.handleExpiringIdeasClose = this.handleExpiringIdeasClose.bind(this)
    this.CountryApiTest= this.CountryApiTest.bind(this)
    this.CountryINApiTest= this.CountryINApiTest.bind(this)
    this.ApiTest= this.ApiTest.bind(this)
  }

  

  componentDidMount() {
    const { getUserOrganisations, getActiveChallenges, getRankings, domainLoaded, getExpiringNearFunded, getTypes, getApiResponseEris,getTestCountrySection,getTestCountryINSection } = this.props
    if (domainLoaded) {
      // console.log("in here look")
      getUserOrganisations()
      getRankings()
      getActiveChallenges()
      getTypes()
      getExpiringNearFunded()
      getTestCountrySection()
      getTestCountryINSection()
      getApiResponseEris()
    }

  }

  componentDidUpdate(prevProps) {
    
    const {
      useHelpSection,
      getHelpSection,
      getHeaderSection,
      domainLoaded,
      getActiveChallenges,
      getRankings,
      organisationsSelected,
      hasUserOrganisationsLoaded,
      getUserOrganisations,
      getTypes,
      getExpiringNearFunded,
      expiringIdeas,
    } = this.props
    if (useHelpSection && prevProps.useHelpSection !== useHelpSection) {
      getHeaderSection()
      getHelpSection()
    }
    if (domainLoaded && prevProps.domainLoaded !== domainLoaded) {
      getUserOrganisations()
      getActiveChallenges()
      getExpiringNearFunded()
      getRankings()
      getTypes()
    }
    if (
      domainLoaded &&
      hasUserOrganisationsLoaded &&
      (prevProps.domainLoaded !== domainLoaded ||
        prevProps.hasUserOrganisationsLoaded !== hasUserOrganisationsLoaded)
    ) {
      const { activeFilters } = this.state
      const { organizations } = this.props
      // console.log(`make sure orgs are here: ${organizations}`)
      // console.log("In the set state stuff here line 80")
      this.setState(
        {
          activeFilters: {
            ...activeFilters,
            folderOrganizations: organisationsSelected,
          },
        },
        this.getIdeas,
      )
    }
  }


  setActiveStep(newStep) {
    this.setState({ activeStep: newStep })
  }

  getIdeas(offset = 0) {
    const { applyFilter } = this.props
    const { activeFilters } = this.state
    // console.log("ACTIVE FILTERS")
    // console.log(activeFilters)
    const { status, challenge, organizations, folderOrganizations, types, ideasLocation } = activeFilters
    applyFilter(
      challenge.id || '',
      folderOrganizations.map(org => `${org.name}`),
      status,
      types.map(type => `${type.id}`),
      ideasLocation,
      offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT,
      offset,
    )
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleCardTypeChange(value) {
    this.setState({ showSlider: value })
    AnalyticsService.trackEvent({
      category: 'explore-ideas',
      action: 'display',
      label: value ? 'carousel' : 'grid',
    })
  }

  handleActiveFilters(newFilters) {
    this.setState({ ...newFilters, offset: 0 }, this.getIdeas)
  }

  handleOnLoadMore() {
    const { offset } = this.state
    const newLimit = offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT
    const newOffset = newLimit + offset
    this.getIdeas(newOffset)
    this.setState({ offset: newOffset })
  }

  handleNavigateToNewIdea() {
    const { isPrivateFlowActive, navigateToNewIdea } = this.props
    navigateToNewIdea(isPrivateFlowActive)
  }

  handleResetSearchClick() {
    this.getIdeas()
    this.setState({ offset: 0 })
  }

  handleExpiringIdeasClick = (event) => {
    this.setState({ anchorElExpiring: event.currentTarget })
  };
  handleExpiringIdeasClose = () => {
    this.setState({ anchorElExpiring: null })
  };
  
  CountryApiTest =()=>{
  const {getTestCountrySection}=  this.props
    getTestCountrySection()
  }

  CountryINApiTest =()=>{
    const {getTestCountryINSection}=  this.props
      getTestCountryINSection()
    }

ApiTest =()=>{
    const {getApiResponseEris}=  this.props
      getApiResponseEris()
    }

  render() {
    const {
      applyFilter,
      applySearch,
      challenges,
      classes,
      footerInvestors,
      footerEntrepreneurs,
      getOrganization,
      getTypes,
      headerSection,
      helpList,
      ideas,
      isIdeasLoading,
      totalIdeas,
      organizationsName,
      navigateToChallengeDetail,
      navigateToExternal,
      // navigateToHowItWorks,
      navigateToIdea,
      navigateToStaticHelpNextinit,
      navigateToRanking,
      navigateToUserProfile,
      navigateToNewPrivateIdea,
      organizations,
      folderOrganizations,
      organisationsSelected,
      types,
      isOrganizationsActive,
      isTypesActive,
      hasMoreIdeasToLoad,
      isPrivateFlowActive,
      privateBannerSrc,
      ideaStatusStepper,
      navigateToAboutus,
      expiringIdeas,
      typeTitle,
    } = this.props
    const { activeStep, activeFilters, search, showSlider, offset, anchorElExpiring } = this.state
    const expiringMenuOpen = Boolean(anchorElExpiring)
    const expiringIdeasMenuItems = expiringIdeas.map((idea, index) => {
      return(
        <MenuItem onClick={() => navigateToIdea(idea.id, index)}>
          <b>{idea.title}</b>{`: ${Math.round((idea.pledged/idea.goal)*100)}% funded | ${  differenceInCalendarDays(idea.countdownEnds, Date.now())} days left`}
        </MenuItem>
      )
    })
    
    return (
      <Fragment>
        {/* {expiringIdeas.length > 0 && */}
        <Fragment>

          <IconButton
            id="confirm-delete"
            onClick={this.handleExpiringIdeasClick}
            className={classes.iconButton}
            aria-label="confirm"
          >
            <Image style={{ width: '80px', height: '80px' }} src={rocketNotification} defaultSrc={rocketNotification} />
            <span className={classes.expiringIdeasNum}>
              {expiringIdeas.length}
            </span>
          </IconButton>
          <span className={classes.expiringIdeasTitle}>Expiring Soon!</span>
          <Menu
            id="basic-menu"
            anchorEl={anchorElExpiring}
            open={expiringMenuOpen}
            onClose={this.handleExpiringIdeasClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {expiringIdeasMenuItems}
          </Menu>
        </Fragment>
        {/* } */}
        {challenges.length > 0 && (
          <div className={classes.top}>
            <div className={classes.topCenter}>
              <Slider
                activeStep={activeStep}
                maxSteps={challenges.length}
                setActiveStep={this.setActiveStep}
              >
                {challenges.map((card, index) => (
                  <Fragment key={card.id}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <SliderCard
                        starts={card.starts}
                        ends={card.ends}
                        mainPhoto={card.mainPhoto}
                        label={card.label}
                        proposerPhoto={card.proposerPhoto}
                        proposerName={card.proposerName}
                        sponsorName={card.sponsorName}
                        sponsorPhoto={card.sponsorPhoto}
                        name={card.name}
                        numIdeas={card.numIdeas}
                        ideas={card.ideas}
                        desc={card.desc}
                        id={card.id}
                        navigateToChallengeDetail={navigateToChallengeDetail}
                        navigateToIdea={navigateToIdea}
                      />
                    ) : null}
                  </Fragment>
                ))}
              </Slider>
              <div className={classes.fill1} />
              <div className={classes.fill2} />
            </div>
          </div>
        )}

        <div className={classes.middle}>
          <div className={classes.center}>
            {isPrivateFlowActive && (
              <PrivateIdeasBanner
                imageSrc={privateBannerSrc}
                onBannerClick={navigateToNewPrivateIdea}
              />
            )}
            {!isPrivateFlowActive && (
              <Fragment>
                <h1 className={classes.mainTitle}>{i18n.t('HOME.FILTER.TEXT.HEADING')}</h1>
                <Filter className={classes.center}>
                  <ExploreFilterFields
                    activeFilters={activeFilters}
                    challenges={challenges}
                    className={classes.gridCenter}
                    getOrganization={getOrganization}
                    getTypes={getTypes}
                    organizationsName={organizationsName}
                    typeTitle={typeTitle}
                    organizations={organizations}
                    folderOrganizations={folderOrganizations}
                    organizationsApplied={organisationsSelected}
                    types={types}
                    isOrganizationsActive={isOrganizationsActive}
                    isTypesActive={isTypesActive}
                    handleActiveFilters={this.handleActiveFilters}
                  />
                </Filter>
                <div className={classes.ideasFilter}>
                  <SearchInput
                    applySearch={applySearch}
                    resetSearch={this.handleResetSearchClick}
                    handleChange={this.handleChange}
                    value={search}
                  />
                </div>
                <NxtCircularLoader isLoading={isIdeasLoading}>
                  <ShowFilter
                    cards={ideas}
                    totalIdeas={totalIdeas}
                    onClick={navigateToIdea}
                    onSubmitterClick={navigateToUserProfile}
                    showSlider={showSlider}
                    onCardTypeChange={this.handleCardTypeChange}
                    activeFilters={activeFilters}
                    applyFilter={applyFilter}
                    onLoadMore={this.handleOnLoadMore}
                    offset={offset}
                    showLoadMoreButton={hasMoreIdeasToLoad}
                    ideaStatusStepper={ideaStatusStepper}
                    showSmallCardCircle
                  />
                </NxtCircularLoader>

                <button style={{background:'red',padding:'8px',margin:'8px'}} onClick={this.CountryApiTest}>Click Country API</button>
                <button style={{background:'red',padding:'8px',margin:'8px'}} onClick={this.CountryINApiTest}>Click Country API With IN</button>
                <button style={{background:'red',padding:'8px',margin:'8px'}} onClick={this.ApiTest}>Click API Test</button>

              </Fragment>
            )}
          </div>
        </div>
        {!isPrivateFlowActive && (
          <div className={classes.bottom}>
            <div className={classes.exploreBottom}>
              <ExploreBottom
                footerInvestors={footerInvestors}
                footerEntrepreneurs={footerEntrepreneurs}
                headerSection={headerSection}
                helpList={helpList}
                expiringIdeas={expiringIdeas}
                navigateToExternal={navigateToExternal}
                navigateToIdea={this.handleNavigateToNewIdea}
                navigateToStaticHelpNextinit={navigateToStaticHelpNextinit}
                // navigateToHowItWorks={navigateToHowItWorks}
                navigateToRanking={navigateToRanking}
                navigateToUserProfile={navigateToUserProfile}
                navigateToAboutus={navigateToAboutus}
              />
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

Explore.defaultProps = {
  headerSection: {},
  organizations: [],
  types: [],
  isOrganizationsActive: false,
  isTypesActive: false,
}

Explore.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  applySearch: PropTypes.func.isRequired,
  challenges: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      ideas: PropTypes.array.isRequired,
      mainPhoto: PropTypes.string.isRequired,
      numIdeas: PropTypes.string.isRequired,
      proposerName: PropTypes.string.isRequired,
      proposerPhoto: PropTypes.string.isRequired,
      sponsorName: PropTypes.string,
      sponsorPhoto: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  classes: PropTypes.shape({
    bottom: PropTypes.string.isRequired,
    middle: PropTypes.string.isRequired,
    top: PropTypes.string.isRequired,
  }).isRequired,
  footerInvestors: PropTypes.arrayOf(
    PropTypes.shape({
      amountInvested: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      fullname: PropTypes.string.isRequired,
      ideas: PropTypes.number.isRequired,
      investments: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string,
    }),
  ).isRequired,
  footerEntrepreneurs: PropTypes.arrayOf(
    PropTypes.shape({
      amountInvested: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      fullname: PropTypes.string.isRequired,
      ideas: PropTypes.number.isRequired,
      investments: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      photo: PropTypes.string,
    }),
  ).isRequired,
  getActiveChallenges: PropTypes.func.isRequired,
  getHeaderSection: PropTypes.func.isRequired,
  getHelpSection: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  getRankings: PropTypes.func.isRequired,
  getTypes: PropTypes.func.isRequired,
  headerSection: PropTypes.shape({}),
  helpList: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string.isRequired,
      header: PropTypes.bool.isRequired,
      ico: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  ideas: PropTypes.arrayOf(
    PropTypes.shape({
      challenged: PropTypes.bool,
      textWithoutTags: PropTypes.string,
      id: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          bucket: PropTypes.string,
          key: PropTypes.string,
          name: PropTypes.string,
          size: PropTypes.number,
          url: PropTypes.string,
        }),
      ),
      investors: PropTypes.string,
      goal: PropTypes.string,
      pledged: PropTypes.string,
      sponsored: PropTypes.bool,
      ownerName: PropTypes.string,
      title: PropTypes.string,
      created: PropTypes.number,
      countdownEnds: PropTypes.number,
    }),
  ).isRequired,
  isIdeasLoading: PropTypes.bool.isRequired,
  isOrganizationsActive: PropTypes.bool,
  isTypesActive: PropTypes.bool,
  isPrivateFlowActive: PropTypes.bool.isRequired,
  navigateToChallengeDetail: PropTypes.func.isRequired,
  navigateToExternal: PropTypes.func.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  // navigateToHowItWorks: PropTypes.func.isRequired,
  navigateToStaticHelpNextinit: PropTypes.func.isRequired,
  navigateToRanking: PropTypes.func.isRequired,
  navigateToUserProfile: PropTypes.func.isRequired,
  navigateToNewIdea: PropTypes.func.isRequired,
  navigateToNewPrivateIdea: PropTypes.func.isRequired,
  navigateToAboutus: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  organizationsName: PropTypes.string.isRequired,
  privateBannerSrc: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  useHelpSection: PropTypes.bool.isRequired,
  domainLoaded: PropTypes.bool.isRequired,
}

export default withStyles(style)(Explore)

import React from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import styles from './View.Style'

const HeaderUserBalance = props => {
  const { classes, userBalance, userName } = props
  return (
    <div className={classes.root}>
      {userName && (
        <p className={clsx(classes.ellipsis, classes.userName)}>
          {i18n.t('HELLO')}, {userName}!
        </p>
      )}
      <span className={classes.balance}>{i18n.t('INVESTMENTS.BALANCE')}</span>
      <p className={clsx(classes.amount, classes.ellipsis)}>
        <b className={clsx(classes.arrow, 'icon-lower')} />
        <span className={classes.userBalance}>
          <NxtVirtualCurrency>{userBalance}</NxtVirtualCurrency>
        </span>
      </p>
    </div>
  )
}

HeaderUserBalance.propTypes = {
  userBalance: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
}

export default withStyles(styles)(HeaderUserBalance)

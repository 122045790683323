const style = theme => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  titles: {
    width: theme.spacing(30),
    marginRight: theme.spacing(5),
    '& > h3': {
      '& > span[class*="icon-"]': {
        marginRight: theme.spacing(1),
      },
    },
    '& p': {
      fontSize: '12px',
    },
  },
  formContainer: {
    width: '100%',
  },
})

export default style

import axios from 'axios'
import i18n from 'support/i18n'
import { uploadDocumentSuccess, uploadDocumentFailure } from 'actions/sync/create_idea_form'
import { checkVideoState } from 'services/videos'

const videoIdInProcess = {}

export const uploadFileThunk = (url, file) => {
  return async (dispatch, getState) => {
    try {
      const formData = new FormData()
      formData.append('files', file)

      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      dispatch(uploadDocumentSuccess(response.files[0]))
    } catch (error) {
      dispatch(uploadDocumentFailure(i18n.t('EXPLORE_IDEA.UPLOAD_DOC_ERROR')))
    } finally {
      if (getState().data.createIdea.documents.length === 0) {
        // TODO: remove this when everything goes well
        const responseFile = {
          id: '123789137812',
          name: 'upload.svg',
          size: 1516,
          key:
            'AMIfv9681BxbY5aBqOR91SYmgJjMDc8TAJN55Nx3AE0sWD0Qx3G426AWUcB85XWGNuYT23cCJwUrGW7A5e0gywydcS4aZTvXfoiXQAm5PnBbjxHsWPAM7dxmWDsKGxMWJ4qz4OmY44IbB7kjh3c6N_EtOaONhbsze7FSjrE7ns3oWiYSTGTUrrY',
        }
        dispatch(uploadDocumentSuccess(responseFile))
      }
    }
  }
}

export const startConfirmationService = ({ isIdea, videoId, maxRetries = 20 }) => dispatch => {
  let timeoutId
  if (!videoIdInProcess[`${videoId}`]) {
    videoIdInProcess[`${videoId}`] = true
    const onSuccess = retries => response => {
      if (timeoutId) clearTimeout(timeoutId)
      if (!response.isVideoReady && retries > 0 && !response.hasVideoError) {
        timeoutId = setTimeout(() => {
          dispatch(checkVideoState(isIdea, videoId, onSuccess(retries - 1)))
        }, 5000)
      } else {
        delete videoIdInProcess[`${videoId}`]
      }
    }
    dispatch(checkVideoState(isIdea, videoId, onSuccess(maxRetries)))
  }
}

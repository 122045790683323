const styles = theme => ({
  root: {},
  title: {},
  body: {},
  linkButton: {
    color: theme.palette.primary.main,
    fontSize: 'inherit',
  },
  quote: {
    display: 'flex',
    alignItems: 'center',
    '&:before': {
      content: "'\\201c'",
      fontSize: '38px',
      lineHeight: 1,
      float: 'left',
      marginRight: 10,
      marginTop: theme.spacing(0.5),
    },
  },
  status: {
    fontSize: '12px',
    color: '#999',
  },
})

export default styles

import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    container: {
      // display: 'flex',
      // flexWrap: 'wrap',
      // margin: theme.spacing(-1),
      // padding: '1rem 0',
      '& > button': {
      //   margin: theme.spacing(1),
      //   alignSelf: "center",
      // borderRadius: "44px",
      // // backgroundColor: "#F63F40",
      // border: "none",
      // cursor: "pointer",
      // fontFamily: theme.fontFamily.fontFamilyRegular,
      // textTransform:'capitalize',
      // fontSize: "18px",
      // whiteSpace: "nowrap",
      // overflow: "hidden",

      height: '38px',
      color: '#FFFFFF',
      fontFamily: 'VodafoneRg-Regular',
      fontSize: '18px',
      display: 'inline-flex',
      minWidth: '120px',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px 16px',
      marginLeft: '0px',
      marginRight:'8px',
      marginBottom:'8px',
      marginTop:'8px',
      cursor: 'pointer',
      borderRadius: '22px',
      border:'0px',
      backgroundColor:'#6d45ff',

      },
    },
    
  })

export default styles

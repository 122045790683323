const styles = theme => ({
  root: {
    height: theme.spacing(9),
    display: 'flex',
    alignItems: 'center',
  },
  suggestionText: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    '& span': {
      margin: theme.spacing(0.25, 0),
    },
  },
  fullnameSuggestion: {
    fontWeight: '700',
  },
  removeSuggestion: {
    color: '#BD362F',
    fontWeight: '400',
    fontSize: '14px',
    cursor: 'pointer',
    '& > span': {
      color: '#BD362F',
    },
  },
})

export default styles

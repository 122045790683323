import { createStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    container: {
      padding: '3rem',
    },
    media: {
      margin: '10px auto 0',
      width: '100%',
      height: 600,
      borderRadius: '4px',
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
      position: 'relative',
      zIndex: 1000,
    },
    table: {
      width: '100%',
      marginTop: '50px',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      '& td': {
        border: '1px solid #dcdcdc',
        padding: '30px 10px',
        textAlign: 'center',
      },
    },
  })

export default styles

import { ServiceCall } from 'support/network/ServiceCallAction'
import {
  GET_ACTIVE_GOALS_STARTED,
  GET_ACTIVE_GOALS_SUCCESS,
  GET_ACTIVE_GOALS_FAILURE,
} from 'actions/sync/goals/Constants'
import { transformGoalServiceResponseDataWithState } from './ResponseUtils'
import { prepareHeadersFromState } from '../HeadersUtils'

export const GET_ACTIVE_GOALS_URL = '/s/domain/goal/active'

export function getActiveGoals() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_ACTIVE_GOALS_STARTED
  apiCall.successReqType = GET_ACTIVE_GOALS_SUCCESS
  apiCall.failureReqType = GET_ACTIVE_GOALS_FAILURE
  apiCall.setEndpointFromState = () => GET_ACTIVE_GOALS_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.transformResponseDataWithState = transformGoalServiceResponseDataWithState
  apiCall.auth = true
  return apiCall.getAction()
}

const styles = theme => ({
  modalPreview: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 960,
    borderRadius: 5,
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: theme.palette.secondary.main,
    height: 60,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: '11px 15px',
  },
  modalLogoHeader: {
    height: 60,
  },
  modalTitleHeader: {
    display: 'block',
    position: 'relative',
    width: '100%',
    color: theme.palette.headerText ? theme.palette.headerText.main : '#FFF',
    fontSize: 24,
    textAlign: 'center',
  },
  modalBody: {
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
  },
  modalContentBody: {
    color: theme.palette.common.black,
    display: 'flex',
    flexGrow: 1,
    marginTop: 30,
    fontSize: 14,
    flexDirection: 'column',
  },
  modalLogoBody: {
    height: 300,
  },
  footer: {
    padding: 15,
    height: 45,
    backgroundColor: '#f5f5f5',
    border: '1px solid #ffff',
    borderTopColor: '#e5e5e5',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export default styles

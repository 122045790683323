const styles = () => ({
  root: {},
  title: {},
  body: {},
  linkButton: {
    textDecoration: 'unset',
    fontFamily: 'unset',
    '&:hover': {
      textDecoration: 'unset',
    },
  },
})

export default styles

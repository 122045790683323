import React, { Component, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import i18n from 'support/i18n'
import { ButtonLink } from 'components'
import IdeasColumnListItem from './components/ideas_column_list_item/View'

class IdeasColumn extends Component {
  renderList = () => {
    const { classes, helpList, navigateToExternal, showHelp } = this.props
    if (showHelp && helpList) {
      return (
        <ul className={classes.helpList}>
          {helpList.map(element => {
            const { desc, header, id, ico, name } = element
            let { url } = element
            const navigateTo = () => {
              if (url.includes('http://')) {
                url.replace('http', 'https')
              } else if (!url.includes('http')) {
                url = `https://${url}`
              }
              navigateToExternal(url)
            }

            return (
              <Fragment key={id.toString()}>
                <IdeasColumnListItem
                  classes={classes}
                  header={header}
                  navigateTo={navigateTo}
                  desc={desc}
                  ico={ico}
                  id={id}
                  name={name}
                />
              </Fragment>
            )
          })}
        </ul>
      )
    }
    return null
  }

  renderButtonIdea = () => {
    const { showHelp, navigateToIdea } = this.props
    if (!showHelp) {
      return (
        <Fragment>
          <p>{i18n.t('SHARE_IDEA')}</p>
          <Button variant="contained" color="primary" onClick={navigateToIdea}>
            {i18n.t('SUBMIT_IDEA')}
          </Button>
        </Fragment>
      )
    }
    return null
  }

  render() {
    const { classes, headerSection, navigateToIdea, navigateToExternal, navigateToAboutus } = this.props
    const sectionName = headerSection.name
    const sectionIcon = headerSection.icon || 'icon-idea'
    const sectionUrl = headerSection.url
    return (
      <Fragment>
        <div className={classes.title}>
          <span className={sectionIcon} />
          <h3>
            <ButtonLink
              onLinkClick={sectionUrl ? () => navigateToExternal(sectionUrl) : navigateToIdea}
            >
              {sectionName || i18n.t('HOME.FOOTER.SUBMIT_IDEA')}
            </ButtonLink>
          </h3>
        </div>
        {this.renderButtonIdea()}
        {this.renderList()}
        <div className={classes.howItWorks}>
          <strong>
            <b className="icon-help" />
            <ButtonLink onLinkClick={navigateToAboutus}>{i18n.t('HOWITWORKS.TITLE')}</ButtonLink>
          </strong>
        </div>
      </Fragment>
    )
  }
}

IdeasColumn.defaultProps = {
  headerSection: {},
  helpList: null,
}

IdeasColumn.propTypes = {
  classes: PropTypes.shape({
    helpList: PropTypes.string.isRequired,
    helpItem: PropTypes.string.isRequired,
    linkButton: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  headerSection: PropTypes.shape({}),
  helpList: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string.isRequired,
      header: PropTypes.bool.isRequired,
      ico: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  showHelp: PropTypes.bool.isRequired,
  navigateToExternal: PropTypes.func.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  navigateToAboutus: PropTypes.func.isRequired,
}

export default IdeasColumn

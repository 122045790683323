import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Typography, Divider } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'
import DescriptionPanel from './components/description_panel/View'
import ImageVideoPanel from './components/ImageVideoPanel/View'

class Description extends Component {
  constructor(props) {
    super(props)
    this.state = { globalChallenge: false }
    this.title = i18n.t('CHALLENGES.CHALLENGE_DESCRIPTION')
    this.imageAndVideoTitle = i18n.t('CHALLENGES.IMAGES_VIDEOS')
    this.descriptionPanelRef = React.createRef()
    this.imagesPanelRef = React.createRef()
    this.handleChangeGlobalChallenge = this.handleChangeGlobalChallenge.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { errorObject } = this.props
    if (prevProps.errorObject !== errorObject && Object.keys(errorObject).length > 0) {
      const refToScroll = Object.keys(errorObject).find(
        key => key === 'title' || key === 'description',
      )
        ? this.descriptionPanelRef
        : this.imagesPanelRef
      refToScroll.current.scrollIntoView()
    }
  }
  handleChangeGlobalChallenge = event => {
    const { onChallengeLocation } = this.props
    onChallengeLocation(event)
    this.setState({ globalChallenge: event.target.checked })
  }

  render() {
    const {
      classes,
      onTitleChange,
      onWhyImportantChange,
      challengeDescription,
      onDescriptionChange,
      onSelectImage,
      onSelectMainImage,
      deleteImage,
      onUploadExternalVideo,
      onRemoveVideo,
      onUploadVideoFile,
      onRemoveExternalVideo,
      errorObject,
      onExternalVideoError,
      canChangeChallengeLocation,
      isServiceLineActivated,
    } = this.props
    const {
      title,
      whyImportant,
      description,
      images,
      mainImageValue,
      externalVideo,
      fileVideo,
      isVideoLoading,
      isVideoReady,
      isVideoRemoving,
      hasVideoError,
    } = challengeDescription

    return (
      <div className={classes.root}>
        <Typography variant="h5" className={classes.mainTitle} ref={this.descriptionPanelRef}>
          {this.title}
        </Typography>
        <Divider />
        <div className={classes.autosuggestionsWrapper}>
          <DescriptionPanel
            onTitleChange={onTitleChange}
            onWhyImportantChange={onWhyImportantChange}
            onDescriptionChange={onDescriptionChange}
            title={title}
            whyImportant={whyImportant}
            description={description}
            hasTitleError={errorObject.title}
            hasDescriptionError={errorObject.description}
            isGlobalChecked= {this.state.globalChallenge}
            globalChallengeValueChange= {this.handleChangeGlobalChallenge}
            canChangeChallengeLocation = {canChangeChallengeLocation}
            isServiceLineActivated={isServiceLineActivated}
          />
          {/* <div>
            Global Challenge
            <Checkbox
              checked={this.state.globalChallenge}
              onChange={this.handleChangeGlobalChallenge}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div> */}
        </div>

        <Typography variant="h5" className={classes.mainTitle} ref={this.imagesPanelRef}>
          {this.imageAndVideoTitle}
        </Typography>
        <Divider />
        <div className={classes.imageAndVideoWrapper}>
          <ImageVideoPanel
            onExternalVideoError={onExternalVideoError}
            images={images}
            mainImageValue={mainImageValue}
            onSelectImage={onSelectImage}
            onSelectMainImage={onSelectMainImage}
            deleteImage={deleteImage}
            onUploadExternalVideo={onUploadExternalVideo}
            externalVideoSrc={externalVideo}
            fileVideoSrc={fileVideo}
            isVideoLoading={isVideoLoading}
            isVideoReady={isVideoReady}
            isVideoRemoving={isVideoRemoving}
            hasVideoError={hasVideoError}
            onUploadVideoFile={onUploadVideoFile}
            onRemoveExternalVideo={onRemoveExternalVideo}
            hasImageError={errorObject.images}
            imageUploadError={errorObject.imageUploadError}
            onRemoveVideo={onRemoveVideo}
          />
        </div>
      </div>
    )
  }
}
Description.defaultProps = {
  errorObject: {},
}

Description.propTypes = {
  onTitleChange: PropTypes.func.isRequired,
  onWhyImportantChange: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  onSelectMainImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  challengeDescription: PropTypes.shape({
    title: PropTypes.string,
    whyImportant: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        src: PropTypes.string,
      }),
    ).isRequired,
    mainImageValue: PropTypes.string,
    externalVideo: PropTypes.string.isRequired,
    fileVideo: PropTypes.string,
  }).isRequired,
  onUploadExternalVideo: PropTypes.func.isRequired,
  onUploadVideoFile: PropTypes.func.isRequired,
  onRemoveExternalVideo: PropTypes.func.isRequired,
  onRemoveVideo: PropTypes.func.isRequired,
  onExternalVideoError: PropTypes.func.isRequired,
  errorObject: PropTypes.shape({
    title: PropTypes.bool,
    description: PropTypes.bool,
    image: PropTypes.bool,
    imageUploadError: PropTypes.string,
  }),
}
export default withStyles(styles)(Description)

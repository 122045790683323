class ModalDataService {
  constructor() {
    if (!ModalDataService.instance) {
      this.dialogFullViews = []
      this.snackbarMessage = undefined
      this.snackbarType = undefined
      ModalDataService.instance = this
    }

    return ModalDataService.instance
  }

  setDialogFullView(fullView) {
    this.dialogFullViews = [fullView]
  }

  addDialogFullView(fullView) {
    this.dialogFullViews = [...this.dialogFullViews, fullView]
  }

  resetDialogToLastDialog() {
    this.dialogFullViews = this.dialogFullViews.slice(0, this.dialogFullViews.length - 1)
  }

  getDialogFullViews() {
    return this.dialogFullViews
  }

  setSnackbarData(message, variant) {
    this.snackbarMessage = message
    this.snackbarType = variant
  }

  getSnackbarData() {
    return {
      message: this.snackbarMessage,
      type: this.snackbarType,
    }
  }
}

const instance = new ModalDataService()

export default instance

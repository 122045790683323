import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    root: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2.5),
    },
  })

export default styles

import { ServiceCall } from 'support/network/ServiceCallAction'
import {
  DELETE_CHALLENGE_BY_ID_STARTED,
  DELETE_CHALLENGE_BY_ID_SUCCESS,
  DELETE_CHALLENGE_BY_ID_FAILURE,
  GET_ACTIVE_CHALLENGES_FAILURE,
  GET_ACTIVE_CHALLENGES_STARTED,
  GET_ACTIVE_CHALLENGES_SUCCESS,
  GET_CHALLENGE_BY_ID_STARTED,
  GET_CHALLENGE_BY_ID_SUCCESS,
  GET_CHALLENGE_BY_ID_FAILURE,
  GET_CHALLENGE_VISITORS_BY_ID_STARTED,
  GET_CHALLENGE_VISITORS_BY_ID_SUCCESS,
  GET_CHALLENGE_VISITORS_BY_ID_FAILURE,
  POST_FINISH_CHALLENGE_BY_ID_STARTED,
  POST_FINISH_CHALLENGE_BY_ID_SUCCESS,
  POST_FINISH_CHALLENGE_BY_ID_FAILURE, 
  GET_FIRST_SEARCH_APPLY_FILTER_FAILURE,
  GET_FIRST_SEARCH_APPLY_FILTER_STARTED,
  GET_FIRST_SEARCH_APPLY_FILTER_SUCCESS,
} from 'actions/sync/challenges/Constants'
import { FIRST_LIMIT_CONSTANT } from 'common_constants/Ideas'
import { transformGetChallengeService, transformGetChallengeByIdService, transformFilterChallengeServiceResponseDataWithState } from './ResponseUtils'
import { prepareHeadersFromState } from '../HeadersUtils'
import { setEndpoint } from './RequestUtils'

export const GET_ACTIVE_CHALLENGES_URL = '/api/v1/challenge'
export const POST_FINISH_CHALLENGE_BY_ID_URL = '/s/v1.0/challenge/##id##/finish'
export const DELETE_CHALLENGE_BY_ID_URL = 's/v1.0/challenge/##id##'
export const GET_CHALLENGE_VISITORS_BY_ID_URL = '/s/v1.0/challenge/downloadVisitors/##id##'


export const FILTER_CHALLENGE_URL = '/api/v1/challenge/filter'
export const GET_PRIVATE_CHALLENGE_URL = '/api/v1/challenge/filter-private'
// challenge apply filter

export function getActiveChallenges(status = 'ACTIVE',limitValue = FIRST_LIMIT_CONSTANT, offset=0, limitGroups = false, ) {
    // console.log("geservice",offset,status)
  const apiCall = new ServiceCall()
  apiCall.startedReqType = offset ? GET_ACTIVE_CHALLENGES_STARTED : GET_FIRST_SEARCH_APPLY_FILTER_STARTED
  apiCall.successReqType = offset ? GET_ACTIVE_CHALLENGES_SUCCESS : GET_FIRST_SEARCH_APPLY_FILTER_SUCCESS
  apiCall.failureReqType = offset ? GET_ACTIVE_CHALLENGES_FAILURE : GET_FIRST_SEARCH_APPLY_FILTER_FAILURE
  apiCall.setEndpointFromState = () =>
    `${GET_ACTIVE_CHALLENGES_URL}?offset=${offset}&status=${status}&limitGroups=${limitGroups}&limit=${limitValue}`
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.transformResponseDataWithState = transformGetChallengeService
  apiCall.auth = true
  return apiCall.getAction()
}

export function getChallengeById(id, limit = FIRST_LIMIT_CONSTANT, offset = 0) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_CHALLENGE_BY_ID_STARTED
  apiCall.successReqType = GET_CHALLENGE_BY_ID_SUCCESS
  apiCall.failureReqType = GET_CHALLENGE_BY_ID_FAILURE
  apiCall.setEndpointFromState = () =>
    `${GET_ACTIVE_CHALLENGES_URL}/${id}/?limit=${limit}&offset=${offset}`
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.transformResponseDataWithState = transformGetChallengeByIdService
  apiCall.auth = true
  return apiCall.getAction()
}

export function postFinishChallengeById(id, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = POST_FINISH_CHALLENGE_BY_ID_STARTED
  apiCall.successReqType = POST_FINISH_CHALLENGE_BY_ID_SUCCESS
  apiCall.failureReqType = POST_FINISH_CHALLENGE_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => POST_FINISH_CHALLENGE_BY_ID_URL.replace('##id##', id)
  apiCall.method = 'POST'
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  return apiCall.getAction()
}

export function deleteChallengeById(id, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = DELETE_CHALLENGE_BY_ID_STARTED
  apiCall.successReqType = DELETE_CHALLENGE_BY_ID_SUCCESS
  apiCall.failureReqType = DELETE_CHALLENGE_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => DELETE_CHALLENGE_BY_ID_URL.replace('##id##', id)
  apiCall.method = 'DELETE'
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  return apiCall.getAction()
}

export function getChallengeVisitorsById(id, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_CHALLENGE_VISITORS_BY_ID_STARTED
  apiCall.successReqType = GET_CHALLENGE_VISITORS_BY_ID_SUCCESS
  apiCall.failureReqType = GET_CHALLENGE_VISITORS_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => GET_CHALLENGE_VISITORS_BY_ID_URL.replace('##id##', id)
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

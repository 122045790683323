import { fade } from '@material-ui/core/styles'

const styles = theme => ({
  errorMessage: {
    backgroundColor: fade(theme.palette.error.main, 0.1),
    color: '#cc5b69',
    border: '1px solid #efcad5',
  },
  successMessage: {
    backgroundColor: '#dff0d8',
    color: '#3c763d',
    border: '1px solid #d6e9c6',
  },
  warningMessage: {
    backgroundColor: '#fdf7dd',
    color: '#c59415',
    border: '1px solid #ebdcb5',
  },
  messageBox: {
    padding: theme.spacing(2),
    display: 'flex',
    textAlign: 'justify',
    alignItems: 'center',
    fontSize: '0.875rem',
  },
})

export default styles

import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Styles'

const NxtBenefits = props => {
  const {
    classes,
    benefitsLabel,
    supportedLabel,
    approvedLabel,
    implementedLabel,
    supportedMult,
    approvedMult,
    implementedMult,
    showBottomLabel,
  } = props
  return (
    <div className={classes.root}>
      <div className={classes.iconLeft}>
        <span className="icon-funds" />
      </div>
      <div className={classes.benefitsContainer}>
        <div className={classes.benefitsLabel}>{benefitsLabel}</div>
        <div className={classes.benefitsValues}>
          <div className={classes.benefitsValue}>
            <span className={classes.factorBenefits}>
              <span>x</span>
              <span>{supportedMult}</span>
            </span>
            {showBottomLabel && <span className={classes.labelBenefits}>{supportedLabel}</span>}
          </div>
          <div className={classes.benefitsValue}>
            <span className={classes.factorBenefits}>
              <span>x</span>
              <span>{approvedMult}</span>
            </span>
            {showBottomLabel && <span className={classes.labelBenefits}>{approvedLabel}</span>}
          </div>
          <div className={classes.benefitsValue}>
            <span className={classes.factorBenefits}>
              <span>x</span>
              <span>{implementedMult}</span>
            </span>
            {showBottomLabel && <span className={classes.labelBenefits}>{implementedLabel}</span>}
          </div>
        </div>
      </div>
    </div>
  )
}

NxtBenefits.defaultProps = {
  classes: PropTypes.shape({
    root: '',
    iconLeft: '',
    benefitsContainer: '',
    benefitsLabel: '',
    benefitsValues: '',
    benefitsValue: '',
    factorBenefits: '',
    labelBenefits: '',
  }),
  showBottomLabel: true,
  benefitsLabel: i18n.t('CHALLENGES.DETAIL.EXTRA_BENEFITS'),
  supportedLabel: i18n.t('CHALLENGES.DETAIL.SUPPORTED'),
  approvedLabel: i18n.t('CHALLENGES.DETAIL.APPROVED'),
  implementedLabel: i18n.t('CHALLENGES.DETAIL.IMPLEMENTED'),
}
NxtBenefits.propsTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    iconLeft: PropTypes.string,
    benefitsContainer: PropTypes.string,
    benefitsLabel: PropTypes.string,
    benefitsValues: PropTypes.string,
    benefitsValue: PropTypes.string,
    factorBenefits: PropTypes.string,
    labelBenefits: PropTypes.string,
  }),
  benefitsLabel: PropTypes.string,
  supportedLabel: PropTypes.string,
  approvedLabel: PropTypes.string,
  implementedLabel: PropTypes.string,
  showBottomLabel: PropTypes.bool,
  supportedMult: PropTypes.string.isRequired,
  approvedMult: PropTypes.string.isRequired,
  implementedMult: PropTypes.string.isRequired,
}
export default withStyles(styles)(NxtBenefits)

export default theme => ({
  root: {
    border: '2px dashed #ccc',
    height: '88px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    '& > p': {
      marginLeft: theme.spacing(1),
    },
  },
})

import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import SimpleDivider from 'components/simple_divider/View'
import styles from './View.Style'

const Item = props => {
  const { classes, navigateToDomain, name, id, hasDivider } = props

  return (
    <Fragment>
      <li>
        <Button component="a" onClick={() => navigateToDomain(id)} className={classes.span}>
          <span className={clsx(classes.icon, 'icon-rocket')} />
          <b>{name}</b>
        </Button>
      </li>
      {hasDivider && <SimpleDivider />}
    </Fragment>
  )
}

Item.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  hasDivider: PropTypes.bool.isRequired,
  navigateToDomain: PropTypes.func.isRequired,
}

export default withStyles(styles)(Item)

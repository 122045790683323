import React, { Fragment, Component } from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import DomainMenu from 'components/domain_menu/View'
import { withStyles } from '@material-ui/core/styles'
import { ClickAwayListener } from '@material-ui/core'
import chevronDown from '../../../img/ic_chevron_down.png'
import styles from './View.Style'

class HeaderDomains extends Component {
  constructor(props) {
    super(props)
    this.state = { showDomainSelector: false }
    this.toggleDomainList = this.toggleDomainList.bind(this)
    this.hideDomainList = this.hideDomainList.bind(this)
  }

  toggleDomainList() {
    const { showDomainSelector } = this.state
    this.setState({
      showDomainSelector: !showDomainSelector,
    })
  }

  hideDomainList() {
    const { showDomainSelector } = this.state
    if (showDomainSelector) {
      this.setState({
        showDomainSelector: false,
      })
    }
  }

  render() {
    const { domains, navigateToDomain, classes } = this.props
    const { showDomainSelector } = this.state
    return (
      <Fragment>
        {domains && domains.length > 1 && (
          <ClickAwayListener onClickAway={this.hideDomainList}>
            <div className={classes.holder}>
              <button className={classes.button} type="button" onClick={this.toggleDomainList}>
              {/* <img className={classes.chevronDown} src={chevronDown} alt='chevron icon' /> */}
              <span className={clsx(classes.colorIcon, 'icon-down')}  />
                {this.props.children}
                {showDomainSelector && (
                  <DomainMenu domains={domains} navigateToDomain={navigateToDomain} />
                )}
              </button>
            </div>
          </ClickAwayListener>
        )}
      </Fragment>
    )
  }
}

HeaderDomains.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  navigateToDomain: PropTypes.func.isRequired,
}

export default withStyles(styles)(HeaderDomains)

export const SET_USER_ORGANISATIONS = 'SET_USER_ORGANISATIONS'

export const GET_MY_PROFILE_STARTED = 'GET_MY_PROFILE_STARTED'
export const GET_MY_PROFILE_SUCCESS = 'GET_MY_PROFILE_SUCCESS'
export const GET_MY_PROFILE_FAILURE = 'GET_MY_PROFILE_FAILURE'

export const GET_USER_PROFILE_STARTED = 'GET_USER_PROFILE_STARTED'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE'

export const GET_USER_IDEAS_STARTED = 'GET_USER_IDEAS_STARTED'
export const GET_USER_IDEAS_SUCCESS = 'GET_USER_IDEAS_SUCCESS'
export const GET_USER_IDEAS_FAILURE = 'GET_USER_IDEAS_FAILURE'

export const GET_USER_IDEAS_TEAM_STARTED = 'GET_USER_IDEAS_TEAM_STARTED'
export const GET_USER_IDEAS_TEAM_SUCCESS = 'GET_USER_IDEAS_TEAM_SUCCESS'
export const GET_USER_IDEAS_TEAM_FAILURE = 'GET_USER_IDEAS_TEAM_FAILURE'

export const GET_USER_ORGANISATIONS_STARTED = 'GET_USER_ORGANISATIONS_STARTED'
export const GET_USER_ORGANISATIONS_SUCCESS = 'GET_USER_ORGANISATIONS_SUCCESS'
export const GET_USER_ORGANISATIONS_FAILURE = 'GET_USER_ORGANISATIONS_FAILURE'

export const PUT_USER_ORGANISATIONS_STARTED = 'PUT_USER_ORGANISATIONS_STARTED'
export const PUT_USER_ORGANISATIONS_SUCCESS = 'PUT_USER_ORGANISATIONS_SUCCESS'
export const PUT_USER_ORGANISATIONS_FAILURE = 'PUT_USER_ORGANISATIONS_FAILURE'

export const GET_USER_IDEAS_PILOT_TEAM_STARTED = 'GET_USER_IDEAS_PILOT_TEAM_STARTED'
export const GET_USER_IDEAS_PILOT_TEAM_SUCCESS = 'GET_USER_IDEAS_PILOT_TEAM_SUCCESS'
export const GET_USER_IDEAS_PILOT_TEAM_FAILURE = 'GET_USER_IDEAS_PILOT_TEAM_FAILURE'

export const GET_USER_CONTRIBUTIONS_STARTED = 'GET_USER_CONTRIBUTIONS_STARTED'
export const GET_USER_CONTRIBUTIONS_SUCCESS = 'GET_USER_CONTRIBUTIONS_SUCCESS'
export const GET_USER_CONTRIBUTIONS_FAILURE = 'GET_USER_CONTRIBUTIONS_FAILURE'

export const GET_USER_TIMELINE_STARTED = 'GET_USER_TIMELINE_STARTED'
export const GET_USER_TIMELINE_SUCCESS = 'GET_USER_TIMELINE_SUCCESS'
export const GET_USER_TIMELINE_FAILURE = 'GET_USER_TIMELINE_FAILURE'

export const RESET_USER_TIMELINE = 'RESET_USER_TIMELINE'

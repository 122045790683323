import React, { Fragment, useState, useEffect, useRef } from 'react'
import {useParams} from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import { Grid, Paper, Box, Container, ClickAwayListener } from '@material-ui/core';
import styles from './View.Style'
import VideoForm from 'components_newui/new_ui_video_form/View'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import TextInputOneLine from '../../components_newui/TextInputOneLine/View'
import ImageUploader from '../../components_newui/new_ui_image_uploader/View'
import DescriptionBlock from './components/DescriptionBlock'
import { VIDEO_STATE_TYPE } from 'common_constants/VzaarVideo'
import Button from './components/Button'
import TextEditor from 'components/text_editor/View'
import i18n from 'support/i18n'
import AutoSuggestion from 'components/autosuggestion/View'
import SuggestionSelected from 'components/suggestion_selected/View'
import ActivePeriod from './components/active_period/View'
import OrganizationSelector from './components/organization_selector/View'
import Gaming from './components/gaming/View'
import warningIco from 'img/warning.png'
import { extraRewardsStepValidator } from './View.Utils';
import clsx from 'clsx';
import ExtraRewards from './components/extra_rewards/View'
import RewardChoices from './components/reward_choices/View'
import navigation from '../../support/navigation';
import ChallengePreview from 'components_newui/challenge_summary_preview/View'
import NavigationService from 'support/navigation/NavigationService'
import * as pages from 'common_constants/Pages'
import { addDays, isBefore } from 'date-fns';
import ChallengeHeader from './ChallengeHeader';
const Post_Challenge = props => {

    const {
        isLoading,
        isDraft,
        classes,
        externalVideoURL,
        onUploadExternalVideo,
        onRemoveExternalVideo,
        imagesLength,
        navigateToChallenges,
        onSelectMainImage,
        deleteImage,
        match,
        submitDraft,
        initializeChallenge,
        onDescriptionChange,
        onWhyImportantChange,
        challengeDescription,
        onTitleChange,
        setActiveTab,
        onSettingChange,
        selectedGamingSetting,
        onSliderChangeCommitted,
        defaultValues,
        approveMult,
        implementMult,
        supportMult,
        currentGoal,
        activeTab,
        sponsorSuggestions,
        getSponsorSuggestions,
        sponsorStarted,
        selectedSponsor,
        selectedProposer,
        onRemoveSponsor,
        hasSponsorError,
        reward,
        onRewardDescriptionChange,
        onRewardedChange,
        onRewardOtherChange,
        onSelectRewardImage,
        deleteRewardImage,
        onRewardRewardedChange,
        rewardImg,
        previewChallenge,
        isActive,
        onSelectPreviewFromDate,
        onSelectPreviewToDate,
        onOrganizationSelect,
        organizationList,
        csrf,
        postLaunchChallengeMethod,
        submitDraftSupportLaunchChallenge,
        getChallenge,
        getServiceLineStatus,
        completeChallengeData,
        editDates,
        isOrganizationsActive,
        getOrganizations,
        onSelectImage,
    } = props
    const { title, whyImportant, description, images, mainImageValue } = challengeDescription
    const {
        fromDate,
        toDate,
        defaultGroups,
        rightGroups,
        organization,
        isGlobal,
    } = previewChallenge

    let {id } = useParams()

    const inputRef = useRef(null)

    const [challengeTitle, setchallengeTitle] = useState('')
    const [validChallenge, setValidChallenge] = useState(true)
    const [validChallengeDescription, setValidChallengeDescription] = useState(true)
    const [validWhyImportant, setValidWhyImportant] = useState(true)
    const [validSponser, setValidSponser] = useState(true)
    const [validToDate, setValidToDate] = useState(true)
    const [showPreview, setShowPreview] = useState(false)
    //
    // const [images, setImages] = useState([]) //DELETE THIS and move to index for dispatch/state
    const [externalVideo, setExternalVideo] = useState('')
    
    //
    const [titleError, settitleError] = useState("")
    const [imageError, setimageError] = useState("")
    const [descriptionError, setdescriptionError] = useState("")
    const [toDateError, setToDateError] = useState(false)
    const [errorObject, setErrorObject] = useState({})
// console.log("match location",match,match.path)
// console.log("navigation id path :")
// console.log(NavigationService.getRoutes()[pages.EDIT_CHALLENGE].path)
const isNewChallenge = NavigationService.getRoutes()[pages.NEW_CHALLENGE].path === match.path
const isEditPage =  NavigationService.getRoutes()[pages.EDIT_CHALLENGE].path === match.path
const isProposeChallenge = NavigationService.getRoutes()[pages.PROPOSE_CHALLENGE].path === match.path
// console.log("isEditpage-propose:", isEditPage,isProposeChallenge)
    // Maybe have page hierarchy come from state????? at least get navigate functions from state
    const pageHierarchy = [
        {string: "Home", navigate: ()=>navigation.navigateToExplore()}, 
        { string:  isEditPage ? "Edit challenge" : "New challenge", navigate: () => {}}
    ]

    useEffect(() => {
        getOrganizations()
    },[isOrganizationsActive])

// useEffect(() => {
//     // onSelectMainImage()
//     // console.log("update",onSelectMainImage)
// },[images])

    useEffect(() => {
        if(isEditPage){
            getChallenge()
        }else{
            initializeChallenge(isProposeChallenge)
        }
        getServiceLineStatus()
    }, [])

    useEffect(() => {
// console.log("in post",externalVideoURL)

    }, [externalVideoURL])



// working code 
    // const postLaunchChallengeMethodFn = () => {
    //     console.log('in post',isActive)
    //     //
    //     if(isEditPage){
    //         editDates()
    //         submitDraftSupportLaunchChallenge()
    //         postLaunchChallengeMethod()
    //     }else{
    //         submitDraftSupportLaunchChallenge() 
    //         postLaunchChallengeMethod()
    //     }
    // }

    const postLaunchChallengeMethodFn = () => {
        const { launchChallenge,saveDescriptionInfo,savePeopleInfo,saveRewardInfo,saveRoiInfo} = props
        // console.log('in post',isActive)
        if(isActive){
            saveDescriptionInfo()
            savePeopleInfo()
            saveRoiInfo()
            saveRewardInfo()
            editDates()
        }else{
            // submitDraftSupportLaunchChallenge()
            saveDescriptionInfo()
            savePeopleInfo()
            saveRoiInfo()
            saveRewardInfo()
            launchChallenge(isNewChallenge)
        }
    }

const titleRef = useRef()
    
    // validator

    const validateChallengeFormData = () => {
        // Add data validation here
        let isValid = true
         const hypenRegex =  new RegExp(/[-!$%^*+|~=`{}\[\]:\/;<>?.@#]/)
        if (!title || hypenRegex.test(title)) {
            isValid = false;
            // titleRef.current.style.border = '1px solid blue';
            settitleError(i18n.t('IDEA.TITLE_ERROR'))
            setValidChallenge(false)
        }
        if (!description) {
            isValid = false;
            setdescriptionError(i18n.t('IDEA.DESC_ERROR'))
            setValidChallengeDescription(false)
        }
        if (whyImportant === "") {
            isValid = false;
            setdescriptionError(i18n.t('IDEA.DESC_ERROR'))
            setValidWhyImportant(false)
        }
        if (images.length === 0) {
            isValid = false;
            setimageError(i18n.t('IDEA.IMAGE_ERROR'))
        }
        const tomorrow = new Date(addDays(new Date(), 1).setHours(0, 0, 0, 0))
            // console.log("tommarrow",tomorrow,!toDate, (toDate < fromDate), isBefore(toDate, tomorrow)  )   

            if ( (toDate < fromDate) || isBefore(toDate, tomorrow) || !toDate) {
                isValid = false
                setToDateError(true)
                
                setValidToDate(false)
            }
            

        const isSponsorSelected = Object.keys(selectedSponsor).length > 0
        if (!isSponsorSelected) {
            isValid = false;
            setValidSponser(false)
        }
        return isValid
    }
    // on save draft button
    const submitChallengeDraft = () => {
        if (validateChallengeFormData()) {
            submitDraft()
        }
    }


    // people

    const handleSponsorSuggestionSelected = (suggestion) => {
        const { onSponsorSelected } = props
        onSponsorSelected(suggestion)
    }

    const handleProposerSuggestionSelected = (suggestion) => {
        const { onProposerSelected } = props
        onProposerSelected(suggestion)
    }

    const renderSponsorSuggestion = () => {

        const isSponsorSelected = Object.keys(selectedSponsor).length > 0
        if (!isSponsorSelected) {
            return (
                <AutoSuggestion
                    suggestions={sponsorSuggestions}
                    getSuggestions={getSponsorSuggestions}
                    onSuggestionSelected={handleSponsorSuggestionSelected}
                    isLoading={sponsorStarted}
                    inputLabel={i18n.t('CHALLENGES.SEARCH_USER')}
                    hasError={validSponser ? false : true}
                />
            )
        }
        return <SuggestionSelected suggestion={selectedSponsor} onRemoveSuggestion={onRemoveSponsor} />
    }

    const renderProposerSuggestion = () => {
        const {
            proposerSuggestions,
            getProposerSuggestions,
            proposerStarted,

            onRemoveProposer,
        } = props
        const isProposerSelected = Object.keys(selectedProposer).length > 0
        if (!isProposerSelected) {
            return (
                <AutoSuggestion
                    suggestions={proposerSuggestions}
                    getSuggestions={getProposerSuggestions}
                    onSuggestionSelected={handleProposerSuggestionSelected}
                    isLoading={proposerStarted}
                    inputLabel={i18n.t('CHALLENGES.SEARCH_USER')}
                />
            )
        }
        return (
            <SuggestionSelected suggestion={selectedProposer} onRemoveSuggestion={onRemoveProposer} />
        )
    }

    const reDirectToDraftChallenge = () => {
        navigation.navigateToChallengeDraft()
    }


 const  getBannerData = () =>{
        const { classes, onResetChallenge } = props
        if (isEditPage) {
          return {
            bannerContent: (
              <span dangerouslySetInnerHTML={{ __html: i18n.t('CHALLENGES.INFO_SAVE') }} />
            ),
            bannerColor: '#fdf4e2',
          }
        }
    
        if (isNewChallenge) {
          return {
            bannerContent: (
              <Fragment>
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${i18n.t('CHALLENGES.INFO_SAVE')} ${i18n.t(
                      'CHALLENGES.INFO_FINISH_LATER',
                    )}`,
                  }}
                />
                <div className={classes.bannerSecondLine}>
                  <span>{i18n.t('CHALLENGES.INFO_RESTART_CHALLENGE')} </span>
                  <button type="button" className={classes.btnlink} onClick={onResetChallenge}>
                    {i18n.t('CHALLENGES.RESTART_CHALLENGE')}
                  </button>
                </div>
              </Fragment>
            ),
            bannerColor: '#fff',
          }
        }
        return {
          bannerColor: '#fff',
          bannerContent: (
            <span dangerouslySetInnerHTML={{ __html: i18n.t('CHALLENGES.INFO_FIELDS') }} />
          ),
        }
      }

const Arrayfn =  organizationList.map(list => list.organizations[0])
const orgName =Arrayfn.find(ArrItem => ArrItem.id === Number(organization) ? ArrItem.name : null)


const ComponentImgUpload = ()=>{
    return(
        <ImageUploader
            ref={inputRef}
            subtitle='Browse image (Max 5)'
            imageUploadError=""
            deleteImage={deleteImage}
            images={images}
            onSelectImage={onSelectImage}
            onSelectMainImage={onSelectMainImage}
            mainImageValue={mainImageValue}
            canChooseMainImage
            UploadLimit={5}
            imagesLength={imagesLength}
        />
    )
}


    return (
        <Fragment>
            <Breadcrumbs crumbs={pageHierarchy} />
            <Container>
                <main className={classes.background}>

                    <div className={classes.content_container}>
                        <div className={classes.upper_background}>
                            <p className={classes.title}> {isEditPage ? 'Edit Challenge' : 'New Challenge'} </p>
                            {/* {isEditPage ? null : 
                            <p className={classes.invested_ideas_number} onClick={reDirectToDraftChallenge}>Draft challenges ({
                                draftChallenges[0].challengeDescription && draftChallenges[0].challengeDescription.title !== '' ?
                                    draftChallenges.length : '0'

                                })</p>
                            } */}
                        </div>
                         <ChallengeHeader {...getBannerData()} />
                        <div className={classes.lower_background}>
                            <div className={classes.create_idea_box}>
                                <div className={classes.create_title}> {isEditPage ? 'Edit Challenge' : 'Create Challenge'} </div>
                                <div className={classes.create_subtitle}> Let's throw an interesting challenge to see what innovative ideas we can come up with</div>
                                <p className={classes.idea_title_in}>Challenge title <span className={classes.astrickColor}>*</span></p>
                                <TextInputOneLine
                                    canEditTitle={id !== undefined}
                                    className={classes.oneInput}
                                    titleRef={titleRef}
                                    width={780}
                                    placeholder='Enter challenge title'
                                    char_limit={50}
                                    value={title}
                                    valid={validChallenge}
                                    //  onChange={event => onTitleChange(event.target.value)}
                                    handleChange={event => { const value = event.target.value; onTitleChange(value); setValidChallenge(value.length > 0 ? true : false) }}
                                />
                                <div className={classes.idea_title_char_limit}>
                                    <span className={classes.idea_title_chars}>{title.length}</span>/50
                                </div>
                              {titleError &&  <span className={classes.error_message}>{titleError}</span>}

                                <div className={classes.secondary_header}>Upload image/video <sup className={classes.astrickColor}>*</sup></div>
                                <div className={classes.smaller_subtitle}>At least one is mandatory</div>
                                <div className={classes.box2cols} >
                                    <ComponentImgUpload />
                                    <div className={classes.vertical_line} />
                                    <VideoForm
                                        onExternalVideoError={() => {}}
                                        externalVideoSrc={externalVideoURL}
                                        onChangeExternalVideo={onUploadExternalVideo}
                                        onSelectFileVideo={() => {}}
                                        onRemoveFileVideo={onRemoveExternalVideo}
                                        isVideoLoading={false}
                                        isVideoReady={false}
                                        isVideoRemoving={false}
                                        hasVideoError={false}
                                    />
                                </div>
                                <span className={classes.error_message}>{imageError}</span>
                                <div className={classes.horizontal_line} />


                                <div className={classes.secondary_header} style={{ marginBottom: '16px' }}>
                                    Challenge description <span className={classes.astrickColor}>*</span>
                                </div>

                                <div className={classes.smaller_subtitle}>Please describe the problem statement you wish to solve.</div>

                                <div className={`${classes.inputContainer} ${!validChallengeDescription && classes.invalid} `}>
                                    <TextEditor
                                        value={description}
                                        onTextChange={e => {
                                            onDescriptionChange(e)
                                            // setchallengeDescriptionData(e);
                                            setValidChallengeDescription(e.length > 0 ? true : false)
                                            setdescriptionError('');
                                            // console.log(challengeDescription)
                                        }
                                        }
                                    />
                                    {!validChallengeDescription && <img src={warningIco} className={classes.warning} alt='warning' />}
                                </div>
                                {descriptionError &&  <span className={classes.error_message}>{descriptionError}</span>}

                                <div className={classes.secondary_header} style={{ marginBottom: '16px' }}>Why is this important? <span className={classes.astrickColor}>*</span></div>
                                <div className={classes.smaller_subtitle}>Explain why your colleagues should publish solutions for this challenge.</div>

                                <div className={`${classes.inputContainer} ${!validWhyImportant && classes.invalid} `}>
                                    <TextEditor
                                        value={whyImportant}
                                        onTextChange={e => {
                                            onWhyImportantChange(e)
                                            // setWhyImportant(e)
                                            setValidWhyImportant(e.length > 0 ? true : false)
                                            setdescriptionError('');
                                            // ;console.log(challengeDescription)
                                        }}


                                    />
                                    {!validWhyImportant && <img src={warningIco} className={classes.warning} alt='warning' />}
                                </div>
                                {descriptionError &&  <span className={classes.error_message}>{descriptionError}</span>}

                                <div className={classes.root}>
                                    <div className={classes.secondary_header} >
                                        {i18n.t('CHALLENGES.WHEN_ACTIVE')}
                                    </div>
                                    <div className={classes.smaller_subtitle}>{i18n.t('CHALLENGES.ACTIVE_INFO')}</div>
                                      
                                    <div className={classes.datesContainer}>
                                        <ActivePeriod
                                            isFromDateDisable={isActive}
                                            fromDate={fromDate}
                                            toDate={toDate}
                                            onSelectPreviewFromDate={onSelectPreviewFromDate}
                                            onSelectPreviewToDate={onSelectPreviewToDate}
                                            hasToDateError={toDateError}
                                            // errorObject={errorObject}
                                        />
                                    </div>
                                    
                                </div>


                                <div className={classes.root}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={6}>
                                            <Box className={classes.paper}>
                                                <div className={classes.secondary_header} >
                                                    Sponsor <span className={classes.astrickColor}>*</span>
                                                </div>
                                                <div className={classes.smaller_subtitle}>A colleague of yours is really interested in supporting this challenge.</div>
                                                <div className={`${classes.inputContainer} `}>
                                                    {renderSponsorSuggestion()}
                                                </div>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Box className={classes.paper}>
                                                <div className={classes.secondary_header} >
                                                    Proposer
                                                </div>
                                                <div className={classes.smaller_subtitle}>This is the person who has created, and / or published the challenge.</div>
                                                <div className={`${classes.inputContainer} `}>
                                                    {renderProposerSuggestion()}
                                                </div>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={classes.horizontal_line} />
                                {/* Gaming */}

                                <div className={classes.root}>
                                    <Gaming
                                        onChangeTab={setActiveTab}
                                        onSettingChange={onSettingChange}
                                        selectedGamingSetting={selectedGamingSetting}
                                        onSliderChangeCommitted={onSliderChangeCommitted}
                                        defaultValues={defaultValues}
                                        approveMult={approveMult}
                                        implementMult={implementMult}
                                        supportMult={supportMult}
                                        currentGoal={currentGoal}
                                        activeTab={activeTab}
                                    />
                                </div>

                                {/* Rewards */}
                                {/* {
                                                errorObject = extraRewardsStepValidator({ ...reward })
                                            } */}
                                <div className={classes.root}>

                                    <ExtraRewards
                                        reward={reward}
                                        onDescriptionChange={onRewardDescriptionChange}
                                        onRewardedChange={onRewardRewardedChange}
                                        onOtherChange={onRewardOtherChange}
                                        onSelectImage={onSelectRewardImage}
                                        deleteImage={deleteRewardImage}
                                    />

                                </div>
                                {/* launch */}
                                {/* {console.log("organizationList",organizationList,organization,onOrganizationSelect)} */}
                                <div className={classes.root}>
                                    {isOrganizationsActive && (!isActive) && (
                                        <Fragment>
                                            <div className={classes.secondary_header} >
                                                {i18n.t('CHALLENGES.ORGANIZATION')}
                                            </div>
                                            <div className={classes.smaller_subtitle}>{i18n.t('CHALLENGES.SELECT_ORGANIZATION')}</div>
                                            <OrganizationSelector
                                                onOrganizationSelect={onOrganizationSelect}
                                                organization={organization}
                                                organizationList={organizationList}
                                            />

                                            {
                                                organizationList.some(list =>  list.organizations.filter(innerList => innerList.id === organization)) 
                                                
                                          }
                                        </Fragment>

                                    )}
                                    {/* {console.log("organization",organization, organizationList.filter(list => list.organizations.filter(innerList => innerList.id === organization))[0], organizationList.filter(item=>item.organizations) ) } */}
                                </div>



                                {/* <div style={{marginTop: '32px'}}>
                                    <FormGroup className={classes.checkbox_formgroup}>
                                        <FormControlLabel control={<Checkbox checked={globalChallenge} onChange={e => setGlobalChallenge(e.target.checked)} />} label="Make this a Global Challenge" />
                                    </FormGroup>
                                    </div> */}

                                <div className={classes.horizontal_line} style={{ marginTop: '32px', marginBottom: '32px' }} />

                                <div className={classes.buttonall} >
                                    <Button text='Cancel' clickCallBack={navigateToChallenges} />

                                    <div className={classes.buttontwocol}>
                                        { (!isActive && isDraft==="DRAFT") || isDraft==="DRAFT" ?  <Button text='Save as draft' clickCallBack={e => submitChallengeDraft(e)} /> : null }
                                        <div className={classes.btnsubmit}>
                                            <Button width={177} text='Preview and submit' clickCallBack={() => validateChallengeFormData() ? setShowPreview(true) : setShowPreview(false)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {showPreview &&
                        <Fragment>
                            {/* <div className={classes.faded_out} /> */}
                            
                            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <ClickAwayListener onClickAway={() => setShowPreview(false)}>
                                    <div className={classes.idea_preview10}> */}
                                        <ChallengePreview
                                            isLoading={isLoading}
                                            ChallangesIsActive={isActive}
                                            showPreview={showPreview}
                                            setShowPreview={setShowPreview}
                                            csrfToken={csrf}
                                            handleCancleClick={() => setShowPreview(false)}
                                            handleSubmitClick={() => postLaunchChallengeMethodFn()}
                                            title={title}
                                            images={images}
                                            video={ externalVideoURL}
                                            description={description}
                                            whyImportant={whyImportant}
                                            fromDate={fromDate}
                                            toDate={toDate}
                                            sponsor={selectedSponsor.fullName}
                                            proposer={selectedProposer.fullName}
                                            optionReward={reward}
                                            approveMult={approveMult}
                                            implementMult={implementMult}
                                            supportMult={supportMult}
                                            currentGoal={currentGoal}
                                            defaultValues={defaultValues}
                                            // organisation={organization ? organizationList.map(org => org.id=== organization)[0].name : null}
                                            organizationSelectedName={orgName && orgName.name}
                                            organization={organization}

                                        />
                                       
                                    {/* </div>

                                </ClickAwayListener>
                            </div> */}
                        </Fragment>
                    }

                    { /* challenge preview  end */ }

                </main>
            </Container>
        </Fragment>
    )
}

export default withStyles(styles)(Post_Challenge)
import { connect } from 'react-redux'
import { setAuthMessageBox, resetAuthMessageBox } from 'actions/sync/auth'
import { postChangePassword } from 'services/auth'
import { MESSAGE_TYPES } from 'components/auth_message_box/View.Utils'
import i18n from 'support/i18n'
import { showPopupSuccessSnackbar } from 'support/popup_dialogs'
import Navigation from 'support/navigation'
import { POSSIBLES_AUTH_ERRORS } from 'support/utils/auth'
import View from './View'

const mapStateToProps = state => ({
  messageType: state.auth.messageBox.messageType,
  messageText: state.auth.messageBox.messageText,
  isChangingPassword: state.auth.isChangingPassword,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetAuthMessageBox: () => {
    dispatch(resetAuthMessageBox())
  },
  setAuthMessageBox: (messageType, messageText) => {
    dispatch(setAuthMessageBox(messageType, messageText))
  },
  onSubmit: password => {
    const tokenId = ownProps.match.params.token
    const onSuccess = () => {
      showPopupSuccessSnackbar(i18n.t('PROFILE.EDIT_PASSWORD_SUCCESS'), dispatch)
      Navigation.navigateToLogin()
    }
    const onFailure = responseError => {
      if (responseError.data.error.error === POSSIBLES_AUTH_ERRORS.REPEATED_INVALID_PASSWORD) {
        dispatch(
          setAuthMessageBox(
            MESSAGE_TYPES.ERROR,
            i18n.t('COMMON_LOGIN.HEADER.CHANGE_PASSWORD_REPEATED_KO'),
          ),
        )
      } else {
        dispatch(
          setAuthMessageBox(
            MESSAGE_TYPES.ERROR,
            i18n.t('COMMON_LOGIN.HEADER.CHANGE_PASSWORD_GENERAL_KO'),
          ),
        )
      }
    }
    dispatch(postChangePassword(password, tokenId, onSuccess, onFailure))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const Overlay = props => {
  const { children, classes } = props

  return (
    <div className={classes.container}>
      <div className={classes.overlay}>{children}</div>
    </div>
  )
}

Overlay.defaultProps = {}

Overlay.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    overlay: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles)(Overlay)

import React from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import Image from 'components/image/View'
import nextInitImage from 'img/nextinit-logo-black.png'
// import bulbRocket from 'img/bulb_rocket.png'
import bulbRocket from 'img/nova-logo.png'
import { Button } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const CopyrightDialog = props => {
  const { classes, year, onAcceptClick } = props
  return (
    <div className={classes.root}>
      <div className={classes.titleSection}>
        <Image defaultSrc={nextInitImage} src={nextInitImage} alt="nextinit image" />
        <h3>{i18n.t('LEGAL_TXT.COPYRIGHT.TITLE').replace('##YEAR##', year)}</h3>
      </div>
      <div className={classes.bodySection}>
        <div>
          <p>{i18n.t('LEGAL_TXT.COPYRIGHT.P1')}</p>
          <p>{i18n.t('LEGAL_TXT.COPYRIGHT.P2')}</p>
          <p>{i18n.t('LEGAL_TXT.COPYRIGHT.P3')}</p>
          <p>{i18n.t('LEGAL_TXT.COPYRIGHT.P4')}</p>
        </div>
        <Image src={bulbRocket} defaultSrc={bulbRocket} alt="rocket NextInit" />
      </div>
      <div className={classes.actionsSection}>
        <Button color="primary" variant="contained" onClick={onAcceptClick}>
          {i18n.t('VALIDATE')}
        </Button>
      </div>
    </div>
  )
}

CopyrightDialog.propTypes = {
  year: PropTypes.string.isRequired,
  onAcceptClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(CopyrightDialog)

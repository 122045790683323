import { connect } from 'react-redux'
import Navigation from 'support/navigation'
import View from './View'

const mapStateToProps = state => ({
  maintenanceTitle: state.data.maintenance.title,
  maintenanceText: state.data.maintenance.text,
  androidAppUrl: state.data.maintenance.androidAppUrl,
  iosAppUrl: state.data.maintenance.iosAppUrl,
})

const mapDispatchToProps = (_, ownProps) => ({
  onLogoClick: () => {
    const domain = (ownProps.match && ownProps.match.params && ownProps.match.params.domain) || ''
    if (domain) {
      Navigation.navigateToCustomLogin(domain)
    } else {
      Navigation.navigateToLogin()
    }
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

import React from 'react'
import * as PropTypes from 'prop-types'
import Collapse from '@material-ui/core/Collapse'

const Filter = props => {
  const { children, openFilters } = props

  return (
    <Collapse in={openFilters}>
      <form noValidate autoComplete="off">
        {children}
      </form>
    </Collapse>
  )
}

Filter.defaultProps = {
  openFilters: true,
}

Filter.propTypes = {
  /**
   * Control the collapse effect. If the want it to start hidden, we have to pass it false.
   */
  openFilters: PropTypes.bool,
}

export default Filter

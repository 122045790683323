import { formatDistanceToNow, format } from 'date-fns'
import { enUS, es, fr, nl } from 'date-fns/locale'
import i18n from 'support/i18n'

const locales = { en: enUS, es, fr, nl }
export const formatLocaleDistanceToNow = date => {
  return formatDistanceToNow(date, { locale: locales[i18n.currentLocale()] })
}

export const getLocaleShortMonth = date => {
  return locales[i18n.currentLocale()].localize.month(date.getMonth(), { width: 'abbreviated' })
}

export const getLocaleShortDate = date => {
  return format(date, 'EEEE, LLL d, yyyy', { locale: locales[i18n.currentLocale()] })
}

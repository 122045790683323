const styles = theme => ({
  base: {
    ...theme.loginBase,
    gridTemplateColumns: props => (props.useCustomLogin ? '1fr 1fr' : '460px 1fr'),
    gridTemplateRows: '1fr',
    backgroundColor: props =>
      props.useCustomLogin && props.useCustomColor && props.customColor ? props.customColor : '',
    backgroundImage: props =>
      props.useCustomLogin && props.useCustomBgImg ? `url(${props.customBgImg})` : '',
    backgroundSize: props => (props.useCustomLogin && props.useCustomBgImg ? 'cover' : 'unset'),
    backgroundPosition: props =>
      props.useCustomLogin && props.useCustomBgImg ? 'bottom' : 'unset',
    color: props =>
      props.useCustomLogin && props.useCustomTextColor && props.customTextColor
        ? props.customTextColor
        : theme.palette.common.black,
  },
  customize: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > h1': {
      marginBottom: 0,
    },
  },
  forgotPassword: {
    fontSize: '0.875rem',
    marginLeft: theme.spacing(1 / 4),
  },
  signUpContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    '& > div:first-child': {
      marginTop: theme.spacing(12),
      marginBottom: theme.spacing(8),
    },
  },
  dataProtectionContainer: {
    padding: '10px',
    '& > h3:first-child': {
      textAlign: 'center',
    },
    '& th': {
      fontSize: '0.625rem',
      color: props => (props.useCustomTextColor ? props.customTextColor : ''),
    },
    '& td': {
      fontSize: '0.625rem',
      color: props => (props.useCustomTextColor ? props.customTextColor : ''),
    },
    '& span': {
      fontSize: '0.625rem',
    },
  },
})

export default styles

const styles = theme => ({
  holder:{
    // position: 'absolute',
    // bottom: '-9px',
    // left: '21.5%',
    display:'flex'
  },
  button: {
    background: 'none',
    position: 'relative',
    color: theme.palette.headerText ? theme.palette.headerText.main : '#FFF',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    // top: '60%',
    margin:0,
  },
  burger: {
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    padding: '10px',
    fontSize: '17px',
  },
})

export default styles

const styles = theme => ({
  actionButton: {
    marginTop: theme.spacing(4),
  },
  root: {
    display: 'flex',
    marginBottom: theme.spacing(8),
  },
  viewsContainer: {
    margin: theme.spacing(5, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      marginLeft: theme.spacing(2),
    },
    '& > div:first-child': {
      marginLeft: 0,
    },
  },
  viewsNumber: {
    fontSize: '1.25rem',
    fontWeight: 700,
  },
  viewsLabel: {
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1 / 4),
  },
  importanceContainer: {
    margin: theme.spacing(5, 0),
  },
  importanceLabel: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  importanceText: {
    fontSize: '0.875rem',
  },
  extraBenefitsContainer: {
    flex: 1,
    width: '85%',
  },
  benefitsDivider: {
    borderTop: '1px solid rgba(255, 255, 255, 0.2)',
    margin: theme.spacing(2, 0),
  },
  rewardContainer: {
    flex: 1,
  },
})

export default styles

const styles = theme => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  groupsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    fontSize: theme.spacing(1.75),
  },
  dangerPaper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: '#fdf4e2',
    border: '1px solid rgb(251, 223, 165)',
    color: 'rgb(188, 149, 71)',
  },
  textsContainer: {
    marginLeft: theme.spacing(2),
  },
  dangerTitle: {
    margin: 0,
    fontWeight: 700,
    fontSize: theme.spacing(2.5),
    marginTop: theme.spacing(1),
  },
  dangerMessage: {
    margin: 0,
    fontSize: theme.spacing(1.75),
  },
  groupsTextContainer: {
    flex: 1.5,
    marginRight: theme.spacing(1),
    textAlign: 'right',
    '& p:first-child': {
      fontWeight: 400,
      fontSize: theme.spacing(2.25),
    },
    '& p:nth-child(2)': {
      fontSize: theme.spacing(1.75),
    },
  },
  leftGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'scroll',
    flex: 2,
  },
  rightGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'scroll',
    flex: 2,
  },
  actionsButtons: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& > button:first-child': {
      marginBottom: theme.spacing(4),
    },
    margin: theme.spacing(0, 2),
  },
})

export default styles

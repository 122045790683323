export const GET_EVALUATION_IDEA_STARTED = 'GET_EVALUATION_IDEA_STARTED'
export const GET_EVALUATION_IDEA_SUCCESS = 'GET_EVALUATION_IDEA_SUCCESS'
export const GET_EVALUATION_IDEA_FAILURE = 'GET_EVALUATION_IDEA_FAILURE'

export const GET_EVALUATION_IDEA_ARCHIVED_STARTED = 'GET_EVALUATION_IDEA_ARCHIVED_STARTED'
export const GET_EVALUATION_IDEA_ARCHIVED_SUCCESS = 'GET_EVALUATION_IDEA_ARCHIVED_SUCCESS'
export const GET_EVALUATION_IDEA_ARCHIVED_FAILURE = 'GET_EVALUATION_IDEA_ARCHIVED_FAILURE'

export const GET_EVALUATION_IDEA_PENDING_STARTED = 'GET_EVALUATION_IDEA_PENDING_STARTED'
export const GET_EVALUATION_IDEA_PENDING_SUCCESS = 'GET_EVALUATION_IDEA_PENDING_SUCCESS'
export const GET_EVALUATION_IDEA_PENDING_FAILURE = 'GET_EVALUATION_IDEA_PENDING_FAILURE'

export const GET_EVALUATION_DRAFT_STARTED = 'GET_EVALUATION_DRAFT_STARTED'
export const GET_EVALUATION_DRAFT_SUCCESS = 'GET_EVALUATION_DRAFT_SUCCESS'
export const GET_EVALUATION_DRAFT_FAILURE = 'GET_EVALUATION_DRAFT_FAILURE'

export const POST_EVALUATION_DRAFT_STARTED = 'POST_EVALUATION_DRAFT_STARTED'
export const POST_EVALUATION_DRAFT_SUCCESS = 'POST_EVALUATION_DRAFT_SUCCESS'
export const POST_EVALUATION_DRAFT_FAILURE = 'POST_EVALUATION_DRAFT_FAILURE'

export const INCREASE_EVALUATION_STEP = 'INCREASE_EVALUATION_STEP'
export const DECREASE_EVALUATION_STEP = 'DECREASE_EVALUATION_STEP'

export const SET_LOGEDOUT = 'SET_LOGEDOUT'

export const GET_EVALUATION_IDEA_TITLE_STARTED = 'GET_EVALUATION_IDEA_TITLE_STARTED'
export const GET_EVALUATION_IDEA_TITLE_SUCCESS = 'GET_EVALUATION_IDEA_TITLE_SUCCESS'
export const GET_EVALUATION_IDEA_TITLE_FAILURE = 'GET_EVALUATION_IDEA_TITLE_FAILURE'

export const POST_EVALUATION_STATUS_STARTED = 'POST_EVALUATION_STATUS_STARTED'
export const POST_EVALUATION_STATUS_SUCCESS = 'POST_EVALUATION_STATUS_SUCCESS'
export const POST_EVALUATION_STATUS_FAILURE = 'POST_EVALUATION_STATUS_FAILURE'

export const POST_EXPERT_COMMENT_STARTED = 'POST_EXPERT_COMMENT_STARTED'
export const POST_EXPERT_COMMENT_SUCCESS = 'POST_EXPERT_COMMENT_SUCCESS'
export const POST_EXPERT_COMMENT_FAILURE = 'POST_EXPERT_COMMENT_FAILURE'

export const GET_EXPERT_GROUP_STARTED = 'GET_EXPERT_GROUP_STARTED'
export const GET_EXPERT_GROUP_SUCCESS = 'GET_EXPERT_GROUP_SUCCESS'
export const GET_EXPERT_GROUP_FAILURE = 'GET_EXPERT_GROUP_FAILURE'

export const GET_USERS_EXPERT_GROUP_STARTED = 'GET_USERS_EXPERT_GROUP_STARTED'
export const GET_USERS_EXPERT_GROUP_SUCCESS = 'GET_USERS_EXPERT_GROUP_SUCCESS'
export const GET_USERS_EXPERT_GROUP_FAILURE = 'GET_USERS_EXPERT_GROUP_FAILURE'

export const POST_SHARE_IDEA_STARTED = 'POST_SHARE_IDEA_STARTED'
export const POST_SHARE_IDEA_SUCCESS = 'POST_SHARE_IDEA_SUCCESS'
export const POST_SHARE_IDEA_FAILURE = 'POST_SHARE_IDEA_FAILURE'

export const GET_EVALUATION_IDEA_EXPERT_COMMENTS_MAIL_STARTED =
  'GET_EVALUATION_IDEA_EXPERT_COMMENTS_MAIL_STARTED'
export const GET_EVALUATION_IDEA_EXPERT_COMMENTS_MAIL_SUCCESS =
  'GET_EVALUATION_IDEA_EXPERT_COMMENTS_MAIL_SUCCESS'
export const GET_EVALUATION_IDEA_EXPERT_COMMENTS_MAIL_FAILURE =
  'GET_EVALUATION_IDEA_EXPERT_COMMENTS_MAIL_FAILURE'

export const GET_EVALUATION_IDEA_BY_ID_STARTED = 'GET_EVALUATION_IDEA_BY_ID_STARTED'
export const GET_EVALUATION_IDEA_BY_ID_SUCCESS = 'GET_EVALUATION_IDEA_BY_ID_SUCCESS'
export const GET_EVALUATION_IDEA_BY_ID_FAILURE = 'GET_EVALUATION_IDEA_BY_ID_FAILURE'

export const GET_EVALUATION_ARCHIVED_IDEA_BY_ID_STARTED =
  'GET_EVALUATION_ARCHIVED_IDEA_BY_ID_STARTED'
export const GET_EVALUATION_ARCHIVED_IDEA_BY_ID_SUCCESS =
  'GET_EVALUATION_ARCHIVED_IDEA_BY_ID_SUCCESS'
export const GET_EVALUATION_ARCHIVED_IDEA_BY_ID_FAILURE =
  'GET_EVALUATION_ARCHIVED_IDEA_BY_ID_FAILURE'

export const RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID_STARTED =
  'RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID_STARTED'
export const RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID_SUCCESS =
  'RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID_SUCCESS'
export const RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID_FAILURE =
  'RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID_FAILURE'

export const SAVE_EVALUATION_BY_ID_STARTED = 'SAVE_EVALUATION_BY_ID_STARTED'
export const SAVE_EVALUATION_BY_ID_SUCCESS = 'SAVE_EVALUATION_BY_ID_SUCCESS'
export const SAVE_EVALUATION_BY_ID_FAILURE = 'SAVE_EVALUATION_BY_ID_FAILURE'

export const SHARE_EVALUATION_BY_ID_STARTED = 'SHARE_EVALUATION_BY_ID_STARTED'
export const SHARE_EVALUATION_BY_ID_SUCCESS = 'SHARE_EVALUATION_BY_ID_SUCCESS'
export const SHARE_EVALUATION_BY_ID_FAILURE = 'SHARE_EVALUATION_BY_ID_FAILURE'

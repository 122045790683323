import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import { ButtonLink } from 'components'
import styles from './View.Style'

const RequestInvestment = props => {
  const { classes, onRequestInvestmentClick } = props
  return (
    <ButtonLink onLinkClick={onRequestInvestmentClick} classes={{ linkButton: classes.linkButton }}>
      <span>{i18n.t('PROFILE.REQUEST_INVEST')}</span>
      <span className="icon-more-funds" />
    </ButtonLink>
  )
}

RequestInvestment.propTypes = {
  onRequestInvestmentClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(RequestInvestment)

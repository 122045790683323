export const parseInvestors = investors => {
  if (!investors) return []
  return investors.map(investor => {
    return {
      photo: investor.photo,
      name: investor.fullname,
      email: investor.email,
      amountInvested: investor.amountInvested,
    }
  })
}

export const parsePosts = investors => {
  if (!investors) return []
  return investors.map(investor => {
    return {
      photo: investor.photo,
      name: investor.fullname,
      email: investor.email,
      ideas: investor.ideas,
    }
  })
}

export const parsePotentialInvestors = richest => {
  if (!richest) return []
  return richest.map(user => {
    return {
      photo: user.photo,
      name: user.fullname,
      email: user.email,
      balance: user.balance,
    }
  })
}

export const parseIdeas = ideas => {
  if (!ideas) return []
//  console.log('HELLO PARSE IDEAS HERE HELLO')
//  console.log(ideas)
  return ideas.map(idea => {
    return {
      photo: idea.images[0].url,
      title: idea.title,
      id: idea.id,
      investment: idea.pledged,
    }
  })
}

export const parseIdeasInvestors = ideas => {
  if (!ideas) return []
  return ideas.map(idea => {
    return {
      //photo: idea.images[0].url,
      photo: idea.images,
      title: idea.title,
      id: idea.id,
      investors: idea.investors,
    }
  })
}

export const transformRankingServiceResponseDataWithState = jsonResponse => {
  return {
    topInvestors: parseInvestors(jsonResponse.investors),
    topPosts: parsePosts(jsonResponse.entrepeneurs),
    topPotentialInvestors: parsePotentialInvestors(jsonResponse.richest),
    topIdeas: parseIdeas(jsonResponse.ideaMoreInvested),
    topIdeasInvestors: parseIdeasInvestors(jsonResponse.ideaMoreInvestors),
  }
}

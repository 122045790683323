import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import defaultLogo from 'img/nxt-logo-rocket-horizontal-white.png'
import ButtonLink from 'components/button_link/View'
import Image from 'components/image/View'
import styles from './View.Style'

const HeaderLogo = props => {
  const { classes, logoUrl, onLogoClick } = props
  return (
    <div>
      <ButtonLink onLinkClick={onLogoClick}>
        <div className={classes.logoBox}>
          <Image
            alt="Company logo"
            className={classes.logo}
            src={logoUrl}
            defaultSrc={defaultLogo}
          />
        </div>
      </ButtonLink>
    </div>
  )
}

HeaderLogo.defaultProps = {
  logoUrl: '',
}

HeaderLogo.propTypes = {
  classes: PropTypes.shape({
    logo: PropTypes.string.isRequired,
  }).isRequired,
  logoUrl: PropTypes.string,
  onLogoClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(HeaderLogo)

const styles = theme => ({
  root: {},
  mainTitle: {
    margin: `${theme.spacing(5 / 4)}px 0`,
  },
  autosuggestionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: `${theme.spacing(15 / 2)}px 0`,
  },
  autosuggestion: {
    backgroundColor: 'white',
  },
  suggestion: {
    margin: theme.spacing(0, 2),
  },
  imageAndVideoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
  },
})

export default styles

import { ServiceCall } from 'support/network/ServiceCallAction'
import {
  NOTIFICATION_ME_FAILURE,
  NOTIFICATION_ME_STARTED,
  NOTIFICATION_ME_SUCCESS,
  NOTIFICATIONS_READ_FAILURE,
  NOTIFICATIONS_READ_STARTED,
  NOTIFICATIONS_READ_SUCCESS,
} from 'services/TypesConstants'
import { prepareHeadersFromState } from 'services/HeadersUtils'
import { transformNotificationMeResponseDataWithState } from 'services/domain/ResponseUtils'

export const NOTIFICATION_URL = '/s/v1.0/notifications/me'
export const NOTIFICATIONS_READ_URL = '/s/v1.0/notifications/me/readed'

export function notificationMeService() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = NOTIFICATION_ME_STARTED
  apiCall.successReqType = NOTIFICATION_ME_SUCCESS
  apiCall.failureReqType = NOTIFICATION_ME_FAILURE
  apiCall.setEndpointFromState = () => NOTIFICATION_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformNotificationMeResponseDataWithState
  return apiCall.getAction()
}

export function markNotificationsRead(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = NOTIFICATIONS_READ_STARTED
  apiCall.successReqType = NOTIFICATIONS_READ_SUCCESS
  apiCall.failureReqType = NOTIFICATIONS_READ_FAILURE
  apiCall.setEndpointFromState = () => NOTIFICATIONS_READ_URL
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  return apiCall.getAction()
}

import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const PrivacyCookies = props => {
  const { classes } = props

  return (
    <div className={classes.container}>
      <h3>Privacy Cookies</h3>
      <p>
        NOVA informs you that the personal data that you may use to register and participate in this
        website and its associated services and applications may be added to its automated files.
      </p>
      <p>
        The NextInit website uses “session cookies” for, among others, the purpose of permitting and
        enabling the identification of users and their interaction with the services and application
        offered via the website.
      </p>{' '}
      <p>
        Furthermore, the statistical services of NOVA or third parties’ -, used by the NOVA website
        may use “cookies” for the purpose of establishing metrics and patterns of site usage and for
        other reasons.
      </p>
      
      <table className={classes.table}>
        <thead>
          <tr>
            <td>Cookie name</td>
            <td>Description</td>
            <td>Cookie owner</td>
            <td>Cookie owner website</td>
            <td>Type</td>
          </tr>
        </thead>
        <tr>
          <td>JSESSIONID</td>
          <td>
            Used to store a reference to the user logged in the application. Personal data is not
            stored.
          </td>
          <td>NOVA</td>
          <td>https://nova.vodafone.com</td>
          <td>Session cookie</td>
        </tr>

        <tr>
          <td>__uvt</td>
          <td>Used to test where cookies are enabled.</td>
          <td>NOVA</td>
          <td>https://nova.vodafone.com</td>
          <td>Session cookie</td>
        </tr>

        <tr>
          <td>uvts</td>
          <td>Used to track user usage analytics inside uservoice.</td>
          <td>UserVoice</td>
          <td>http://www.uservoice.com</td>
          <td>Analysis cookie</td>
        </tr>

        <tr>
          <td>_ga</td>
          <td>Google Analytics: provides traffic analysis and statistics for the website.</td>
          <td>Google Inc.</td>
          <td>http://www.google.com/ analitycs</td>
          <td>Analysis cookie</td>
        </tr>

        <tr>
          <td>bcookie</td>
          <td>Tracking cookies for linkedin.</td>
          <td>Linkedin</td>
          <td>http://www.linkedin.com</td>
          <td>Session cookie</td>
        </tr>

        <tr>
          <td>linkdedin_oauth_*</td>
          <td>Stores user data of users previously logged in with linkedIn.</td>
          <td>Linkedin</td>
          <td>http://www.linkedin.com</td>
          <td>Session cookie</td>
        </tr>
      </table>
    </div>
  )
}

PrivacyCookies.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(PrivacyCookies)

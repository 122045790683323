import React from 'react'
import * as PropTypes from 'prop-types'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { amber, green } from '@material-ui/core/colors/index'
import IconButton from '@material-ui/core/IconButton/index'
import SnackbarContent from '@material-ui/core/SnackbarContent/index'
import WarningIcon from '@material-ui/icons/Warning'
import { createStyles } from '@material-ui/core/styles/index'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  SNACKBAR_ERROR_TYPE,
  SNACKBAR_INFO_TYPE,
  SNACKBAR_SUCCESS_TYPE,
  SNACKBAR_WARNING_TYPE,
} from './Constants'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const styles = theme =>
  createStyles({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      fontSize:'1.1rem'
    },
  })

const MySnackbarContentWrapper = props => {
  const { classes, className, idText, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={`${classes[variant]} ${className}`}
      aria-describedby="client-snackbar"
      message={
        <span id={idText} className={classes.message}>
          <Icon className={`${classes.icon} ${classes.iconVariant}`} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

MySnackbarContentWrapper.propTypes = {
  classes: PropTypes.shape({
    success: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    warning: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    iconVariant: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    SNACKBAR_SUCCESS_TYPE,
    SNACKBAR_WARNING_TYPE,
    SNACKBAR_ERROR_TYPE,
    SNACKBAR_INFO_TYPE,
  ]),
}

MySnackbarContentWrapper.defaultProps = {
  message: 'bad snackbar configuration',
  variant: SNACKBAR_WARNING_TYPE,
}

export default withStyles(styles)(MySnackbarContentWrapper)

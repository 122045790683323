import React from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import ResumeSlider from 'components/nxt_resume_card/components/resume_slider/View'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Share'
import SpeedDials from '../fab_action/View'

const EvaluatorIdeaDescription = props => {
  const {
    images,
    videoUrl,
    title,
    description,
    handleShareIdea,
    showFab,
    id,
    getExpertCommentsMail,
    handleViewExpertComments,
  } = props

  return (
    <Grid container direction="row" justify="center">
      {/* Left grid */}
      <Grid item xs={12} sm={6}>
        <Box item xs={6} height="50%" width="80%">
          {images && <ResumeSlider images={images} />}
        </Box>
      </Grid>
      {/* Mid grid */}
      <Grid item xs={12} sm={6}>
        <Container height="50%" width="100%" style={{ backgroundColor: 'white' }}>
          {showFab && (
            // <Fab
            //   color="primary"
            //   aria-label="add"
            //   style={{ position: 'absolute', top: 10, right: 10 }}
            // >
            //   <AddIcon onClick={() => handleShareIdea()} />
            // </Fab>
            <SpeedDials
              id={id}
              handleShareIdea={handleShareIdea}
              handleViewExpertComments={handleViewExpertComments}
              getExpertCommentsMail={getExpertCommentsMail}
            />
          )}
          <Box py={3}>
            <Typography style={{ overflowWrap: 'break-word' }} variant="h5" component="h1">
              {title}
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Box>
        </Container>
      </Grid>
    </Grid>
  )
}

export default EvaluatorIdeaDescription

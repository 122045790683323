import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    text: {
      color: theme.palette.common.white,
      width: '100%',
      margin: '0',
      overflowX: 'hidden',
    },
  })

export default styles

import i18n from 'support/i18n'

export const headerCells = [
  {
    id: 'USER',
    label: i18n.t('DASHBOARD.USER'),
    numeric: false,
  },
  {
    id: 'INVESTMENTS',
    label: i18n.t('DASHBOARD.INVESTMENTS'),
    numeric: true,
  },
  {
    id: 'COMMENTS',
    label: i18n.t('DASHBOARD.COMMENTS'),
    numeric: true,
  },
  {
    id: 'IDEAS',
    label: i18n.t('DASHBOARD.IDEAS'),
    numeric: true,
  },
  {
    id: 'IDEASFUNDED',
    label: i18n.t('DASHBOARD.IDEASFUNDED'),
    numeric: true,
  },
  {
    id: 'IDEASAPPROVED',
    label: i18n.t('DASHBOARD.IDEASAPPROVED'),
    numeric: true,
  },
  {
    id: 'IDEASPILOT',
    label: i18n.t('DASHBOARD.IDEASPILOT'),
    numeric: true,
  },
  {
    id: 'IDEASIMPLEMENTED',
    label: i18n.t('DASHBOARD.IDEASIMPLEMENTED'),
    numeric: true,
  },
  {
    id: 'IDEASDISCARDED',
    label: i18n.t('DASHBOARD.IDEASDISCARDED'),
    numeric: true,
  },
]

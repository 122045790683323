import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { Avatar, TableCell, TableRow } from '@material-ui/core'
import Image from 'components/image/View'
import defaultImg from 'img/default-photo.png'
import ButtonLink from 'components/button_link/View'

const TableBody = props => {
  const { bodyRows, navigateToUserProfile } = props
  return (
    <Fragment>
      {bodyRows.map(row => {
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={row.user.email}>
            <TableCell align="center" scope="row">
              <Avatar onClick={() => navigateToUserProfile(row.user.email)}>
                <Image defaultSrc={defaultImg} src={row.user.photo} />
              </Avatar>
            </TableCell>

            <TableCell align="left">
              <div>
                <ButtonLink
                  color="primary"
                  flat
                  onLinkClick={() => navigateToUserProfile(row.user.email)}
                >
                  {row.user.fullname}
                </ButtonLink>
                <span>{row.user.email}</span>
              </div>
            </TableCell>

            <TableCell align="right">{row.investments}</TableCell>
            <TableCell align="right">{row.comments}</TableCell>
            <TableCell align="right">{row.ideasOwned}</TableCell>
            <TableCell align="right">{row.ideasFunded}</TableCell>
            <TableCell align="right">{row.ideasApproved}</TableCell>
            <TableCell align="right">{row.ideasPilot}</TableCell>
            <TableCell align="right">{row.ideasImplemented}</TableCell>
            <TableCell align="right">{row.ideasDiscarded}</TableCell>
          </TableRow>
        )
      })}
    </Fragment>
  )
}

TableBody.propTypes = {
  bodyRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default TableBody

const styles = theme => ({
  root: {
    gridColumnStart: 2,
    padding: theme.spacing(5, 0),
  },
  paper: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      marginRight: theme.spacing(1),
    },
  },
  dashboardContainer: {
    paddingTop: theme.spacing(3),
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    marginBottom: '1rem',
  },
  hide: {
    display: 'none',
  },
})

export default styles

import { ServiceCall } from 'support/network/ServiceCallAction'
import * as types from 'actions/sync/video/Constants'
import { transformConfirmationVideoResponse, transformUploadVideoResponse } from './ResponseUtils'
import { setVideoContent } from './RequestUtils'

const GET_CONFIRMATION_VIDEO_URL = '/s/videos/##videoId##'
const DELETE_VIDEO_URL = '/s/videos/##videoId##'
const POST_UPLOAD_IDEA_VIDEO_URL = '/s/idea/##ideaId##/videos'
const POST_UPLOAD_CHALLENGE_VIDEO_URL = '/s/v1.0/challenge/##challengeId##/videos'

export function checkVideoState(isIdea, videoId, onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = !isIdea
    ? types.GET_CONFIRMATION_CHALLENGE_VIDEO_STARTED
    : types.GET_CONFIRMATION_IDEA_VIDEO_STARTED
  apiCall.successReqType = !isIdea
    ? types.GET_CONFIRMATION_CHALLENGE_VIDEO_SUCCESS
    : types.GET_CONFIRMATION_IDEA_VIDEO_SUCCESS
  apiCall.failureReqType = !isIdea
    ? types.GET_CONFIRMATION_CHALLENGE_VIDEO_FAILURE
    : types.GET_CONFIRMATION_IDEA_VIDEO_FAILURE
  apiCall.setEndpointFromState = () => GET_CONFIRMATION_VIDEO_URL.replace('##videoId##', videoId)
  apiCall.transformResponseDataWithState = transformConfirmationVideoResponse
  apiCall.method = 'GET'
  apiCall.onSuccess = onSuccess
  apiCall.auth = true
  return apiCall.getAction()
}

function postVideo(isIdea, id, video, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = isIdea
    ? types.POST_UPLOAD_IDEA_VIDEO_STARTED
    : types.POST_UPLOAD_CHALLENGE_VIDEO_STARTED
  apiCall.successReqType = isIdea
    ? types.POST_UPLOAD_IDEA_VIDEO_SUCCESS
    : types.POST_UPLOAD_CHALLENGE_VIDEO_SUCCESS
  apiCall.failureReqType = isIdea
    ? types.POST_UPLOAD_IDEA_VIDEO_FAILURE
    : types.POST_UPLOAD_CHALLENGE_VIDEO_FAILURE
  apiCall.setEndpointFromState = () =>
    isIdea
      ? POST_UPLOAD_IDEA_VIDEO_URL.replace('##ideaId##', id)
      : POST_UPLOAD_CHALLENGE_VIDEO_URL.replace('##challengeId##', id)
  apiCall.method = 'POST'
  apiCall.getHeadersFromState = () => ({
    'content-type': 'multipart/form-data',
  })
  apiCall.setBodyFromState = () => setVideoContent(video)
  apiCall.transformResponseDataWithState = transformUploadVideoResponse
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

function deleteVideo(isIdea, videoId, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = isIdea
    ? types.DELETE_IDEA_VIDEO_STARTED
    : types.DELETE_CHALLENGE_VIDEO_STARTED
  apiCall.successReqType = isIdea
    ? types.DELETE_IDEA_VIDEO_SUCCESS
    : types.DELETE_CHALLENGE_VIDEO_SUCCESS
  apiCall.failureReqType = isIdea
    ? types.DELETE_IDEA_VIDEO_FAILURE
    : types.DELETE_CHALLENGE_VIDEO_FAILURE
  apiCall.setEndpointFromState = () => DELETE_VIDEO_URL.replace('##videoId##', videoId)
  apiCall.method = 'DELETE'
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export function deleteIdeaVideo(videoId, onSuccess = () => {}, onFailure = () => {}) {
  return deleteVideo(true, videoId, onSuccess, onFailure)
}

export function deleteChallengeVideo(videoId, onSuccess = () => {}, onFailure = () => {}) {
  return deleteVideo(false, videoId, onSuccess, onFailure)
}

export function postIdeaVideo(ideaId, video, onFailure) {
  return postVideo(true, ideaId, video, onFailure)
}

export function postChallengeVideo(challengeId, video, onFailure) {
  return postVideo(false, challengeId, video, onFailure)
}

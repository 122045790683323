import * as maintenanceTextTypes from 'actions/sync/about_us/Constants'
import { ServiceCall } from '../../support/network/ServiceCallAction'

import { transformAboutusDetails } from './ResponseUtils'

export const getAboutUsDetailsUrl = 's/domain/get-aboutus-data'

export function getAboutusDetails(onSuccess, onFailure) {
  const serviceCall = new ServiceCall()
  serviceCall.startedReqType = maintenanceTextTypes.ABOUT_US_STARTED
  serviceCall.successReqType = maintenanceTextTypes.ABOUT_US_SUCCESS
  serviceCall.failureReqType = maintenanceTextTypes.ABOUT_US_FAILURE
  serviceCall.setEndpointFromState = () => getAboutUsDetailsUrl
  serviceCall.transformResponseDataWithState = transformAboutusDetails
  serviceCall.method = 'GET'
  serviceCall.auth = true
  serviceCall.onSuccess = onSuccess
  serviceCall.onFailure = onFailure
  return serviceCall.getAction()
}

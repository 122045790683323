import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    container: {
      width: '100%',
      '& > img': {
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        height: '280px',
        objectFit: 'cover',
      },
    },
    overlay: {
      ...theme.flexRowCenter,
      position: 'absolute',
      bottom: 0,
      minHeight: '25%',
      width: '100%',
      padding: '10px',
      boxSizing: 'border-box',
      backgroundColor: 'rgba(51, 51, 51, 0.65)',
    },
  })

export default styles

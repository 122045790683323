const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    gridArea: 'content',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    gridTemplateAreas: '"loginContent"',
  },
  form: {
    display: 'flex',
    maxWidth: '31rem',
    maxHeight: '25rem',
    minHeight: '25rem',
    flexDirection: 'column',
    background: theme.palette.common.white,
    borderRadius: props => (props.useCustomLogin ? '0.3rem' : 'unset'),
    color: theme.palette.common.black,
    gridArea: 'loginForm',
    padding: props => (props.useCustomLogin ? theme.spacing(2, 2, 4.5, 2) : 'unset'),
  },
  login: {
    gridArea: 'loginContent',
    padding: '0 3rem',
    display: 'grid',
    gridTemplateColumns: '1fr 31rem 1fr',
    gridTemplateRows: '20% 1fr 20%',
    gridTemplateAreas: '". loginTitle ." ". loginForm ."',
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
    backgroundColor: props =>
      props.useCustomLogin && props.useCustomColor ? props.customColor : theme.palette.primary.main,
    color: props => (props.useCustomLogin && props.customTextColor ? props.customTextColor : ''),
    '&:hover': {
      backgroundColor: props =>
        props.useCustomLogin && props.useCustomColor ? props.customColor : '',
    },
  },
  terms: {
    gridArea: 'loginForm',
  },
  title: {
    display: 'flex',
    alignItems: 'flex-end',
    gridArea: 'loginTitle',
    paddingBottom: theme.spacing(1 / 2),
    '& > h1': {
      fontFamily: '"Open Sans", open-sans, sans-serif',
      fontSize: '28px',
      fontWeight: '400',
      margin: 0,
      '& small': {
        display: 'block',
        marginTop: '10px',
        fontSize: '16px',
      },
    },
  },
  buttonProgress: {
    position: 'absolute',
  },
  messageBoxContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > div': {
      width: '100%',
    },
  },
  pickerContainer: {
    margin: 0,
    '& > div': {
      marginTop: theme.spacing(1),
    },
  },
  pictureButton: {
    backgroundColor: props =>
      props.useCustomLogin && props.useCustomColor ? props.customColor : theme.palette.primary.main,
    color: props => (props.useCustomLogin && props.customTextColor ? props.customTextColor : ''),
    '&:hover': {
      backgroundColor: props =>
        props.useCustomLogin && props.useCustomColor ? props.customColor : '',
    },
  },
})

export default styles

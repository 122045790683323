import { ROI_SETTINGS, fromValueToPercentage } from 'pages/challenge/form/View.Utils'
import { VIDEO_TYPE } from 'common_constants/VzaarVideo'
import { extractPropertiesFromJsonObject } from '../RequestUtils'
import { func } from 'prop-types'

export const setImageContent = (image, state, type) => {
  return `${encodeURIComponent('id')}=${encodeURIComponent(
    state.data.challengeSteps.id,
  )}&${encodeURIComponent('image')}=${encodeURIComponent(image)}&${encodeURIComponent(
    'mode',
  )}=${encodeURIComponent(type)}`
}

export const setImageContentSaveDraft = (image, state, type) => {
  return `${encodeURIComponent('id')}=${encodeURIComponent(
    state.data.ChallengeDraft.id,
  )}&${encodeURIComponent('image')}=${encodeURIComponent(image)}&${encodeURIComponent(
    'mode',
  )}=${encodeURIComponent(type)}`
}


export const setVideoContent = video => {
  const videoFile = new FormData()
  videoFile.append('file', video)
  return videoFile
}

export const getDeleteChallengeVideoUrlFromState = url => state => {
  const id = state.data.challengeSteps.challengeDescription.fileVideoId
  return url.replace('##challengeVideoId##', id)
}

export const setMainImageContent = (imageName, state) => {
  return `${encodeURIComponent('id')}=${encodeURIComponent(
    state.data.challengeSteps.id,
  )}&${encodeURIComponent('name')}=${encodeURIComponent(imageName)}`
}

// new challenge draft
export const setMainImageContentSaveDraft = (imageName, state) => {
  return `${encodeURIComponent('id')}=${encodeURIComponent(
    state.data.ChallengeDraft.id,
  )}&${encodeURIComponent('name')}=${encodeURIComponent(imageName)}`
}

export const setDeleteImageContent = (imageName, state, type) => {
  return `${state.data.challengeSteps.id}/image/${encodeURIComponent(type)}/${encodeURIComponent(
    imageName,
  )}`
}
//new challenge draft
export const setDeleteImageContentSaveDraft = (imageName, state, type) => {
  // console.log('delte step 3 req=>',type,state,imageName)
  return `${state.data.ChallengeDraft.id}/image/${encodeURIComponent(type)}/${encodeURIComponent(
    imageName,
  )}`
}

const setVideoFromState = (externalVideoSrc, fileVideoSrc) => {
  let videoType = VIDEO_TYPE.NONE
  let videoLink = ''
  if (externalVideoSrc) {
    videoType = VIDEO_TYPE.YOUTUBE
    videoLink = externalVideoSrc
  }
  if (fileVideoSrc) {
    videoType = VIDEO_TYPE.UPLOAD
    videoLink = fileVideoSrc
  }
  return [videoType, videoLink]
}

export const setChallengeInfo = (id = '',{ title, description, whyImportant = '', externalVideo = '', fileVideo = '' }, ) => {
  const [videoType, videoLink] = setVideoFromState(externalVideo, fileVideo)
 
  return `${encodeURIComponent('id')}=${encodeURIComponent(id)}&${encodeURIComponent(
    'title',
  )}=${encodeURIComponent(title)}&${encodeURIComponent('desc')}=${encodeURIComponent(
    description,
  )}&${encodeURIComponent('importance')}=${encodeURIComponent(whyImportant)}&${encodeURIComponent(
    'videoType',
  )}=${encodeURIComponent(videoType)}&${encodeURIComponent('videoLink')}=${encodeURIComponent(
    videoLink,
  )}`
}

export const setPeopleInfo = (id = '', selectedSponsor, selectedProposer) => {
  return `${encodeURIComponent('id')}=${encodeURIComponent(id)}&${encodeURIComponent(
    'sponsor',
  )}=${encodeURIComponent(selectedSponsor.email)}&${encodeURIComponent(
    'proposer',
  )}=${encodeURIComponent(selectedProposer.email || '')}`
}

export const setRoiInfo = (id = '', gaming, activeTab) => {
  const goalMult = fromValueToPercentage(
    gaming.advancedMode.defaultValues.currentGoal,
    gaming.advancedMode.currentGoal,
  )
  const supportMult = fromValueToPercentage(
    gaming.advancedMode.defaultValues.supportMult,
    gaming.advancedMode.supportMult,
  )
  const approveMult = fromValueToPercentage(
    gaming.advancedMode.defaultValues.approveMult,
    gaming.advancedMode.approveMult,
  )
  const implementMult = fromValueToPercentage(
    gaming.advancedMode.defaultValues.implementMult,
    gaming.advancedMode.implementMult,
  )

  return `${encodeURIComponent('id')}=${encodeURIComponent(id)}&${encodeURIComponent(
    'goalMult',
  )}=${encodeURIComponent(goalMult)}&${encodeURIComponent('supportMult')}=${encodeURIComponent(
    supportMult,
  )}&${encodeURIComponent('approveMult')}=${encodeURIComponent(approveMult)}&${encodeURIComponent(
    'implementMult',
  )}=${encodeURIComponent(implementMult)}&${encodeURIComponent('roiDefault')}=${encodeURIComponent(
    activeTab === 0 ? gaming.easyMode.settings === ROI_SETTINGS.DEFAULT : false,
  )}&${encodeURIComponent('roiLessIdeas')}=${encodeURIComponent(
    activeTab === 0 ? gaming.easyMode.settings === ROI_SETTINGS.FEWER_IDEAS : false,
  )}&${encodeURIComponent('roiMoreIdeas')}=${encodeURIComponent(
    activeTab === 0 ? gaming.easyMode.settings === ROI_SETTINGS.MORE_IDEAS : false,
  )}`
}

export function setRewardInfo(id, reward) {
  return extractPropertiesFromJsonObject({
    id,
    rewardDesc: reward.description, 
    rewardFor: reward.rewarded,
    rewardOther: reward.rewardedOther,
  })
}

export function setChangeDatesBody(state) {
  return extractPropertiesFromJsonObject({
    id: state.data.challengeSteps.id,
    starts: state.data.challengeSteps.preview.fromDate.getTime(),
    ends: state.data.challengeSteps.preview.toDate.getTime(),
    isGlobal: state.data.challengeSteps.preview.challengeLocation,
  })
}


export function setLaunchBody(state) {
  return extractPropertiesFromJsonObject({
    id: state.data.challengeSteps.id,
    starts: state.data.challengeSteps.preview.fromDate.getTime(),
    ends: state.data.challengeSteps.preview.toDate.getTime(),
    groupIds: state.data.challengeSteps.preview.rightGroups.reduce((accumulator, current) => {
      return accumulator ? `${accumulator}##${current.id}` : current.id
    }, ''),
    organization: state.data.domain.domainConfiguration.isOrganizationsActive
      ? state.data.challengeSteps.preview.organization || null
      : null,
    isGlobal: state.data.challengeSteps.preview.challengeLocation,
  })
}


export function setChangeDatesBodyNew(state) {

  const fromstarts = state.data.ChallengeDraft.preview.fromDate
  const fromstartsNumber =  new Date(fromstarts).getTime();
  const toEnds = state.data.ChallengeDraft.preview.toDate
  const toEndsNumber = new Date(toEnds).getTime()

  return extractPropertiesFromJsonObject({
    id: state.data.ChallengeDraft.id,
    starts: fromstartsNumber,
    ends: toEndsNumber,
    isGlobal: state.data.ChallengeDraft.preview.challengeLocation,
  })
}


export function setLaunchBodyChallenges(state) {

  // const fromstarts = state.data.ChallengeDraft.preview.fromDate
  // const fromstartsNumber =  new Date(fromstarts).getTime();
  // const toEnds = state.data.ChallengeDraft.preview.toDate
  // const toEndsNumber = new Date(toEnds).getTime()

    return extractPropertiesFromJsonObject({
      id: state.data.ChallengeDraft.id,
      starts: state.data.ChallengeDraft.preview.fromDate.getTime(),
      ends: state.data.ChallengeDraft.preview.toDate.getTime(),
      groupIds: state.data.ChallengeDraft.preview.rightGroups.reduce((accumulator, current) => {
        return accumulator ? `${accumulator}##${current.id}` : current.id
      }, ''),
      organization: state.data.domain.domainConfiguration.isOrganizationsActive
        ? state.data.ChallengeDraft.preview.organization || null
        : null,
      isGlobal: state.data.ChallengeDraft.preview.challengeLocation,
    })

}

export function setProposeBody(state) {
  const { fileVideo, externalVideo } = state.data.challengeSteps.challengeDescription
  const [videoType, videoLink] = setVideoFromState(externalVideo, fileVideo)
  return extractPropertiesFromJsonObject({
    id: state.data.challengeSteps.id,
    title: state.data.challengeSteps.challengeDescription.title,
    desc: state.data.challengeSteps.challengeDescription.description,
    importance: state.data.challengeSteps.challengeDescription.whyImportant,
    videoLink,
    videoType,
    isGlobal: state.data.challengeSteps.preview.challengeLocation,
  })
}
 

export function getChallengeData(state){
  const { fileVideo, externalVideo } = state.data.ChallengeDraft.challengeDescription
  const [videoType, videoLink] = setVideoFromState(externalVideo, fileVideo)

  const goalMult = fromValueToPercentage(
    state.data.ChallengeDraft.gaming.advancedMode.defaultValues.currentGoal,
    state.data.ChallengeDraft.gaming.advancedMode.currentGoal,
  )
  const supportMult = fromValueToPercentage(
    state.data.ChallengeDraft.gaming.advancedMode.defaultValues.supportMult,
    state.data.ChallengeDraft.gaming.advancedMode.supportMult,
  )
  const approveMult = fromValueToPercentage(
    state.data.ChallengeDraft.gaming.advancedMode.defaultValues.approveMult,
    state.data.ChallengeDraft.gaming.advancedMode.approveMult,
  )
  const implementMult = fromValueToPercentage(
    state.data.ChallengeDraft.gaming.advancedMode.defaultValues.implementMult,
    state.data.ChallengeDraft.gaming.advancedMode.implementMult,
  )
  // console.log('demo',state.data.ChallengeDraft.selectedProposer)
  const selectedProposer = state.data.ChallengeDraft.selectedProposer
  const selectedSponsor  = state.data.ChallengeDraft.selectedSponsor


  const fromstarts = state.data.ChallengeDraft.preview.fromDate
  const fromstartsNumber =  new Date(fromstarts).getTime();
  const toEnds = state.data.ChallengeDraft.preview.toDate
  const toEndsNumber = new Date(toEnds).getTime()
  
  return{
      
    id: state.data.ChallengeDraft.id,
    title: state.data.ChallengeDraft.challengeDescription.title,
    desc: state.data.ChallengeDraft.challengeDescription.description,
    importance: state.data.ChallengeDraft.challengeDescription.whyImportant,

      videoType:videoType || null,
      videoLink:videoLink || null,
      proposer:selectedProposer && selectedProposer.email || '',
      sponsor:selectedSponsor && selectedSponsor.email,
      roiDefault:state.data.ChallengeDraft.gaming.activeTab === 0 ? state.data.ChallengeDraft.gaming.easyMode.settings === ROI_SETTINGS.DEFAULT : false,
      roiLessIdeas: state.data.ChallengeDraft.gaming.activeTab === 0 ? state.data.ChallengeDraft.gaming.easyMode.settings === ROI_SETTINGS.FEWER_IDEAS : false,
      roiMoreIdeas:state.data.ChallengeDraft.gaming.activeTab === 0 ? state.data.ChallengeDraft.gaming.easyMode.settings === ROI_SETTINGS.MORE_IDEAS : false,
      goalMult:goalMult,
      supportMult:supportMult,
      approveMult:approveMult,
      implementMult:implementMult,      
      rewardDesc: state.data.ChallengeDraft.reward.description,
      rewardFor: state.data.ChallengeDraft.reward.rewarded,
      rewardOther: state.data.ChallengeDraft.reward.rewardedOther,
      starts: fromstartsNumber,
      ends: toEndsNumber,
      groupIds: state.data.ChallengeDraft.preview.rightGroups.reduce((accumulator, current) => {
        return accumulator ? `${accumulator}##${current.id}` : current.id
      }, ''),
      organization: state.data.domain.domainConfiguration.isOrganizationsActive
        ? state.data.ChallengeDraft.preview.organization || null
        : null,
      isGlobal: state.data.ChallengeDraft.preview.challengeLocation,
  }
}


export function setChallengeLaunch(state){
  const outGoingData = getChallengeData(state )
  return extractPropertiesFromJsonObject(outGoingData)
}


export function setChallengeDraft(state) {
  const { fileVideo, externalVideo } = state.data.ChallengeDraft.challengeDescription
  const [videoType, videoLink] = setVideoFromState(externalVideo, fileVideo)

  const goalMult = fromValueToPercentage(
    state.data.ChallengeDraft.gaming.advancedMode.defaultValues.currentGoal,
    state.data.ChallengeDraft.gaming.advancedMode.currentGoal,
  )
  const supportMult = fromValueToPercentage(
    state.data.ChallengeDraft.gaming.advancedMode.defaultValues.supportMult,
    state.data.ChallengeDraft.gaming.advancedMode.supportMult,
  )
  const approveMult = fromValueToPercentage(
    state.data.ChallengeDraft.gaming.advancedMode.defaultValues.approveMult,
    state.data.ChallengeDraft.gaming.advancedMode.approveMult,
  )
  const implementMult = fromValueToPercentage(
    state.data.ChallengeDraft.gaming.advancedMode.defaultValues.implementMult,
    state.data.ChallengeDraft.gaming.advancedMode.implementMult,
  )

  const selectedProposer = state.data.ChallengeDraft.selectedProposer
  const selectedSponsor  = state.data.ChallengeDraft.selectedSponsor

  const fromstarts = state.data.ChallengeDraft.preview.fromDate
  const fromstartsNumber =  new Date(fromstarts).getTime();
  const toEnds = state.data.ChallengeDraft.preview.toDate
  const toEndsNumber = new Date(toEnds).getTime()

  return extractPropertiesFromJsonObject({
    id: state.data.ChallengeDraft.id,
    title: state.data.ChallengeDraft.challengeDescription.title,
    desc: state.data.ChallengeDraft.challengeDescription.description,
    importance: state.data.ChallengeDraft.challengeDescription.whyImportant,

      videoType:videoType || null,
      videoLink:videoLink || null,
      proposer:selectedProposer && selectedProposer.email || '',
      sponsor:selectedSponsor && selectedSponsor.email,
      roiDefault:state.data.ChallengeDraft.gaming.activeTab === 0 ? state.data.ChallengeDraft.gaming.easyMode.settings === ROI_SETTINGS.DEFAULT : false,
      roiLessIdeas: state.data.ChallengeDraft.gaming.activeTab === 0 ? state.data.ChallengeDraft.gaming.easyMode.settings === ROI_SETTINGS.FEWER_IDEAS : false,
      roiMoreIdeas:state.data.ChallengeDraft.gaming.activeTab === 0 ? state.data.ChallengeDraft.gaming.easyMode.settings === ROI_SETTINGS.MORE_IDEAS : false,
      goalMult:goalMult,
      supportMult:supportMult,
      approveMult:approveMult,
      implementMult:implementMult,      
      rewardDesc: state.data.ChallengeDraft.reward.description,
      rewardFor: state.data.ChallengeDraft.reward.rewarded,
      rewardOther: state.data.ChallengeDraft.reward.rewardedOther,
      starts: fromstartsNumber,
      ends: toEndsNumber,
      groupIds: state.data.ChallengeDraft.preview.rightGroups.reduce((accumulator, current) => {
        return accumulator ? `${accumulator}##${current.id}` : current.id
      }, ''),
      organization: state.data.domain.domainConfiguration.isOrganizationsActive
        ? state.data.ChallengeDraft.preview.organization || null
        : null,
      isGlobal: state.data.ChallengeDraft.preview.challengeLocation,
  })
}


export function setProposeBodyDraft(state) {
  const { fileVideo, externalVideo } = state.data.ChallengeDraft.challengeDescription
  const [videoType, videoLink] = setVideoFromState(externalVideo, fileVideo)
  return extractPropertiesFromJsonObject({
    id: state.data.ChallengeDraft.id,
    title: state.data.ChallengeDraft.challengeDescription.title,
    desc: state.data.ChallengeDraft.challengeDescription.description,
    importance: state.data.ChallengeDraft.challengeDescription.whyImportant,
    videoType:videoType || null,
    videoLink:videoLink || null,
    isGlobal: state.data.ChallengeDraft.preview.challengeLocation,
  })
}
 






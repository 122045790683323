import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import styles from './View.Style'

const TotalInvestments = props => {
  const { classes, totalInvested, totalProfit } = props
  return (
    <div className={classes.container}>
      <div>
        <span className={classes.label}>{i18n.t('INVESTMENTS.TOTAL_INVEST')}</span>
        <span className={classes.amount}>
          <NxtVirtualCurrency>{totalInvested}</NxtVirtualCurrency>
        </span>
      </div>

      <div>
        <span className={classes.label}>{i18n.t('INVESTMENTS.TOTAL_PROFIT')}</span>
        <span className={classes.amount}>
          <NxtVirtualCurrency>{totalProfit}</NxtVirtualCurrency>
        </span>
      </div>
    </div>
  )
}

TotalInvestments.defaultProps = {
  totalProfit: 0,
  totalInvested: 0,
}

TotalInvestments.propTypes = {
  totalProfit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalInvested: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default withStyles(styles)(TotalInvestments)

import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Paper } from '@material-ui/core'
import clsx from 'clsx'
import styles from './View.Style'
import IdeaCard from '../idea_card/View'
import BalanceCard from '../balance_card/View'
import CommentCard from '../comment_card/View'
import BadgeCard from '../badge_card/View'

const Components = {
  IDEA: IdeaCard,
  BALANCE: BalanceCard,
  COMMENT: CommentCard,
  BADGE: BadgeCard,
}

const EventItem = props => {
  const { classes, isRight, event, navigateToIdea, badges, labelColorPrimary } = props
  const Component = Components[event.type] || Fragment
  return (
    <Fragment>
      <div className={clsx(classes.timeAgoLabel, isRight && classes.rightTimeAgo)}>
        <div className={clsx(classes.timeAgo, labelColorPrimary && classes.primaryBackground)}>
          <span>{event.timeAgo}</span>
        </div>
      </div>
      <Paper className={classes.card}>
        <Component
          classes={{ body: classes.cardBody, title: classes.cardTitle }}
          navigateToIdea={navigateToIdea}
          badges={badges}
          {...event}
        />
      </Paper>
    </Fragment>
  )
}

EventItem.propTypes = {
  isRight: PropTypes.bool.isRequired,
  event: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  badges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labelColorPrimary: PropTypes.bool.isRequired,
}

export default withStyles(styles)(EventItem)

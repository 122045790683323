import { connect } from 'react-redux'
import { resetPopupDialogToCurrentDialog } from 'support/popup_dialogs/index'
import { hideDialog, hideSnackbar } from './ModalActions'
import ModalAlertControllerView from './ModalAlertControllerView'

const mapStateToProps = state => ({
  showedDialogs: state.modalAlertReducer.showedDialogs,
  showSnackbar: state.modalAlertReducer.showSnackbar,
})

const mapDispatchToProps = dispatch => ({
  hideDialog: () => {
    dispatch(hideDialog())
  },
  resetDialog: () => {
    resetPopupDialogToCurrentDialog()
  },
  hideSnackbar: () => {
    dispatch(hideSnackbar())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalAlertControllerView)

import { createStyles } from '@material-ui/core/styles'

const styles = () =>
  createStyles({
    stepperRoot: {
      background: 'unset',
    },
  })

export default styles

const styles = theme => ({
  imageContainer: {
    float: 'right',
    flexGrow: 1,
    position: 'relative',
    width: '100%',
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    height: '310px',
  },
  deleteButton: {
    padding: 5,
    position: 'absolute',
    right: 5,
    top: 5,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
  radioButtonMain: {
    paddingLeft: theme.spacing(1.5),
  },
})

export default styles

import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    borderRight: {
      borderRight: '1px',
      borderRightColor: '#666',
      borderRightStyle: 'solid',
    },
    colorBlack: {
      color: 'black',
    },
    colorPrimary: {
      color: theme.palette.primary.main,
    },
    column: {
      flexDirection: 'column',
    },
    listItem: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0.5rem',
    },
    secondaryText: {
      display: 'flex',
      flexFlow: 'column nowrap',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    primaryText: {
      display: 'flex',
      flexFlow: 'column nowrap',
      width: '55%',
      '& > div': {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        '& > i': {
          fontSize: '1rem',
        },
        '& > span': {
          fontSize: '1.2rem',
          fontWeight: 'bold',
        },
      },
      '& > span': {
        fontSize: '0.75rem',
      },
    },
    row: {
      flexDirection: 'row',
      alignItems: 'baseline',
      '& > span': {
        marginLeft: '0.5rem',
      },
      '& i': {
        marginRight: '0.5rem',
      },
    },
  })

export default styles

import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Button } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'

const PeopleSectionBar = props => {
  const { classes, children, onSendButtonClick } = props
  return (
    <div className={classes.root}>
      <div>{children}</div>
      <Button color="primary" variant="contained" onClick={onSendButtonClick}>
        {i18n.t('DASHBOARD.DOWNLOAD_EXCEL')}
      </Button>
    </div>
  )
}

PeopleSectionBar.propTypes = {
  onSendButtonClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(PeopleSectionBar)

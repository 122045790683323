import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const TermsOfService = props => {
  const { classes } = props

  return (
    <div className={classes.container}>
      <h3>NOVA ONLINE IDEA DEVELOPMENT PLATFORM AND COLLABORATION SOFTWARE</h3>
      <p>Effective Date: July, 1st. 2015.</p>
      <p>
        THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU REPRESENT (hereinafter “You” or
        “Your”) AND NOVA.Inc (hereinafter “NOVA”) GOVERNING YOUR NON-EXCLUSIVE USE OF
        NOVA’S ONLINE IDEA DEVELOPMENT PLATFORM AND COLLABORATION SOFTWARE.
      </p>
      <ol className="roman">
        <li>
          Parts of this Agreement
          <p>
            This Agreement consists of the following terms and conditions (hereinafter the “General
            Terms”) and terms and conditions, if any, specific to use of individual Services
            (hereinafter the “Service Specific Terms”). The General Terms and Service Specific Terms
            are collectively referred to as the “Terms”. In the event of a conflict between the
            General Terms and Service Specific Terms, the Service Specific Terms shall prevail.
          </p>
        </li>
        <li>
          Acceptance of the Terms
          <p>
            Usage of the Services implies full acceptance of the General Terms and any applicable
            Service Specific Terms. You understand that you must be of legal age to enter into a
            binding agreement in order to accept the Terms. If you are not of legal age and/or do
            not agree to the General Terms, please do not use any of our Services. If you agree to
            the General Terms and do not agree to any Service Specific Terms, please do not use the
            corresponding Service. You can accept the Terms by checking a checkbox or clicking on a
            button indicating your acceptance of the terms or by actually using the Services. In
            addition to this, acceptance by you or the entity that you represent of a “NOVA
            Platform License Proposal” or a “NOVA Platform License and Customization Proposal”
            implies full acceptance of the General Terms and any applicable Service Specific Terms.
          </p>
        </li>
        <li>
          Description of Service
          <p>
            We provide an on-line platform that comprises an array of services and features for idea
            generation and development as well as for on-line collaboration (&quot;Service&quot; or
            &quot;Services&quot;). You may use the Services for your personal and business use or
            for internal business purpose in the organization that you represent. You may connect to
            the Services using any Internet browser supported by the Services. You are responsible
            for obtaining access to the Internet and the equipment necessary to use the Services.
            You can create and edit content with your user account and if you choose to do so, you
            can publish and share such content.
          </p>
        </li>
        <li>
          Subscription to Beta Service
          <p>
            We may offer certain Services as closed or open beta services (&quot;Beta Service&quot;
            or “Beta Services”) for the purpose of testing and evaluation. You agree that we have
            the sole authority and discretion to determine the period of time for testing and
            evaluation of Beta Services. We will be the sole judge of the success of such testing
            and the decision, if any, to offer the Beta Services as commercial services. You will be
            under no obligation to acquire a subscription to use any paid Service as a result of
            your subscription to any Beta Service. We reserve the right to fully or partially
            discontinue, at any time and from time to time, temporarily or permanently, any of the
            Beta Services with or without notice to you. You agree that NOVA will not be liable
            to you or to any third party for any harm related to, arising out of, or caused by the
            modification, suspension or discontinuance of any of the Beta Services for any reason.
          </p>
        </li>
        <li>
          Modification of Terms of Service
          <p>
            We may modify the Terms upon notice to you at any time through a service announcement or
            by sending email to your primary email address. If we make significant changes to the
            Terms that affect your rights, you will be provided with at least 30 days advance notice
            of the changes by email to your primary email address. You may terminate your use of the
            Services by providing NOVA notice by email within 30 days of being notified of the
            availability of the modified Terms if the Terms are modified in a manner that
            substantially affects your rights in connection with use of the Services. In the event
            of such termination, you will be entitled to prorated refund of the unused portion of
            any prepaid fees. Your continued use of the Service after the effective date of any
            change to the Terms will be deemed to be your agreement to the modified Terms.
          </p>
        </li>
        <li>
          User Sign up Obligations
          <p>
            You are required to sign up for a user account by providing all required information in
            order to access or use the Services. If you represent an organization and wish to use
            the Services for corporate internal use, we recommend that you, and all other users from
            your organization, sign up for user accounts by providing your corporate contact
            information. In particular, we recommend that you use your corporate email address. You
            agree to: a) provide true, accurate, current and complete information about yourself as
            prompted by the sign up process; and b) maintain and promptly update the information
            provided during sign up to keep it true, accurate, current, and complete. If you provide
            any information that is untrue, inaccurate, outdated, or incomplete, or if NOVA has
            reasonable grounds to suspect that such information is untrue, inaccurate, outdated, or
            incomplete, NOVA may terminate your user account and refuse current or future use of
            any or all of the Services.
          </p>
        </li>
        <li>
          Organization Accounts and Administrators
          <p>
            When you sign up for an account for your organization you may specify one or more
            administrators. The administrators will have the right to configure the Services based
            on your requirements and manage end users in your organization account. If your
            organization account is created and configured on your behalf by a third party, it is
            likely that such third party has assumed administrator role for your organization. Make
            sure that you enter into a suitable agreement with such third party specifying such
            party’s roles and restrictions as an administrator of your organization account.
          </p>
          <p>
            You are responsible for i) ensuring confidentiality of your organization account
            password, ii) appointing competent individuals as administrators for managing your
            organization account, and iii) ensuring that all activities that occur in connection
            with your organization account comply with this Agreement and any applicable Service
            Specific Terms. You understand that NOVA is not responsible for account
            administration and internal management of the Services for you.
          </p>
          <p>
            You are responsible for taking necessary steps for ensuring that your organization does
            not lose control of the administrator accounts. You may specify a process to be followed
            for recovering control in the event of such loss of control of the administrator
            accounts by sending an email to{' '}
            <a href="mailto:nova@vodafone.com">nova@vodafone.com</a>, provided that
            the process is acceptable to NOVA. In the absence of any specified administrator
            account recovery process, NOVA may provide control of an administrator account to an
            individual providing proof satisfactory to NOVA demonstrating authorization to act
            on behalf of the organization. You agree not to hold NOVA liable for the
            consequences of any action taken by NOVA in good faith in this regard.
          </p>
        </li>
        <li>
          Intellectual Property
          <p>
            All the software and contents of the Services, including: html code, texts, animations,
            images etc. in addition to the trademarks, trade names and/or distinctive marks
            displayed are property of NOVA or of third parties and are protected by national and
            international intellectual and industrial property laws. Any use of them outside of the
            Services, including the reproduction, modification, distribution, transmission,
            republication, arrangement or representation of any part thereof, is strictly forbidden
            without express written consent from NOVA. All rights not expressly granted in this
            Agreement are expressly reserved by NOVA.
          </p>
          <p>
            You hereby acknowledge that NOVA owns all rights, titles and interests in and
            pertaining to the Services, its Documentation and all Intellectual Property Rights
            associated therewith, including the layout (look and feel), design, texts and
            organization of the Services and any reports, forms or templates or management
            information and/or statistical usage data generated by the Services. Users shall not
            acquire any right, title or interest in the Services, the Documentation and all
            Intellectual Property Rights associated therewith, including without limitation the
            layout (look and feel), design, texts and organization of the Services and any reports
            or management information and/or statistical usage data generated by them.
          </p>
        </li>
        <li>
          Personal Information and Privacy
          <p>
            All personal information you provide to NOVA at sign-up and through the Services is
            governed by NOVA’s Privacy Policy. Your election to use the Service indicates your
            acceptance of the terms of the NOVA Privacy Policy. You are responsible for
            maintaining confidentiality of your username, password and any other sensitive
            information. You are responsible for all activities that occur in your user account and
            you agree to inform NOVA immediately of any unauthorized use of your user account by
            email to <a href="mailto:nova@vodafone.com">nova@vodafone.com</a>. We
            are not responsible for any loss or damage to you or to any third party incurred as a
            result of any unauthorized access and/or use of your user account, or otherwise.
          </p>
        </li>
        <li>
          Communications from NOVA
          <p>
            The Service may include certain communications from NOVA, such as service
            announcements, administrative messages and newsletters. You understand that these
            communications shall be considered part of using the Services. As part of our policy to
            provide you with a reasonable level of privacy, we also provide you the option of opting
            out from receiving newsletters from us. However, you will not be able to opt-out from
            receiving service announcements and administrative messages.
          </p>
        </li>
        <li>
          Complaints
          <p>
            If we receive a complaint from any person against you with respect to your activities as
            part of use of the Services, we will forward the complaint to the primary email address
            of your user account. You must respond to the complainant directly within 10 days of
            receiving the complaint forwarded by us and copy NOVA in the communication. If you
            do not respond to the complainant within 10 days from the date of our email to you, we
            may disclose your name and contact information to the complainant for enabling the
            complainant to take legal action against you. You understand that your failure to
            respond to the forwarded complaint within the 10 days’ time limit will be construed as
            your consent to disclosure of your name and contact information by NOVA to the
            complainant.
          </p>
        </li>
        <li>
          Fees and Payments
          <p>
            The Services are available under subscription plans of various durations. Your
            subscription will be automatically renewed at the end of each subscription period unless
            you downgrade your paid subscription plan to a free plan or inform us that you do not
            wish to renew the subscription If you do not wish to renew the subscription, you must
            inform us at least seven days prior to the renewal date. If you have not downgraded to a
            free plan and if you have not informed us that you do not wish to renew the
            subscription, you will be presumed to have authorized NOVA to charge the
            subscription fee.
          </p>
          <p>
            From time to time, we may change the price of any Service or charge for use of Services
            that are currently available free of charge. Any increase in charges will not apply
            until the expiry of your then current billing cycle. You will not be charged for using
            any Service unless you have opted for a paid subscription plan.
          </p>
        </li>
        <li>
          Restrictions on Use
          <p>
            In addition to all other terms and conditions of this Agreement, you shall not: (i)
            transfer the Services or otherwise make it available to any third party; (ii) provide
            any service based on the Services without prior written permission; (iii) use the third
            party links to sites without agreeing to their website terms & conditions; (iv) post
            links to third party sites or use their logo, company name, etc. without their prior
            written permission; (v) publish any personal or confidential information belonging to
            any person or entity without obtaining consent from such person or entity; (vi) use the
            Services in any manner that could damage, disable, overburden, impair or harm any
            server, network, computer system, resource of NOVA; (vii) violate any applicable
            local, state, national or international law; and (viii) create a false identity to
            mislead any person as to the identity or origin of any communication.
          </p>
        </li>
        <li>
          Spamming and Illegal Activities
          <p>
            You agree to be solely responsible for the contents of your transmissions through the
            Services. You agree not to use the Services for illegal purposes or for the transmission
            of material that is unlawful, defamatory, harassing, libelous, invasive of
            another&apos;s privacy, abusive, threatening, harmful, vulgar, pornographic, obscene, or
            is otherwise objectionable, offends religious sentiments, promotes racism, contains
            viruses or malicious code, or that which infringes or may infringe intellectual property
            or other rights of another. You agree not to use the Services for the transmission of
            &quot;junk mail&quot;, &quot;spam&quot;, &quot;chain letters&quot;, &quot;phishing&quot;
            or unsolicited mass distribution of email. We reserve the right to terminate your access
            to the Services if there are reasonable grounds to believe that you have used the
            Services for any illegal or unauthorized activity. Inactive User Accounts Policy
          </p>
          <p>
            We reserve the right to terminate unpaid user accounts that are inactive for a
            continuous period of 120 days. In the event of such termination, all data associated
            with such user account will be deleted. We will provide you prior notice of such
            termination and option to back-up your data. The data deletion policy may be implemented
            with respect to any or all of the Services. Each Service will be considered an
            independent and separate service for the purpose of calculating the period of
            inactivity. In other words, activity in one of the Services is not sufficient to keep
            your user account in another Service active. In case of accounts with more than one
            user, if at least one of the users is active, the account will not be considered
            inactive.
          </p>
        </li>
        <li>
          Data Ownership
          <p>
            We respect your right to ownership of content created or stored by you. You own the
            content created or stored by you. Unless specifically permitted by you, your use of the
            Services does not grant NOVA the license to use, reproduce, adapt, modify, publish
            or distribute the content created by you or stored in your user account for NOVA’s
            commercial, marketing or any similar purpose. But you grant NOVA permission to
            access, copy, distribute, store, transmit, reformat, publicly display and publicly
            perform the content of your user account solely as required for the purpose of providing
            the Services to you.
          </p>
          <p>
            For procedure relating to complaints of illegality or infringement of third party rights
            in content transmitted or published using the Services, click here.
          </p>
          <p>
            If you wish to protest any blocking or removal of content by NOVA, you may do so in
            the manner provided here.
          </p>
        </li>
        <li>
          Sample files and Applications
          <p>
            NOVA may provide sample files and applications for the purpose of demonstrating the
            possibility of using the Services effectively for specific purposes. The information
            contained in any such sample files and applications consists of random data. NOVA
            makes no warranty, either express or implied, as to the accuracy, usefulness,
            completeness or reliability of the information or the sample files and applications.
            Trademark
          </p>
          <p>
            NOVA, the NOVA logo, the names of individual Services and their logos are
            trademarks of NOVA.inc or its affiliates. You agree not to display or use, in any
            manner, the NOVA trademarks, without NOVA&apos;s prior permission.
          </p>
        </li>
        <li>
          Cookie usage notice
          <p>
            We use &quot;cookies&quot; within the services for the purpose of permitting and user
            interaction with it. Furthermore, the statistical services - ours or from third
            parties&apos; -, used by the Services use &quot;cookies&quot; for the sole purpose of
            establishing metrics and patterns of site and Service usage. Access to and usage of the
            Services necessarily implies your consent to the use of &quot;cookies&quot; by NOVA
            and/or its service providers.
          </p>
        </li>
        <li>
          Disclaimer of Warranties
          <p>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE SERVICES IS AT YOUR SOLE RISK.
            THE SERVICES ARE PROVIDED ON AN AS-IS-AND-AS-AVAILABLE BASIS. NOVA EXPRESSLY
            DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
            LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
            PURPOSE.
          </p>
          <p>
            NOVA MAKES NO WARRANTY THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
            ERROR FREE. USE OF ANY MATERIAL DOWNLOADED OR OBTAINED THROUGH THE USE OF THE SERVICES
            SHALL BE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY
            DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE TELEPHONE, WIRELESS DEVICE OR DATA THAT RESULTS
            FROM THE USE OF THE SERVICES OR THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR
            INFORMATION, WHETHER WRITTEN OR ORAL, OBTAINED BY YOU FROM NOVA, ITS EMPLOYEES OR
            REPRESENTATIVES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.
          </p>
        </li>
        <li>
          Limitation of Liability
          <p>
            YOU AGREE THAT NOVA AND/OR ITS AFFILIATE COMPANIES SHALL, IN NO EVENT, BE LIABLE FOR
            ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, OR OTHER LOSS OR DAMAGE
            WHATSOEVER OR FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, COMPUTER FAILURE,
            LOSS OF BUSINESS INFORMATION, OR OTHER LOSS ARISING OUT OF OR CAUSED BY YOUR USE OF OR
            INABILITY TO USE THE SERVICE, EVEN IF NOVA HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGE. IN NO EVENT SHALL NOVA’S ENTIRE LIABILITY TO YOU IN RESPECT OF ANY
            SERVICE, WHETHER DIRECT OR INDIRECT, EXCEED THE FEES PAID BY YOU TOWARDS SUCH SERVICE.
          </p>
        </li>
        <li>
          Indemnification
          <p>
            You agree to indemnify and hold harmless NOVA, its officers, directors, employees,
            suppliers, and affiliates, from and against any losses, damages, fines and expenses
            (including attorney&apos;s fees and costs) arising out of or relating to any claims that
            you have used the Services in violation of another party&apos;s rights, in violation of
            any law, in violations of any provisions of the Terms, or any other claim related to
            your use of the Services, except where such use is authorized by NOVA
          </p>
        </li>
        <li>
          Arbitration
          <p>
            Any controversy or claim arising out of or relating to the Terms shall be settled by
            binding arbitration in accordance with the commercial arbitration rules of the American
            Arbitration Association. Any such controversy or claim shall be arbitrated on an
            individual basis, and shall not be consolidated in any arbitration with any claim or
            controversy of any other party. The decision of the arbitrator shall be final and
            unappealable. The arbitration shall be conducted in Redwood City, California and
            judgment on the arbitration award may be entered into any court having jurisdiction
            thereof. Notwithstanding anything to the contrary, NOVA may at any time seek
            injunctions or other forms of equitable relief from any court of competent jurisdiction.
          </p>
        </li>
        <li>
          Suspension and Termination
          <p>
            We may suspend your user account or temporarily disable access to whole or part of any
            Service in the event of any suspected illegal activity, extended periods of inactivity
            or requests by law enforcement or other government agencies. Objections to suspension or
            disabling of user accounts should be made to{' '}
            <a href="mailto:nova@vodafone.com">nova@vodafone.com</a> within thirty
            days of being notified about the suspension. We may terminate a suspended or disabled
            user account after thirty days. We will also terminate your user account on your
            request.
          </p>
          <p>
            In addition, we reserve the right to terminate your user account and deny the Services
            upon reasonable belief that you have violated the Terms and to terminate your access to
            any Beta Service in case of unexpected technical issues or discontinuation of the Beta
            Service. You have the right to terminate your user account if NOVA breaches its
            obligations under these Terms and in such event, you will be entitled to prorated refund
            of any prepaid fees. Termination of user account will include denial of access to all
            Services, deletion of information in your user account such as your email address and
            password and deletion of all data in your user account.
          </p>
        </li>
      </ol>
    </div>
  )
}

TermsOfService.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(TermsOfService)

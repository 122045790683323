import background from 'img/bg-user-dashboard-balance2x.png'

const styles = theme => ({
  container: {
    background: `url(${background}) repeat`,
    backgroundSize: '18px 4px',
    border: '1px solid #dad7d7',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
    width: '100%',
    boxSizing: 'border-box',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      width: '48%',
      borderBottom: '1px dotted',
    },
    '& > div:first-child': {
      alignItems: 'flex-start',
    },
    '& > div:last-child': {
      alignItems: 'flex-end',
    },
  },
  label: {
    fontWeight: 'bold',
    fontSize: '16px',
    display: 'block',
    color: '#333',
  },
  amount: {
    fontSize: '48px',
    color: theme.palette.primary.main,
  },
})

export default styles

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  stepper: {
    backgroundColor: 'transparent',
    '& svg': {
      fontSize: theme.spacing(3.5),
    },
    '& .MuiStepConnector-alternativeLabel': {
      top: theme.spacing(1.5),
    },
  },
  infoMessage: {
    display: 'flex',
    backgroundColor: props => props.bannerColor,
    padding: theme.spacing(3),
    fontSize: theme.spacing(1.75),
  },
  infoMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    margin: '0',
    padding: '0',
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      outline: 'none',
    },
  },
})

export default styles

const styles = theme => ({
  imageName: {
    ...theme.flexRowCenter,
    justifyContent: 'flex-start',
    '& > img': {
      width: '18%',
      borderRadius: '100%',
    },
    '& > div': {
      width: '82%',
      paddingLeft: '5%',
    },
  },
  nameImage: {
    ...theme.flexRowCenter,
    justifyContent: 'flex-end',
    '& > img': {
      width: '25%',
      borderRadius: '100%',
    },
    '& > div': {
      width: '75%',
      paddingRight: '5%',
      textAlign: 'end',
    },
  },
  textContainer: {
    '& span': {
      display: 'block',
      fontSize: '0.875rem',
    },
    '& span:last-child': {
      fontWeight: 'bold',
    },
  },
  flexInverted: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
})

export default styles

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import styles from './View.Style'

class NxtCardStatus extends Component {
  getIconFromStatus = statusType => {
    switch (statusType) {
      case 'SUPPORTED':
        return 'icon-success'
      case 'PENDING_SUPPORT':
        return 'icon-earn-money'
      case 'ROUND_CLOSED':
        return 'icon-share'
      case 'APPROVED_BY_IT':
        return 'icon-approved'
      case 'DISCARDED':
        return 'icon-close'
      case 'IMPLEMENTED':
        return 'icon-ranking'
      case 'PILOT_ACTIVE':
        return 'icon-prototype'
      case 'DRAFT':
        return ''
      default:
        return false
    }
  }

  render() {
    const { classes, status } = this.props

    const icon = this.getIconFromStatus(status)

    return (
      <div className={classes.ideaStatus}>
        <span>{i18n.t(`IDEA_STATUS.${status}`)}</span>
        <i className={icon} />
      </div>
    )
  }
}

NxtCardStatus.defaultProps = {}

NxtCardStatus.propTypes = {
  classes: PropTypes.shape({
    ideaStatus: PropTypes.string.isRequired,
  }).isRequired,
  status: PropTypes.string.isRequired,
}

export default withStyles(styles)(NxtCardStatus)

const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '30rem',
    justifyContent: 'space-between',
  },
  formControl: {
    width: '8rem',
    '& > div:first-child > div:first-child': {
      padding: '0.7rem',
    },
  },
})

export default styles

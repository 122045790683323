import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { withStyles, InputAdornment } from '@material-ui/core'
import i18n from 'i18n-js'
import styles from './View.Styles'

class NxtDatePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      localeFile: null,
    }
  }

  componentDidMount() {
    let { locale } = this.props
    locale = locale === 'en' ? 'en-US' : locale
    this.setLocaleFile(locale)
  }

  async setLocaleFile(localeString) {
    let localeFile = await import(`date-fns/locale/${localeString}`)
    localeFile = localeFile.default
    this.setState({
      localeFile,
    })
  }

  render() {
    const { localeFile } = this.state
    const {
      className,
      onDateChange,
      label,
      isFullWidth,
      format,
      value,
      endIconName,
      minDate,
      hasError,
      isDisabled,
    } = this.props
    return (
      <div className={className}>
        {localeFile && (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeFile}>
            <DatePicker
              disabled={isDisabled}
              value={value}
              onChange={onDateChange}
              label={label}
              fullWidth={isFullWidth}
              format={format}
              minDate={minDate}
              error={hasError}
              InputProps={{
                endAdornment:
                  endIconName.length > 0 ? (
                    <InputAdornment position="end">
                      <span className={endIconName}></span>
                    </InputAdornment>
                  ) : null,
              }}
            />
          </MuiPickersUtilsProvider>
        )}
      </div>
    )
  }
}

NxtDatePicker.defaultProps = {
  className: '',
  locale: i18n.currentLocale(),
  label: '',
  isFullWidth: true,
  format: 'MMMM do yyyy',
  endIconName: '',
  minDate: new Date('1900-01-01'),
  hasError: false,
  isDisabled: false,
}
NxtDatePicker.propsTypes = {
  className: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  locale: PropTypes.string,
  label: PropTypes.string,
  isFullWidth: PropTypes.bool,
  format: PropTypes.string,
  value: PropTypes.instanceOf(Date).isRequired,
  endIconName: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
}
export default withStyles(styles)(NxtDatePicker)

export function extractPropertiesFromJsonObject(jsonObject) {
  const formBody = Object.keys(jsonObject).reduce((acc, key) => {
    const value = jsonObject[key]
    if (value === undefined || value === null) {
      return acc
    }
    if (value instanceof Array) {
      const arrayItem = value.reduce((accArray, valueArray) => {
        if (valueArray instanceof Object) {
          accArray.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(valueArray))}`,
          )
        } else {
          accArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(valueArray)}`)
        }
        return accArray
      }, [])
      return acc.concat(arrayItem)
    }
    if (value instanceof Object) {
      acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`)
    } else {
      acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    }
    return acc
  }, [])
  return formBody.join('&')
}

const styles = theme => ({
  root: {},
  moneyTitle: {},
  moneyBody: {
    paddingLeft: theme.spacing(0.5),
    fontSize: '20px',
    fontWeight: 700,
  },
  title: {},
  body: {
    '& > div:last-child': {
      display: 'flex',
      alignItems: 'baseline',
    },
  },
  linkButton: {
    color: theme.palette.primary.main,
    fontSize: 'inherit',
  },
  currency: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
})

export default styles

const styles = () => ({
  box: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow:
      'inset 0 1px 0 rgba(0,0,0,.1), inset 0 -1px 0 rgba(0,0,0,.4), 0 1px 2px rgba(0,0,0,.2)',
    height: '570px',
    marginBottom: '50px',
    padding: '20px',
  },
  iframe: {
    width: '100%',
    height: '408px',
    border: '0',
  },
  title: {
    display: 'flex',
    margin: '10px',
    marginTop: '20px',
    marginLeft: '20px',
  },
  icons: {
    height: '117px',
    paddingTop: '46px',
  },
  icon: {
    float: 'left',
    display: 'grid',
    textAlign: 'center',
    width: '150px',
    marginRight: '34px',
  },
  label: {
    fontSize: '40px',
    marginBottom: '12px',
    color: '#666',
  },
  textIcon: {
    fontSize: '16px',
    color: '#333',
  },
})

export default styles

import React from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import { withStyles } from '@material-ui/core/styles'
// import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
// import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
// import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import Rating from '@material-ui/lab/Rating'

import MuiDialogTitle from '@material-ui/core/DialogTitle'
// import MuiDialogContent from '@material-ui/core/DialogContent'
// import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root1: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  dialogPaper: {
    minHeight: '70vh',
    maxHeight: '30vh',
  },
  dialogPaper2: {
    minHeight: '40vh',
  },
})
const customIcons = {
  1: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  2: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  3: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  },
}
function IconContainer(props) {
  const { value, ...other } = props
  return <span {...other}>{customIcons[value].icon}</span>
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

// const DialogContent = withStyles(theme => ({
//   root: {
//     padding: theme.spacing(2),
//   },
// }))(MuiDialogContent)

// const DialogActions = withStyles(theme => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions)

function EvaluatorCommentsList(props) {
  const [open, setOpen] = React.useState(true)
  const { commentsList, handleDialog, stage, classes, isAdmin } = props

  // var avg = 0

  const handleClose = () => {
    setOpen(false)
    handleDialog()
  }

  return (
    // <Dialog fullScreen open={true} TransitionComponent={Transition}>
    <Dialog
      fullWidth={true}
      classes={{ paper: isAdmin ? classes.dialogPaper : classes.dialogPaper2 }}
      maxWidth={isAdmin ? 'md' : 'sm'}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      TransitionComponent={Transition}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Previous Comments
      </DialogTitle>
      {commentsList.map((data, index) => {
        return (
          <List>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary={data.loggedInUser}
                secondary={
                  <React.Fragment>
                    <Typography component="span" variant="body2" color="textPrimary">
                      {stage === 1 && data.Q1Comment}
                      {stage === 2 && data.Q2Comment}
                      {stage === 3 && data.Q3Comment}
                    </Typography>
                    <div>
                      <Rating
                        name="customized-icons"
                        max={3}
                        readOnly={true}
                        size="large"
                        defaultValue={() => {
                          if (stage === 1) return data.Q1measurement
                          if (stage === 2) return data.Q2measurement
                          if (stage === 3) return data.Q3measurement
                        }}
                        // getLabelText={value => customIcons[value].label}
                        IconContainerComponent={IconContainer}
                      />
                    </div>
                    <div>Action: {data.type ? data.type : 'N/A'}</div>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </List>
        )
      })}
      {/* <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  )
}

export default withStyles(styles)(EvaluatorCommentsList)

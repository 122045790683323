import React, { useState, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Avatar, IconButton } from '@material-ui/core'
import { Delete, Close, Check, Folder } from '@material-ui/icons'
import i18n from 'support/i18n'
import styles from './View.Style'

const DocumentElement = ({
  classes,
  documentId,
  name,
  timeAgo,
  size,
  src,
  onDeleteDocument,
  canDelete,
  csrfToken,
}) => {
  const [isDeleting, setIsDeleting] = useState(false)
  return (
    <Fragment>
      <div className={classes.leftContent}>
        <Avatar>
          <Folder />
        </Avatar>
        <span>
          <a href={`${src}?_nxt_csrf=${csrfToken}`}>{name}</a>
        </span>
      </div>
      <div className={classes.middleContent}>
        <span>{timeAgo}</span>
      </div>
      <div className={classes.rightContent}>
        {!isDeleting && (
          <Fragment>
            <span>{`${i18n.toCurrency(size, {
              unit: '',
              precision: 0,
            })} KB`}</span>
            {canDelete && (
              <IconButton
                className={classes.deleteButton}
                onClick={() => setIsDeleting(true)}
                aria-label="delete"
              >
                <Delete />
              </IconButton>
            )}
          </Fragment>
        )}
        {canDelete && isDeleting && (
          <Fragment>
            <IconButton
              id="confirm-delete"
              onClick={() => {
                onDeleteDocument(documentId)
                setIsDeleting(false)
              }}
              aria-label="confirm"
            >
              <Check />
            </IconButton>
            <IconButton id="cancel-delete" onClick={() => setIsDeleting(false)} aria-label="cancel">
              <Close />
            </IconButton>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

DocumentElement.defaultProps = {
  onDeleteDocument: () => {},
}

DocumentElement.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  timeAgo: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
  canDelete: PropTypes.bool.isRequired,
  onDeleteDocument: PropTypes.func,
}

export default withStyles(styles)(DocumentElement)

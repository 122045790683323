import React from 'react'
import * as PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const TabPanel = props => {
  const { classes, children, tabValue, index, ...other } = props

  return (
    <Typography
      className={classes.typographyRoot}
      component="div"
      role="tabpanel"
      hidden={tabValue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  classes: PropTypes.shape({
    typographyRoot: PropTypes.string.isRequired,
  }).isRequired,
  tabValue: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

export default TabPanel

import React from 'react'
import * as PropTypes from 'prop-types'
import {
  TextField,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  withStyles,
} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import i18n from 'support/i18n'
import { isValidPassword, isValidEmail } from 'support/utils/auth'
import { MESSAGE_TYPES } from 'components/auth_message_box/View.Utils'
import AuthMessageBox from 'components/auth_message_box/View'
import styles from './View.Style'

const SignUpForm = props => {
  const {
    classes,
    onSubmit,
    messageText,
    messageType,
    isSigning,
    setAuthMessageBox,
    email,
    onEmailChange,
    isEmailFixed,
  } = props
  const [password, setPassword] = React.useState('')
  const [repeatedPassword, setRepeatedPassword] = React.useState('')
  const [dataProtectionAccepted, setDataProtectionAccepted] = React.useState(false)

  function handlePasswordChange(event) {
    const { value } = event.target
    setPassword(value)
  }

  function handleRepeatedPasswordChange(event) {
    const { value } = event.target
    setRepeatedPassword(value)
  }

  function handleSubmit(event) {
    event.preventDefault()
    const error = MESSAGE_TYPES.ERROR
    if (!email) {
      // show error insert email
      setAuthMessageBox(error, i18n.t('COMMON_LOGIN.HEADER.NO_EMAIL'))
    } else if (!isValidEmail(email)) {
      // show error invalid email
      setAuthMessageBox(error, i18n.t('COMMON_LOGIN.HEADER.INVALID_EMAIL'))
    } else if (!password || !repeatedPassword) {
      // show error insert password
      setAuthMessageBox(error, i18n.t('COMMON_LOGIN.HEADER.NO_PASSWORD'))
    } else if (password !== repeatedPassword) {
      // show error pwd are not the same
      setAuthMessageBox(error, i18n.t('PROFILE.INVALID_DIFFERENT_PASSWORD'))
    } else if (!isValidPassword(password)) {
      // show error pwd is not valid
      setAuthMessageBox(error, i18n.t('PROFILE.INVALID_PASSWORD'))
    } else if (!dataProtectionAccepted) {
      // show error read data protection
      setAuthMessageBox(error, i18n.t('COMMON_LOGIN.HEADER.DATA_PROTECTION_NOT_ACCEPTED'))
    } else {
      // call the endpoint
      onSubmit(email, password)
    }
  }

  return (
    <div className={classes.content}>
      <div className={classes.login}>
        <div className={classes.title}>
          <h1>{i18n.t('COMMON_LOGIN.HEADER.CREATE_ACCOUNT')}</h1>
        </div>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          {isEmailFixed ? (
            <div className={classes.fixedEmail}>
              <EmailIcon />
              <span>{email}</span>
            </div>
          ) : (
            <TextField
              autoFocus
              fullWidth
              label={i18n.t('COMMON_LOGIN.HEADER.EMAIL')}
              placeholder={i18n.t('COMMON_LOGIN.HEADER.EMAIL')}
              margin="normal"
              name="email"
              value={email}
              onChange={event => onEmailChange(event.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              type="email"
              variant="outlined"
            />
          )}
          <TextField
            fullWidth
            label={i18n.t('COMMON_LOGIN.HEADER.PWD')}
            placeholder={i18n.t('COMMON_LOGIN.HEADER.PWD')}
            margin="normal"
            name="password"
            onChange={handlePasswordChange}
            InputLabelProps={{ shrink: true }}
            required
            type="password"
            variant="outlined"
          />
          <TextField
            fullWidth
            label={i18n.t('COMMON_LOGIN.HEADER.REPEAT_PWD')}
            placeholder={i18n.t('COMMON_LOGIN.HEADER.REPEAT_PWD')}
            margin="normal"
            name="repeatedPassword"
            onChange={handleRepeatedPasswordChange}
            InputLabelProps={{ shrink: true }}
            required
            type="password"
            variant="outlined"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={dataProtectionAccepted}
                onChange={event => setDataProtectionAccepted(event.target.checked)}
                value="dataProtectionAcceptedCheckbox"
                color="primary"
              />
            }
            label={i18n.t('TERMS_AND_CONDITIONS_TABLE.CHECKBOX')}
          />
          <Button
            className={classes.submit}
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
            disabled={isSigning}
          >
            {i18n.t('COMMON_LOGIN.HEADER.SIGN_UP')}
            {isSigning && (
              <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />
            )}
          </Button>
          <div className={classes.messageBoxContainer}>
            <AuthMessageBox messageText={messageText} messageType={messageType} />
          </div>
        </form>
      </div>
    </div>
  )
}

SignUpForm.defaultProps = {
  isEmailFixed: false,
}

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setAuthMessageBox: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  isSigning: PropTypes.bool.isRequired,
  messageType: PropTypes.number.isRequired,
  messageText: PropTypes.node.isRequired,
  isEmailFixed: PropTypes.bool,
}

export default withStyles(styles)(SignUpForm)

const styles = theme => ({
  root: {
    display: 'flex',
    '& > div > p': {
      minWidth: theme.spacing(30),
      marginRight: theme.spacing(5),
      fontSize: '12px',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  group: {
    color: 'black',
    '& .icon-approved': {
      color: 'black',
      fontSize: '38px',
      width: '33px',
      height: '33px',
      border: '1px solid transparent',
      borderRadius: '4px',
      boxShadow: 'none',
      '&:before': {
        marginTop: '-5px',
        display: 'block',
      },
    },
  },
  radioNotChecked: {
    color: 'black',
    'font-size': '38px',
    width: '33px',
    height: '33px',
    border: '1px solid #B6B6B6;',
    'border-radius': '4px',
    'box-shadow': '0 0 3px #666666 inset',
  },
  radioLabelWithLink: {
    fontSize: '13px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > button': {
      color: '#0096e2',
    },
  },
  radioLabel: {
    fontSize: '15px',
  },
  marginLeft: {
    marginLeft: theme.spacing(7),
  },
  itemName: {
    marginLeft: theme.spacing(2),
  },
  expandButton: {
    fontSize: '26px',
    color: 'black',
    marginLeft: '-11px',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& > span': {
      color: theme.palette.error.main,
      marginBottom: theme.spacing(2),
      fontSize: '12px',
    },
  },
})

export default styles

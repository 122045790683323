import createStyles from '@material-ui/core/styles/createStyles'

const styles = theme =>
  createStyles({
    bottom: {
      gridArea: 'bottom',
      ...theme.gridMiddleColumnCenter,
      height: '25rem',
      backgroundColor: '#1c1c1c',
      color: '#fff',
    },
    center: {
      gridArea: 'center',
    },
    topCenter: {
      gridArea: 'center',
      ...theme.gridMiddleColumnCenter,
      gridTemplateColumns: 'auto 960px auto',
      gridTemplateRows: '310px auto',
      // backgroundColor: theme.palette.common.white,
      '& > div:first-child': {
        height: '310px',
        gridColumn: 2,
        gridRow: 1,
      },
      '& > div:nth-child(2)': {
        gridColumn: 2,
        gridRow: 2,
        backgroundColor: '#f1f1f1',
      },
    },
    centerDark: {
      backgroundColor: '#1c1c1c',
      color: '#fff',
    },
    exploreBottom: {
      gridArea: 'center',
    },
    fill1: {
      gridColumn: 1,
      gridRow: 2,
      backgroundColor: '#f1f1f1',
    },
    fill2: {
      gridColumn: 3,
      gridRow: 2,
      backgroundColor: '#f1f1f1',
    },
    gridCenter: {
      gridArea: 'center',
    },
    ideasFilter: {
      marginBottom: '1rem',
    },
    mainTitle: {
      margin: '2rem 0',
      fontFamily: '"HelveticaNeueCondensedBold", "Helvetica Neue", sans-serif',
      textAlign: 'center',
      fontWeight: 'normal',
    },
    middle: {
      gridArea: 'middle',
      ...theme.gridMiddleColumnCenter,
      gridTemplateColumns: 'auto 960px auto',
    },
    top: {
      gridArea: 'top',
    },
    iconButton: {
      left: 20,
      bottom: 10,
      position: 'fixed',
      zIndex: theme.zIndex.modal-1,
      opacity: 0.8,
    },
    expiringIdeasNum: {
      left: 66,
      bottom: 60,
      position: 'fixed',
      color: '#e60000',
      fontSize: '1.3em',
      zIndex: theme.zIndex.modal,
      fontFamily: '"HelveticaNeueCondensedBold", "Helvetica Neue", sans-serif',
      fontWeight: 'bold',
      textAlign: 'center',
      opacity: 0.8,
    },
    expiringIdeasTitle: {
      left: 25,
      bottom: 8,
      position: 'fixed',
      textAlign: 'center',
      color: '#e60000',
      fontSize: '0.9em',
      zIndex: theme.zIndex.modal,
      fontFamily: '"HelveticaNeueCondensedBold", "Helvetica Neue", sans-serif',
      fontWeight: 'bold',
      opacity: 0.8,
    },
    background:{
      backgroundColor: "#fbfaff", // body should have this background color in the main .css for the new site design 
  },
  content_container:{
      paddingLeft: "6%",
      paddingRight: "6%"
  },
  top_section_text: {
      display: "flex",
      alignItems: "center", 
      justifyContent: "space-between", 
  },
  title: {
      fontFamily: 'VodafoneRg-Regular',
      fontSize: "32px",
      color: "#333333",
  },
  invested_ideas_number: {
      fontFamily: 'VodafoneRg-Regular',
      fontSize: "20px",
      paddingTop: "16px",
      color: "#7836FF",
  },
  lower_title: {
      fontFamily: "VodafoneRg-Regular",
      color: "#333333",
      fontSize: "40px",
      textAlign: "center",
      marginBottom: "16px",
  },
  lower_description: {
      fontFamily: "VodafoneLt-Regular",
      color: "#333333",
      fontSize: "24px",
      textAlign: "center",
      marginTop: "16px",
  },
  idea_grid: {
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      alignItems: "center",
      justifyItems: "center",
      '@media (max-width:1250px)': { 
          gridTemplateColumns: "repeat(3, 1fr)",
      },
      '@media (max-width:1050px)': { 
          gridTemplateColumns: "repeat(2, 1fr)",
      },
      '@media (max-width:720px)': { 
          gridTemplateColumns: "1fr",
      },
  },
  })

export default styles

import { configService, fullInfoService } from 'services/domain'
import { notificationMeService } from 'services/notifications'
import { postRegisterSystemAccess } from 'services/user'
import { setI18nLang } from 'support/i18n'
import AnalyticsService from 'support/analytics'

export const initialLoadActionConditional = () => (dispatch, getState) => {
  const { currentDomain, dataLoaded, dataLoading } = getState().data.domain

  const onSuccess = response => {
    setI18nLang(response.domainConfiguration.lang, dispatch)
    dispatch(postRegisterSystemAccess())
    AnalyticsService.initialise(response.domainConfiguration.gaTrackId)
  }

  if (!dataLoaded && !dataLoading && currentDomain) {
    dispatch(fullInfoService(onSuccess))
    dispatch(configService())
    dispatch(notificationMeService())
  }
}

export const FULL_INFO_STARTED = 'FULL_INFO_STARTED'
export const FULL_INFO_SUCCESS = 'FULL_INFO_SUCCESS'
export const FULL_INFO_FAILURE = 'FULL_INFO_FAILURE'

export const SET_CURRENT_DOMAIN = 'SET_CURRENT_DOMAIN'

export const ORGANIZATIONS_STARTED = 'ORGANIZATIONS_STARTED'
export const ORGANIZATIONS_SUCCESS = 'ORGANIZATIONS_SUCCESS'
export const ORGANIZATIONS_FAILURE = 'ORGANIZATIONS_FAILURE'

export const TYPES_STARTED = 'TYPES_STARTED'
export const TYPES_SUCCESS = 'TYPES_SUCCESS'
export const TYPES_FAILURE = 'TYPES_FAILURE'

export const GET_DOMAIN_LOGIN_INFO_STARTED = 'GET_DOMAIN_LOGIN_INFO_STARTED'
export const GET_DOMAIN_LOGIN_INFO_SUCCESS = 'GET_DOMAIN_LOGIN_INFO_SUCCESS'
export const GET_DOMAIN_LOGIN_INFO_FAILURE = 'GET_DOMAIN_LOGIN_INFO_FAILURE'

export const CHANGE_UI = 'CHANGE_UI'
import { connect } from 'react-redux'
import View from './View'
import { onLogin } from '../../actions/sync/auth'

const mapStateToProps = state => {
  return {
    info: state.data.domain.info,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClick: chosenDomain => {
      onLogin(dispatch, chosenDomain)
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

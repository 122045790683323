import React from 'react'
import * as PropTypes from 'prop-types'
import { TextField, Button, CircularProgress } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import { MESSAGE_TYPES } from 'components/auth_message_box/View.Utils'
import AuthMessageBox from 'components/auth_message_box/View'
import { isValidPassword } from 'support/utils/auth'
import styles from './View.Style'

const ResetPassword = props => {
  const {
    classes,
    onSubmit,
    messageText,
    messageType,
    isChangingPassword,
    setAuthMessageBox,
  } = props
  const [password, setPassword] = React.useState('')
  const [repeatedPassword, setRepeatedPassword] = React.useState('')

  function handlePasswordChange(event) {
    const { value } = event.target
    setPassword(value)
  }

  function handleRepeatedPasswordChange(event) {
    const { value } = event.target
    setRepeatedPassword(value)
  }

  function handleSubmit(event) {
    event.preventDefault()
    const error = MESSAGE_TYPES.ERROR
    if (!password || !repeatedPassword) {
      // show error insert password
      setAuthMessageBox(error, i18n.t('COMMON_LOGIN.HEADER.NO_PASSWORD'))
    } else if (password !== repeatedPassword) {
      // show error pwd are not the same
      setAuthMessageBox(error, i18n.t('PROFILE.INVALID_DIFFERENT_PASSWORD'))
    } else if (!isValidPassword(password)) {
      // show error pwd is not valid
      setAuthMessageBox(error, i18n.t('PROFILE.INVALID_PASSWORD'))
    } else {
      // call the endpoint
      onSubmit(password)
    }
  }

  return (
    <div className={classes.content}>
      <div className={classes.login}>
        <div className={classes.title}>
          <h1>{i18n.t('COMMON_LOGIN.HEADER.RESET_PASSWORD')}</h1>
        </div>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            autoFocus
            fullWidth
            label={i18n.t('COMMON_LOGIN.HEADER.PWD')}
            placeholder={i18n.t('COMMON_LOGIN.HEADER.PWD')}
            margin="normal"
            name="password"
            onChange={handlePasswordChange}
            InputLabelProps={{ shrink: true }}
            required
            type="password"
            variant="outlined"
            helperText={i18n.t('PROFILE.INVALID_PASSWORD')}
          />
          <TextField
            fullWidth
            label={i18n.t('COMMON_LOGIN.HEADER.PWD')}
            placeholder={i18n.t('COMMON_LOGIN.HEADER.PWD')}
            margin="normal"
            name="repeatedPassword"
            onChange={handleRepeatedPasswordChange}
            InputLabelProps={{ shrink: true }}
            required
            type="password"
            variant="outlined"
          />
          <Button
            className={classes.submit}
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
            disabled={isChangingPassword}
          >
            {i18n.t('COMMON_LOGIN.HEADER.CHANGE_PASSWORD')}
            {isChangingPassword && (
              <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />
            )}
          </Button>
          <AuthMessageBox messageType={messageType} messageText={messageText} />
        </form>
      </div>
    </div>
  )
}

ResetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setAuthMessageBox: PropTypes.func.isRequired,
  isChangingPassword: PropTypes.bool.isRequired,
  messageType: PropTypes.number.isRequired,
  messageText: PropTypes.string.isRequired,
}

export default withStyles(styles)(ResetPassword)

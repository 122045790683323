import createStyles from '@material-ui/core/styles/createStyles'

const styles = theme =>
  createStyles({
    backButton: {
      color: theme.palette.common.white,
    },
    body: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& img': {
        width: '10rem',
        marginRight: '3rem',
      },
    },
  })

export default styles

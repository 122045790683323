


export const setEndpoint = ({
  baseUrl,
  organizations,
  group,
  types,
  status,
  challenge,
  goal,
  ideasLocation,
  limit = 11,
  offset = 0,
}) => {
  let url = baseUrl
  url += `?${encodeURIComponent('limit')}=${limit}`
  url += `&${encodeURIComponent('offset')}=${offset}`
  if (ideasLocation) {
    url += `&${encodeURIComponent('ideas')}=${ideasLocation}`
  } else {
    url += `&${encodeURIComponent('ideas')}=global`
  }
  if (organizations && organizations.length) {
    url += `&${encodeURIComponent('organizations')}=${organizations}`
  }
  if (group && group.length) {
    url += `&${encodeURIComponent('group')}=${group}`
  }
  if (types && types.length) {
    url += `&${encodeURIComponent('types')}=${types}`
  }
  if (status && status.length) {
    url += `&${encodeURIComponent('status')}=${status}`
  }
  if (challenge && challenge.length) {
    url += `&${encodeURIComponent('challenge')}=${challenge}`
  }
  if (goal && goal.length) {
    url += `&${encodeURIComponent('goal')}=${goal}`
  }
  return url
}

export const setDiscardIdeaBodyFromState = (reason, returnInvestment) => {
  return `${encodeURIComponent('dt')}=${reason}&${encodeURIComponent(
    'returnInvests',
  )}=${returnInvestment}`
}

export const setFeedbackIdeaBodyFromState = reason => {
  return `${encodeURIComponent('comment')}=${reason}`
}

export const setRequestBody = request =>
  `${encodeURIComponent('text')}=${encodeURIComponent(request)}`

export const setAdditionalFilters = ({ challengeId, shouldLoadPrivateIdeas }) => {
  let url = challengeId
    ? `&${encodeURIComponent('challenge')}=${encodeURIComponent(challengeId)}`
    : ''
  if (shouldLoadPrivateIdeas) {
    url += `&${encodeURIComponent('private')}=${encodeURIComponent(true)}`
  }
  return url
}

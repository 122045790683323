import React, { useState } from 'react'

import * as PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'

import Card from '@material-ui/core/Card'
import IdeaLifeCycle8 from './img/image009.png'
import IdeaLifeCycle9 from './img/image010.png'
import IdeaLifeCycle10 from './img/image011.png'
import IdeaLifeCycle from './img/image0011.png'
import ButtonLink from 'components/button_link/View'
import { Image } from 'components'
import VerticalTab from './components/vertical_tab/View'
import NOVADesign from '../docs/eRIS_7_minute_Design_Kit.pdf'
import NOVAInnovatonChallenge from '../docs/eRIS_Team_Innovation_Challenge.pdf'
import FileViewer from './components/file_viewer/View'
import styles from './View.Style'

const HowItWorks = props => {
  const { classes } = props
  const [designFile, setDesignFile] = useState(false)
  const [challengeFile, setChallengeFile] = useState(false)

  const openDesignFile = () => {
    setDesignFile(true)
  }

  const openChallengeFile = () => {
    setChallengeFile(true)
  }

  const closeDesignDialog = () => {
    setDesignFile(false)
  }
  const closeChallengeDialog = () => {
    setChallengeFile(false)
  }

  return (
    <div className={classes.container}>
      <h3>How NOVA Works</h3>
      <ol className="roman">
        <li>
          <p className={classes.question}>Idea Timeline</p>
          <p>
            NOVA is an Innovation Management Ecosystem that enables and empowers you to source
            funding, talent and management support to turn their ideas into success stories. The
            ecosystem includes setup and operation of processes that span across the idea life
            cycle; from inception of idea, selection, the pitch, rapid prototype and business case
            definition.
          </p>
          <Image
            src={IdeaLifeCycle}
            defaultSrc={IdeaLifeCycle}
            style={{ width: '87%', height: '30%' }}
            alt="hi"
          />
          {/* <Box border={1} borderColor="#FF0000"> */}
          <VerticalTab />
          {/* </Box> */}
          {/* <Image  src={IdeaLifeCycle1} alt='hi' />
          <Image  src={IdeaLifeCycle2} alt='hi' />
          <Image  src={IdeaLifeCycle3} alt='hi' />
          <Image  src={IdeaLifeCycle4} alt='hi' />
          <Image  src={IdeaLifeCycle5} alt='hi' />
          <Image  src={IdeaLifeCycle6} alt='hi' />
          <Image  src={IdeaLifeCycle7} alt='hi' /> */}
        </li>

        <li>
          <p className={classes.question}>Ideation</p>
          <p>
            Ideas are usually the spark for innovation. All successful businesses started with an
            idea at the beginning. We believe in the power of ideas, and the impact great ideas can
            have on our business. Therefore, the NOVA team is really keen to support you and your
            team come up with ideas.
          </p>
          <p>Where can I get inspiration from?</p>
          <p>Getting Inspired</p>
          <p>
            In your daily life you probably get a lot of inspiration without realizing. Normally, we
            tend to see different things happening around us in a specific way and we start
            questioning why is it not done this way? This is where it starts!!! You would probably
            need to question as many things as possible that happen around you whether it is at work
            or in your personal life. You will be able to find a gap based on your experience.
          </p>
          <p>Making A Start</p>
          <p>
            Human centred design is the most common design thinking approach used in the market, and
            we highly empathize the importance of using it. Human centred design states that the
            best place to get inspiration from is the customer or consumer you are proposing the
            product or service to. So you need to get the inspiration from the customer or consumer
            side, so you save time having to create something your customer or consumer
            doesn&rsquo;t really need. Co-creating with the customer or consumer will be the key to
            success of your idea in the future. Your customer or consumer can vary based on the type
            of idea you are proposing, but at the end of the day there will be a person who should
            be interested in using your product or service. For more information about design
            thinking please contact our team.
          </p>
          <p>How can NOVA help you?</p>
          Design Thinking Sessions
          <p>
            We help organize design thinking workshops, and will be able to support you on framing
            your problem statement which you need to solve. Feel free to get in touch with us and we
            will be more than happy to arrange a design thinking session, and run the session
            helping you and your team to generate ideas. This can be a day workshop or may be more
            or less depending on the context of the problem and what is the goal you would like to
            achieve.
          </p>
          <p>
            {/* <a href="https://workspace2.vodafone.com/Group/NOVA/SitePages/assets/docs/NOVA_7_minute_Design_Kit.pdf"> */}
            {/* <a href={NOVADesign} target="_blank" rel="noopener noreferrer"> */}
            {/* <a href={NOVADesign} download="My_File.pdf">
              Download our NOVA 7 minute Design Kit
            </a> */}

            <br></br>

            <ButtonLink onLinkClick={openDesignFile}>
              Download our NOVA 7 minute Design Kit
            </ButtonLink>
            {designFile && (
              <FileViewer
                name="eRIS_7_minute_Design_Kit.pdf"
                file={NOVADesign}
                closeDialog={closeDesignDialog}
              />
            )}
          </p>
        </li>
        <li>
          <p className={classes.question}>Challenges</p>
          <p>
            We can also launch a challenge with your problem statement on NOVA platform for all of
            our users. This will be launched as a challenge on our platform, where we invite all of
            our users to try to solve your problem. This will also be a great way of getting
            inspiration on ideas from different parts of the business. In case, you would like to
            launch it only for your team we can also do a closed challenge specifically for your
            team. Feel free to contact us and we are happy to set it up for you.
          </p>
          <p>
            {/* You can find a powerpoint that explains in detail what a challenge is&nbsp; */}
            {/* <a href="https://workspace2.vodafone.com/Group/NOVA/SitePages/Assets/docs/NOVA_Team_Innovation_Challenge.pptx"> */}
            {/* <a href={NOVAInnovatonChallenge} target="_blank" rel="noopener noreferrer">
              here
            </a> */}
            <ButtonLink onLinkClick={openChallengeFile}>
              You can find a powerpoint that explains in detail what a challenge is&nbsp;
            </ButtonLink>
            {challengeFile && (
              <FileViewer
                name="eRIS_Team_Innovation_Challenge.pdf"
                file={NOVAInnovatonChallenge}
                closeDialog={closeChallengeDialog}
              />
            )}
          </p>
        </li>
        <li>
          <p className={classes.question}>Idea Submission</p>
          <p>
            When you have thought of an amazing game changing idea, you now need to submit the idea
            onto the platform. To do this, you click on &lsquo;submit your idea&rsquo;. You&rsquo;ll
            see a page that requesting for information about your idea.
          </p>
          <p>
            When you are submitting your idea, it is important that you have all the relevant pieces
            of information. Your idea will be up for investment from other NOVA users and they want
            to know what&rsquo;s great about idea and benefits your idea can give.
          </p>
          {/* <Card className={classes.root}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography variant="body2" gutterBottom>
                  <p>
                    When you have thought of an amazing game changing idea, you now need to submit
                    the idea onto the platform. To do this, you click on &lsquo;submit your
                    idea&rsquo;. You&rsquo;ll see a page that requesting for information about your
                    idea.
                  </p>
                  <p>
                    When you are submitting your idea, it is important that you have all the
                    relevant pieces of information. Your idea will be up for investment from other
                    NOVA users and they want to know what&rsquo;s great about idea and benefits your
                    idea can give.
                  </p>
                </Typography>
              </CardContent>
            </div>
            <Image className={classes.cover} src={IdeaLifeCycle8} alt="hi" />
          </Card> */}
          <Card>
            <Image
              className={classes.ideaSub}
              src={IdeaLifeCycle8}
              defaultSrc={IdeaLifeCycle8}
              alt="Idea Submission"
            />
          </Card>
          {/* <Image src={IdeaLifeCycle8} alt="hi" /> */}
          <p>
            Once you submit your idea, you will have 30 days to reach fully funded status. Other
            stages below:
          </p>
          {/* <div className={classes.ideaRounds}> */}
          <Card>
            <Image
              className={classes.ideaRounds}
              src={IdeaLifeCycle9}
              defaultSrc={IdeaLifeCycle9}
              alt="idea rounds"
            />
          </Card>
          {/* </div>  */}

          {/* <Card className={classes.root}>
            {/* <CardMedia
              className={classes.cover}
              image={IdeaLifeCycle9}
              title="Drones as a service"
            /> 
            <Image className={classes.cover} src={IdeaLifeCycle9} alt="hi" />
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography variant="body2" gutterBottom>
                  Once you submit your idea, you will have 30 days to reach fully funded status.
                </Typography>
              </CardContent>
            </div>
          </Card> */}
        </li>
        <li>
          <p className={classes.question}>Idea Investing</p>
          <p>
            The NOVA platform is built on crowdsourcing ideas from colleagues across Vodafone,
            providing a platform for innovators to propose and collaborate on varying ideas. The
            NOVA platform allows participants to invest in ideas by using a virtual currency; the
            level of investment and number of investors gives an indication of the quality of the
            idea. In this way the investment process serves as a first filter, essentially
            crowdsourcing the reviews through gamification.
          </p>
          <p>
            Users should only invest in ideas that they feel are beneficial to Vodafone, when an
            idea is successful all investors are rewarded with a percentage of their initial
            investment. This reward depends on some parameters such as when the investment was made
            or which stage the idea progresses to.
          </p>
          <br></br>
          <p className={classes.rounds}>Venture Rounds:</p> The time an investment is made matters,
          the sooner you invest in the idea, the better is the reward you get for that investment.
          <br></br>
          <p className={classes.rounds}>Seeding Round: </p>Seeding round are investments made by the
          idea creators, this is not compulsory though.
          <br></br>
          <p className={classes.rounds}>First Round:</p> First round are all investments made before
          the idea reaches 33% of its total goal.
          <br></br>
          <p className={classes.rounds}>Second Round:</p>
          Second round are all investments made between 34% and 66% of the idea&rsquo;s total goal
          <br></br>
          <p className={classes.rounds}>Third Round:</p> Third round are all investments made over
          67% of the idea&rsquo;s total goal.
          <p>
            Investments indicate the level of support and acceptance that an idea has generated
            among the other NOVA users. Investments serve as a first filter for the innovation team
            to identify potentially good ideas. If an idea has received a lot of funding, it may be
            a good sign of its potential.
          </p>
        </li>
        <li>
          <p className={classes.question}>Pitching your Idea</p>

          {/* <Card className={classes.root}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography variant="body2" gutterBottom>
                  <p>
                    What exactly is the Pitch stage? The pitch allows innervators to secure real
                    funding to develop their ideas into prototypes. Ideas are presented to Senior
                    Leaders across Vodafone, or as they are called in NOVA:&nbsp;Olympians, who can
                    grant up to &euro;15,000 for prototyping. Ideas that have progressed to this
                    stage will have succesfully reached their funding targets and have been approved
                    by the NOVA review board.
                  </p>
                </Typography>
              </CardContent>
            </div>
            <Image className={classes.cover} src={IdeaLifeCycle10} alt="hi" />
          </Card> */}
          <p>
            What exactly is the Pitch stage? The pitch allows innervators to secure real funding to
            develop their ideas into prototypes. Ideas are presented to Senior Leaders across
            Vodafone, or as they are called in NOVA:&nbsp;Olympians, who can grant up to
            &euro;15,000 for prototyping. Ideas that have progressed to this stage will have
            succesfully reached their funding targets and have been approved by the NOVA review
            board.
          </p>
          <Card>
            <Image
              className={classes.ideaRounds}
              src={IdeaLifeCycle10}
              defaultSrc={IdeaLifeCycle10}
              alt="idea Pitching"
            />
          </Card>

          {/* <p>Documents to prepare (add in the current pitch document)</p> */}
        </li>
      </ol>
    </div>
  )
}

HowItWorks.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(HowItWorks)

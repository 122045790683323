import React from 'react'
import i18n from 'support/i18n'
import * as PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import style from './View.Style'

const EstimatedBenefitsForm = props => {
  const {
    classes,
    title,
    helpText,
    benefits,
    benefitsError,
    otherBenefits,
    onEconomicBenefitsChange,
    onOtherBenefitsChange,
    classIcon,
  } = props
  return (
    <div className={classes.root}>
      <div className={classes.titles}>
        <h3>
          <span className={`icon-${classIcon}`} />
          {title}
        </h3>
        <p>{helpText}</p>
      </div>
      <div className={classes.formContainer}>
        <TextField
          fullWidth
          label={i18n.t('LOGISTIC.OTHER_EXPECTED')}
          margin="normal"
          type="number"
          value={benefits}
          onChange={event => onEconomicBenefitsChange(event.target.value)}
          error={benefitsError}
          helperText={benefitsError ? i18n.t('LOGISTIC.FIELD_FORMAT_ERROR') : ''}
        />
        <TextField
          fullWidth
          multiline
          rows={1}
          label={i18n.t('LOGISTIC.OTHER')}
          margin="normal"
          value={otherBenefits}
          onChange={event => onOtherBenefitsChange(event.target.value)}
        />
      </div>
    </div>
  )
}

EstimatedBenefitsForm.defaultProps = {
  helpText: '',
  classIcon: '',
  benefits: '',
  benefitsError: false,
  otherBenefits: '',
}

EstimatedBenefitsForm.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    titles: PropTypes.string,
    formContainer: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  benefits: PropTypes.number,
  benefitsError: PropTypes.bool,
  otherBenefits: PropTypes.string,
  onEconomicBenefitsChange: PropTypes.func.isRequired,
  onOtherBenefitsChange: PropTypes.func.isRequired,
  classIcon: PropTypes.string,
}

export default withStyles(style)(EstimatedBenefitsForm)

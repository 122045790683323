import React from 'react'
import { Checkbox, withStyles } from '@material-ui/core'
import styles from './View.Styles'

const NxtCheckbox = ({ classes, isChecked }) => {
  return (
    <Checkbox
      className={classes.root}
      color="default"
      disableRipple
      disableFocusRipple
      checked={isChecked}
      disabled
      icon={<span className={classes.radioNotChecked} />}
      checkedIcon={<span className="icon-approved" />}
    />
  )
}

export default withStyles(styles)(NxtCheckbox)

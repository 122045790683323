
const styles = (props) => ({
    
    container: {
        height: '38px',
        color: '#FFFFFF',
        fontFamily: 'VodafoneRg-Regular',
        fontSize: '18px',
        display: 'inline-flex',
        minWidth: '120px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 16px',
        marginLeft: '0px',
        marginRight:'8px',
        marginBottom:'8px',
        cursor: 'pointer',
        borderRadius: '22px',
        border:'0px',
        backgroundColor:'#6d45ff',      
        '&:disabled':{
            opacity: 0.5,
            'pointer-events': 'none',
            backgroundColor:'#000000 !important',
        },
    },
})
export default styles
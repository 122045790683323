const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 0),
    '& > span:first-child': {
      fontSize: '2rem',
      marginRight: theme.spacing(1),
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bar: {
    width: '3px',
    borderRight: '3px solid #ddd',
    height: '30px',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

export default styles

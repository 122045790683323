import { ServiceCall } from 'support/network/ServiceCallAction'
import {
  GET_ACTIVE_TYPES_STARTED,
  GET_ACTIVE_TYPES_SUCCESS,
  GET_ACTIVE_TYPES_FAILURE,
} from 'actions/sync/types/Constants'
import { transformTypeServiceResponseDataWithState } from './ResponseUtils'
import { prepareHeadersFromState } from '../HeadersUtils'

export const GET_ACTIVE_TYPES_URL = '/s/domain/type/active'

export function getActiveTypes() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_ACTIVE_TYPES_STARTED
  apiCall.successReqType = GET_ACTIVE_TYPES_SUCCESS
  apiCall.failureReqType = GET_ACTIVE_TYPES_FAILURE
  apiCall.setEndpointFromState = () => GET_ACTIVE_TYPES_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.transformResponseDataWithState = transformTypeServiceResponseDataWithState
  apiCall.auth = true
  return apiCall.getAction()
}

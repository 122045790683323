import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import imgDefault from 'img/nxt-logo-fondo-ideas.png'
import { differenceInCalendarDays } from 'date-fns'
import i18n from 'support/i18n'
import defaultPhoto from 'img/default-photo.png'
import { ButtonLink, CircleGraph, Image } from 'components'
import NxtUserCard from 'components/nxt_user_card/View'
import NxtCircleContent from 'components/nxt_circle_content/View'
import styles from './View.Style'

const SliderCard = props => {
  const {
    classes,
    desc,
    ends,
    id,
    ideas,
    label,
    mainPhoto,
    name,
    navigateToChallengeDetail,
    navigateToIdea,
    numIdeas,
    sponsorPhoto,
    sponsorName,
    starts,
    proposerPhoto,
    proposerName,
  } = props
  const value = differenceInCalendarDays(Date.now(), starts)
  const maxValue = differenceInCalendarDays(ends, starts)
  return (
    <div className={classes.sliderContent}>
      <div className={classes.sliderCard}>
        <Image defaultSrc={imgDefault} src={mainPhoto} alt={label} />
        <div className={classes.overlay}>
          <NxtUserCard
            label={i18n.t('CHALLENGES.DETAIL.PROPOSER')}
            imageSrc={proposerPhoto}
            usernameElement={<span>{proposerName}</span>}
            imageSize="2.5rem"
          />
          {sponsorName && sponsorPhoto && (
            <NxtUserCard
              label={i18n.t('CHALLENGES.DETAIL.SPONSOR')}
              imageSrc={sponsorPhoto}
              usernameElement={<span>{sponsorName}</span>}
              isInverted
            />
          )}
        </div>
      </div>
      <div className={classes.descriptionCard}>
        <div className={classes.graphBlock}>
          <div className={classes.graph}>
            <CircleGraph
              maxValue={maxValue}
              size="big"
              value={value}
              themeColor="dark"
              pathColor="white"
            >
              <NxtCircleContent days={maxValue - value} />
            </CircleGraph>
          </div>
          <div className={classes.numIdeas}>
            <p>
              <span>{numIdeas} </span>
              <span>{i18n.t('CHALLENGES.DETAIL.IDEAS_PROPOSED')}</span>
            </p>

            {ideas.length > 0 && (
              <ul className={classes.ideasAvatar}>
                {ideas.map(idea=>{
                  const [img]= idea.images
                  return(
                  <Fragment key={idea.id}>
                    <li>
                      <ButtonLink onLinkClick={() => navigateToIdea(idea.id)}>
                        <Image defaultSrc={defaultPhoto} src= {typeof img !== 'undefined' ? img.url: ' '} alt="" />
                      </ButtonLink>
                    </li>
                  </Fragment>)
                })}
              </ul>
            )}
          </div>
        </div>

        <div className={classes.descriptionBlock}>
          <div className={classes.descriptionEllipsisParent}>
            <h2 className={classes.descriptionEllipsis}>{name}</h2>
          </div>
          <div className={classes.descriptionEllipsisParent}>
            <div className={clsx(classes.descriptionEllipsis, classes.descriptionBody)}>{desc}</div>
            {/* <div dangerouslySetInnerHTML={{ __html: desc }}  className={clsx(classes.descriptionEllipsis, classes.descriptionBody)}/> */}
          </div>
          <Button
            className={classes.descriptionButton}
            color="primary"
            variant="contained"
            onClick={() => navigateToChallengeDetail(id)}
          >
            {i18n.t('CHALLENGES.GO_TO_CHALLENGE')}
          </Button>
        </div>
      </div>
    </div>
  )
}

SliderCard.defaultProps = {
  label: '',
  sponsorName: '',
  sponsorPhoto: '',
}

SliderCard.propTypes = {
  classes: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    descriptionCard: PropTypes.string.isRequired,
    dialGraph: PropTypes.string.isRequired,
    dialGraphText: PropTypes.string.isRequired,
    graphBlock: PropTypes.string.isRequired,
    sliderCard: PropTypes.string.isRequired,
    sliderContent: PropTypes.string.isRequired,
  }).isRequired,
  desc: PropTypes.string.isRequired,
  ends: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  ideas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  navigateToChallengeDetail: PropTypes.func.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  numIdeas: PropTypes.string.isRequired,
  mainPhoto: PropTypes.string.isRequired,
  starts: PropTypes.number.isRequired,
  sponsorName: PropTypes.string,
  sponsorPhoto: PropTypes.string,
  proposerPhoto: PropTypes.string.isRequired,
  proposerName: PropTypes.string.isRequired,
}

export default withStyles(styles)(SliderCard)

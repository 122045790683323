const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 0),
    '& > span:first-child': {
      fontSize: '2rem',
      marginRight: theme.spacing(1),
    },
  },
  titleText: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  noIdeasPaper: {
    padding: theme.spacing(2, 3),
    textAlign: 'center',
    fontSize: '0.875rem',
  },
  cardGroup: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingBottom: '1rem',
    '& > div': {
      marginRight: '2%',
      height: '231px',
      width: '231px',
    },
    '& > div:last-child': {
      marginRight: 0,
    },
  },
})

export default styles

import React from 'react'
import * as PropTypes from 'prop-types'
import { Image } from 'components'
import { IconButton, Paper, withStyles } from '@material-ui/core'
import defaultImage from 'img/nxt-logo-fondo-ideas.png'
import style from './View.Style'

const ImageViewDialog = props => {
  const { classes, src, onClose, defaultSrc, alt } = props
  return (
    <Paper className={classes.root}>
      <div className={classes.close}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <span className="icon-close" />
        </IconButton>
      </div>
      <Image defaultSrc={defaultSrc} src={src} alt={alt} />
    </Paper>
  )
}

ImageViewDialog.defaultProps = {
  defaultSrc: defaultImage,
  alt: '',
}

ImageViewDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  defaultSrc: PropTypes.string,
  alt: PropTypes.string,
}

export default withStyles(style)(ImageViewDialog)

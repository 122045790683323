import React from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'

const DevicesGraph = props => {
  const {
    classes,
    desktopAccesses,
    mobileAccesses,
    iosAccesses,
    androidAccesses,
    createdIdeas,
    implementedIdeas,
    ideasVisits,
    activeUsers,
    investmentsMade,
    publishedComments,
  } = props

  const totalAccesses = desktopAccesses + mobileAccesses
  const desktopPercentage = (desktopAccesses * 100) / totalAccesses
  const mobilePercentage = (mobileAccesses * 100) / totalAccesses
  const iosPercentage = (iosAccesses * 100) / mobileAccesses
  const androidPercentage = (androidAccesses * 100) / mobileAccesses

  return (
    <div className={classes.root}>
      <div className={classes.firstRow}>
        <div>
          <div className={classes.device}>
            <div className={clsx(classes.iconForm, classes.desktopShape)}>
              <div className={classes.shape} />
              <div className={classes.fill} style={{ top: `${100 - desktopPercentage}%` }} />
              <div className={classes.background} />
            </div>
            <span className={classes.bigNumber}>{desktopAccesses}</span>
            <span className={classes.description}>{i18n.t('DASHBOARD.DESKTOP_ACCESS')}</span>
          </div>
          <div className={classes.device}>
            <div className={clsx(classes.iconForm, classes.mobileShape)}>
              <div className={classes.shape} />
              <div className={classes.fill} style={{ top: `${100 - mobilePercentage}%` }} />
              <div className={classes.background} />
            </div>
            <span className={classes.bigNumber}>{mobileAccesses}</span>
            <span className={classes.description}>{i18n.t('DASHBOARD.MOBILE_ACCESS')}</span>
          </div>
        </div>
        <div>
          <div className={classes.device}>
            <div className={clsx(classes.iconForm, classes.iosShape)}>
              <div className={classes.shape} />
              <div className={classes.fill} style={{ top: `${100 - iosPercentage}%` }} />
              <div className={classes.background} />
            </div>
            <span className={classes.smallNumber}>{iosAccesses}</span>
          </div>
          <div className={classes.device}>
            <div className={clsx(classes.iconForm, classes.androidShape)}>
              <div className={classes.shape} />
              <div className={classes.fill} style={{ top: `${100 - androidPercentage}%` }} />
              <div className={classes.background} />
            </div>
            <span className={classes.smallNumber}>{androidAccesses}</span>
          </div>
        </div>
      </div>
      <div className={classes.secondRow}>
        <div className={classes.firstColumn}>
          <div className={classes.statisticRow}>
            <span className={classes.firstItem}>{createdIdeas}</span>
            <span>{i18n.t('DASHBOARD.IDEAS_CREATED')}</span>
          </div>
          <div className={classes.statisticRow}>
            <span className={classes.secondItem}>{implementedIdeas}</span>
            <span>{i18n.t('DASHBOARD.IDEAS_IMPLE')}</span>
          </div>
          <div className={classes.statisticRow}>
            <span className={classes.thirdItem}>{ideasVisits}</span>
            <span>{i18n.t('DASHBOARD.IDEAS_VISITS')}</span>
          </div>
        </div>
        <div className={classes.secondColumn}>
          <div className={classes.statisticRow}>
            <span className={classes.fourthItem}>{activeUsers}</span>
            <span>{i18n.t('DASHBOARD.USERS')}</span>
          </div>
          <div className={classes.statisticRow}>
            <span className={classes.fifthItem}>{investmentsMade}</span>
            <span>{i18n.t('DASHBOARD.INVESTMENTS_MADE')}</span>
          </div>
          <div className={classes.statisticRow}>
            <span className={classes.sixthItem}>{publishedComments}</span>
            <span>{i18n.t('DASHBOARD.COMMENTS_PUB')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

DevicesGraph.propTypes = {
  desktopAccesses: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  mobileAccesses: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  iosAccesses: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  androidAccesses: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  createdIdeas: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  implementedIdeas: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  ideasVisits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  activeUsers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  investmentsMade: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  publishedComments: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default withStyles(styles)(DevicesGraph)

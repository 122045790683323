const styles = theme => ({
  root: {
    gridColumnStart: 2,
    '& > h1': {
      '& > span[class*="icon-"]': {
        marginRight: theme.spacing(1),
      },
    },
  },
  paper: {
    padding: theme.spacing(3, 3),
  },
  section: {
    marginTop: theme.spacing(2),
    '& > h2': {
      '& > span[class*="icon-"]': {
        marginRight: theme.spacing(1),
      },
    },
  },
  noOrgsSpan: {
    textAlign: 'center',
  },
})

export default styles

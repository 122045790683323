import React from 'react'
import * as PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const NxtCircularLoader = props => {
  const { classes, children, isLoading } = props
  return (
    <div className={classes.container}>
      {isLoading && (
        <div className={classes.loader}>
          <CircularProgress size={24} color="primary" />
        </div>
      )}
      {children}
    </div>
  )
}

NxtCircularLoader.defaultProps = {
  isLoading: false,
}

NxtCircularLoader.propTypes = {
  isLoading: PropTypes.bool,
}

export default withStyles(styles)(NxtCircularLoader)

import React, { Component, Fragment } from 'react'
import { withStyles, createTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import * as PropTypes from 'prop-types'
import defaultLogo from 'img/nxt-logo-rocket-horizontal-white.png'
import i18n from 'support/i18n'
import ButtonLink from 'components/button_link/View'
import Image from '../image/View'
import styles from './View.Style'
import { FullPageDialog } from 'components'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      showpc: false,
      showln: false,
    }
    this.hideTnC = this.hideTnC.bind(this)
    this.showPrivacyCookies = this.showPrivacyCookies.bind(this)
    this.showLegalNotice = this.showLegalNotice.bind(this)
  }

  showPrivacyCookies = () => {
    this.setState({ title: 'Privacy and Cookies', showpc: true, showln: false })
  }

  showLegalNotice = () => {
    this.setState({ title: 'Legal Notice', showpc: false, showln: true })
  }

  hideTnC = () => {
    this.setState({ showpc: false, showln: false })
  }

  // TO-DO: Navigate to the specific domain
  navToAdmin = () => {
    window.location.replace(process.env.REACT_APP_ADMIN_BASE_URL)
    return null
  }

  render() {
    const {
      appVersion,
      classes,
      copyrightYear,
      newIdeas,
      logoUrl,
      showAdmin,
      //submitYourIdea,
      totalInvestments,
      customFooterText,
      onCopyrightClick,
      onLogoClick,
      //isPrivate,
    } = this.props
    const { showpc, showln, title } = this.state

    return (
      <Fragment>
        <div className={classes.footer}>
          <div className={classes.footerContent}>
            <ButtonLink onLinkClick={onLogoClick}>
              <Image
                alt="app logo"
                className={classes.appLogo}
                src={logoUrl}
                defaultSrc={defaultLogo}
              />
            </ButtonLink>
            <div className={classes.summary}>
              <div className={classes.summaryIcon}>
                <b className="icon-idea" />
                <span>{newIdeas}</span>
              </div>
              <span className={classes.summaryText}>{i18n.t('IDEAS_PROPOSED')}</span>
            </div>

            <div className="summary">
              <div className={classes.summaryIcon}>
                <b className="icon-ideas-implemented" />
                <span>{totalInvestments}</span>
              </div>
              <span className={classes.summaryText}>{i18n.t('IDEAS_INVESTED')}</span>
            </div>
            {/* <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => submitYourIdea(isPrivate)}
            >
              {i18n.t('MY_IDEAS.SUBMIT')}
            </Button> */}
            <div>
            {showAdmin && 
              <>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.navToAdmin}
              >
                {i18n.t('AVATAR_MENU.ADMIN')}
              </Button>
              <br/>
              </>
            }
            <a href='https://forms.office.com/r/EEyJuQEmnF'>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#E60000',
                  color: '#FFFFFF'
                }}
                className={classes.button}
              >
                JOIN NOVA
              </Button>
            </a>
            </div>
          </div>
        </div>
        <div className={classes.links}>
          <div className={classes.linksContent}>
            <ButtonLink
              classes={{ linkButton: classes.copyrightButton }}
              onLinkClick={() => onCopyrightClick(copyrightYear)}
            >
              {i18n.t('FOOTER.COPYRIGHT').replace('##YEAR##', copyrightYear)}
            </ButtonLink>

            <ButtonLink
              classes={{ linkButton: classes.copyrightButton }}
              onLinkClick={this.showPrivacyCookies}
            >
              {i18n.t('FOOTER.COOKIES')}
            </ButtonLink>

            <ButtonLink
              classes={{ linkButton: classes.copyrightButton }}
              onLinkClick={this.showLegalNotice}
            >
              {i18n.t('FOOTER.LEGAL')}
            </ButtonLink>

            {/* <button
              className={classes.linkButton && classes.flat}
              onClick={this.showPrivacyCookies}
            >
              {i18n.t('FOOTER.COOKIES')}
            </button>

            <button className={classes.linkButton && classes.flat} onClick={this.showLegalNotice}>
              {i18n.t('FOOTER.LEGAL')}
            </button> */}
            {/* <a
              // href="https://www.nextinit.com/cookie-policy"
              onClick={this.showTnC}
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t('FOOTER.COOKIES')}
            </a> */}
            {/* <a
              href="https://www.nextinit.com/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t('FOOTER.LEGAL')}
            </a> */}
            <span>{appVersion}</span>
          </div>
          {(showpc || showln) && (
            <FullPageDialog
              openDialog={true}
              closeDialog={this.hideTnC}
              title={title}
              privacyCookies={showpc}
              legalNotice={showln}
            />
          )}
          {customFooterText && (
            // <div className={classes.customizedMessageFooter}>{customFooterText}</div>
            <div className={classes.customizedMessageFooter}>
              <span dangerouslySetInnerHTML={{ __html: customFooterText }} />
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}


Footer.defaultProps = {
  customFooterText: '',
  logoUrl: '',
}

Footer.propTypes = {
  classes: PropTypes.shape({
    appLogo: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    footer: PropTypes.string.isRequired,
    footerContent: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    summaryIcon: PropTypes.string.isRequired,
    summaryText: PropTypes.string.isRequired,
    links: PropTypes.string.isRequired,
    linksContent: PropTypes.string.isRequired,
    customizedMessageFooter: PropTypes.string.isRequired,
  }).isRequired,
  appVersion: PropTypes.string.isRequired,
  newIdeas: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  showAdmin: PropTypes.bool.isRequired,
  onLogoClick: PropTypes.func.isRequired,
  onCopyrightClick: PropTypes.func.isRequired,
  //submitYourIdea: PropTypes.func.isRequired,
  totalInvestments: PropTypes.string.isRequired,
  customFooterText: PropTypes.string,
  copyrightYear: PropTypes.string.isRequired,
}

export default withStyles(styles)(Footer)

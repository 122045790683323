import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    base: {
      ...theme.loginBase,
      gridTemplateColumns: props => {
        return props.useCustomLogin ? '1fr 1fr' : '460px 1fr'
      },
      gridTemplateRows: '1fr',
      backgroundSize: 'cover',
      background: props => {
        return props.useCustomLogin && props.useCustomBgImg
          ? `url(${props.customBgImg}) ${props.customColor} repeat-x bottom`
          : 'unset'
      },
      color: props =>
        props.useCustomLogin && props.useCustomTextColor ? props.customTextColor : 'black',
    },
    customize: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > h1': {
        marginBottom: 0,
      },
    },
  })

export default styles

import { parseIdeasData } from '../ideas/ResponseUtils'

export const parseImages = array => {
  return array.map(image => {
    return {
      src: image.url,
    }
  })
}

export const parseChallengeObject = (challenge, parseImage) => {
  // console.log("challenge--respo",challenge,)
  return {
    // approvedMult: challenge.approveMult || 0,
    description: challenge.desc,
    desc: challenge.descWithoutTags,
    //desc: challenge.desc,
    ends: (challenge.ends && challenge.ends.timestamp) || null,
    goalMult: challenge.goalMult || 0,
    id: challenge.id,
    ideas: parseIdeasData(challenge.ideas),
    images: parseImage ? parseImages(challenge.images) : challenge.images || [],
    implementedMult: challenge.implementMult || 0,
    importance: challenge.importance,
    mainPhoto: challenge.mainPhoto,
    video: challenge.videoURL || (challenge.video && challenge.video.playerUrl) || '',
    name: challenge.title,
    numIdeas: `${challenge.numIdeas || '0'}`,
    proposedDate: challenge.proposedDate || '',
    proposerName: challenge.proposerName || '',
    proposerEmail: challenge.proposerEmail || '',
    proposerPhoto: challenge.proposerPhoto || '',
    proposeItems: challenge.proposeItems,
    rewardText: challenge.rewardDesc || '',
    rewardOther: challenge.rewardOther || '',
    rewardImage: challenge.rewardImage
      ? {
          src: challenge.rewardImage.url || '',
          key: challenge.rewardImage.key || '',
          name: challenge.rewardImage.name || '',
        }
      : {},
    rewardFor: challenge.rewardFor || '',
    sponsorName: challenge.sponsorName || '',
    sponsorPhoto: challenge.sponsorPhoto || '',
    starts: (challenge.starts && challenge.starts.timestamp) || null,
    supportedMult: challenge.supportMult || 0,
    visits: challenge.visits,
    status: challenge.status || '',
    organisation: challenge.organization || '',
    isGlobal: challenge.isGlobal,
    origin: challenge.origin,
  }
}

export const parseChallenges = challenges => {
  if (!challenges) return []
  return challenges.map(object => parseChallengeObject(object, false))
}

export const transformGetChallengeService = jsonResponse => ({
  challenges: parseChallenges(jsonResponse.data),
  items: jsonResponse.items,
  total: jsonResponse.total
})

export const transformGetChallengeByIdService = jsonResponse => {
 
  return {
    challenge: parseChallengeObject(jsonResponse, true),
  }
}


export const transformFilterChallengeServiceResponseDataWithState = jsonResponse => {
  return {
    data: parseChallenges(jsonResponse.data),
    items: jsonResponse.items,
    total: jsonResponse.total,
  }
}
export const PASSWORD_LOGIN_STARTED = 'PASSWORD_LOGIN_STARTED'
export const PASSWORD_LOGIN_SUCCESS = 'PASSWORD_LOGIN_SUCCESS'
export const PASSWORD_LOGIN_FAILURE = 'PASSWORD_LOGIN_FAILURE'

export const POST_RECOVER_PASSWORD_STARTED = 'POST_RECOVER_PASSWORD_STARTED'
export const POST_RECOVER_PASSWORD_SUCCESS = 'POST_RECOVER_PASSWORD_SUCCESS'
export const POST_RECOVER_PASSWORD_FAILURE = 'POST_RECOVER_PASSWORD_FAILURE'

export const POST_CHANGE_PASSWORD_STARTED = 'POST_CHANGE_PASSWORD_STARTED'
export const POST_CHANGE_PASSWORD_SUCCESS = 'POST_CHANGE_PASSWORD_SUCCESS'
export const POST_CHANGE_PASSWORD_FAILURE = 'POST_CHANGE_PASSWORD_FAILURE'

export const POST_SIGN_UP_STARTED = 'POST_SIGN_UP_STARTED'
export const POST_SIGN_UP_SUCCESS = 'POST_SIGN_UP_SUCCESS'
export const POST_SIGN_UP_FAILURE = 'POST_SIGN_UP_FAILURE'

export const POST_USER_DETAILS_STARTED = 'POST_USER_DETAILS_STARTED'
export const POST_USER_DETAILS_SUCCESS = 'POST_USER_DETAILS_SUCCESS'
export const POST_USER_DETAILS_FAILURE = 'POST_USER_DETAILS_FAILURE'

export const POST_SEND_VERIFICATION_EMAIL_STARTED = 'POST_SEND_VERIFICATION_EMAIL_STARTED'
export const POST_SEND_VERIFICATION_EMAIL_SUCCESS = 'POST_SEND_VERIFICATION_EMAIL_SUCCESS'
export const POST_SEND_VERIFICATION_EMAIL_FAILURE = 'POST_SEND_VERIFICATION_EMAIL_FAILURE'

export const POST_GOOGLE_LOGIN_STARTED = 'POST_GOOGLE_LOGIN_STARTED'
export const POST_GOOGLE_LOGIN_SUCCESS = 'POST_GOOGLE_LOGIN_SUCCESS'
export const POST_GOOGLE_LOGIN_FAILURE = 'POST_GOOGLE_LOGIN_FAILURE'

export const POST_AZURE_LOGIN_STARTED = 'POST_AZURE_LOGIN_STARTED'
export const POST_AZURE_LOGIN_SUCCESS = 'POST_AZURE_LOGIN_SUCCESS'
export const POST_AZURE_LOGIN_FAILURE = 'POST_AZURE_LOGIN_FAILURE'

export const POST_ADFS_LOGIN_STARTED = 'POST_ADFS_LOGIN_STARTED'
export const POST_ADFS_LOGIN_SUCCESS = 'POST_ADFS_LOGIN_SUCCESS'
export const POST_ADFS_LOGIN_FAILURE = 'POST_ADFS_LOGIN_FAILURE'

export const SET_LOGGEDIN = 'SET_LOGGEDIN'
export const SET_LOGGEDOUT = 'SET_LOGGEDOUT'

export const SET_AUTH_MESSAGE_BOX_CONTENT = 'SET_AUTH_MESSAGE_BOX_CONTENT'
export const RESET_AUTH_MESSAGE_BOX_CONTENT = 'RESET_AUTH_MESSAGE_BOX_CONTENT'
export const USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS'
export const IDENTITY_NOT_VERIFIED_YET = 'IDENTITY_NOT_VERIFIED_YET'
export const INCREASE_SIGN_UP_STEP = 'INCREASE_SIGN_UP_STEP'
export const SHOW_LOADING_SPINNER = 'SHOW_LOADING_SPINNER'


export const SSO_PASSWORD_LOGIN_STARTED = 'SSO_PASSWORD_LOGIN_STARTED'
export const SSO_PASSWORD_LOGIN_SUCCESS = 'SSO_PASSWORD_LOGIN_SUCCESS'
export const SSO_PASSWORD_LOGIN_FAILURE = 'SSO_PASSWORD_LOGIN_FAILURE'

import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const NxtFormHelper = props => {
  const { classes, helpText, error, children, reference } = props

  return (
    <Fragment>
      <div className={classes.root} ref={reference}>
        {helpText && (
          <div>
            <p>{helpText}</p>
            {error && <p className={classes.errorMessage}>{error}</p>}
          </div>
        )}
        <div className={classes.inputContainer}>{children}</div>
      </div>
    </Fragment>
  )
}

NxtFormHelper.defaultProps = {
  helpText: '',
  error: '',
  reference: null,
}

NxtFormHelper.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    group: PropTypes.string,
    radioNotChecked: PropTypes.string,
    radioLabelWithLink: PropTypes.string,
    radioLabel: PropTypes.string,
    marginLeft: PropTypes.string,
    itemName: PropTypes.string,
    expandButton: PropTypes.string,
    inputContainer: PropTypes.string,
  }).isRequired,
  helpText: PropTypes.string,
  error: PropTypes.string,
  reference: PropTypes.shape({}),
}

export default withStyles(styles)(NxtFormHelper)

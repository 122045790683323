export const ideaStatusMapper = {
  PENDING_SUPPORT: 0,
  SUPPORTED: 1,
  ROUND_CLOSED: 2,
  APPROVED_BY_IT: 3,
  PILOT_ACTIVE: 4,
  IMPLEMENTED: 5,
  DISCARDED: 6,
  PENDING_VALIDATION: 7,
  DRAFT: 8,
}

export const ideaStatusIconMapper = [
  'earn-money',
  'success',
  'share',
  'approved',
  'prototype',
  'ranking',
  'close',
]

export const ideaStatusTypeMapper = {
  previous: 'previous',
  current: 'current',
  next: 'next',
}

export const ideaStatusDiscardedMapper = {
  DISCARDED_BY_IT: 'DISCARDED_BY_IT',
  INVESTMENT_COUNTDOWN_EXPIRED: 'INVESTMENT_COUNTDOWN_EXPIRED',
  INNOVATION_COUNTDOWN_EXPIRED: 'INNOVATION_COUNTDOWN_EXPIRED',
}

export const queryParamsActions = {
  SHOW_ACCEPT_SNACKBAR: 'invite_team_accept',
  SHOW_REJECT_SNACKBAR: 'invite_team_reject',
}

export const makeItRealValues = {
  TIME: 'time',
  BUDGET: 'budget',
  OTHER: 'other',
}

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import { Button, Paper } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import NxtCardListItems from 'components/nxt_card/nxt_card_list_items/View'
import NxtCardCorner from 'components/nxt_card/nxt_card_corner/View'
import NxtCardStatus from 'components/nxt_card/nxt_card_status/View'
import CircleGraph from 'components/circle_graph/View'
import Image from 'components/image/View'
import i18n from 'support/i18n'
import imgDefault from 'img/nxt-inverted-image.png'
import styles from './View.Style'
import ButtonLink from '../button_link/View'

class NxtExpandedCard extends Component {
  constructor(props) {
    super(props)
    this.onMainButtonClick = this.onMainButtonClick.bind(this)
    this.handleGoToButtonClick = this.handleGoToButtonClick.bind(this)
    this.renderListItem = this.renderListItem.bind(this)
    this.renderTextBlock = this.renderTextBlock.bind(this)
  }

  onMainButtonClick() {
    const { id, onMainButtonClick } = this.props
    onMainButtonClick(id)
  }

  handleGoToButtonClick() {
    const { id, onGoToButtonClick } = this.props
    onGoToButtonClick(id)
  }

  renderListItem() {
    const { investors, pledged } = this.props
    if (investors || pledged) {
      return <NxtCardListItems primaryValue={pledged} secondaryValue={investors} />
    }
    return null
  }

  renderTextBlock() {
    const { altTextBlock, classes, submittedBy, onSubmitterClick, isAnonymous } = this.props
    if (submittedBy) {
      return (
        <div className={classes.subText}>
          <p className={classes.subTextLeft}>
            {i18n.t('SUBMITTED_BY')}
            {!isAnonymous && (
              <ButtonLink
                onLinkClick={onSubmitterClick}
                classes={{ linkButton: classes.submitterLinkButton }}
              >
                {submittedBy}
              </ButtonLink>
            )}
            {isAnonymous && <span className={classes.submitterSpan}>{submittedBy}</span>}
          </p>
          {altTextBlock && <p className={classes.subTextRight}>{altTextBlock}</p>}
        </div>
      )
    }
    return null
  }

  render() {
    const {
      alt,
      buttonText,
      challenged,
      children,
      classes,
      defaultSrc,
      goToButtonText,
      height,
      img,
      onGoToButtonClick,
      sponsored,
      status,
      title,
      theme,
      circleValue,
      circleMaxValue,
      circleColor,
      circleContent,
      showCircle,
    } = this.props
    const childrenCount = React.Children.count(children)
    return (
      <div className={classes.container}>
        <Paper
          className={clsx(classes.paper, theme === 'dark' && classes.paperBlack)}
          style={{ height }}
        >
          <div className={classes.gridHeader}>
            <div className={classes.relative}>
              {status && <NxtCardStatus status={status} />}
              <Image alt={alt} className={classes.img} src={img} defaultSrc={defaultSrc} />
            </div>
            <NxtCardCorner challenged={challenged} sponsored={sponsored} />
            <div
              className={clsx(classes.headerBlock, theme === 'dark' && classes.headerBlockBlack)}
            >
              <div className={classes.textBlock}>
                <h2 className={classes.title}>{title}</h2>
              </div>
              <div className={classes.graph}>
                {showCircle && (
                  <CircleGraph
                    value={circleValue}
                    maxValue={circleMaxValue}
                    themeColor={theme}
                    pathColor={circleColor}
                  >
                    {circleContent}
                  </CircleGraph>
                )}
              </div>
            </div>
          </div>
          <div
            className={clsx(
              classes.gridInfo,
              classes.colorLight,
              theme === 'dark' && classes.colorBlack,
            )}
          >
            {this.renderTextBlock()}
          </div>

          {childrenCount > 0 && (
            <div className={classes.gridList}>
              <ul
                className={clsx(
                  classes.list,
                  theme === 'light' && classes.listLight,
                  theme === 'dark' && classes.listDark,
                )}
              >
                {children}
              </ul>
            </div>
          )}
          <div className={classes.gridButton}>
            <Button color="primary" fullWidth onClick={this.onMainButtonClick} variant="contained">
              {buttonText}
            </Button>
            {onGoToButtonClick && goToButtonText && (
              <Button
                color="primary"
                fullWidth
                onClick={this.handleGoToButtonClick}
                variant="contained"
              >
                {goToButtonText}
              </Button>
            )}
          </div>
        </Paper>
      </div>
    )
  }
}

NxtExpandedCard.defaultProps = {
  alt: 'default image',
  buttonText: i18n.t('GO_TO_IDEA'),
  challenged: false,
  altTextBlock: '',
  defaultSrc: imgDefault,
  height: '',
  id: '',
  img: '',
  investors: '',
  goToButtonText: i18n.t('CHALLENGES.GO_TO_CHALLENGE'),
  pledged: '',
  sponsored: false,
  status: '',
  submittedBy: '',
  theme: 'light',
  title: '',
  onSubmitterClick: () => {},
  onGoToButtonClick: undefined,
  circleMaxValue: '100',
  circleColor: '',
  showCircle: false,
  isAnonymous: false,
}

NxtExpandedCard.propTypes = {
  alt: PropTypes.string,
  altTextBlock: PropTypes.string,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  challenged: PropTypes.bool,
  defaultSrc: PropTypes.string,
  height: PropTypes.string,
  id: PropTypes.string,
  img: PropTypes.string,
  investors: PropTypes.string,
  onMainButtonClick: PropTypes.func.isRequired,
  onGoToButtonClick: PropTypes.func,
  onSubmitterClick: PropTypes.func,
  goToButtonText: PropTypes.string,
  pledged: PropTypes.string,
  sponsored: PropTypes.bool,
  status: PropTypes.string,
  submittedBy: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
  title: PropTypes.string,
  circleValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  circleColor: PropTypes.string,
  circleMaxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCircle: PropTypes.bool,
  isAnonymous: PropTypes.bool,
}

export default withStyles(styles)(NxtExpandedCard)

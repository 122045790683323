import { transformToEmbedUrl } from 'support/utils/file'
import { isBefore, addDays } from 'date-fns'

const ViewUtils = {}

export const challengeStatuses = {
  DRAFT: 'DRAFT',
  PROPOSED: 'PROPOSED',
  PLANNED: 'PLANNED',
  ACTIVE: 'ACTIVE',
  FINISHED: 'FINISHED',
  DELETED: 'DELETED',
  ALL: 'ALL',
}

export const STEPS_MAPPER = {
  description: 0,
  people: 1,
  gaming: 2,
  extraRewards: 3,
  previewAndLaunch: 4,
}

export const ROI_SETTINGS = {
  FEWER_IDEAS: 'fewerIdeas',
  MORE_IDEAS: 'moreIdeas',
  DEFAULT: 'default',
}

export function fromValueToPercentage(defaultValue, currentValue) {
  return Math.floor((currentValue * 200) / (defaultValue * 2)) / 100
}

export function fromPercentageToValue(defaultValue, stateValue) {
  return Math.ceil((defaultValue * 2 * stateValue) / 200)
}

export function advancedValuesBySetting(setting, defaultValues) {
  switch (setting) {
    case ROI_SETTINGS.FEWER_IDEAS:
      return {
        approveMult: ViewUtils.fromPercentageToValue(defaultValues.approveMult, 150),
        implementMult: ViewUtils.fromPercentageToValue(defaultValues.implementMult, 150),
        supportMult: ViewUtils.fromPercentageToValue(defaultValues.supportMult, 150),
        currentGoal: ViewUtils.fromPercentageToValue(defaultValues.currentGoal, 150),
      }
    case ROI_SETTINGS.MORE_IDEAS:
      return {
        approveMult: ViewUtils.fromPercentageToValue(defaultValues.approveMult, 50),
        implementMult: ViewUtils.fromPercentageToValue(defaultValues.implementMult, 50),
        supportMult: ViewUtils.fromPercentageToValue(defaultValues.supportMult, 50),
        currentGoal: ViewUtils.fromPercentageToValue(defaultValues.currentGoal, 50),
      }
    default:
      return {
        ...defaultValues,
      }
  }
}

export const descriptionStepValidator = ({
  title,
  description,
  images,
  imageUploadError,
  externalVideoError,
}) => {
  const errorObject = {}
  if (!title) {
    errorObject.title = true
  }
  if (!description || description.length < 2) {
    errorObject.description = true
  }
  if (!images || !images.length) {
    errorObject.images = true
  }
  if (imageUploadError) {
    errorObject.imageUploadError = imageUploadError
  }
  if (externalVideoError) {
    errorObject.externalVideoError = true
  }
  return errorObject
}

export const peopleStepValidator = sponsor => {
  const errorObject = {}
  if (!sponsor || !Object.keys(sponsor).length) {
    errorObject.sponsor = true
  }
  return errorObject
}

export const extraRewardsStepValidator = ({ description, rewarded, imageUploadError }) => {
  const errorObject = {}
  if (!description && rewarded) {
    errorObject.rewardDescription = true
  }
  if (!rewarded && description) {
    errorObject.rewarded = true
  }
  if (imageUploadError) {
    errorObject.imageUploadError = imageUploadError
  }
  return errorObject
}

export const previewStepValidator = ({ fromDate, toDate }) => {
  const errorObject = {}
  const tomorrow = new Date(addDays(new Date(), 1).setHours(0, 0, 0, 0))
  if (!toDate || toDate < fromDate || isBefore(toDate, tomorrow)) {
    errorObject.toDate = true
  }
  return errorObject
}

ViewUtils.STEPS_MAPPER = STEPS_MAPPER
ViewUtils.ROI_SETTINGS = ROI_SETTINGS
ViewUtils.descriptionStepValidator = descriptionStepValidator
ViewUtils.fromValueToPercentage = fromValueToPercentage
ViewUtils.fromPercentageToValue = fromPercentageToValue
ViewUtils.transformToEmbedUrl = transformToEmbedUrl
ViewUtils.advancedValuesBySetting = advancedValuesBySetting
ViewUtils.extraRewardsStepValidator = extraRewardsStepValidator
ViewUtils.peopleStepValidator = peopleStepValidator
ViewUtils.previewStepValidator = previewStepValidator

export default ViewUtils

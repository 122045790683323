const styles = () => ({
  top: {
    gridArea: 'top',
    gridColumn: 2,
    gridRow: 2,
    textAlign: 'center',
  },
  box: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow:
      'inset 0 1px 0 rgba(0,0,0,.1), inset 0 -1px 0 rgba(0,0,0,.4), 0 1px 2px rgba(0,0,0,.2)',
    height: '570px',
    marginBottom: '50px',
    padding: '15px',
  },
  title: {
    display: 'flex',
    margin: '10px',
    marginTop: '20px',
    marginLeft: '20px',
  },
  check: {
    margin: '10px 0px 10px 0px',
    float: 'left',
    clear: 'both',
  },
})

export default styles

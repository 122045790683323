import NavigationService from 'support/navigation/NavigationService'
import { setCurrentDomain } from '../domain'
import * as types from './Constants'

export function setLoggedIn() {
  return {
    type: types.SET_LOGGEDIN,
  }
}

export function setLoggedOut() {
  return {
    type: types.SET_LOGGEDOUT,
  }
}

export function onLogin(dispatch, domain) {
  dispatch(setCurrentDomain(domain))
  NavigationService.setDomain(domain)
  dispatch(setLoggedIn())
}

export const setAuthMessageBox = (messageType, messageText) => ({
  type: types.SET_AUTH_MESSAGE_BOX_CONTENT,
  response: {
    messageType,
    messageText,
  },
})

export const resetAuthMessageBox = () => ({
  type: types.RESET_AUTH_MESSAGE_BOX_CONTENT,
})

export const userAlreadyExists = () => ({
  type: types.USER_ALREADY_EXISTS,
  isUserAlreadyExisting: true,
})

export const identityNotVerifiedYet = () => ({
  type: types.IDENTITY_NOT_VERIFIED_YET,
  isEmailNotVerifiedYet: true,
})

export const increaseSignUpStep = () => ({
  type: types.INCREASE_SIGN_UP_STEP,
})

export const showLoadingSpinner = () => ({
  type: types.SHOW_LOADING_SPINNER,
})

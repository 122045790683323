const styles = theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    '& > div': {
      height: theme.spacing(67),
      width: theme.spacing(38.75),
      margin: 4,
    },
  },
  top: {
    gridArea: 'top',
    gridColumn: 2,
    gridRow: 2,
    textAlign: 'center',
  },
  center: {
    gridArea: 'center',
  },
  middles: {
    gridArea: 'middle',
    ...theme.gridMiddleColumnCenter,
    gridTemplateColumns: 'auto 960px auto',
  },
  mainbutton: {
    backgroundColor: '#C71111',
    color: 'white',
    borderRadius: '0',
    textTransform: 'capitalize',
    padding: '10px 40px 25px 40px',
  },
  selectedMainButton: {
    backgroundColor: '#424242',
    color: 'white',
    borderRadius: '0',
    textTransform: 'capitalize',
    padding: '10px 40px 25px 40px',
  },
  novarotate: {
    verticalAlign: 'top',
    transform: 'rotate(7deg)',
    '-ms-transform': 'rotate(90deg)' /* IE 9 */,
    '-moz-transform': 'rotate(90deg)' /* Firefox */,
    '-webkit-transform': 'rotate(90deg)' /* Safari and Chrome */,
    '-o-transform': 'rotate(90deg)' /* Opera */,
  },
  divRightButtons: {
    float: 'right',
    position: 'relative',
    right: '-23px',
    top: '30px',
    zIndex: 100,
  },
  greyInnerContainer: {
    position: 'relative',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    zIndex: 400,
  },
  outerSpace: {
    height: '100%',
    padding: '2% 2% 2% 5%',
  },
  redOuterContainer: {
    backgroundColor: '#C71111',
    padding: 35,
  },
  pendingButton: {
    position: 'absolute',
    left: '180px',
  },
  archivedButton: {
    position: 'absolute',
  },
  dashboardButton: {
    position: 'absolute',
    left: '200px',
  },
  evaluationButton: {
    position: 'absolute',
  },
  topButtons: {
    position: 'relative',
    left: '23px',
    top: '-30px',
    zIndex: 100,
  },
})

export default styles

const styles = theme => ({
  linkButton: {
    textDecoration: 'unset',
    display: 'flex',
    alignItems: 'center',
    '& > span:first-child': {
      color: theme.palette.primary.main,
      padding: theme.spacing(0, 1 / 2),
      fontSize: '1rem',
      textDecoration: 'underline',
    },
    '& > span:last-child': {
      padding: theme.spacing(0, 1 / 2),
      fontSize: '2rem',
      textDecoration: 'unset',
    },
    '& > span:last-child:hover': {
      textDecoration: 'unset',
    },
  },
})

export default styles

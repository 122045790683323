import React, { Component } from 'react'

import * as PropTypes from 'prop-types'
import { IconContext } from 'react-icons'
import { FaHeart, FaLink, FaYammer, FaFacebook } from 'react-icons/fa'

import { FiMail } from 'react-icons/fi'
import Grid from '@material-ui/core/Grid'
import ScrollIntoView from 'react-scroll-into-view'

import AuthProvider from '../azure_sso/AuthProvider'

import ExternalLogin from './components/external_login/View'
// import TermsDialog from './components/terms_dialog/View'
import { FullPageDialog } from 'components'
import './App.css'

class LandingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      showtnc: false,
    }
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.showTnC = this.showTnC.bind(this)
  }

  onSsoLogin() {
    const { onSSOSubmit, account } = this.props

    if (account) {
      const jsonData = JSON.stringify(account)
      const obj = JSON.parse(jsonData)
      onSSOSubmit(
        obj.idToken.given_name,
        obj.idToken.family_name,
        obj.idToken.email,
        obj.idToken.ctry,
        true,
      )
    }
  }

  showModal = () => {
    const { onErrorReset } = this.props
    onErrorReset()
    this.setState({ show: true })
  }

  showTnC = () => {
    const { onErrorReset } = this.props
    onErrorReset()
    this.setState({ showtnc: true })
  }

  hideModal = () => {
    this.setState({ show: false, showtnc: false })
  }

  render() {
    const { show, showtnc } = this.state
    const {
      onSSOSubmit,
      account,
      error,
      isLogging,
      // onSignIn,
      ssoLoginError,
      onExternalSubmit,
      onForgotPasswordClick,
      isEmailNotVerifiedYet,
      messageText,
      messageType,
      resetAuthMessageBox,
      onSendMeActivationEmailClick,
    } = this.props
    if (!isLogging) {
      if (account) {
        const jsonData = JSON.stringify(account)
        const obj = JSON.parse(jsonData)
        onSSOSubmit(
          obj.idToken.given_name,
          obj.idToken.family_name,
          obj.idToken.email,
          obj.idToken.ctry,
          true,
        )
      } else {
        console.log(`.`)
      }
    }
    if (error) {
      // console.log(error)
      ssoLoginError(`Error: ${error}`)
    }

    return (
      <div>
        <div className="App-header1">
          <div className="App-header-nav">
            <ScrollIntoView selector="#feature">
              <div className="App-header-nav1">FEATURE</div>
            </ScrollIntoView>
            <ScrollIntoView selector="#contacts">
              <div className="App-header-nav2">CONTACT</div>
            </ScrollIntoView>
            <button type="button" className="App-header-nav3" onClick={this.showTnC}>
              TERMS OF SERVICE
            </button>
          </div>

          <div className="App-header-content">
            <Grid container direction="row" justify="space-around" alignItems="center">
              <Grid item xs={12} sm={6}>
                {/* <div className="App-header-text"> */}
                <p className="p-title">
                  NOVA is an innovation <b />
                  programme, delivering
                  <b /> value by ideation and <b />
                  collaboration.
                </p>
                <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item xs={6} sm={3}>
                    {/* <button disabled type="button" className="button-access" onClick={onSignIn}>
                      Vodafone User
                    </button> */}
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <button type="button" className="button-ex-access" onClick={this.showModal}>
                      External User
                    </button>
                  </Grid>
                </Grid>
                {/* </div> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="App-header-image" />
              </Grid>
            </Grid>
          </div>
        </div>

        {show && (
          <ExternalLogin
            openDialog={show}
            isLogging={isLogging}
            closeDialog={this.hideModal}
            forgotPassword={onForgotPasswordClick}
            onExternalSubmit={onExternalSubmit}
            isEmailNotVerifiedYet={isEmailNotVerifiedYet}
            messageText={messageText}
            messageType={messageType}
            onSendMeActivationEmailClick={onSendMeActivationEmailClick}
            resetAuthMessageBox={resetAuthMessageBox}
          />
        )}
        {showtnc && <FullPageDialog openDialog={showtnc} closeDialog={this.hideModal} />}

        <div className="App-header2" id="feature">
          <div className="Agile-Baackground" />
        </div>

        <div className="App-header3" id="contacts">
          <div className="header3-love">
            We{' '}
            <IconContext.Provider
              value={{ color: '#ff4203', size: '30px', style: { marginRight: 10 } }}
            >
              <FaHeart />
            </IconContext.Provider>
            new Ideas!
          </div>

          <div className="Social-Container">
            <a href="https://workspace2.vodafone.com/Group/eris/SitePages/HomePage.html">
              <button type="button" className="button-link button5">
                <IconContext.Provider
                  value={{ color: 'white', size: '30px', style: { marginRight: 10 } }}
                >
                  <FaLink />
                </IconContext.Provider>
              </button>
            </a>

            <a href="https://www.yammer.com/vodafone.com/#/threads/inGroup?type=in_group&feedId=8633561&view=all">
              <button type="button" className="button-yammer button5">
                <IconContext.Provider
                  value={{ color: 'white', size: '30px', style: { marginRight: 10 } }}
                >
                  <FaYammer />
                </IconContext.Provider>
              </button>
            </a>

            <a href="https://vodafone.facebook.com/groups/2136237873262314/?ref=bookmarks">
              <button type="button" className="button-facebook button5">
                <IconContext.Provider
                  value={{ color: 'white', size: '30px', style: { marginRight: 10 } }}
                >
                  <FaFacebook />
                </IconContext.Provider>
              </button>
            </a>

            <a href="mailto:eris@vodafone.com">
              <button type="button" className="button-mail button5">
                <IconContext.Provider
                  value={{ color: 'white', size: '30px', style: { marginRight: 10 } }}
                >
                  <FiMail />
                </IconContext.Provider>
              </button>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

LandingPage.propTypes = {
  account: PropTypes.object,
  onSignIn: PropTypes.func.isRequired,
  error: PropTypes.string,
  onSSOSubmit: PropTypes.func.isRequired,
  isLogging: PropTypes.bool.isRequired,
  isUserAlreadyExisting: PropTypes.bool.isRequired,
  isEmailNotVerifiedYet: PropTypes.bool.isRequired,
  messageType: PropTypes.number.isRequired,
  messageText: PropTypes.string.isRequired,
}

export default AuthProvider(LandingPage)

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 0),
    '& > span:first-child': {
      fontSize: '2rem',
      marginRight: theme.spacing(1),
    },
  },
  investmentsRoot: {
    padding: 0,
  },
  panelExpanded: {
    marginTop: 0,
  },
  titleText: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  noIdeasPaper: {
    padding: theme.spacing(2, 3),
    textAlign: 'center',
    fontSize: '0.875rem',
  },
  rootTable: {
    paddingTop: 0,
    width: '100%',
    marginBottom: 0,
    '& > div > div:last-child': {
      marginBottom: 0,
      marginTop: 0,
    },
  },
})

export default styles

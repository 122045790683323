import { connect } from 'react-redux'
import {
  loginService,
  postRecoverPassword,
  postSendVerificationEmail,
  postGoogleLoginResult,
} from 'services/auth'
import Navigation from 'support/navigation'
import i18n from 'support/i18n'
import {
  onLogin,
  resetAuthMessageBox,
  setAuthMessageBox,
  identityNotVerifiedYet,
} from 'actions/sync/auth'
import { MESSAGE_TYPES } from 'components/auth_message_box/View.Utils'
import { POSSIBLES_AUTH_ERRORS, POSSIBLES_AUTH_STATUSES } from 'support/utils/auth'
import View from './View'

const mapStateToProps = state => ({
  messageType: state.auth.messageBox.messageType,
  messageText: state.auth.messageBox.messageText,
  isLogging: state.auth.isLogging,
  isEmailNotVerifiedYet: state.auth.isEmailNotVerifiedYet,
})

const mapDispatchToProps = dispatch => {
  const onLoginSuccess = response => {
    if (response.domainInfo.length > 1) {
      Navigation.navigateToIdentityChoose()
    } else {
      const { domain } = response.domainInfo[0]
      onLogin(dispatch, domain) 
    }
  }
  return {
    setAuthMessageBox: (text, type = MESSAGE_TYPES.ERROR) =>
      dispatch(setAuthMessageBox(type, text)),
    postGoogleLoginResult: code => {
      const onError = () =>
        dispatch(
          setAuthMessageBox(
            MESSAGE_TYPES.ERROR,
            i18n.t('COMMON_LOGIN.HEADER.ACTIVATION_MAIL_SENT_FAILURE'),
          ),
        )
      dispatch(postGoogleLoginResult(code, onLoginSuccess, onError))
    },
    resetAuthMessageBox: () => {
      dispatch(resetAuthMessageBox())
    },
    onForgotPasswordClick: email => {
      dispatch(
        setAuthMessageBox(
          MESSAGE_TYPES.SUCCESS,
          i18n.t('COMMON_LOGIN.HEADER.FORGOT_PASSWORD_EMAIL_SENT'),
        ),
      )
      dispatch(postRecoverPassword(email))
    },
    onSubmit: (email, password) => {
      const onFailure = responseError => {
        if (
          responseError.status === POSSIBLES_AUTH_STATUSES.FORBIDDEN &&
          responseError.data.error === POSSIBLES_AUTH_ERRORS.IDENTITY_NOT_VERIFIED_YET
        ) {
          dispatch(identityNotVerifiedYet())
        } else {
          dispatch(setAuthMessageBox(MESSAGE_TYPES.ERROR, i18n.t('COMMON_LOGIN.HEADER.LOGIN_KO')))
        }
      }
      dispatch(loginService(email, password, onLoginSuccess, onFailure))
    },
    onSendMeActivationEmailClick: email => {
      const onSuccess = () => {
        dispatch(
          setAuthMessageBox(
            MESSAGE_TYPES.SUCCESS,
            i18n.t('COMMON_LOGIN.HEADER.ACTIVATION_MAIL_SENT_SUCCESS'),
          ),
        )
      }
      const onError = () => {
        dispatch(
          setAuthMessageBox(
            MESSAGE_TYPES.ERROR,
            i18n.t('COMMON_LOGIN.HEADER.ACTIVATION_MAIL_SENT_FAILURE'),
          ),
        )
      }
      dispatch(postSendVerificationEmail(email, onSuccess, onError))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)

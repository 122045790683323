import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import clsx from 'clsx'
import Image from 'components/image/View'
import defaultImage from 'img/nxt-inverted-image.png'
import styles from './View.Style'

const NotificationItem = props => {
  const { classes, text, timeAgo, isRead, imageSrc, icon } = props
  return (
    <div className={classes.root}>
      {imageSrc ? (
        <Image className={classes.image} defaultSrc={defaultImage} src={imageSrc} />
      ) : (
        <div className={classes.icon}>
          <span className={`icon-${(icon || '').toLowerCase()}`} />
        </div>
      )}
      <div className={classes.textWrapper}>
        <div className={classes.description}>
          <span
            className={clsx(!isRead ? classes.bold : '')}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
        <div className={classes.timeAgo}>{`${timeAgo} ago`}</div>
      </div>
    </div>
  )
}

NotificationItem.defaultProps = {
  imageSrc: '',
  icon: '',
}

NotificationItem.propTypes = {
  text: PropTypes.string.isRequired,
  timeAgo: PropTypes.string.isRequired,
  isRead: PropTypes.bool.isRequired,
  imageSrc: PropTypes.string,
  icon: PropTypes.string,
}

export default withStyles(styles)(NotificationItem)

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import clsx from 'clsx'
import styles from './View.Style'

const HowItWorksVideo = props => {
  const { classes } = props

  return (
    <div className={classes.box}>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        src="https://www.youtube.com/embed/j4amX_o5Esk"
        frameBorder="0"
        allowFullScreen
        className={classes.iframe}
      />
      <div className={classes.icons}>
        <div className={classes.icon}>
          <i className={clsx(classes.label, 'icon-idea')}></i>
          <b className={classes.textIcon}> {i18n.t('HOWITWORKS.IDEA')} </b>
        </div>
        <div className={classes.icon}>
          <i className={clsx(classes.label, 'icon-ranking')}></i>
          <b className={classes.textIcon}> {i18n.t('HOWITWORKS.CREATE_TEAM')} </b>
        </div>
        <div className={classes.icon}>
          <i className={clsx(classes.label, 'icon-earn-money')}></i>
          <b className={classes.textIcon}> {i18n.t('HOWITWORKS.SEEK')} </b>
        </div>
        <div className={classes.icon}>
          <i className={clsx(classes.label, 'icon-promote')}></i>
          <b className={classes.textIcon}> {i18n.t('HOWITWORKS.RAISE')} </b>
        </div>
        <div className={classes.icon}>
          <i className={clsx(classes.label, 'icon-funds')}></i>
          <b className={classes.textIcon}> {i18n.t('HOWITWORKS.MADE_REAL')} </b>
        </div>
      </div>
    </div>
  )
}

HowItWorksVideo.propTypes = {
  classes: PropTypes.shape({
    box: PropTypes.string,
    iframe: PropTypes.string,
    icons: PropTypes.string,
    icon: PropTypes.string,
    textIcon: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles)(HowItWorksVideo)

import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Avatar, Button } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'

const SuggestionSelected = props => {
  const { classes, suggestion, onRemoveSuggestion } = props
  return (
    <div className={classes.root}>
      <Avatar src={suggestion.photoSrc} />
      <div className={classes.suggestionText}>
        <span className={classes.fullnameSuggestion}>{suggestion.fullName}</span>
        <Button
          className={classes.removeSuggestion}
          onClick={onRemoveSuggestion}
          component="span"
          disableFocusRipple
          disableRipple
        >
          {i18n.t('CHALLENGES.REMOVE')}
        </Button>
      </div>
    </div>
  )
}

SuggestionSelected.propTypes = {
  suggestion: PropTypes.shape({}).isRequired,
  onRemoveSuggestion: PropTypes.func.isRequired,
}
export default withStyles(styles)(SuggestionSelected)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  TableCell,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import NxtCustomTable from 'components/nxt_custom_table/View'
import { FiberManualRecord } from '@material-ui/icons'
import Image from 'components/image/View'
import imageDefault from 'img/nxt-inverted-image.png'
import ButtonLink from 'components/button_link/View'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import styles from './View.Style'
import { getSortedItems } from '../../pages/investments/View.Utils'

const headRows = [
  {
    id: 'title',
    numeric: false,
    label: i18n.t('IDEA_TITLE'),
  },
  {
    id: 'status',
    numeric: false,
    label: i18n.t('IDEA_STATUS_T'),
  },
  {
    id: 'invested',
    numeric: true,
    label: i18n.t('IDEA.INVESTED'),
  },
  { id: 'profit', numeric: true, label: i18n.t('INVESTMENTS.PROFIT_UPPER') },
]

class InvestmentsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      investmentStatus: 'all',
      order: true,
      orderBy: 'title',
      showedGroups: getSortedItems(props.ideas, 'asc', 'created'),
    }
    this.renderItemRow = this.renderItemRow.bind(this)
    this.filterIdeas = this.filterIdeas.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { order, orderBy } = this.state
    const { ideas } = this.props
    if (ideas !== prevProps.ideas && ideas) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showedGroups: getSortedItems(ideas, order, orderBy) })
    }
  }

  parseStatus = status => {
    switch (status) {
      case 'APPROVED_BY_IT': {
        return i18n.t('IDEA_STATUS.APPROVED_BY_IT')
      }
      case 'DELETED': {
        return i18n.t('IDEA_STATUS.DELETED')
      }
      case 'DISCARDED': {
        return i18n.t('IDEA_STATUS.DISCARDED')
      }
      case 'IMPLEMENTED': {
        return i18n.t('IDEA_STATUS.IMPLEMENTED')
      }
      case 'PENDING_SUPPORT': {
        return i18n.t('IDEA_STATUS.PENDING_SUPPORT')
      }
      case 'PILOT_ACTIVE': {
        return i18n.t('IDEA_STATUS.PILOT_ACTIVE')
      }
      case 'ROUND_CLOSED': {
        return i18n.t('IDEA_STATUS.ROUND_CLOSED')
      }
      case 'SUPPORTED': {
        return i18n.t('IDEA_STATUS.SUPPORTED')
      }
      default: {
        return 'STATUS'
      }
    }
  }

  parseInvestmentStatus = status => {
    switch (status) {
      case 'APPROVED_BY_IT':
      case 'IMPLEMENTED':
      case 'PENDING_SUPPORT':
      case 'PILOT_ACTIVE':
      case 'ROUND_CLOSED':
      case 'SUPPORTED': {
        return true
      }

      case 'DELETED':
      case 'DISCARDED': {
        return false
      }

      default: {
        return 'all'
      }
    }
  }

  handleFilterChange = event => {
    this.setState({ investmentStatus: event.target.value })
  }

  filterIdeas(idea) {
    const { investmentStatus } = this.state
    if (investmentStatus === 'all') {
      return idea
    }
    return this.parseInvestmentStatus(idea.status) === investmentStatus
  }

  handleRequestSort(property) {
    const { order, orderBy, showedGroups } = this.state
    const newOrder = orderBy === property ? !order : true

    const sortGroups = getSortedItems(showedGroups, newOrder, property)
    this.setState({
      order: newOrder,
      orderBy: property,
      showedGroups: sortGroups,
    })
  }

  renderItemRow() {
    const { showedGroups } = this.state
    const { classes, onTitleClick } = this.props

    return showedGroups.filter(this.filterIdeas).map(item => {
      const { id, image, title, status, invested, profit } = item
      const parsedStatus = this.parseStatus(status)
      const parsedInvestmentStatus = this.parseInvestmentStatus(status)
      const color = parsedInvestmentStatus ? '#6FB24E' : '#ED4040'

      return (
        <TableRow hover className={classes.itemRow} key={id}>
          <TableCell align="right" size="small">
            <FiberManualRecord className={classes.iconStatus} htmlColor={color} />
          </TableCell>
          <TableCell>
            <Image defaultSrc={imageDefault} src={image} />
          </TableCell>
          <TableCell align="left">
            <ButtonLink classes={{ linkButton: classes.link }} onLinkClick={() => onTitleClick(id)}>
              {title}
            </ButtonLink>
          </TableCell>
          <TableCell>{parsedStatus}</TableCell>
          <TableCell align="right" className={classes.label}>
            <NxtVirtualCurrency>{invested}</NxtVirtualCurrency>
          </TableCell>
          <TableCell align="right" className={classes.label}>
            <NxtVirtualCurrency>{profit}</NxtVirtualCurrency>
          </TableCell>
        </TableRow>
      )
    })
  }

  renderHeader = () => {
    const { orderBy, order } = this.state
    const { classes } = this.props
    return (
      <TableRow>
        <TableCell />
        <TableCell />
        {headRows.map(row => (
          <TableCell className={classes.cell} align={row.numeric ? 'right' : 'left'} key={row.id}>
            <TableSortLabel
              id={row.id}
              active={orderBy === row.id}
              direction={order ? 'asc' : 'desc'}
              onClick={() => this.handleRequestSort(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    )
  }

  render() {
    const { investmentStatus } = this.state
    const { classes } = this.props
    return (
      <div className={classes.middle}>
        <Paper>
          <div className={classes.tableHeader}>
            <div className={classes.tableTitle}>
              <span className="icon-promote" />
              <h3 className={classes.tableTitle}>{i18n.t('INVESTMENTS.IDEAS_INVESTED')}</h3>
            </div>
            <div className={classes.filterContainer}>
              <span>{i18n.t('INVESTMENTS.FILTERS.INVESTMENT_STATUS')}: </span>
              <FormControl variant="outlined" classes={{ root: classes.formControl }}>
                <Select value={investmentStatus} onChange={this.handleFilterChange}>
                  <MenuItem value="all">{i18n.t('ALL')}</MenuItem>
                  <MenuItem value>{i18n.t('IDEA_STATUS.ACTIVE')}</MenuItem>
                  <MenuItem value={false}>{i18n.t('IDEA_STATUS.FINISHED')}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <NxtCustomTable
            renderHeaderComponent={this.renderHeader}
            renderRowComponent={this.renderItemRow}
          />
        </Paper>
      </div>
    )
  }
}

InvestmentsTable.propTypes = {
  ideas: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onTitleClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(InvestmentsTable)

import { createStyles } from '@material-ui/core/styles'

const styles = () =>
  createStyles({
    ideaStatus: {
      width: '7rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#FFF',
      right: '0',
      padding: '0.4rem',
      position: 'absolute',
      fontSize: '12px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  })

export default styles

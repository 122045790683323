export const prepareHeadersFromState = state => {
  return {
    'nxt-domain': state.data.domain.currentDomain,
    'x-mock-match-request-body': process.env.REACT_APP_TESTING_MODE,
  }
}

export function prepareMultipartHeadersFromState() {
  return () => {
    return {
      'Content-Type': 'multipart/form-data',
    }
  }
}

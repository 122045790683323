const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      padding: theme.spacing(0, 3),
    },
  },
})

export default styles

import { createStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    container: {
      height: '100%',
      padding: '3rem',
      backgroundColor: '#0d0d0d',
      color: '#FFFFFF',
    },
    table: {
      width: '100%',
      marginTop: '50px',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      '& td': {
        border: '1px solid #dcdcdc',
        padding: '30px 10px',
        textAlign: 'center',
      },
      // '& td:first-child': {
      //   border: 'none',
      //   width: '15%',
      // },
      '& th:nth-child(1) > div:last-child': {
        borderBottom: '3px solid',
      },
      '& th:nth-child(2) > div:last-child': {
        borderBottom: '3px solid',
      },
      '& th:nth-child(3) > div:last-child': {
        borderBottom: '3px solid',
      },
      '& th:nth-child(4) > div:last-child': {
        borderBottom: '3px solid',
      },
      '& th:nth-child(5) > div:last-child': {
        borderBottom: '3px solid',
      },
      '& th:nth-child(6) > div:last-child': {
        borderBottom: '3px solid',
      },
      '& th:nth-child(7) > div:last-child': {
        borderBottom: '3px solid',
      },
      '& th:nth-child(8) > div:last-child': {
        borderBottom: '3px solid',
      },
    },
  })

export default styles

const styles = theme => ({
  linkButton: {
    fontSize: '1rem',
    '& > span': {
      padding: theme.spacing(0, 1 / 2),
    },
  },
})

export default styles

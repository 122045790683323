const styles = theme => ({
  container: {
    margin: theme.spacing(1),
    width: 400,
    '& img': {
      width: 136,
      height: 136,
      marginRight: theme.spacing(1.5),
    },
  },
  imageContainer: {
    marginTop: theme.spacing(4.25),
    display: 'flex',
  },
  imageInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  input: {
    display: 'none',
  },
  text: {
    fontSize: 14,
    lineHeight: '20px',
    color: '#646464',
  },
  pictureButton: {},
})

export default styles

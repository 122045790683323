export default [
  { value: 'APPROVED_BY_IT', id: 'APPROVED_BY_IT', name: 'Approved' },
  { value: 'DISCARDED', id: 'DISCARDED', name: 'Discarded' },
  { value: 'IMPLEMENTED', id: 'IMPLEMENTED', name: 'Implemented' },
  { value: 'PENDING_SUPPORT', id: 'PENDING_SUPPORT', name: 'Seeking Funding' },
  { value: 'PILOT_ACTIVE', id: 'PILOT_ACTIVE', name: 'Pilot Project' },
  { value: 'ROUND_CLOSED', id: 'ROUND_CLOSED', name: 'Round Closed' },
  { value: 'SUPPORTED', id: 'SUPPORTED', name: 'Funded' },
]
export const TYPE_OF_TIME = [
  { name: 'LOGISTIC.DAYS', id: 'days' },
  { name: 'LOGISTIC.WEEKS', id: 'weeks' },
  { name: 'LOGISTIC.MONTHS', id: 'months' },
]

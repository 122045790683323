const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100px',
    minHeight: '50px',
    padding: theme.spacing(1),
  },
  close: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1),
  },
})
export default styles

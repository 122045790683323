const styles = theme => ({
  base: {
    // backgroundColor: '#FFFFFF',
    height: '100%'
  },
  main:{
    backgroundColor:'#fbfaff'
  }
  
})

export default styles

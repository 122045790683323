import React from 'react'
import * as PropTypes from 'prop-types'
import { TextField, Button, CircularProgress, withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import { MESSAGE_TYPES } from 'components/auth_message_box/View.Utils'
import AuthMessageBox from 'components/auth_message_box/View'
import ProfileImagePicker from 'components/profile_image_picker/View'
import styles from './View.Style'

const UserDetailsForm = props => {
  const {
    classes,
    onSubmit,
    messageText,
    messageType,
    setAuthMessageBox,
    isSavingUserDetails,
    onImageSelected,
    onClose,
  } = props
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [base64Image, setBase64Image] = React.useState('')

  function handleSubmit(event) {
    event.preventDefault()
    const error = MESSAGE_TYPES.ERROR
    if (!firstName) {
      // show error insert first name
      setAuthMessageBox(error, i18n.t('COMMON_LOGIN.HEADER.NO_FIRST_NAME'))
    } else if (!lastName) {
      // show error insert last name
      setAuthMessageBox(error, i18n.t('COMMON_LOGIN.HEADER.NO_LAST_NAME'))
    } else {
      // call the endpoint
      onSubmit(firstName, lastName, base64Image)
    }
  }

  return (
    <div className={classes.content}>
      <div className={classes.login}>
        <div className={classes.title}>
          <h1>{i18n.t('COMMON_LOGIN.HEADER.FINALIZE_ACCOUNT')}</h1>
        </div>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            autoFocus
            fullWidth
            label={i18n.t('COMMON_LOGIN.HEADER.FIRST_NAME')}
            placeholder={i18n.t('COMMON_LOGIN.HEADER.FIRST_NAME')}
            margin="normal"
            name="firstName"
            onChange={event => setFirstName(event.target.value)}
            InputLabelProps={{ shrink: true }}
            required
            type="text"
            variant="outlined"
          />
          <TextField
            fullWidth
            label={i18n.t('COMMON_LOGIN.HEADER.LAST_NAME')}
            placeholder={i18n.t('COMMON_LOGIN.HEADER.LAST_NAME')}
            margin="normal"
            name="lastName"
            onChange={event => setLastName(event.target.value)}
            InputLabelProps={{ shrink: true }}
            required
            type="text"
            variant="outlined"
          />
          <ProfileImagePicker
            classes={{
              container: classes.pickerContainer,
              pictureButton: classes.pictureButton,
            }}
            image={base64Image}
            setImageBase64={setBase64Image}
            onSelectImage={onImageSelected}
            onClose={onClose}
            showTitle={false}
          />
          <Button
            className={classes.submit}
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
            disabled={isSavingUserDetails}
          >
            {i18n.t('COMMON_LOGIN.HEADER.SIGN_UP')}
            {isSavingUserDetails && (
              <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />
            )}
          </Button>
          <div className={classes.messageBoxContainer}>
            <AuthMessageBox messageText={messageText} messageType={messageType} />
          </div>
        </form>
      </div>
    </div>
  )
}

UserDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setAuthMessageBox: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSavingUserDetails: PropTypes.bool.isRequired,
  messageType: PropTypes.number.isRequired,
  messageText: PropTypes.node.isRequired,
}

export default withStyles(styles)(UserDetailsForm)

import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import styles from './View.Style'

const NxtControlButtons = props => {
  const { classes, buttons } = props
  return (
    <div className={classes.container}>
      {buttons.map(button => (
        <Button
          key={button.text}
          variant="contained"
          color={button.isNotPrimary ? 'secondary' : 'primary'}
          className={classes.button}
          onClick={button.action}
        >
          {button.text}
        </Button>
      ))}
    </div>
  )
}

NxtControlButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node.isRequired,
      action: PropTypes.func.isRequired,
      isNotPrimary: PropTypes.bool,
    }),
  ).isRequired,
}

export default withStyles(styles)(NxtControlButtons)

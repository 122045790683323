import React, { Component, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import { CircleGraph, Filter, ShowFilter } from 'components'
import NxtControlButtons from 'components/nxt_control_buttons/View'
import NxtResumeCard from 'components/nxt_resume_card/View'
import NxtChallengeResumeDetail from 'components/nxt_challenge_resume_detail/View'
import ExploreFilterFields from 'pages/explore/components/explore_filter_fields/View'
import SearchInput from 'pages/explore/components/search_input/View'
import { differenceInCalendarDays } from 'date-fns'
import i18n from 'support/i18n'
import { FIRST_LIMIT_CONSTANT, LIMIT_CONSTANT } from 'common_constants/Ideas'
import { challengeStatuses } from 'pages/challenge/form/View.Utils'
import NxtCircleContent from 'components/nxt_circle_content/View'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import styles from './View.Style'

class ChallengeDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFilters: {
        status: [],
        challenge: {},
        organizations: [],
        types: [],
      },
      search: '',
      showSlider: false,
      buttons: [],
      offset: 0,
    }
    this.id = props.match.params.id
    this.handleChange = this.handleChange.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
    this.handleOnLoadMore = this.handleOnLoadMore.bind(this)
    this.handleActiveFilters = this.handleActiveFilters.bind(this)
    this.handleResetSearchClick = this.handleResetSearchClick.bind(this)
  }

  componentDidMount() {
    const { applyFilter, getChallengeById, match } = this.props
    getChallengeById(match.params.id)
    applyFilter(match.params.id)
    this.calculateButtons()
  }

  componentDidUpdate(prevProps) {
    const { challenge, isAdmin, isInnovator, isLocalAdmin } = this.props
    if (
      challenge.status !== prevProps.challenge.status ||
      isAdmin !== prevProps.isAdmin ||
      isInnovator !== prevProps.isInnovator ||
      isLocalAdmin !== prevProps.isLocalAdmin
    ) {
      this.calculateButtons()
    }
  }

  getIdeas(offset = 0) {
    const { applyFilter, match } = this.props
    const { activeFilters } = this.state
    const { status, organizations, types } = activeFilters
    applyFilter(
      match.params.id,
      organizations.map(org => `${org.id}`),
      status,
      types.map(type => `${type.id}`),
      offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT,
      offset,
    )
  }

  calculateButtons() {
    const {
      challenge: { status },
      challenge: { isGlobal },
      isAdmin,
      isInnovator,
      isLocalAdmin,
      onEditClick,
      onDeleteClick,
      onFinishClick,
      onVisitorsClick,
      sameOrigin,
      countryId,
      origin,
    } = this.props
    // console.log(`sameOrigin: ${sameOrigin}`)
    // console.log(`countryId: ${countryId}`)
    // console.log(`origin: ${origin}`)
    const buttons = []
    if (isAdmin || isInnovator || isLocalAdmin) {
      if (status === challengeStatuses.PROPOSED) {
        if (isAdmin || isInnovator || (isLocalAdmin && sameOrigin && !isGlobal)) {
          buttons.push({
            text: i18n.t('CHALLENGES.VALIDATE'),
            action: () => onEditClick(this.id),
          })
        }
      }
      if (status !== challengeStatuses.DELETED) {
        if (status !== challengeStatuses.PROPOSED) {
          if (isAdmin || isInnovator || (isLocalAdmin && sameOrigin && !isGlobal)) {
            buttons.push({ text: i18n.t('CHALLENGES.EDIT'), action: () => onEditClick(this.id) })
          }
        }
        if (isAdmin || isInnovator || (isLocalAdmin && sameOrigin && !isGlobal)) {
          buttons.push({
            text: i18n.t('CHALLENGES.DELETE'),
            action: () => onDeleteClick(this.id),
            isNotPrimary: true,
          })
        }
      }
      if (status === challengeStatuses.ACTIVE) {
        if (isAdmin || isInnovator || (isLocalAdmin && sameOrigin && !isGlobal)) {
          buttons.push({ text: i18n.t('CHALLENGES.FINISH'), action: () => onFinishClick(this.id) })
        }
      }
      buttons.push({ text: i18n.t('CHALLENGES.VISITORS'), action: () => onVisitorsClick(this.id) })
    }
    this.setState({ buttons })
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleActiveFilters(newFilters) {
    this.setState({ ...newFilters, offset: 0 }, this.getIdeas)
  }

  handleFilter(value) {
    this.setState({ showSlider: value })
  }

  handleOnLoadMore() {
    const { offset } = this.state
    const newLimit = offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT
    const newOffset = newLimit + offset
    this.getIdeas(newOffset)
    this.setState({ offset: newOffset })
  }

  handleResetSearchClick() {
    this.getIdeas()
    this.setState({ offset: 0 })
  }

  render() {
    const {
      applySearch,
      challenge,
      ideasChallenge,
      totalIdeasChallenge,
      challengesLoading,
      classes,
      getOrganization,
      getTypes,
      hasMoreIdeasToLoad,
      ideaStatusStepper,
      isOrganizationsActive,
      isTypesActive,
      navigateToIdeaDetail,
      navigateToNewIdea,
      onClickToShowRewardImage,
      organizationsName,
      organizations,
      folderOrganizations,
      types,
      challengeIdeasLoading,
      navigateToUserProfile,
    } = this.props
    const { activeFilters, buttons, search, showSlider } = this.state
    const {
      approvedMult,
      images,
      name,
      description,
      video,
      ends,
      implementedMult,
      importance,
      numIdeas,
      proposerName,
      proposerPhoto,
      rewardText,
      rewardOther,
      rewardImage,
      rewardFor,
      sponsorName,
      sponsorPhoto,
      supportedMult,
      starts,
      visits,
      organisation,
    } = challenge
    const value = differenceInCalendarDays(Date.now(), starts)
    const maxValue = differenceInCalendarDays(ends, starts)
    // console.log("Inside challenge detail one")
    return (
      <Fragment>
        <div className={classes.top}>
          <div className={classes.topCenter}>
            <h1>
              <i className="icon-target" /> {i18n.t('CHALLENGES.CHALLENGES')}
            </h1>
            <NxtControlButtons buttons={buttons} type="challenges" />
            {!challengesLoading && (
              <NxtResumeCard
                images={images}
                title={name}
                subTitle={organisation}
                description={description}
                selectedProposer={{
                  fullName: proposerName,
                  photo: proposerPhoto,
                }}
                videoSrc={video}
              >
                <NxtChallengeResumeDetail
                  approveMult={approvedMult}
                  daysToGo={
                    !Number.isNaN(maxValue - value) && (
                      <CircleGraph
                        className={{ base: classes.circleWithNoPadding }}
                        maxValue={maxValue}
                        size="big"
                        value={value}
                        themeColor="dark"
                        pathColor="white"
                      >
                        <NxtCircleContent days={maxValue - value} />
                      </CircleGraph>
                    )
                  }
                  ideas={numIdeas}
                  implementMult={implementedMult}
                  onClickToShowRewardImage={onClickToShowRewardImage}
                  reward={{
                    description: rewardText,
                    image: rewardImage,
                    rewarded: rewardFor,
                    rewardedOther: rewardOther,
                  }}
                  selectedSponsor={{
                    fullName: sponsorName,
                    photo: sponsorPhoto,
                  }}
                  supportMult={supportedMult}
                  visits={visits}
                  whyImportant={importance}
                  actionButton={
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => navigateToNewIdea(this.id)}
                      fullWidth
                    >
                      {i18n.t('CHALLENGES.DETAIL.ANSWER_CHALLENGE')}
                    </Button>
                  }
                />
              </NxtResumeCard>
            )}
          </div>
        </div>

        <div className={classes.middle}>
          <div className={classes.center}>
            <h1 className={classes.mainTitle}>{i18n.t('CHALLENGES.DETAIL.IDEAS_FOR_CHALLENGE')}</h1>
            <Filter className={classes.center}>
              <ExploreFilterFields
                activeFilters={activeFilters}
                challengeDetail={this.id}
                className={classes.gridCenter}
                getOrganization={getOrganization}
                getTypes={getTypes}
                organizationsName={organizationsName}
                organizations={organizations}
                folderOrganizations={folderOrganizations}
                types={types}
                isOrganizationsActive={isOrganizationsActive}
                isTypesActive={isTypesActive}
                handleActiveFilters={this.handleActiveFilters}
              />
            </Filter>
            <div className={classes.ideasFilter}>
              <SearchInput
                applySearch={applySearch}
                resetSearch={this.handleResetSearchClick}
                handleChange={this.handleChange}
                value={search}
              />
            </div>
            <NxtCircularLoader isLoading={challengeIdeasLoading}>
              <ShowFilter
                cards={ideasChallenge}
                showSlider={showSlider}
                onClick={navigateToIdeaDetail}
                onCardTypeChange={this.handleFilter}
                onLoadMore={this.handleOnLoadMore}
                totalIdeas={totalIdeasChallenge}
                showLoadMoreButton={hasMoreIdeasToLoad}
                ideaStatusStepper={ideaStatusStepper}
                onSubmitterClick={navigateToUserProfile}
                showSmallCardCircle
              />
            </NxtCircularLoader>
          </div>
        </div>
      </Fragment>
    )
  }
}

ChallengeDetail.defaultProps = {
  headerSection: {},
  organizations: [],
  types: [],
  isOrganizationsActive: false,
  isTypesActive: false,
  isAdmin: false,
  isInnovator: false,
  isLocalAdmin: false,
}

ChallengeDetail.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  applySearch: PropTypes.func.isRequired,
  challenge: PropTypes.shape({
    approvedMult: PropTypes.number,
    desc: PropTypes.string,
    video: PropTypes.string,
    description: PropTypes.string,
    ends: PropTypes.number,
    id: PropTypes.string,
    ideas: PropTypes.array,
    images: PropTypes.arrayOf(PropTypes.shape({})),
    implementedMult: PropTypes.number,
    mainPhoto: PropTypes.string,
    name: PropTypes.string,
    numIdeas: PropTypes.string,
    proposerName: PropTypes.string,
    proposerPhoto: PropTypes.string,
    rewardOther: PropTypes.string,
    rewardText: PropTypes.string,
    sponsorName: PropTypes.string,
    sponsorPhoto: PropTypes.string,
    starts: PropTypes.number,
    supportedMult: PropTypes.number,
  }).isRequired,
  classes: PropTypes.shape({
    bottom: PropTypes.string.isRequired,
    middle: PropTypes.string.isRequired,
    top: PropTypes.string.isRequired,
  }).isRequired,
  getChallengeById: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  getTypes: PropTypes.func.isRequired,
  navigateToUserProfile: PropTypes.func.isRequired,
  headerSection: PropTypes.shape({}),
  isOrganizationsActive: PropTypes.bool,
  isTypesActive: PropTypes.bool,
  navigateToIdeaDetail: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  organizationsName: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  isAdmin: PropTypes.bool,
  isInnovator: PropTypes.bool,
  isLocalAdmin: PropTypes.bool,
  ideaStatusStepper: PropTypes.shape({
    approvedLabel: PropTypes.string,
    approvedColor: PropTypes.string,
    discardedLabel: PropTypes.string,
    discardedColor: PropTypes.string,
    implementedLabel: PropTypes.string,
    implementedColor: PropTypes.string,
    pendingSupportLabel: PropTypes.string,
    pendingSupportColor: PropTypes.string,
    pilotActiveLabel: PropTypes.string,
    pilotActiveColor: PropTypes.string,
    roundClosedLabel: PropTypes.string,
    roundClosedColor: PropTypes.string,
    supportedLabel: PropTypes.string,
    supportedColor: PropTypes.string,
  }).isRequired,
  challengeIdeasLoading: PropTypes.bool.isRequired,
}

export default withStyles(styles)(ChallengeDetail)

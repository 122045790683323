const styles = theme => ({
    root: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: 'red',
    },
    buttonsContainer: {
      '& > button:first-child': {
        marginRight: theme.spacing(1),
      },
      '& > button:last-child': {
        marginLeft: theme.spacing(1),
      },
    },
    buttonProgress: {
      position: 'absolute',
    },
    background: {
        //background: `url(${Persons}) no-repeat`,
        opacity: '0.6'
    }
  })
  
  export default styles
  
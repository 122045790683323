import React, { Component } from 'react'
import i18n from 'support/i18n'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import FormLabel from './components/form_control_label/View'
import styles from './View.Style'

const prefsKeys = [
  {
    id: 'ideastatus',
    title: i18n.t('USERPREF.IDEA_STATUS_TITLE'),
    description: i18n.t('USERPREF.IDEA_STATUS_DESC'),
  },
  {
    id: 'invest',
    title: i18n.t('USERPREF.INVEST_TITLE'),
    description: i18n.t('USERPREF.INVEST_DESC'),
  },
  {
    id: 'week',
    title: i18n.t('USERPREF.WEEK_TITLE'),
    description: i18n.t('USERPREF.WEEK_DESC'),
  },
  {
    id: 'splash',
    title: i18n.t('USERPREF.SPLASH_TITLE'),
    description: i18n.t('USERPREF.SPLASH_DESC'),
  },
  {
    id: 'newidea',
    title: i18n.t('USERPREF.NEWIDEA_TITLE'),
    description: i18n.t('USERPREF.NEWIDEA_DESC'),
  },
  {
    id: 'challenge',
    title: i18n.t('USERPREF.CHALLENGE_TITLE'),
    description: i18n.t('USERPREF.CHALLENGE_DESC'),
  },
  {
    id: 'newcoment',
    title: i18n.t('USERPREF.NEWCOMENT_TITLE'),
    description: i18n.t('USERPREF.NEWCOMENT_DESC'),
  },
]

class UserPreferences extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ideastatus: props.ideastatus,
      invest: props.invest,
      week: props.week,
      splash: props.splash,
      newidea: props.newidea,
      challenge: props.challenge,
      newcoment: props.newcoment,
    }

    this.handleOnCheckPrefs = this.handleOnCheckPrefs.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { ideastatus, invest, week, splash, newidea, challenge, newcoment } = this.props
    if (prevProps !== this.props) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ideastatus, invest, week, splash, newidea, challenge, newcoment })
    }
  }

  handleOnCheckPrefs(key) {
    const { getIdeaStatusPref } = this.props
    // eslint-disable-next-line react/destructuring-assignment
    const value = this.state[key]
    getIdeaStatusPref(key, !value)
    this.setState({ [key]: !value })
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.top}>
        <h1>
          <i className="icon-settings" /> {i18n.t('PREFERENCES')}
        </h1>
        <div className={classes.box}>
          <span className={classes.title}>{i18n.t('USERPREF.IDEA_STATUS_TEXT')}</span>
          {prefsKeys.map(item => (
            <FormLabel
              key={item.id}
              title={item.title}
              description={item.description}
              /* eslint-disable-next-line react/destructuring-assignment */
              value={this.state[item.id]}
              /* eslint-disable-next-line react/destructuring-assignment */
              onClick={() => this.handleOnCheckPrefs(item.id)}
            />
          ))}
        </div>
      </div>
    )
  }
}

UserPreferences.propTypes = {
  classes: PropTypes.shape({
    top: PropTypes.string,
    box: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  getIdeaStatusPref: PropTypes.func.isRequired,
}

export default withStyles(styles)(UserPreferences)

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Paper, Tabs, Tab } from '@material-ui/core'
import i18n from 'support/i18n'
import clsx from 'clsx'
import { USERS_LIMIT_CONSTANT } from 'common_constants/Dashboard'
import { dashboardTabs, typesViewAs } from './Constants'
import styles from './View.Style'
import Activity from './components/activity/View'
import ActivityIdeas from './components/activity_ideas/View'
import ActivityPeople from './components/activity_people/View'
import IntervalDatesPicker from './components/interval_dates_picker/View'
import ReportSelector from './components/report_selector/View'

const comparisonIdeasTableHeaders = [
  { text: i18n.t('DASHBOARD.IDEAS_RAISING'), icon: 'earn-money', borderColor: '#ffcd40' },
  { text: i18n.t('DASHBOARD.IDEAS_SUPPORTED'), icon: 'success', borderColor: '#d19600' },
  { text: i18n.t('INVESTMENTS.ROUND_CLOSED'), icon: 'success', borderColor: '#0070a7' },
  { text: i18n.t('DASHBOARD.IDEAS_APPROVED'), icon: 'approved', borderColor: '#0097e2' },
  { text: i18n.t('DASHBOARD.IDEAS_PILOT'), icon: 'prototype', borderColor: '#63ffe4' },
  { text: i18n.t('DASHBOARD.IDEAS_IMPLEMENTED'), icon: 'ranking', borderColor: '#00d24c' },
  { text: i18n.t('DASHBOARD.IDEAS_DISCARDED'), icon: 'close', borderColor: '#fa5d7f' },
]
const comparisonPrivateIdeasTableHeaders = [
  { text: i18n.t('INVESTMENTS.ROUND_CLOSED'), icon: 'success', borderColor: '#0070a7' },
  { text: i18n.t('DASHBOARD.IDEAS_APPROVED'), icon: 'approved', borderColor: '#0097e2' },
  { text: i18n.t('DASHBOARD.IDEAS_PILOT'), icon: 'prototype', borderColor: '#63ffe4' },
  { text: i18n.t('DASHBOARD.IDEAS_IMPLEMENTED'), icon: 'ranking', borderColor: '#00d24c' },
  { text: i18n.t('DASHBOARD.IDEAS_DISCARDED'), icon: 'close', borderColor: '#fa5d7f' },
]

const privateStatusFilterOptions = [
  { value: 'ROUND_CLOSED', text: 'IDEA_STATUS.ROUND_CLOSED' },
  { value: 'APPROVED_BY_IT', text: 'IDEA_STATUS.APPROVED_BY_IT' },
  { value: 'PILOT_ACTIVE', text: 'IDEA_STATUS.PILOT_ACTIVE' },
  { value: 'IMPLEMENTED', text: 'IDEA_STATUS.IMPLEMENTED' },
  { value: 'DISCARDED', text: 'IDEA_STATUS.DISCARDED' },
]

const statusFilterOptions = [
  { value: 'PENDING_VALIDATION', text: 'IDEA_STATUS.PENDING_VALIDATION' },
  { value: 'PENDING_SUPPORT', text: 'IDEA_STATUS.PENDING_SUPPORT' },
  { value: 'SUPPORTED', text: 'IDEA_STATUS.SUPPORTED' },
  { value: 'ROUND_CLOSED', text: 'IDEA_STATUS.ROUND_CLOSED' },
  { value: 'APPROVED_BY_IT', text: 'IDEA_STATUS.APPROVED_BY_IT' },
  { value: 'PILOT_ACTIVE', text: 'IDEA_STATUS.PILOT_ACTIVE' },
  { value: 'IMPLEMENTED', text: 'IDEA_STATUS.IMPLEMENTED' },
  { value: 'DISCARDED', text: 'IDEA_STATUS.DISCARDED' },
]

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.userParticipationOffset = 0
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleDatesChange = this.handleDatesChange.bind(this)
    this.handleViewAsClick = this.handleViewAsClick.bind(this)
    this.getDates = this.getDates.bind(this)
    this.handleFilterByText = this.handleFilterByText.bind(this)
    this.handleFilterByType = this.handleFilterByType.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
    this.handlePeoplePageChange = this.handlePeoplePageChange.bind(this)
    this.handleIdeaPageChange = this.handleIdeaPageChange.bind(this)
    this.handlePrivateIdeaPageChange = this.handlePrivateIdeaPageChange.bind(this)
  }

  componentDidMount() {
    const {
      activeTab,
      getActivityComparisonTable,
      getIdeasComparisonTable,
      getSystemAccesses,
      getPeopleComparisonTable,
      getPrivateIdeasComparisonTable,
      getIdeasSeries,
      getPrivateIdeasSeries,
      getUsers,
      getUserParticipation,
      peopleUserFilter,
      domainCreationDate,
      getPrivateIdeas,
      getActiveOrganizations,
      getGroups,
      getActiveGoals,
      getIdeas,
      getActiveChallenges,
      getActiveTypes,
      privateIdeas,
    } = this.props
    const { fromDate, toDate } = this.getDates()
    getIdeasSeries(fromDate)
    getPrivateIdeasSeries(fromDate)
    getUserParticipation(this.userParticipationOffset)
    getActiveOrganizations()
    getGroups()
    getActiveGoals()
    getActiveChallenges()
    getActiveTypes()
    if (domainCreationDate) {
      getSystemAccesses(domainCreationDate)
    }
    switch (activeTab) {
      case dashboardTabs.IDEAS: {
        const { rowsPerPage, page } = privateIdeas.pagination
        getIdeasComparisonTable(fromDate, toDate)
        getIdeas({ limit: rowsPerPage, offset: page * rowsPerPage })
        break
      }
      case dashboardTabs.PEOPLE: {
        getPeopleComparisonTable(fromDate, toDate)
        getUsers(peopleUserFilter)
        break
      }
      case dashboardTabs.PRIVATE_IDEAS: {
        const { rowsPerPage, page } = privateIdeas.pagination
        getPrivateIdeasComparisonTable(fromDate, toDate)
        getPrivateIdeas({ limit: rowsPerPage, offset: page * rowsPerPage })
        break
      }
      default: {
        getActivityComparisonTable(fromDate, toDate)
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      activeTab,
      activity,
      ideas,
      people,
      privateIdeas,
      getActivityComparisonTable,
      getIdeasComparisonTable,
      getPeopleComparisonTable,
      getPrivateIdeasComparisonTable,
      getUserParticipation,
      privateIdeasFromDate,
      privateIdeasToDate,
      activityFromDate,
      activityToDate,
      ideasFromDate,
      ideasToDate,
      peopleFromDate,
      peopleToDate,
      getUsers,
      peopleUserFilter,
      usersParticipation,
      getSystemAccesses,
      domainCreationDate,
      getPrivateIdeas,
      getIdeas,
      ideaTablePagination,
    } = this.props
    const { fromDate, toDate } = this.getDates()
    if (domainCreationDate !== prevProps.domainCreationDate && domainCreationDate) {
      getSystemAccesses(domainCreationDate)
    }
    if (prevProps.activeTab !== activeTab) {
      if (activeTab === dashboardTabs.ACTIVITY && !activity.tableData.dataRows.length) {
        getActivityComparisonTable(fromDate, toDate)
      }
      if (activeTab === dashboardTabs.IDEAS && !ideas.tableData.dataRows.length) {
        const { rowsPerPage, page } = ideaTablePagination
        getIdeasComparisonTable(fromDate, toDate)
        getIdeas({ limit: rowsPerPage, offset: page * rowsPerPage })
      }
      if (activeTab === dashboardTabs.PEOPLE && !people.tableData.dataRows.length) {
        getPeopleComparisonTable(fromDate, toDate)
        getUsers(peopleUserFilter)
      }
      if (activeTab === dashboardTabs.PRIVATE_IDEAS && !privateIdeas.tableData.dataRows.length) {
        const { rowsPerPage, page } = privateIdeas.pagination
        getPrivateIdeasComparisonTable(fromDate, toDate)
        getPrivateIdeas({ limit: rowsPerPage, offset: page * rowsPerPage })
      }
    }
    if (
      prevProps.activityFromDate.getTime() !== activityFromDate.getTime() ||
      prevProps.activityToDate.getTime() !== activityToDate.getTime()
    ) {
      getActivityComparisonTable(fromDate, toDate)
    }
    if (
      prevProps.ideasFromDate.getTime() !== ideasFromDate.getTime() ||
      prevProps.ideasToDate.getTime() !== ideasToDate.getTime()
    ) {
      getIdeasComparisonTable(fromDate, toDate)
    }
    if (
      prevProps.peopleFromDate.getTime() !== peopleFromDate.getTime() ||
      prevProps.peopleToDate.getTime() !== peopleToDate.getTime()
    ) {
      getPeopleComparisonTable(fromDate, toDate)
    }
    if (
      prevProps.privateIdeasFromDate.getTime() !== privateIdeasFromDate.getTime() ||
      prevProps.privateIdeasToDate.getTime() !== privateIdeasToDate.getTime()
    ) {
      getPrivateIdeasComparisonTable(fromDate, toDate)
    }
    if (
      usersParticipation.hasMore &&
      prevProps.usersParticipation.series[0] !== usersParticipation.series[0]
    ) {
      this.userParticipationOffset += USERS_LIMIT_CONSTANT
      getUserParticipation(this.userParticipationOffset)
    }
  }

  getDates() {
    const { activeTab } = this.props
    switch (activeTab) {
      case dashboardTabs.IDEAS: {
        const { ideasFromDate, ideasToDate } = this.props
        return {
          fromDate: ideasFromDate,
          toDate: ideasToDate,
        }
      }
      case dashboardTabs.PEOPLE: {
        const { peopleFromDate, peopleToDate } = this.props
        return {
          fromDate: peopleFromDate,
          toDate: peopleToDate,
        }
      }
      case dashboardTabs.PRIVATE_IDEAS: {
        const { privateIdeasFromDate, privateIdeasToDate } = this.props
        return {
          fromDate: privateIdeasFromDate,
          toDate: privateIdeasToDate,
        }
      }
      default: {
        const { activityFromDate, activityToDate } = this.props
        return {
          fromDate: activityFromDate,
          toDate: activityToDate,
        }
      }
    }
  }

  handleChange(event, newValue) {
    const { onChangeTab } = this.props
    onChangeTab(newValue)
  }

  handleDateChange(date, isFrom) {
    const datesObject = {}
    if (isFrom) {
      datesObject.fromDate = date
    } else {
      datesObject.toDate = date
    }
    this.handleDatesChange(datesObject)
  }

  handleDatesChange(datesObject) {
    const { onChangeDate, activeTab } = this.props
    onChangeDate(activeTab, datesObject)
  }

  handleViewAsClick(typeOfView) {
    const { getUsers, peopleUserFilter } = this.props
    if (typeOfView === typesViewAs.GRID || typeOfView === typesViewAs.LIST) {
      getUsers(peopleUserFilter)
    }
  }

  handleFilter(filter) {
    const { getUsers, setUsersTableFilter } = this.props

    setUsersTableFilter(filter)
    getUsers(filter)
  }

  handleFilterByText(value) {
    const { userTableFilter } = this.props
    const { filterType, rowsPerPage } = userTableFilter
    const newFilter = {
      name: value,
      filterType,
      page: 0,
      rowsPerPage,
    }
    this.handleFilter(newFilter)
  }

  handleFilterByType(value) {
    const { userTableFilter } = this.props
    const { name, rowsPerPage } = userTableFilter
    const newFilter = {
      name,
      filterType: value,
      page: 0,
      rowsPerPage,
    }
    this.handleFilter(newFilter)
  }

  handlePeoplePageChange(value, newRowsPerPage) {
    const { userTableFilter } = this.props
    const { filterType, name } = userTableFilter
    const newFilter = {
      name,
      filterType,
      page: value,
      rowsPerPage: newRowsPerPage,
    }
    this.handleFilter(newFilter)
  }

  handlePrivateIdeaPageChange(page, rowsPerPage, filters) {
    const { getPrivateIdeas, setPrivateIdeaTableFilter } = this.props
    const newFilter = {
      page,
      rowsPerPage,
    }
    setPrivateIdeaTableFilter(newFilter)
    getPrivateIdeas({ ...filters, limit: rowsPerPage, offset: page * rowsPerPage })
  }

  handleIdeaPageChange(page, rowsPerPage, filters) {
    const { getIdeas, setIdeaTableFilter } = this.props
    const newFilter = {
      page,
      rowsPerPage,
    }
    setIdeaTableFilter(newFilter)
    getIdeas({ ...filters, limit: rowsPerPage, offset: page * rowsPerPage })
  }

  render() {
    const {
      classes,
      activeTab,
      isLoading,
      isActivitySeriesLoading,
      isIdeasSeriesLoading,
      isPrivateIdeasLoading,
      activity,
      ideas,
      people,
      privateIdeas,
      showPrivateTab,
      onSendReport,
      navigateToUserProfile,
      domainCreationDate,
      usersParticipation,
      isUsersParticipationSeriesLoading,
      onSendPeopleReport,
      ideasTableBottom,
      ideasTableBottomTotal,
      ideasTableBottomIsLoading,
      navigateToIdea,
      organizations,
      groups,
      strategics,
      challenges,
      types,
      ideaTablePagination,
    } = this.props
    const { fromDate, toDate } = this.getDates()
    return (
      <div className={classes.root}>
        <h1 className={classes.titleContainer}>
          <span className="icon-portfolio" />
          {i18n.t('DASHBOARD.TITLE')}
        </h1>
        <div className={classes.reportContainer}>
          <ReportSelector onMainButtonAction={onSendReport} />
        </div>
        <Paper className={classes.paper}>
          <Tabs
            value={activeTab}
            onChange={this.handleChange}
            aria-label="simple tabs example"
            variant="fullWidth"
          >
            <Tab
              label={i18n.t('DASHBOARD.ACTIVITY')}
              value={dashboardTabs.ACTIVITY}
              disabled={isLoading}
            />
            <Tab
              label={i18n.t('DASHBOARD.IDEAS')}
              value={dashboardTabs.IDEAS}
              disabled={isLoading}
            />
            <Tab
              label={i18n.t('DASHBOARD.PEOPLE')}
              value={dashboardTabs.PEOPLE}
              disabled={isLoading}
            />
            {showPrivateTab && (
              <Tab
                label={i18n.t('DASHBOARD.PRIVATE_IDEAS')}
                value={dashboardTabs.PRIVATE_IDEAS}
                disabled={isLoading}
              />
            )}
          </Tabs>
          <div className={classes.dashboardContainer}>
            <IntervalDatesPicker
              onSelectFromDate={date => this.handleDateChange(date, true)}
              onSelectToDate={date => this.handleDateChange(date)}
              fromDate={fromDate}
              toDate={toDate}
              disabled={isLoading}
              minDate={domainCreationDate}
            />
            <div className={clsx(activeTab !== dashboardTabs.ACTIVITY && classes.hide)}>
              <Activity
                tableData={activity.tableData}
                toDate={toDate}
                fromDate={fromDate}
                onChangeDates={this.handleDatesChange}
                isChartLoading={isActivitySeriesLoading}
                isTableLoading={isLoading}
                series={activity.chart.series}
                devicesInfo={activity.devicesInfo}
              />
            </div>
            <div className={clsx(activeTab !== dashboardTabs.IDEAS && classes.hide)}>
              <ActivityIdeas
                tableHeaders={comparisonIdeasTableHeaders}
                tableData={ideas.tableData}
                tableDataBottom={ideasTableBottom}
                tableDataBottomTotal={ideasTableBottomTotal}
                tableDataBottomIsLoading={ideasTableBottomIsLoading}
                toDate={toDate}
                fromDate={fromDate}
                onChangeDates={this.handleDatesChange}
                isChartLoading={isIdeasSeriesLoading}
                isTableLoading={isLoading}
                series={ideas.chart.series}
                navigateToIdea={navigateToIdea}
                organizations={organizations}
                groups={groups}
                strategics={strategics}
                challenges={challenges}
                types={types}
                statusFilterOptions={statusFilterOptions}
                onPageChange={this.handleIdeaPageChange}
                rowsPerPage={ideaTablePagination.rowsPerPage}
                page={ideaTablePagination.page}
              />
            </div>
            <div className={clsx(activeTab !== dashboardTabs.PEOPLE && classes.hide)}>
              <ActivityPeople
                tableData={people.tableData}
                isTableLoading={isLoading}
                tableUsersData={people.usersTable.users}
                totalSearchItems={people.usersTable.totalSearchItems}
                isTableUsersDataLoading={people.usersTable.isLoading}
                series={usersParticipation.series}
                totalUsers={usersParticipation.totalUsers}
                participationAvg={usersParticipation.participationAvg}
                successAvg={usersParticipation.successAvg}
                onViewAsClick={this.handleViewAsClick}
                navigateToUserProfile={navigateToUserProfile}
                onFilterByText={this.handleFilterByText}
                onFilterByType={this.handleFilterByType}
                isUsersParticipationSeriesLoading={isUsersParticipationSeriesLoading}
                onSendReportClick={onSendPeopleReport}
                onPageChange={this.handlePeoplePageChange}
                rowsPerPage={people.usersTable.filter.rowsPerPage}
                page={people.usersTable.filter.page}
              />
            </div>
            {showPrivateTab && activeTab === dashboardTabs.PRIVATE_IDEAS && (
              <div className={clsx(activeTab !== dashboardTabs.PRIVATE_IDEAS && classes.hide)}>
                <ActivityIdeas
                  tableHeaders={comparisonPrivateIdeasTableHeaders}
                  tableData={privateIdeas.tableData}
                  tableDataBottom={privateIdeas.tableDataBottom.data}
                  tableDataBottomTotal={privateIdeas.tableDataBottom.total}
                  tableDataBottomIsLoading={privateIdeas.tableDataBottom.ideasLoading}
                  toDate={toDate}
                  fromDate={fromDate}
                  onChangeDates={this.handleDatesChange}
                  isChartLoading={isPrivateIdeasLoading}
                  isTableLoading={isLoading}
                  series={privateIdeas.chart.series}
                  navigateToIdea={navigateToIdea}
                  organizations={organizations}
                  groups={groups}
                  strategics={strategics}
                  statusFilterOptions={privateStatusFilterOptions}
                  onPageChange={this.handlePrivateIdeaPageChange}
                  rowsPerPage={privateIdeas.pagination.rowsPerPage}
                  page={privateIdeas.pagination.page}
                  isPrivate
                />
              </div>
            )}
          </div>
        </Paper>
      </div>
    )
  }
}

Dashboard.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  showPrivateTab: PropTypes.bool.isRequired,
  activityFromDate: PropTypes.instanceOf(Date).isRequired,
  activityToDate: PropTypes.instanceOf(Date).isRequired,
  ideasFromDate: PropTypes.instanceOf(Date).isRequired,
  ideasToDate: PropTypes.instanceOf(Date).isRequired,
  peopleFromDate: PropTypes.instanceOf(Date).isRequired,
  peopleToDate: PropTypes.instanceOf(Date).isRequired,
  privateIdeasFromDate: PropTypes.instanceOf(Date).isRequired,
  privateIdeasToDate: PropTypes.instanceOf(Date).isRequired,
  getActivityComparisonTable: PropTypes.func.isRequired,
  getSystemAccesses: PropTypes.func.isRequired,
  getIdeasComparisonTable: PropTypes.func.isRequired,
  getPeopleComparisonTable: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getUserParticipation: PropTypes.func.isRequired,
  onSendReport: PropTypes.func.isRequired,
  getIdeasSeries: PropTypes.func.isRequired,
  getPrivateIdeasSeries: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isActivitySeriesLoading: PropTypes.bool.isRequired,
  isIdeasSeriesLoading: PropTypes.bool.isRequired,
  isUsersParticipationSeriesLoading: PropTypes.bool.isRequired,
  activity: PropTypes.shape({}).isRequired,
  ideas: PropTypes.shape({}).isRequired,
  peopleUserFilter: PropTypes.shape({
    name: PropTypes.string,
    filterType: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
  }).isRequired,
  usersParticipation: PropTypes.shape({
    hasMore: PropTypes.bool,
    totalUsers: PropTypes.number,
    participationAvg: PropTypes.string,
    successAvg: PropTypes.string,
    series: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onSendPeopleReport: PropTypes.func.isRequired,
}

export default withStyles(styles)(Dashboard)

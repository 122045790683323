import { createStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    chips: {
      margin: '0 0.2rem',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '960px',
      '& > div': {
        marginBottom: '1rem',
        marginRight: '0.5rem',
      },
    },
  })

export default styles

import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import styles from 'support/splash/View.Style'

const Splash = props => {
  const {
    classes,
    children,
    dataLoaded,
    isSplashActiveOrg,
    isSplashActiveUser,
    showSplashModal,
  } = props

  useEffect(() => {
    if (dataLoaded && isSplashActiveOrg && isSplashActiveUser) {
      showSplashModal()
    }
  }, [dataLoaded,isSplashActiveOrg,isSplashActiveUser,showSplashModal])

  return (
    <div className={classes.container}>
      <div className={classes.container}>{children}</div>
    </div>
  )
}

Splash.propTypes = {
  children: PropTypes.node.isRequired,
  dataLoaded: PropTypes.bool.isRequired,
  isSplashActive: PropTypes.bool.isRequired,
  showSplashModal: PropTypes.func.isRequired,
}

export default withStyles(styles)(Splash)

import React from 'react'
import * as PropTypes from 'prop-types'
import { InputAdornment, TextField } from '@material-ui/core'
import InnerSelect from './components/inner_select/View'

const TextWithSelect = ({
  label,
  handleTextValue,
  selectValue,
  handleSelectValue,
  selectOptions = [],
  fullWidth,
  value,
  error,
  helperText,
  type,
}) => {
  return (
    <TextField
      label={label}
      id="simple-start-adornment"
      onChange={event => handleTextValue(event.target.value)}
      type={type}
      value={value}
      fullWidth={fullWidth}
      error={error}
      helperText={error ? helperText : ''}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <InnerSelect
              options={selectOptions}
              handleChange={handleSelectValue}
              value={selectValue}
            />
          </InputAdornment>
        ),
      }}
    />
  )
}

TextWithSelect.defaultProps = {
  fullWidth: false,
  label: '',
  selectValue: '',
  value: '',
  type: 'number',
  error: false,
  helperText: '',
}

TextWithSelect.propTypes = {
  handleTextValue: PropTypes.func.isRequired,
  handleSelectValue: PropTypes.func.isRequired,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectValue: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
}

export default TextWithSelect

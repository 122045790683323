import React, { Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import styles from './View.Style'

const NxtCurrency = props => {
  const { classes, hasIconLeftSide, iconName, currencyConfiguration, children } = props
  return (
    <span className={classes.root}>
      {hasIconLeftSide && (
        <Fragment>
          {iconName && <span className={`icon-${iconName}`} />}
          {i18n.toCurrency(children, currencyConfiguration)}
        </Fragment>
      )}
      {!hasIconLeftSide && (
        <Fragment>
          {i18n.toCurrency(children, currencyConfiguration)}
          {iconName && <span className={`icon-${iconName}`} />}
        </Fragment>
      )}
    </span>
  )
}

NxtCurrency.defaultProps = {
  hasIconLeftSide: true,
  iconName: '',
  currencyConfiguration: {},
}

NxtCurrency.propTypes = {
  hasIconLeftSide: PropTypes.bool,
  iconName: PropTypes.string,
  currencyConfiguration: PropTypes.shape({}),
}

export default withStyles(styles)(NxtCurrency)

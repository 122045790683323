import { connect } from 'react-redux'
import AlertModalView from 'support/popup_dialogs/views/components/alert_modal_view/AlertModalView'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import i18n from 'support/i18n'

const mapStateToProps = (state, ownProps) => ({
  title: i18n.t('CONFIRMATION_MESSAGE'),
  description: ownProps.description,
  mainButtonText: i18n.t('ACCEPT'),
})

const mapDispatchToProps = dispatch => {
  return {
    mainButtonAction: () => dispatch(hideDialog()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertModalView)

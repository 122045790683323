import { connect } from 'react-redux'
import View from './View'

const mapStateToProps = state => ({
  lang: state.i18n.currentLang,
})

export default connect(
  mapStateToProps,
  null,
)(View)

import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
// import Paper from '@material-ui/core/Paper'
// import Grid from '@material-ui/core/Grid'
// import CardMedia from '@material-ui/core/CardMedia'
import Card from '@material-ui/core/Card'
// import CardContent from '@material-ui/core/CardContent'
// import Typography from '@material-ui/core/Typography'
import styles from './View.Style'
// import Iot from './img/iot_bio.jpg'
// import Site from './img/on_site.jpg'
// import Chain from './img/the_chain.png'
// import Drones from './img/drones.png'
import SwipeableTextMobileStepper from './component/prototype_slides/View'

const NovaDelivered = props => {
  const { classes } = props

  return (
    <div className={classes.container}>
      <p>
        NOVA has delivered a number of proof-of-concept prototypes over the years, in partnership with
        the original idea-owners. This section will show prototypes for NOVA across the globe in Vodafone 
        Group and across other markets too. These prototypes prove the functionality and potential of an 
        NOVA idea, and from this stage they can be adopted throughout the wider company and put to use where 
        required. Check out some of our prototypes below:
      </p>

      <p></p>
      <p></p>
      <p></p>
      <Card className={classes.root}>
      <SwipeableTextMobileStepper />
      </Card>
     
      <p></p>
      <p></p>
      <p></p>



      {/* <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6">
              ON SITE recycled - IT shop
            </Typography>
            <Typography variant="body2" gutterBottom>
              This idea is to reuse the spare kit we have here at Vodafone. Why are we (as a
              business) ordering new kit and throwing it away, worse still we pay someone to collect
              and recycle it as we waste. The ON Site IT Recycle shop provides a place where you can
              find second hand IT equipment and at the same time be able to recycle yours.
            </Typography>
          </CardContent>
        </div>
        <CardMedia className={classes.cover} image={Site} title="ON SITE recycled - IT shop" />
      </Card>
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6">
              IoT Biometrics
            </Typography>
            <Typography variant="body2" gutterBottom>
              This idea used existing Vodafone competences - IOT and Connectivity, and created a low
              cost and highly secure Fingerprint Reading IOT device that can be used as a UAM
              authentication appliance. This is a Multi Factor Authentication demonstrator to
              provide frictionless User Access Management to Staff, Enterprise Customers and
              Suppliers using Biometrics. There is various potential use cases this prototype will
              be used for in consumer as well as enterprise customers that are currently being
              explored.
            </Typography>
          </CardContent>
        </div>
        <CardMedia className={classes.cover} image={Iot} title="IoT Biometrics" />
      </Card>

      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6">
              Blockchain & IoT Analytics
            </Typography>
            <Typography variant="body2" gutterBottom>
              This prototype was built using blockchains to generate value in conjunction with our
              IoT & Security Products. Using block chain for analytics for use case: Audit trails
              for Law Enforcement Agency disclosures, sufficient to satisfy a Court of Law Audit
              trails for data traffic for use by IOT customers – so that device activity for
              life/money/mission critical application can be proved (to be separately pitched to
              Business to be implemented as a service for customers)
            </Typography>
          </CardContent>
        </div>
        <CardMedia className={classes.cover} image={Chain} title="Blockchain & IoT Analytics" />
      </Card>

      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6">
              Drones as a service
            </Typography>
            <Typography variant="body2" gutterBottom>
              This was initially an idea experimenting the use of Drones for cell tower inspection.
              The prototype captured visual & thermal Imagery, captured Omnidirectional EMF data.
              Proof of Concept to support the Business Case for an MVP.
            </Typography>
          </CardContent>
        </div>
        <CardMedia className={classes.cover} image={Drones} title="Drones as a service" />
      </Card>
*/}
      {/* <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6">
              Red Pages
            </Typography>
            <Typography variant="body2" gutterBottom>
              Red Pages – your employee directory and guide is here! Red Pages is a slick and easy
              to use directory where you can access the links that you need based on predictive
              searches, tags and categories. The directory is community driven which means we are
              counting on you to suggest new links and share the website with your colleagues!
            </Typography>
          </CardContent>
        </div>
        <iframe
          id="vzvd-21521212"
          name="vzvd-21521212"
          title="video player"
          class="video-player"
          type="text/html"
          width="100%"
          height="100%"
          frameborder="0"
          allowFullScreen
          allowTransparency="true"
          src="https://view.vzaar.com/21521212/player"
          allow="autoplay"
        />
      </Card>  */}
    </div>

    // <div className={classes.container}>
    //   <h3>NOVA Delivered Prototypes section</h3>
    //   <ol className="roman">
    //     <p>
    //       NOVA has delivered a number of proof-of-concept prototypes over the years, in partnership
    //       with the original idea-owners. These prototypes prove the functionality and potential of
    //       an NOVA idea, and from this stage they can be adopted throughout the wider company and put
    //       to use where required. Check out some of our prototypes below:
    //     </p>
    //     <div className={classes.root}>
    //       <li>
    //         ON SITE recycled - IT shop
    //         <Grid container spacing={0}>
    //           <Grid item xs={12} sm={6}>
    //             <Paper className={classes.paper}>
    //               <p>
    //                 This idea is to reuse the spare kit we have here at Vodafone. Why are we (as a
    //                 business) ordering new kit and throwing it away, worse still we pay someone to
    //                 collect and recycle it as we waste. The ON Site IT Recycle shop provides a place
    //                 where you can find second hand IT equipment and at the same time be able to
    //                 recycle yours.
    //               </p>
    //             </Paper>
    //           </Grid>
    //           <Grid item xs={12} sm={6}>
    //             <CardMedia className={classes.media} image={Site} title="Contemplative Reptile" />
    //           </Grid>
    //         </Grid>
    //       </li>
    //       <li>
    //         IoT Biometrics
    //         <Grid container spacing={0}>
    //           <Grid item xs={12} sm={6}>
    //             <Paper className={classes.paper}>
    //               <p>
    //                 This idea used existing Vodafone competences - IOT and Connectivity, and created
    //                 a low cost and highly secure Fingerprint Reading IOT device that can be used as
    //                 a UAM authentication appliance. This is a Multi Factor Authentication
    //                 demonstrator to provide frictionless User Access Management to Staff, Enterprise
    //                 Customers and Suppliers using Biometrics. There is various potential use cases
    //                 this prototype will be used for in consumer as well as enterprise customers that
    //                 are currently being explored.
    //               </p>
    //             </Paper>
    //           </Grid>
    //           <Grid item xs={12} sm={6}>
    //             <CardMedia className={classes.media} image={Iot} title="Contemplative Reptile" />
    //           </Grid>
    //         </Grid>
    //       </li>

    //       <li>
    //         Blockchain & IoT Analytics
    //         <Grid container spacing={0}>
    //           <Grid item xs={12} sm={6}>
    //             <Paper className={classes.paper}>
    //               <p>
    //                 This prototype was built using blockchains to generate value in conjunction with
    //                 our IoT & Security Products. Using block chain for analytics for use case: Audit
    //                 trails for Law Enforcement Agency disclosures, sufficient to satisfy a Court of
    //                 Law Audit trails for data traffic for use by IOT customers – so that device
    //                 activity for life/money/mission critical application can be proved (to be
    //                 separately pitched to Business to be implemented as a service for customers)
    //               </p>
    //             </Paper>
    //           </Grid>

    //           <Grid item xs={12} sm={6}>
    //             <CardMedia className={classes.media} image={Chain} title="Contemplative Reptile" />
    //           </Grid>
    //         </Grid>
    //       </li>
    //       <li>
    //         Drones as a service
    //         <Grid container spacing={0}>
    //           <Grid item xs={12} sm={6}>
    //             <Paper className={classes.paper}>
    //               <p>
    //                 This was initially an idea experimenting the use of Drones for cell tower
    //                 inspection. The prototype captured visual & thermal Imagery, captured
    //                 Omnidirectional EMF data. Proof of Concept to support the Business Case for an
    //                 MVP.
    //               </p>
    //             </Paper>
    //           </Grid>
    //           <Grid item xs={12} sm={6}>
    //             <CardMedia className={classes.media} image={Drones} title="Contemplative Reptile" />
    //           </Grid>
    //         </Grid>
    //       </li>
    //       <li>
    //         Red Pages
    //         <Grid container spacing={0}>
    //           <Grid item xs={12} sm={6}>
    //             <Paper className={classes.paper}>
    //               <p>
    //                 Red Pages – your employee directory and guide is here! Red Pages is a slick and
    //                 easy to use directory where you can access the links that you need based on
    //                 predictive searches, tags and categories. The directory is community driven
    //                 which means we are counting on you to suggest new links and share the website
    //                 with your colleagues!
    //               </p>
    //             </Paper>
    //           </Grid>
    //           <Grid item xs={12} sm={6}>
    //             {/* <CardMedia
    //               className={classes.media}
    //               component="video"
    //               image="https://view.vzaar.com/21521212/video"
    //               title="Contemplative Reptile"
    //             /> */}
    //             <iframe
    //               id="vzvd-21521212"
    //               name="vzvd-21521212"
    //               title="video player"
    //               class="video-player"
    //               type="text/html"
    //               width="100%"
    //               height="100%"
    //               frameborder="0"
    //               allowFullScreen
    //               allowTransparency="true"
    //               src="https://view.vzaar.com/21521212/player"
    //               allow="autoplay"
    //             />
    //           </Grid>
    //         </Grid>
    //       </li>
    //     </div>
    //   </ol>
    // </div>
  )
}

NovaDelivered.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(NovaDelivered)

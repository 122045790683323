import React, { Component, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, FormControl, MenuItem, Select } from '@material-ui/core'
import {
  IdeasSlider,
  IdeasGrid,
  NxtBenefits,
  NxtExpandedCard,
  NxtReward,
  NxtSmallCard,
  NxtUserCard,
  ShowFilter,
} from 'components'
import { FIRST_LIMIT_CONSTANT, LIMIT_CONSTANT } from 'common_constants/Ideas'
import imgDefault from 'img/nxt-inverted-image.png'
import { arrayToGroups } from 'support/utils/array'
import i18n from 'support/i18n'
import { differenceInCalendarDays } from 'date-fns'
import NxtCircleContent from 'components/nxt_circle_content/View'
import { challengeStatuses } from 'pages/challenge/form/View.Utils'
import styles from './View.Style'

class Challenges extends Component {
  constructor(props) {
    super(props)
    this.state = {
      challengeStatus: 'ACTIVE',
      showSlider: true,
      offset: 0,
    }
    this.handleFilterShow = this.handleFilterShow.bind(this)
    this.renderSliderComponent = this.renderSliderComponent.bind(this)
    this.renderGridComponent = this.renderGridComponent.bind(this)
    this.handleNewChallengeClick = this.handleNewChallengeClick.bind(this)
    this.handleProposeChallengeClick = this.handleProposeChallengeClick.bind(this)
    this.handleOnLoadMore = this.handleOnLoadMore.bind(this)
  }


  componentDidMount() {
    const { getActiveChallenges } = this.props
    // const {offset}= this.state
    // this.setState({offset:0})
    getActiveChallenges()
    const {offset} = this.state
    this.setState({ offset: 0 })
  }


  componentDidUpdate(prevProps) {
    const { getActiveChallenges } = this.props
    
  }

  handleFilterChange = event => {
    const { getFilteredChallenges, } = this.props
   
    this.setState({ offset: 0 })
    const status = event.target.value

    this.setState({ challengeStatus: status })
   
    getFilteredChallenges(status,FIRST_LIMIT_CONSTANT,0)
  }

  handleFilterShow(value) {
    this.setState({ showSlider: value })
  }

  handleOnLoadMore() {
    const {getFilteredChallenges} = this.props
    const { offset,challengeStatus } = this.state
    // console.log('offset',offset)
    const newLimit = offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT
    const newOffset = newLimit + offset
    getFilteredChallenges(challengeStatus,newOffset,LIMIT_CONSTANT)
    this.setState({ offset: newOffset })
  }

  // handleNewChallengeClick() {
  //   const { canProposeChallenge, navigateToNewChallenge, navigateToProposeChallenge } = this.props
  //   if (canProposeChallenge) {
  //     navigateToProposeChallenge()
  //   } else {
  //     navigateToNewChallenge()
  //   }
  // }



    handleNewChallengeClick() {
    const { canProposeChallenge, navigateToNewChallenge, navigateToProposeChallenge } = this.props  
      navigateToNewChallenge()
  }

  handleProposeChallengeClick() {
    const { canProposeChallenge, navigateToNewChallenge, navigateToProposeChallenge } = this.props
      navigateToProposeChallenge()

  }

//offset
handleOnLoadMore() {

  const { getFilteredChallenges, } = this.props

  const { offset,challengeStatus } = this.state
  const newLimit = offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT
  const newOffset = newLimit + offset
  // this.getIdeas(newOffset)
  getFilteredChallenges(challengeStatus,newLimit,newOffset)
  this.setState({ offset: newOffset })

}


  renderListComponent = card => {
    const { approvedMult, implementedMult, supportedMult, rewardText } = card
    return (
      <Fragment>
        <li>
          <NxtBenefits
            approvedMult={approvedMult}
            implementedMult={implementedMult}
            supportedMult={supportedMult}
          />
        </li>
        <li>
          <NxtReward rewardText={rewardText} />
        </li>
      </Fragment>
    )
  }

  renderSliderComponent(array) {
    const {
      classes,
      navigateToChallengeDetail,
      navigateToNewIdea,
      navigateToUserProfile,
    } = this.props
    const dataGrouped = arrayToGroups(array, 3)
    return array.length === 0 ? null : (
      <IdeasSlider>
        {dataGrouped.length > 0 &&
          dataGrouped.map((group, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className={classes.cardGroup} key={`slide-${index}`}>
                {group.map(card => {
                  const {
                    starts,
                    ends,
                    numIdeas,
                    mainPhoto,
                    id,
                    sponsored,
                    proposerName,
                    name,
                    status,
                    proposerEmail,
                  } = card

                  const days = differenceInCalendarDays(Date.now(), starts)
                  const totalDays = differenceInCalendarDays(ends, starts)

                  return (
                    <NxtExpandedCard
                      altTextBlock={`${numIdeas} ${i18n.t('IDEAS')}`}
                      buttonText={
                        <Fragment>
                          <i className="icon-target" />
                          {i18n.t('CHALLENGES.DETAIL.ANSWER_CHALLENGE')}
                        </Fragment>
                      }
                      days={days}
                      defaultSrc={imgDefault}
                      height="35rem"
                      id={id}
                      img={mainPhoto}
                      key={id}
                      onMainButtonClick={navigateToNewIdea}
                      sponsored={sponsored}
                      submittedBy={proposerName}
                      onSubmitterClick={() => navigateToUserProfile(proposerEmail)}
                      title={name}
                      totalDays={totalDays}
                      theme="dark"
                      onGoToButtonClick={navigateToChallengeDetail}
                      circleContent={<NxtCircleContent days={totalDays - days} />}
                      showCircle={status !== challengeStatuses.PROPOSED}
                      circleValue={days}
                      circleMaxValue={totalDays}
                      circleColor="white"
                    >
                      {this.renderListComponent(card)}
                    </NxtExpandedCard>
                  )
                })}
              </div>
            )
          })}
      </IdeasSlider>
    )
  }

  renderGridComponent(array) {
    const {
      classes,
      navigateToChallengeDetail,
      navigateToNewIdea,
      navigateToUserProfile,
    } = this.props
    return array.length === 0 ? null : (
      <IdeasGrid length={array.length} countSquare={false}>
        {array.length > 0 &&
          array.map(card => {
            const { proposerEmail } = card
            return (
              <NxtSmallCard
                altTextBlock={`${card.ideas.length} ${i18n.t('IDEAS')}`}
                mainButtonText={
                  <Fragment>
                    <span className="icon-target" />
                    {i18n.t('CHALLENGES.DETAIL.STEP_UP')}
                  </Fragment>
                }
                days={differenceInCalendarDays(Date.now(), card.starts)}
                defaultSrc={imgDefault}
                description={card.desc}
                height="35rem"
                id={card.id}
                img={card.mainPhoto}
                key={card.id}
                onClickMainButton={navigateToNewIdea}
                onClickSecondaryButton={navigateToChallengeDetail}
                onSubmitterClick={() => navigateToUserProfile(proposerEmail)}
                sponsored={card.sponsored}
                submittedBy={card.proposerName}
                title={card.name}
                totalDays={differenceInCalendarDays(card.ends, card.starts)}
                theme="dark"
              >
                <li>
                  <NxtBenefits
                    approvedMult={card.approvedMult}
                    implementedMult={card.implementedMult}
                    showBottomLabel={false}
                    supportedMult={card.supportedMult}
                  />
                </li>
                <li>
                  <div className={classes.userCard}>
                    <NxtUserCard
                      label={i18n.t('CHALLENGES.DETAIL.SPONSOR')}
                      srcImage={card.sponsorPhoto}
                      usernameElement={<span>{card.sponsorName}</span>}
                      isTextInverted
                      imageSize="2.2rem"
                    />
                  </div>
                </li>
              </NxtSmallCard>
            )
          })}
      </IdeasGrid>
    )
  }

  render() {
    const { classes, challenges, isAdmin, canProposeChallenge, isInnovator, localAdmin,hasMoreChallengeLoad } = this.props
    const { challengeStatus, showSlider,offset } = this.state
    // console.log('ch old', challenges,offset)
    return (
      <Fragment>
        <div className={classes.top}>
          <h1>
            <i className="icon-target" /> {i18n.t('CHALLENGES.CHALLENGES')}
          </h1>
          <div className={classes.headerContainer}>
            <div>
              {
              
              // (isAdmin || isInnovator || localAdmin || canProposeChallenge) && (
              //   <Button variant="contained" color="primary" onClick={this.handleNewChallengeClick}>
              //     {canProposeChallenge
              //       ? i18n.t('CHALLENGES.PROPOSE')
              //       : i18n.t('CHALLENGES.CREATE_CHALLENGE')}
              //   </Button>
              // )
              
              
              
              (isAdmin || isInnovator || localAdmin ) ? (
                <Button variant="contained" color="primary" onClick={this.handleNewChallengeClick}>
                  {i18n.t('CHALLENGES.CREATE_CHALLENGE')}
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={this.handleProposeChallengeClick}>
                  {i18n.t('CHALLENGES.PROPOSE')}
                </Button>
              )

              }
            </div>

            <div className={classes.filterContainer}>
              <span>{i18n.t('CHALLENGES.FILTER.SHOW')}: </span>
              <FormControl variant="outlined" classes={{ root: classes.formControl }}>
                <Select value={challengeStatus} onChange={this.handleFilterChange}>
                  <MenuItem value="ACTIVE">{i18n.t('CHALLENGES.FILTER.ACTIVE')}</MenuItem>
                  {(isAdmin || isInnovator || localAdmin) && [
                    <MenuItem key="PROPOSED" value="PROPOSED">
                      {i18n.t('CHALLENGES.FILTER.PROPOSED')}
                    </MenuItem>,
                    <MenuItem key="PLANNED" value="PLANNED">
                      {i18n.t('CHALLENGES.FILTER.COMING_SOON')}
                    </MenuItem>,
                    <MenuItem key="DELETED" value="DELETED">
                      {i18n.t('CHALLENGES.FILTER.DELETED')}
                    </MenuItem>,
                  ]}
                  <MenuItem value="FINISHED">{i18n.t('CHALLENGES.FILTER.FINISHED')}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className={classes.middle}>
          <div className={classes.center}>
            <ShowFilter
              cards={challenges}
              showSlider={showSlider}
              onClick={this.handleFilterShow}
              slideComponent={this.renderSliderComponent(challenges)}
              gridComponent={this.renderGridComponent(challenges)}
              onCardTypeChange={this.handleFilterShow}
              onLoadMore={this.handleOnLoadMore}
              offset={offset}
              showLoadMoreButton={hasMoreChallengeLoad}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

Challenges.propTypes = {
  classes: PropTypes.shape({
    cardGroup: PropTypes.string,
    userCard: PropTypes.string,
    top: PropTypes.string,
    middle: PropTypes.string,
  }).isRequired,
  challenges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getActiveChallenges: PropTypes.func.isRequired,
  navigateToChallengeDetail: PropTypes.func.isRequired,
  navigateToProposeChallenge: PropTypes.func.isRequired,
  navigateToNewChallenge: PropTypes.func.isRequired,
  navigateToUserProfile: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isInnovator: PropTypes.bool.isRequired,
  localAdmin: PropTypes.bool.isRequired,
  canProposeChallenge: PropTypes.bool.isRequired,
}

export default withStyles(styles)(Challenges)

const styles = theme => ({
  root: {
    height: '90px',
    width: '310px',
    '& > img': {
      width: '90px',
      objectFit: 'cover',
    },
    display: 'flex',
  },
  compactIdeaDetail: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1.5),
    width: '212px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '35%',
  },
  ideaTitle: {
    textAlign: 'left',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1rem',
    fontWeight: 700,
  },
  ideaFinance: {
    display: 'flex',
    alignItems: 'center',
    height: '65%',
  },
  pledgedFinance: {
    width: '60%',
    borderRight: '1px solid #dad7d7',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > span:first-child': {
      fontSize: '1rem',
      fontWeight: 700,
      textAlign: 'left',
    },
    '& > span:last-child': {
      fontSize: '0.75rem',
    },
  },
  investorsFinance: {
    paddingLeft: theme.spacing(1),
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > span:first-child': {
      fontSize: '1rem',
      fontWeight: 700,
      textAlign: 'left',
    },
    '& > span:last-child': {
      fontSize: '0.75rem',
    },
  },
})

export default styles

import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    amount: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    days: {
      margin: 0,
    },
    descriptionBlock: {
      padding: '0 1.25rem 1.25rem 1.25rem',
      minHeight: '9.5rem',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
    },
    descriptionBody: {},
    descriptionButton: {
      width: '100%',
      marginTop: '3%',
    },
    descriptionCard: {
      width: '47%',
      backgroundColor: '#333',
      color: theme.palette.common.white,
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
    },
    descriptionEllipsis: {
      flex: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginTop: 0,
    },
    descriptionEllipsisParent: {
      display: 'flex',
      alignItems: 'center',
    },
    dialGraph: {
      width: '20%',
      height: 127,
    },
    dialGraphCircle: {
      width: '96px',
      height: '96px',
      display: 'inline-block',
      position: 'relative',
      top: '7px',
      left: '-24px',
      background: '#333',
      borderRadius: '100%',
      padding: '12px',
    },
    dialGraphText: {
      position: 'relative',
      top: '-102px',
      left: '-12px',
      marginBottom: '0',
      width: '96px',
      height: '96px',
      lineHeight: '96px',
      textAlign: 'center',
      fontSize: '12px',
    },
    graph: {
      position: 'absolute',
      top: '7px',
      left: '-24px',
    },
    graphBlock: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
    },
    ideasAvatar: {
      listStyle: 'none',
      padding: '0',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      margin: 0,
      '& > li': {
        padding: '2%',
        height: '30px',
        width: '30px',
      },
      '& img': {
        width: 30,
        height: 30,
        borderRadius: '100%',
      },
    },
    numIdeas: {
      padding: '1.25rem',
      boxSizing: 'border-box',
      width: '100%',
      '& > p': {
        textAlign: 'right',
        padding: '0 2%',
      },
    },
    sliderCard: {
      width: '53%',
      '& > img': {
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        height: '310px',
        objectFit: 'cover',
      },
    },
    sliderContent: {
      display: 'flex',
      flexDirection: 'row',
      maxHeight: '310px',
    },
    overlay: {
      ...theme.flexRowCenter,
      justifyContent: 'space-between',
      padding: theme.spacing(0, 3),
      position: 'relative',
      bottom: '80px',
      height: '80px',
      backgroundColor: 'rgba(51, 51, 51, 0.65)',
      '& div:first-child': {
        flex: 1,
      },
      '& div:last-child': {
        flex: 1,
      },
      '& span': {
        color: theme.palette.common.white,
        display: 'block',
        fontSize: '14px',
      },
      '& span:last-child': {
        fontWeight: 'bold',
      },
    },
  })

export default styles

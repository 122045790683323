export const APPLY_FILTER_IDEAS_FAILURE = 'APPLY_FILTER_IDEAS_FAILURE'
export const APPLY_FILTER_IDEAS_STARTED = 'APPLY_FILTER_IDEAS_STARTED'
export const APPLY_FILTER_IDEAS_SUCCESS = 'APPLY_FILTER_IDEAS_SUCCESS'

export const FIRST_SEARCH_APPLY_FILTER_STARTED = 'FIRST_SEARCH_APPLY_FILTER_STARTED'
export const FIRST_SEARCH_APPLY_FILTER_SUCCESS = 'FIRST_SEARCH_APPLY_FILTER_SUCCESS'
export const FIRST_SEARCH_APPLY_FILTER_FAILURE = 'FIRST_SEARCH_APPLY_FILTER_FAILURE'

export const APPLY_SEARCH_IDEAS_FAILURE = 'APPLY_SEARCH_IDEAS_FAILURE'
export const APPLY_SEARCH_IDEAS_STARTED = 'APPLY_SEARCH_IDEAS_STARTED'
export const APPLY_SEARCH_IDEAS_SUCCESS = 'APPLY_SEARCH_IDEAS_SUCCESS'

export const IDEA_ME_STARTED = 'IDEA_ME_STARTED'
export const IDEA_ME_SUCCESS = 'IDEA_ME_SUCCESS'
export const IDEA_ME_FAILURE = 'IDEA_ME_FAILURE'

export const DELETE_IDEA_BY_ID_STARTED = 'DELETE_IDEA_BY_ID_STARTED'
export const DELETE_IDEA_BY_ID_SUCCESS = 'DELETE_IDEA_BY_ID_SUCCESS'
export const DELETE_IDEA_BY_ID_FAILURE = 'DELETE_IDEA_BY_ID_FAILURE'

export const DISCARD_IDEA_BY_ID_STARTED = 'DISCARD_IDEA_BY_ID_STARTED'
export const DISCARD_IDEA_BY_ID_SUCCESS = 'DISCARD_IDEA_BY_ID_SUCCESS'
export const DISCARD_IDEA_BY_ID_FAILURE = 'DISCARD_IDEA_BY_ID_FAILURE'

export const FEEDBACK_IDEA_BY_ID_STARTED = 'FEEDBACK_IDEA_BY_ID_STARTED'
export const FEEDBACK_IDEA_BY_ID_SUCCESS = 'FEEDBACK_IDEA_BY_ID_SUCCESS'
export const FEEDBACK_IDEA_BY_ID_FAILURE = 'FEEDBACK_IDEA_BY_ID_FAILURE'

export const APPROVE_IDEA_BY_ID_STARTED = 'APPROVE_IDEA_BY_ID_STARTED'
export const APPROVE_IDEA_BY_ID_SUCCESS = 'APPROVE_IDEA_BY_ID_SUCCESS'
export const APPROVE_IDEA_BY_ID_FAILURE = 'APPROVE_IDEA_BY_ID_FAILURE'

export const REQUEST_JOIN_IDEA_STARTED = 'REQUEST_JOIN_IDEA_STARTED'
export const REQUEST_JOIN_IDEA_SUCCESS = 'REQUEST_JOIN_IDEA_SUCCESS'
export const REQUEST_JOIN_IDEA_FAILURE = 'REQUEST_JOIN_IDEA_FAILURE'

export const GET_PENDING_VALIDATION_IDEAS_STARTED = 'GET_PENDING_VALIDATION_IDEAS_STARTED'
export const GET_PENDING_VALIDATION_IDEAS_SUCCESS = 'GET_PENDING_VALIDATION_IDEAS_SUCCESS'
export const GET_PENDING_VALIDATION_IDEAS_FAILURE = 'GET_PENDING_VALIDATION_IDEAS_FAILURE'

export const GET_MY_IDEAS_TO_INVEST_IN_STARTED = 'GET_MY_IDEAS_TO_INVEST_IN_STARTED'
export const GET_MY_IDEAS_TO_INVEST_IN_SUCCESS = 'GET_MY_IDEAS_TO_INVEST_IN_SUCCESS'
export const GET_MY_IDEAS_TO_INVEST_IN_FAILURE = 'GET_MY_IDEAS_TO_INVEST_IN_FAILURE'

export const GET_PRIVATE_IDEAS_STARTED = 'GET_PRIVATE_IDEAS_STARTED'
export const GET_PRIVATE_IDEAS_SUCCESS = 'GET_PRIVATE_IDEAS_SUCCESS'
export const GET_PRIVATE_IDEAS_FAILURE = 'GET_PRIVATE_IDEAS_FAILURE'



export const GET_EXPIRING_IDEAS_STARTED = 'GET_EXPIRING_IDEAS_STARTED'
export const GET_EXPIRING_IDEAS_SUCCESS = 'GET_EXPIRING_IDEAS_SUCCESS'
export const GET_EXPIRING_IDEAS_FAILURE = 'GET_EXPIRING_IDEAS_FAILURE'

export const GET_CONFIRMATION_CHALLENGE_VIDEO_STARTED = 'GET_CONFIRMATION_CHALLENGE_VIDEO_STARTED'
export const GET_CONFIRMATION_CHALLENGE_VIDEO_SUCCESS = 'GET_CONFIRMATION_CHALLENGE_VIDEO_SUCCESS'
export const GET_CONFIRMATION_CHALLENGE_VIDEO_FAILURE = 'GET_CONFIRMATION_CHALLENGE_VIDEO_FAILURE'

export const GET_CONFIRMATION_IDEA_VIDEO_STARTED = 'GET_CONFIRMATION_IDEA_VIDEO_STARTED'
export const GET_CONFIRMATION_IDEA_VIDEO_SUCCESS = 'GET_CONFIRMATION_IDEA_VIDEO_SUCCESS'
export const GET_CONFIRMATION_IDEA_VIDEO_FAILURE = 'GET_CONFIRMATION_IDEA_VIDEO_FAILURE'

export const POST_UPLOAD_IDEA_VIDEO_STARTED = 'POST_UPLOAD_IDEA_VIDEO_STARTED'
export const POST_UPLOAD_IDEA_VIDEO_SUCCESS = 'POST_UPLOAD_IDEA_VIDEO_SUCCESS'
export const POST_UPLOAD_IDEA_VIDEO_FAILURE = 'POST_UPLOAD_IDEA_VIDEO_FAILURE'

export const POST_UPLOAD_CHALLENGE_VIDEO_STARTED = 'POST_UPLOAD_CHALLENGE_VIDEO_STARTED'
export const POST_UPLOAD_CHALLENGE_VIDEO_SUCCESS = 'POST_UPLOAD_CHALLENGE_VIDEO_SUCCESS'
export const POST_UPLOAD_CHALLENGE_VIDEO_FAILURE = 'POST_UPLOAD_CHALLENGE_VIDEO_FAILURE'

export const DELETE_IDEA_VIDEO_STARTED = 'DELETE_IDEA_VIDEO_STARTED'
export const DELETE_IDEA_VIDEO_SUCCESS = 'DELETE_IDEA_VIDEO_SUCCESS'
export const DELETE_IDEA_VIDEO_FAILURE = 'DELETE_IDEA_VIDEO_FAILURE'

export const DELETE_CHALLENGE_VIDEO_STARTED = 'DELETE_CHALLENGE_VIDEO_STARTED'
export const DELETE_CHALLENGE_VIDEO_SUCCESS = 'DELETE_CHALLENGE_VIDEO_SUCCESS'
export const DELETE_CHALLENGE_VIDEO_FAILURE = 'DELETE_CHALLENGE_VIDEO_FAILURE'

const styles = theme => ({
  root: {
    margin: theme.spacing(2, 0, 4, 0),
  },
  mainTitle: {
    margin: theme.spacing(5 / 4, 0),
  },
  rewardsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(4),
  },
  inputLabel: {
    flex: 1,
    textAlign: 'right',
    paddingTop: theme.spacing(2),
  },
  imageLabel: {
    flex: 1,
    textAlign: 'right',
    paddingTop: theme.spacing(1),
  },
  inputContainer: {
    flex: 3,
    marginLeft: theme.spacing(4),
  },
  error: {
    color: theme.palette.error.main,
  },
})

export default styles

export const parseOrganizations = organizations => {
  if (!organizations) return []
  organizations.map(organization => {
    return {
      desc: organization.desc,
      name: organization.name,
      id: `${organization.id || ''}`,
      folder: organization.folder,
      order: organization.order,
    }
  })

  const clean = organizations.filter(
    (arr, index, self) => index === self.findIndex(t => t.folder === arr.folder),
  )

  //return clean
  return organizations
}

export const reduceIntoFolders = organizations => {
  return organizations.reduce((accumulator, nextItem) => {
    if (nextItem.folder) {
      const found = accumulator.find(item => item.name === nextItem.folder)
      if (found) {
        found.organizations.push({ name: nextItem.name, id: nextItem.id })
      } else {
        accumulator.push({
          name: nextItem.folder,
          organizations: [{ name: nextItem.name, id: nextItem.id }],
        })
      }
    } else {
      accumulator.push({ name: nextItem.name, id: nextItem.id })
    }
    return accumulator
  }, [])
}

export const transformOrganizationServiceResponseDataWithState = jsonResponse => {
  const parsedOrganizations = parseOrganizations(jsonResponse)
  return {
    organizations: parsedOrganizations,
    folderOrganizations: reduceIntoFolders(parsedOrganizations),
  }
}

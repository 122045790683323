import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import styles from './View.Style'

import CustomButton from '../customButton/View' 


const ControlButtons = props => {
  const { classes, buttons } = props
  // console.log('allbutton',buttons)
  return (
    
    <div className={classes.container}>
      { buttons.map(button => (

        // <CustomButton  key={button.text} text={button.text} action={button.action} bgColor={button.isNotPrimary ? '#F63F40' : 'primary'} />

        <button
          style={{backgroundColor: button.isNotPrimary ? '#F63F40' : 'primary'}}
          key={button.text}
          // color={button.isNotPrimary ? 'secondary' : 'primary'}
          className={classes.button}
          onClick={button.action}
        >
          {button.text}
        </button>
      ))}
    </div>
  )
}

ControlButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node.isRequired,
      action: PropTypes.func.isRequired,
      isNotPrimary: PropTypes.bool,
    }),
  ).isRequired,
}

export default withStyles(styles)(ControlButtons)

import React from "react";
import withStyles from '@material-ui/core/styles/withStyles'
import * as PropTypes from 'prop-types'
import styles from './View.Style'

const Button = props => {
    const {
        classes,
        text,
        action,
        bgColor,
        disabled,
        type
    } = props
    // console.log("action button",action)


    return(
        <button 
            type={type ? type : 'button'}
            style={{
                backgroundColor: bgColor ==='transparent' ? 'transparent' : bgColor , 
                color: bgColor === 'transparent' ? '#6D45FF' : '#fff',
                border: bgColor === 'transparent' ? '1px solid #6D45FF' : null
            }} 
            disabled={disabled ? disabled : false}
            onClick={action} 
            className={classes.container}>
            {text}
        </button>
    )
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    bgColor: PropTypes.string,
}

Button.defaultProps = {
    bgColor: '#6D45FF'
}

export default withStyles(styles)(Button)
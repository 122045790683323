import { HIDE_DIALOG, HIDE_SNACKBAR, SHOW_DIALOG, SHOW_SNACKBAR } from './ModalActionConstants'

export const showDialog = () => ({
  type: SHOW_DIALOG,
})

export const hideDialog = () => ({
  type: HIDE_DIALOG,
})

export const showSnackbar = () => ({
  type: SHOW_SNACKBAR,
})

export const hideSnackbar = () => ({
  type: HIDE_SNACKBAR,
})

const styles = theme => ({
  videoFormContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainButtons: {
    display: 'flex',
    width: 700,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    paddingTop: theme.spacing(2),
    '& > button': {
      width: '30%',
      height: 36,
      marginRight: theme.spacing(2),
    },
  },
  buttonsWrapper: {
    marginTop: theme.spacing(2),
    '& > button': {
      marginRight: theme.spacing(2),
    },
  },
  iframe: {
    margin: theme.spacing(3, 0),
  },
  iframeVideo:{
    height:'auto',
    minHeight:'50vh',
    margin:'10px'
  },
  video: {
    margin: theme.spacing(3, 0),
    maxWidth: 560,
    maxHeight: 400,
  },
  spinner: {
    marginRight: theme.spacing(1.5),
  },
})

export default styles

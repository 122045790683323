import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { makeStyles } from '@material-ui/core/styles'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Persons from '../../img/person.svg'
import People from '../../img/people.svg'
import NovaLogo from '../../img/novalogo.png'
import Transparent from '../../img/transparent.jpg'
import Workplace from '../../img/workplace.png'
import styles from './View.Style'
import "./slides.css";
import { color } from 'highcharts'

const useStyles = makeStyles(theme => ({
  background: {
    height: '95vh',
    alignItems: 'center',
  },
  img: {
    position: 'relative',
    width: '100%',
    opacity: '0.1',
  },
  contentChild: {
    position: 'absolute',
    width: '70%',
    top: '35%',
    left: '17%',
    zIndex: 1000,
    color: 'white',
    textAlign: 'center',
    '&:focus': {
      outline: 0,
    },
  },
}))

function NovaHomeSlides(props) {
  /*
  var items = [
    {
      name: '',
      description: '',
      image: Persons,
      logo: NovaLogo,
    },
    {
      name: 'Who are we?',
      description:
        'Nova is an innovation management ecosystem that enables and empowers you to find funding, talent and leadership support to turn your ideas into successful realities.',
      image: People,
      logo: Transparent,
    },
  ]
  */
  var items = [
    {
      name: 'Who are we?',
      description:
        'Nova is an innovation management ecosystem that enables and empowers you to find funding, talent and leadership support to turn your ideas into successful realities.',
      image: People,
      logo: NovaLogo,
    },
  ]

  return (
    //<Carousel>
    //  {items.map((item, i) => (
    //    <Item key={i} item={item} />
    //  ))}
    //</Carousel>
    <div className='slidesContainer'>
      <div className='slidesContents'>
        <img src={NovaLogo} className="slidesNovaImage" />
        <div className='slidesTitle'>
          Who are we?
        </div>
        <div className='slidesDescription'>
          Nova is an innovation management ecosystem that enables and empowers you to find funding, talent and leadership support to turn your ideas into successful realities.
        </div>
        <div className='slidesWorkplaceDiv'>
          <div className='slidesWorkplaceInvitation'>To find out more follow the link below to our Workplace page!!!!!!!!!!!!!!</div>
          <a className='slidesWorkplaceNovaLogo' href="https://vodafone.workplace.com/groups/novainnovation">
            <img src={Workplace} className='slidesWorkplaceImage' />
            <div className='slidesWorkplaceNova'>Nova on Workplace</div>
          </a>
        </div>

      </div>
      <img src={People} className='slidesbg' />
    </div>
  )
}

function Item(props) {
  const classes = useStyles()
  return (
    <div className={classes.background}>
      <img className={classes.img} src={props.item.image} width="100%" height="97%" alt="NOVA"/>
      <div className={classes.contentChild}>
        <Typography variant="h2" gutterBottom>
          {props.item.name}
        </Typography>

        <Typography variant="h5" gutterBottom>
          {props.item.description}
        </Typography>
        <img src={props.item.logo} width="90%" height="30%" alt="NOVA"/>
      </div>
    </div>
  )
}

export default withStyles(styles)(NovaHomeSlides)

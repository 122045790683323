import { createStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    container: {
      padding: '3rem',
    },
  })

export default styles

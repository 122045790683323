import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import clsx from 'clsx'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import styles from './View.Style'

const NxtCardListItems = props => {
  const {
    classes,
    direction,
    primaryText,
    primaryValue,
    secondaryText,
    secondaryValue,
    variant,
  } = props
  return (
    <li
      className={clsx(
        classes.listItem,
        variant === 'black' && classes.colorBlack,
        variant === 'primary' && classes.colorPrimary,
      )}
    >
      {primaryValue !== undefined && (
        <div
          className={clsx(
            classes[direction],
            classes.primaryText,
            secondaryValue && classes.borderRight,
          )}
        >
          <div>
            <NxtVirtualCurrency>{primaryValue}</NxtVirtualCurrency>
          </div>
          <span>{primaryText}</span>
        </div>
      )}
      {secondaryValue && (
        <div className={classes.secondaryText}>
          <span>{secondaryValue}</span>
          <span>{secondaryText}</span>
        </div>
      )}
    </li>
  )
}

NxtCardListItems.defaultProps = {
  direction: 'column',
  primaryText: i18n.t('PLEDGED'),
  secondaryText: i18n.t('INVESTORS'),
  variant: 'black',
}

NxtCardListItems.propTypes = {
  classes: PropTypes.shape({
    borderRight: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
  }).isRequired,
  direction: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  variant: PropTypes.oneOf(['black', 'primary']),
}

export default withStyles(styles)(NxtCardListItems)

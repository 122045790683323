import React from 'react'
import { connect } from 'react-redux'
import { postLaunchChallenge } from 'services/challenge_steps'
import AlertModalView from 'support/popup_dialogs/views/components/alert_modal_view/AlertModalView'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import { showPopupDialog } from 'support/popup_dialogs'
import i18n from 'support/i18n'
import ErrorSubmitModal from 'pages/idea/form/components/dialogs/ErrorSubmitContainer'
import SuccessSubmitModal from './SuccessSubmitContainer'

const mapStateToProps = () => ({
  title: i18n.t('CONFIRMATION_MESSAGE'),
  description: i18n.t('CHALLENGES.ASK_ACTIVE_AFTER_LAUNCH'),
  mainButtonText: i18n.t('ACCEPT'),
  cancelable: true,
  cancelButtonText: i18n.t('CANCEL'),
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    mainButtonAction: () => {
      const onSuccess = () => {
        showPopupDialog(
          <SuccessSubmitModal shouldDeleteDraft={ownProps.shouldDeleteDraft} />,
          dispatch,
        )
      }
      const onFailure = ({ data }) => {
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        showPopupDialog(<ErrorSubmitModal description={i18n.t(messageTag)} />, dispatch)
      }
      dispatch(postLaunchChallenge(onSuccess, onFailure))
      dispatch(hideDialog())
    },
    cancelButtonAction: () => dispatch(hideDialog()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertModalView)

import React from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const FlipCard = props => {
  const { classes, children, side, theme } = props
  return <div className={clsx(classes.card, classes[side], classes[theme])}>{children}</div>
}

FlipCard.defaultProps = {
  theme: 'light',
}

FlipCard.propTypes = {
  classes: PropTypes.shape({
    back: PropTypes.string.isRequired,
    card: PropTypes.string.isRequired,
    front: PropTypes.string.isRequired,
  }).isRequired,
  side: PropTypes.oneOf(['front', 'back']).isRequired,
  theme: PropTypes.oneOf(['dark', 'light']),
}

export default withStyles(styles)(FlipCard)

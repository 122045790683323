const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > div:nth-child(n+2)': {
      marginTop: theme.spacing(4),
    },
  },
  line: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  inputLabel: {
    flex: 1,
    textAlign: 'right',
    paddingTop: theme.spacing(2),
  },
  inputContainer: {
    flex: 3,
    marginLeft: theme.spacing(4),
  },
  whyImportantMessage: {
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(1.75),
    color: '#595959',
  },
  error: {
    color: theme.palette.error.main,
  },
})

export default styles

const styles = theme => ({
  container: {
    '& button': {
      color: theme.palette.primary.main,
    },
  },
  marginLeft: {},
  radioLabelWithLink: {
    fontSize: '13px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  radioLabel: {
    fontSize: '15px',
  },
})

export default styles

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: '13px',
    fontWeight: '400',
  },
  modifiedWrapper: {
    marginLeft: theme.spacing(2),
  },
  modifiedLabel: {
    color: '#D28445',
    fontWeight: 700,
  },
})

export default styles

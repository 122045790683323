const styles = theme => ({
  root: {
    '& > :first-child': {
      padding: theme.spacing(2, 0),
    },
  },
  viewAsContainer: {
    '& > span': {
      fontSize: '0.9rem',
      marginRight: theme.spacing(),
    },
  },
  bottomTableTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  hide: {
    display: 'none',
  },
})

export default styles

const styles = theme => ({
  root: {
    width: theme.spacing(64),
    height: 'auto',
    padding: theme.spacing(3, 3),
    position: 'relative',
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  imageSection: {
    width: '100%',
    position: 'relative',
    marginTop: theme.spacing(1),
    height: '60vh',
  },
  colorSlider: {
    color: 'black',
  },
  buttonsSection: {
    bottom: 0,
    padding: theme.spacing(2, 2, 0, 2),
  },
  buttonTitle: {
    flex: 1,
  },
})

export default styles

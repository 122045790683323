import { ServiceCall } from 'support/network/ServiceCallAction'
import * as types from 'actions/sync/dashboard/Constants'
import { USERS_LIMIT_CONSTANT } from 'common_constants/Dashboard'
import {
  transformActivityTable,
  transformIdeasTable,
  transformPeopleTable,
  transformPrivateIdeasTable,
  transformSystemAccessesResponse,
  transformIdeasSeriesResponse,
  transformRequestReportResponse,
  transformUsersTableResponse,
  transformUsersParticipationResponse,
} from './ResponseUtils'

const GET_SYSTEM_DAILY_URL =
  '/s/v1.0/dashboard/report/system_daily?dateStart=##dateStart##&dateEnd=##dateEnd##'
const REQUEST_REPORT_URL = '/s/report/##type##/csv'
const GET_SYSTEM_ACCESSES_URL = '/s/v1.0/dashboard/report/system-access?from=##fromDate##'
const GET_IDEAS_SERIES_URL = '/s/v1.0/dashboard/report/ideas-series?from=##fromDate##'
const GET_PRIVATE_IDEAS_SERIES_URL =
  '/s/v1.0/dashboard/report/private-ideas-series?from=##fromDate##'
const GET_USERS_TABLE_URL = '/s/user/filter?l=##limit##&o=##offset##&filter=##filter##'
const GET_USERS_BY_NAME_TABLE_URL = '/s/user/search-user-stats?subject=##name##&filter=##filter##'
const GET_USERS_PARTICIPATION_URL = '/s/user/participation-success?o=##offset##&l=##limit##'
const GET_REPORT_USER_CSV_URL = '/s/report/user/all/csv'

function getSystemDaily({
  fromDate,
  toDate,
  onSuccess,
  onFailure,
  transformFunction,
  startAction,
  successAction,
  failureAction,
}) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = startAction
  apiCall.successReqType = successAction
  apiCall.failureReqType = failureAction
  apiCall.setEndpointFromState = () =>
    GET_SYSTEM_DAILY_URL.replace('##dateStart##', fromDate.getTime()).replace(
      '##dateEnd##',
      toDate.getTime(),
    )
  apiCall.method = 'GET'
  apiCall.setBodyFromState = () => {}
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = jsonResponse =>
    transformFunction(jsonResponse, fromDate, toDate)
  apiCall.auth = true
  return apiCall.getAction()
}

export function getActivityTable(fromDate, toDate, onSuccess, onFailure) {
  return getSystemDaily({
    fromDate,
    toDate,
    onSuccess,
    onFailure,
    transformFunction: transformActivityTable,
    startAction: types.GET_ACTIVITY_TABLE_STARTED,
    successAction: types.GET_ACTIVITY_TABLE_SUCCESS,
    failureAction: types.GET_ACTIVITY_TABLE_FAILURE,
  })
}

export function getIdeasTable(fromDate, toDate, onSuccess, onFailure) {
  return getSystemDaily({
    fromDate,
    toDate,
    onSuccess,
    onFailure,
    transformFunction: transformIdeasTable,
    startAction: types.GET_IDEAS_TABLE_STARTED,
    successAction: types.GET_IDEAS_TABLE_SUCCESS,
    failureAction: types.GET_IDEAS_TABLE_FAILURE,
  })
}

export function getPeopleTable(fromDate, toDate, onSuccess, onFailure) {
  return getSystemDaily({
    fromDate,
    toDate,
    onSuccess,
    onFailure,
    transformFunction: transformPeopleTable,
    startAction: types.GET_PEOPLE_TABLE_STARTED,
    successAction: types.GET_PEOPLE_TABLE_SUCCESS,
    failureAction: types.GET_PEOPLE_TABLE_FAILURE,
  })
}

export function getPrivateIdeasTable(fromDate, toDate, onSuccess, onFailure) {
  return getSystemDaily({
    fromDate,
    toDate,
    onSuccess,
    onFailure,
    transformFunction: transformPrivateIdeasTable,
    startAction: types.GET_PRIVATE_IDEAS_TABLE_STARTED,
    successAction: types.GET_PRIVATE_IDEAS_TABLE_SUCCESS,
    failureAction: types.GET_PRIVATE_IDEAS_TABLE_FAILURE,
  })
}

export function getSystemAccesses(fromDate) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_SYSTEM_ACCESSES_STARTED
  apiCall.successReqType = types.GET_SYSTEM_ACCESSES_SUCCESS
  apiCall.failureReqType = types.GET_SYSTEM_ACCESSES_FAILURE
  apiCall.setEndpointFromState = () =>
    GET_SYSTEM_ACCESSES_URL.replace('##fromDate##', fromDate.getTime())
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = jsonResponse =>
    transformSystemAccessesResponse(jsonResponse)
  apiCall.auth = true
  return apiCall.getAction()
}

export function requestDashboardReport(type, onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.REQUEST_DASHBOARD_REPORT_STARTED
  apiCall.successReqType = types.REQUEST_DASHBOARD_REPORT_SUCCESS
  apiCall.failureReqType = types.REQUEST_DASHBOARD_REPORT_FAILURE
  apiCall.setEndpointFromState = () => REQUEST_REPORT_URL.replace('##type##', type)
  apiCall.method = 'GET'
  apiCall.onSuccess = onSuccess
  apiCall.transformResponseDataWithState = transformRequestReportResponse
  apiCall.auth = true
  return apiCall.getAction()
}

export function getIdeasSeries(fromDate) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_IDEAS_SERIES_STARTED
  apiCall.successReqType = types.GET_IDEAS_SERIES_SUCCESS
  apiCall.failureReqType = types.GET_IDEAS_SERIES_FAILURE
  apiCall.setEndpointFromState = () =>
    GET_IDEAS_SERIES_URL.replace('##fromDate##', fromDate.getTime())
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = transformIdeasSeriesResponse
  apiCall.auth = true
  return apiCall.getAction()
}

export function getPrivateIdeasSeries(fromDate) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_PRIVATE_IDEAS_SERIES_STARTED
  apiCall.successReqType = types.GET_PRIVATE_IDEAS_SERIES_SUCCESS
  apiCall.failureReqType = types.GET_PRIVATE_IDEAS_SERIES_FAILURE
  apiCall.setEndpointFromState = () =>
    GET_PRIVATE_IDEAS_SERIES_URL.replace('##fromDate##', fromDate.getTime())
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = transformIdeasSeriesResponse
  apiCall.auth = true
  return apiCall.getAction()
}

export function getUsers({ name, page, filterType, rowsPerPage }) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_USERS_TABLE_STARTED
  apiCall.successReqType = types.GET_USERS_TABLE_SUCCESS
  apiCall.failureReqType = types.GET_USERS_TABLE_FAILURE
  apiCall.setEndpointFromState = () =>
    (name ? GET_USERS_BY_NAME_TABLE_URL : GET_USERS_TABLE_URL)
      .replace('##limit##', rowsPerPage)
      .replace('##name##', name)
      .replace('##offset##', `${page * rowsPerPage || 0}`)
      .replace('##filter##', filterType)
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = transformUsersTableResponse
  apiCall.auth = true
  return apiCall.getAction()
}

export function getUsersParticipation(offset = 0) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_USERS_PARTICIPATION_STARTED
  apiCall.successReqType = types.GET_USERS_PARTICIPATION_SUCCESS
  apiCall.failureReqType = types.GET_USERS_PARTICIPATION_FAILURE
  apiCall.setEndpointFromState = () =>
    GET_USERS_PARTICIPATION_URL.replace('##offset##', `${offset}`).replace(
      '##limit##',
      `${USERS_LIMIT_CONSTANT}`,
    )
  apiCall.method = 'GET'
  apiCall.transformResponseDataWithState = (jsonResponse, state) =>
    transformUsersParticipationResponse(jsonResponse, state, offset)
  apiCall.auth = true
  return apiCall.getAction()
}

export function sendUsersReport(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.SEND_USERS_REPORT_STARTED
  apiCall.successReqType = types.SEND_USERS_REPORT_SUCCESS
  apiCall.failureReqType = types.SEND_USERS_REPORT_FAILURE
  apiCall.setEndpointFromState = () => GET_REPORT_USER_CSV_URL
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  return apiCall.getAction()
}

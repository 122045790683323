import React from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import withTheme from '@material-ui/core/styles/withTheme'
import withStyles from '@material-ui/core/styles/withStyles'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import styles from './View.Style'

const CircleGraph = props => {
  const {
    classes,
    children,
    className,
    maxValue,
    value,
    pathColor,
    size,
    strokeLinecap,
    theme,
    themeColor,
    trailColor,
  } = props
  return (
    <div
      className={clsx(
        classes.dialGraphCircle,
        themeColor === 'light' && classes.dialGraphCircleLight,
        themeColor === 'dark' && classes.dialGraphCircleDark,
        size === 'small' && classes.sizeSmall,
        size === 'big' && classes.sizeBig,
        className.base,
      )}
      style={{ color: themeColor === 'light' ? 'black' : 'white' }}
    >
      <CircularProgressbarWithChildren
        value={value}
        maxValue={maxValue || 100}
        styles={buildStyles({
          strokeLinecap,
          pathColor: pathColor || theme.palette.primary.main,
          trailColor: themeColor === 'light' ? '#f0f0f0' : trailColor,
        })}
      >
        {children}
      </CircularProgressbarWithChildren>
    </div>
  )
}

CircleGraph.defaultProps = {
  className: {
    base: '',
  },
  value: '0',
  strokeLinecap: 'butt',
  maxValue: '100',
  pathColor: '',
  size: 'small',
  themeColor: 'light',
  trailColor: '#999999',
}

CircleGraph.propTypes = {
  className: PropTypes.shape({
    base: PropTypes.string,
  }),
  classes: PropTypes.shape({
    dialGraphCircle: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pathColor: PropTypes.string,
  size: PropTypes.oneOf(['big', 'small']),
  strokeLinecap: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
  themeColor: PropTypes.oneOf(['light', 'dark']),
  trailColor: PropTypes.string,
}

export default withTheme(withStyles(styles)(CircleGraph))

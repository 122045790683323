import React from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { Chip } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

const ActiveFilters = props => {
  const { activeFilters, classes, showChallenges, globalIdeas } = props
  const { status, challenge, organizations, folderOrganizations, types } = activeFilters
  
  return (
    <div className={classes.container}>
      {status.map(element => (
        <Chip
          className={classes.chips}
          color="primary"
          key={element}
          label={i18n.t(`HOME.FILTER.OPTIONS.${element}`)}
        />
      ))}

      {showChallenges && challenge.id && (
        <Chip className={classes.chips} color="primary" label={challenge.name} />
      )}

      {folderOrganizations &&
        folderOrganizations.map(element => (
          <Chip className={classes.chips} color="primary" key={element.name} label={element.name} />
        ))}

      {types &&
        types.map(element => (
          <Chip className={classes.chips} color="primary" key={element.id} label={element.name} />
        ))}

      {/* {globalIdeas && <Chip className={classes.chips} color="primary" label={'Global Ideas'} />} */}
    </div>
  )
}

ActiveFilters.defaultProps = {
  showChallenges: true,
}

ActiveFilters.propTypes = {
  activeFilters: PropTypes.shape({
    challenge: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    organizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ).isRequired,
    status: PropTypes.arrayOf(PropTypes.string).isRequired,
    types: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    chips: PropTypes.string.isRequired,
  }).isRequired,
  showChallenges: PropTypes.bool,
}

export default withStyles(styles)(ActiveFilters)

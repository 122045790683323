import React from 'react'
import {
  withStyles,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  List,
  Paper,
} from '@material-ui/core'
import * as PropTypes from 'prop-types'
import styles from './View.Style'

const NxtGroupsList = props => {
  const { classes, group, onToggle } = props
  return (
    <Paper className={classes.root} elevation={2}>
      <List dense component="div" role="list" className={classes.groupsList}>
        {group.map(value => {
          const labelId = `transfer-list-item-${value.name}-label`
          return (
            <ListItem key={value.name} role="listitem" button onClick={() => onToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={value.checked}
                  tabIndex={-1}
                  color="primary"
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          )
        })}
      </List>
    </Paper>
  )
}
NxtGroupsList.defaultProps = {
  classes: {
    root: '',
    groupsList: '',
  },
  group: [],
}
NxtGroupsList.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  group: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.string,
      id: PropTypes.string,
      checked: PropTypes.bool,
    }),
  ),
  onToggle: PropTypes.func.isRequired,
}
export default withStyles(styles)(NxtGroupsList)

import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { TableCell, TableRow } from '@material-ui/core'

const TableHeader = props => {
  const { headRows, showFilters } = props
  return (
    <Fragment>
      <TableRow>
        <TableCell />
        {headRows.map(row => {
          const showColumn = showFilters ? row.showIfFilterOpen : true
          return (
            showColumn && (
              <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
                {row.label}
              </TableCell>
            )
          )
        })}
      </TableRow>
    </Fragment>
  )
}

TableHeader.propTypes = {
  headRows: PropTypes.arrayOf(
    PropTypes.shape({
      numeric: PropTypes.bool,
      label: PropTypes.string,
    }),
  ).isRequired,
}

export default TableHeader

const styles = theme => ({
  root: {},
  mainTitle: {
    margin: `${theme.spacing(5 / 4)}px 0`,
  },
  tabsSection: {
    marginTop: theme.spacing(4),
  },
})

export default styles

import React from 'react'
import * as PropTypes from 'prop-types'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import CustomPagination from './components/pagination/View'

const NxtCustomTable = props => {
  const {
    classes,
    renderHeaderComponent,
    renderRowComponent,
    page,
    fullCount,
    rowsPerPage,
    paginated,
    onPaginationChanged,
  } = props
  const headerComponent = renderHeaderComponent()
  const bodyComponent = renderRowComponent()
  const bodyLength = Object.keys(bodyComponent).length
  return (
    <div className={classes.paper}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <TableHead>{headerComponent}</TableHead>
          <TableBody>
            {bodyComponent}
            {bodyLength === 0 && (
              <TableRow className={classes.noRows}>
                <TableCell colSpan={6}> NO ROWS </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {paginated && (
        <CustomPagination
          fullCount={fullCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPaginationChange={onPaginationChanged}
        />
      )}
    </div>
  )
}

NxtCustomTable.defaultProps = {
  fullCount: 0,
  onPaginationChanged: () => {},
  paginated: false,
  renderHeaderComponent: () => {},
  renderRowComponent: () => {},
}

NxtCustomTable.propTypes = {
  renderHeaderComponent: PropTypes.func,
  renderRowComponent: PropTypes.func,
  fullCount: PropTypes.number,
  paginated: PropTypes.bool,
  onPaginationChanged: PropTypes.func,
}

export default withStyles(styles)(NxtCustomTable)

const styles = theme => ({
  root: {
    margin: theme.spacing(),
  },
  mainTitle: {
    margin: `${theme.spacing(5 / 4)}px 0`,
  },
  datesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: theme.spacing(4, 0),
  },
  groupsLimit: {},
  groupsSelected: {
    margin: theme.spacing(5, 0),
  },
  noGroupsSelected: {
    margin: theme.spacing(5, 0),
    backgroundColor: '#d9edf7',
    color: '#3a87ad',
    fontWeight: '700',
    padding: theme.spacing(2),
    fontSize: theme.spacing(1.75),
  },
  challengeType: {
    padding: theme.spacing(2, 3)
  }
})

export default styles

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'baseline',
    margin: theme.spacing(2, 0, 5, 0),
  },
})

export const customSliderStyles = theme => ({
  root: {
    margin: theme.spacing(0, 2),
    color: '#626262',
  },
  mark: {
    backgroundColor: 'transparent',
  },
  markLabel: {
    top: theme.spacing(4.5),
    color: 'black',
    marginLeft: theme.spacing(0.5),
  },
  thumb: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: `calc(-50% + ${theme.spacing(1.4)}px)`,
    top: theme.spacing(-2.5),
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: theme.spacing(2),
    borderRadius: 4,
  },
  rail: {
    height: theme.spacing(2),
    borderRadius: 4,
  },
})

export default styles

export const POSSIBLES_AUTH_STATUSES = {
  FORBIDDEN: 403,
}
export const POSSIBLES_AUTH_ERRORS = {
  USER_ALREADY_EXISTS: 'identity-exist',
  INSTANCE_BLOCK: 'instance-block',
  IDENTITY_NOT_VERIFIED_YET: 'identity-not-verified',
  REPEATED_INVALID_PASSWORD: 'invalid_password_repeated',
}
export const isValidPassword = pwd =>
  RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/g).test(pwd)

export const isValidEmail = email =>
  RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ).test(String(email).toLowerCase())

import { createStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    container: {
      padding: '3rem',
    },
    media: {
      margin: '10px auto 0',
      width: '95%',
      height: 400,
      borderRadius: '4px',
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
      position: 'relative',
      zIndex: 1000,
    },
    root: {
      display: 'flex',
      height: '300px',
      marginBottom: '15px',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      width: '18vw',
      textAlign: 'center',
    },
    cover: {
      width: '20vw',
      height: '80%',
      alignItems: 'flex',
    },
    ideaSub: {
      width: '100%',
      height: '100%',
    },
    ideaRounds: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '10px',
    },
    rounds: {
      display: 'inline',
      color: 'red',
    },
    question: {
      color: 'red',
      fontStyle: '15px',
    },
  })

export default styles

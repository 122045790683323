const styles = theme => ({
  root: {
    width: '700px',
    padding: theme.spacing(1, 3),
    background: 'black',
    color: "#FFFFFF"
    
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      width: '50px',
    },
    '& > h3': {
      marginLeft: theme.spacing(2),
    },
  },
  bodySection: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: '65%',
    },
  },
  actionsSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0),
  },
})

export default styles

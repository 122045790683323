import { createStyles } from '@material-ui/core'

const styles = theme =>
  createStyles({
    container: {
      padding: '1rem', 
    },
    root: {
      display: 'flex',
      height:'60%',
      marginBottom: '15px'
      
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      width: '22vw',
      textAlign: 'center'
    },
    cover: {
      width: '100vw',
    },
  })

export default styles

import { connect } from 'react-redux'
import {
  identityNotVerifiedYet,
  onLogin,
  resetAuthMessageBox,
  setAuthMessageBox,
  showLoadingSpinner,
} from 'actions/sync/auth'
import View from 'pages/login/View'
import {
  loginService,
  postGoogleLoginResult,
  postRecoverPassword,
  postSendVerificationEmail,
} from 'services/auth'
import { getDomainLoginInfo } from 'services/domain'
import Navigation from 'support/navigation'
import { MESSAGE_TYPES } from 'components/auth_message_box/View.Utils'
import i18n from 'support/i18n'
import { POSSIBLES_AUTH_ERRORS, POSSIBLES_AUTH_STATUSES } from 'support/utils/auth'

const mapStateToProps = (state, ownProps) => {
  return {
    useCustomUrlLanding: state.data.domain.loginInfo.useCustomUrlLanding,
    customUrlLanding: state.data.domain.loginInfo.customUrlLanding,
    useCreateAccount: state.data.domain.loginInfo.useCreateAccount,
    useCustomColor: state.data.domain.loginInfo.useCustomColor,
    customColor: state.data.domain.loginInfo.customColor,
    useCustomTextColor: state.data.domain.loginInfo.useCustomTextColor,
    customTextColor: state.data.domain.loginInfo.customTextColor,
    useCustomImg: state.data.domain.loginInfo.useCustomImg,
    customImg: state.data.domain.loginInfo.customImg,
    useCustomText: state.data.domain.loginInfo.useCustomText,
    customText: state.data.domain.loginInfo.customText,
    useCustomBgImg: state.data.domain.loginInfo.useCustomBgImg,
    customBgImg: state.data.domain.loginInfo.customBgImg,
    isLoadedLoginInfo: state.data.domain.isLoadedLoginInfo,
    useCustomLogin: !!ownProps.match.params.domain,
    messageType: state.auth.messageBox.messageType,
    messageText: state.auth.messageBox.messageText,
    isLogging: state.auth.isLogging,
    isEmailNotVerifiedYet: state.auth.isEmailNotVerifiedYet,
    isGoogleAuthEnabled: state.data.domain.loginInfo.accessMedia.Google,
    isAzureAuthEnabled: state.data.domain.loginInfo.accessMedia.Azure,
    isAdfsAuthEnabled: state.data.domain.loginInfo.accessMedia.ADFS,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const onLoginSuccess = () => {
    const { domain } = ownProps.match.params
    onLogin(dispatch, domain)
  }
  return {
    onAzureClick: () => {
      const { domain } = ownProps.match.params
      dispatch(showLoadingSpinner())
      window.location.href = `/oauth?provider=windows.com&namespace=${domain}`
    },
    onAdfsClick: () => {
      const { domain } = ownProps.match.params
      dispatch(showLoadingSpinner())
      window.location.href = `/oauth?provider=login.app.adfs.com&namespace=${domain}`
    },
    resetAuthMessageBox: () => {
      dispatch(resetAuthMessageBox())
    },
    postGoogleLoginResult: code => {
      const onError = () =>
        dispatch(
          setAuthMessageBox(
            MESSAGE_TYPES.ERROR,
            i18n.t('COMMON_LOGIN.HEADER.ACTIVATION_MAIL_SENT_FAILURE'),
          ),
        )
      dispatch(postGoogleLoginResult(code, onLoginSuccess, onError))
    },
    onForgotPasswordClick: email => {
      dispatch(
        setAuthMessageBox(
          MESSAGE_TYPES.SUCCESS,
          i18n.t('COMMON_LOGIN.HEADER.FORGOT_PASSWORD_EMAIL_SENT'),
        ),
      )
      dispatch(postRecoverPassword(email))
    },
    onSubmit: (email, password) => {
      const onFailure = responseError => {
        if (
          responseError.status === POSSIBLES_AUTH_STATUSES.FORBIDDEN &&
          responseError.data.error === POSSIBLES_AUTH_ERRORS.IDENTITY_NOT_VERIFIED_YET
        ) {
          dispatch(identityNotVerifiedYet())
        } else {
          dispatch(setAuthMessageBox(MESSAGE_TYPES.ERROR, i18n.t('COMMON_LOGIN.HEADER.LOGIN_KO')))
        }
      }
      dispatch(loginService(email, password, onLoginSuccess, onFailure))
    },
    onSendMeActivationEmailClick: email => {
      const onSuccess = () => {
        dispatch(
          setAuthMessageBox(
            MESSAGE_TYPES.SUCCESS,
            i18n.t('COMMON_LOGIN.HEADER.ACTIVATION_MAIL_SENT_SUCCESS'),
          ),
        )
      }
      const onError = () => {
        dispatch(
          setAuthMessageBox(
            MESSAGE_TYPES.ERROR,
            i18n.t('COMMON_LOGIN.HEADER.ACTIVATION_MAIL_SENT_FAILURE'),
          ),
        )
      }
      dispatch(postSendVerificationEmail(email, onSuccess, onError))
    },
    onCreateAccountClick: () => {
      const domain = ownProps.match && ownProps.match.params && ownProps.match.params.domain
      Navigation.navigateToSignUp(domain)
    },
    onLoad: () => dispatch(getDomainLoginInfo(ownProps.match.params.domain)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
